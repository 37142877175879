import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const WhatIsIt = () => {
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    const questions = [
        "Do you want to share your ideas with the world?",
        "Are you tired of consuming content and want to be more creative?",
        "Do you want to share your perspective with others?",
        "Do you want to find people that think like you?",
        "Do you want a platform with no likes, no pictures, no videos, no celebrities, just chill vibes and positive peaceful thoughts?",
        "Do you feel like your thoughts and ideas are going to waste?",
        "Do you feel like there's noone who understands you?",
        "Know you fit in somewhere, but not sure where?",
        "Don't know where to find your crowd?",
    ];

    let columns = 1;
    if (isLarge) {
        columns = 3;
    } else if (isMedium) {
        columns = 2;
    }

    const chunkedQuestions = [];
    for (let i = 0; i < questions.length; i += Math.ceil(questions.length / columns)) {
        chunkedQuestions.push(questions.slice(i, i + Math.ceil(questions.length / columns)));
    }

    return (
        <Box p={3}>
            <Grid container spacing={3}>
                {chunkedQuestions.map((chunk, idx) => (
                    <Grid item xs={12} md={6} lg={4} key={idx}>
                        {chunk.map((question, qIdx) => (
                            <Box mb={2} key={qIdx}>
                                <Typography variant="body1">{question}</Typography>
                            </Box>
                        ))}
                    </Grid>
                ))}
            </Grid>
            
            <Box mb={3} mt={5}>
                <Typography variant="h3">
                    Thought Portals is the Answer to These Questions 🧭
                </Typography>
            </Box>

            <Typography variant="body1" paragraph>
                By providing a space where like-minded souls can connect, discuss, and explore, we're weaving a tapestry of understanding that transcends the boundaries of conventional thought. So, whether you're seeking answers or pondering life's mysteries, journey through Thought Portals and discover the endless possibilities that await. 🚀✨ Check out our 'How to use though portals page'
            </Typography>
        </Box>
    );
}

export default WhatIsIt;
