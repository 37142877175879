import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Button, TextField, Avatar, Typography, LinearProgress, CircularProgress, Box, Backdrop, Card, CardContent } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/lab/Alert';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../../firebaseConfig';
import { updateProfile } from 'firebase/auth';
import { doc, setDoc, updateDoc, collection, query, orderBy, onSnapshot, deleteDoc } from 'firebase/firestore';
import checkString from '../../../ContentModeration';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ImageUploader from '../../../ImageUploader';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

function EditProfileBridge({
    setShowEditProfileBridge,
    setShowMyProfileBridge
}) {
  const [displayName, setDisplayName] = useState(FIREBASE_AUTH.currentUser.displayName || '');
  const [photoURL, setPhotoURL] = useState(FIREBASE_AUTH.currentUser.photoURL || '');
  const [originalPhotoURL, setOriginalPhotoURL] = useState(FIREBASE_AUTH.currentUser.photoURL || '');
  const [bio, setBio] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [thoughts, setThoughts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [archivedDialogOpen, setArchivedDialogOpen] = useState(false);
  const [selectedThought, setSelectedThought] = useState(null);
  const profileQuotesColl = collection(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid, "Profile Quotes")

  useEffect(() => {
    if (FIREBASE_AUTH.currentUser) {
      fetchThoughts();
    }
  }, [FIREBASE_AUTH.currentUser]);

  const handleEditThought = (thoughtId) => {
    // Set the selected thought and open the appropriate dialog
    const thought = thoughts.find(thought => thought.id === thoughtId);
    setSelectedThought(thoughtId);
    if (thought.isArchived) {
      setArchivedDialogOpen(true);
    } else {
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    // Close the dialog and reset the selected thought
    setDialogOpen(false);
    setSelectedThought(null);
  };

  const handleArchive = () => {
    (async () => {
      await updateDoc(doc(profileQuotesColl, selectedThought), {
        isArchived: true
      });
    })();
    
    // Here you should handle the archiving process.
    console.log(`Archive thought with ID: ${selectedThought}`);
    handleCloseDialog();
  };

  const handleUnarchive = () => {
    // Here you should handle the unarchiving process.
    (async () => {
      await updateDoc(doc(profileQuotesColl, selectedThought), {
        isArchived: false
      });
    })();
    console.log(`Unarchive thought with ID: ${selectedThought}`);
    setArchivedDialogOpen(false);
  };

  const handleDelete = () => {
    (async () => {
      await deleteDoc(doc(profileQuotesColl, selectedThought));
    })();

    // Here you should handle the deletion process.
    console.log(`Delete thought with ID: ${selectedThought}`);
    handleCloseDialog();
  };
  
  const handleImageUpload = (uploadedImageUrl) => {
    setPhotoURL(uploadedImageUrl);
  };

  const handleSave = async () => {
    if (!displayName || !photoURL) {
      alert('Please fill in both display name and profile picture.');
      return;
    }

    const isDisplayNameAccepted = await checkString(displayName);
    let isBioAccepted = true;
    if (bio) {
      isBioAccepted = await checkString(bio);
    }
    if (!isDisplayNameAccepted || !isBioAccepted) {
      alert('Content moderation triggered. Please check that you are using this app appropriately.');
      return;
    }
    
    setIsSaving(true);

    let uploadedPhotoURL = photoURL;
    if (photoURL !== originalPhotoURL) {
      try {
        uploadedPhotoURL = await uploadImageToFirebase(photoURL);
      } catch (error) {
        console.error('Failed to upload image: ', error);
        setIsSaving(false);
        return;
      }
    }

    updateProfile(FIREBASE_AUTH.currentUser, {
      displayName: displayName,
      photoURL: uploadedPhotoURL,
    }).then(() => {
        const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser?.uid)
        setDoc(docRef, {
          "name": displayName,
          "picURI": uploadedPhotoURL,
          "uid": FIREBASE_AUTH.currentUser?.uid,
          "bio": bio,
        }, { merge: true })
        .then(() => {
            setIsSaving(false);
            setOriginalPhotoURL(uploadedPhotoURL);
            setShowEditProfileBridge(false);
        })
        .catch((error) => {
            console.error('Failed to update Firestore document: ', error);
            setIsSaving(false);
        });
    })
    .catch((error) => {
        console.error('Failed to update profile: ', error);
        setIsSaving(false);
    });
    

  };

  const uploadImageToFirebase = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${FIREBASE_AUTH.currentUser.uid}`);
    try {
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      throw error;
    }
  };

  // fetching and listening to thoughts
  const fetchThoughts = () => {
    const thoughtsCollection = collection(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid, 'Profile Quotes');
    const q = query(thoughtsCollection, orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedThoughts = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
      setThoughts(fetchedThoughts);
    });

    // Clean up the subscription when unmounting
    return () => unsubscribe();
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
        <IconButton color='primary' onClick={() => setShowEditProfileBridge(null)}>
          <BackIcon />
        </IconButton>
      </div>

      {(!displayName || !photoURL) && 
      <Typography variant="h6" style={{ color: 'red', alignSelf: 'start', marginLeft: '1rem' }}>
        Please complete your profile with a profile picture and your name before moving on.
      </Typography>}
      <ImageUploader handleImageUpload={handleImageUpload} initialImage={photoURL} text={!photoURL ? "Tap here!" : undefined} sx={{ marginTop: 2 }}/>

      <TextField
        placeholder="Full Name"
        value={displayName}
        onChange={event => setDisplayName(event.target.value)}
        style={{ margin: '1rem', width: '200px' }}
        inputProps={{ maxLength: 20 }}
      />

      <TextField
        placeholder="Biography"
        value={bio}
        onChange={event => setBio(event.target.value)}
        style={{ margin: '1rem', width: '200px' }}
        multiline
        rows={4}
        inputProps={{ maxLength: 250 }}
      />

      <Button variant="contained" color="primary" onClick={handleSave} style={{ margin: '1rem' }} disabled={isSaving}>
        Save
      </Button>

      {thoughts.map(thought => (
        <Box key={thought.id} sx={{ width: { xs: '95%', md: '75%' }, maxWidth: { xs: '100%', sm: '600px', md: '800px', lg: '1000px' }, mx: '2.5%', mb: '10px'}}>
          <Card sx={{ backgroundImage: thought.isArchived ?  `linear-gradient(lightgrey, grey)` : (thought.color? `linear-gradient(${thought.color}, ${thought.color})` :  `linear-gradient(45deg, ${thought.color1} 30%, ${thought.color2} 90%)`) }}>
            <CardContent>
            <Typography variant="body2" color="black">
                {thought.isArchived ? '(Archived) ' : ''}{thought.quote.toString()}
              </Typography>
              <div style={{ display: 'flex' }}>
                <IconButton onClick={() => handleEditThought(thought.id)} color={thought.isArchived ? 'default' : 'primary'}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleEditThought(thought.id)} color={thought.isArchived ? 'default' : 'primary'}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </CardContent>
          </Card>
        </Box>
      ))}

<Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          {"What would you like to do with this thought?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can either archive this thought, delete it permanently, or cancel this operation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
            <Button onClick={handleArchive} color="primary">
                Archive
            </Button>
            <Button onClick={handleDelete} color="error">
                Delete
            </Button>
            <Button onClick={handleCloseDialog} color="primary" autoFocus>
                Cancel
            </Button>
        </Box>
    </DialogActions>
      </Dialog>

      <Dialog
        open={archivedDialogOpen}
        onClose={() => setArchivedDialogOpen(false)}
      >
        <DialogTitle>
          {"What would you like to do with this archived thought?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can either unarchive this thought, delete it permanently, or cancel this operation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
            <Button onClick={handleUnarchive} color="primary">
                Unarchive
            </Button>
            <Button onClick={handleDelete} color="error">
                Delete
            </Button>
            <Button onClick={() => setArchivedDialogOpen(false)} color="primary" autoFocus>
                Cancel
            </Button>
        </Box>
    </DialogActions>
      </Dialog>

      {isSaving ? (
        <LinearProgress color="secondary" style={{ margin: '1rem' }} />
      ) : null}


      {/* Loading overlay when saving */}
      <Backdrop open={isSaving} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default EditProfileBridge;

