import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Box } from '@mui/material';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { collection, query, getDocs, orderBy, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { checkIfUserBlocked } from './BlockedUsersHelper';
import { useTheme } from '@mui/material/styles';


function DirectMessages() {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!FIREBASE_AUTH || !FIREBASE_AUTH.currentUser) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    async function deleteConversation(conversationId) {
      if (!FIREBASE_AUTH || !FIREBASE_AUTH.currentUser || !FIRESTORE_DB || !conversationId) return;
      const conversationRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid, "Conversations", conversationId);
      try {
        await deleteDoc(conversationRef);
      } catch (error) {
        console.error(`Error deleting conversation with ID ${conversationId}:`, error);
      }
    }

    if (!FIREBASE_AUTH || !FIREBASE_AUTH.currentUser || !FIRESTORE_DB) return;
    const conversationsRef = collection(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid, "Conversations");
    const conversations_query = query(conversationsRef, orderBy("time", "desc"));
    const unsubscribe = onSnapshot(conversations_query, async (querySnapshot) => {
      const newConversations = [];
      querySnapshot.forEach((doc) => {
        const conversation = doc.data();
        conversation.id = doc.id;
        newConversations.push(conversation);
      });
  
      const uids = newConversations.map((conversation) => conversation.id);
      const blockedUserIDs = await checkIfUserBlocked(uids);
  
      const filteredConversations = newConversations.filter((conversation) => {
        if (blockedUserIDs.includes(conversation.id)) {
          deleteConversation(conversation.id);
          return false;
        }
        return true;
      });
  
      setConversations(filteredConversations);
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching conversations:', error);
      setIsLoading(false);
    });
  
    return unsubscribe;
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
    <Box sx={{ width: { xs: '100%', md: '35%',  }, m: 'auto', }}>
      <List>
        {conversations.length > 0 ? (
          conversations.map((conversation) => (
            <ListItem button key={conversation.id} onClick={() => navigate('/chatscreen', {state: {focusUID: conversation.id}} )}>
              <ListItemAvatar>
                <Avatar src={conversation.profilePicURL} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    {conversation.hasNewMessage && <Box sx={{ width: 10, height: 10, borderRadius: '50%', bgcolor: '#33CC33', mr: 1 }} />}
                    {conversation.name}
                  </Box>
                }
                secondary={conversation.lastMessage && conversation.lastMessage.length > 60 ? conversation.lastMessage.slice(0, 60) + "..." : conversation.lastMessage}
                primaryTypographyProps={{ color: theme.palette.text.primary }}
                secondaryTypographyProps={{ color: theme.palette.text.primary }} // text color based on hasNewMessage
                />
            </ListItem>
          ))) : (
          <Typography variant="body1" align="center" style={{ color: theme.palette.text.primary ,margin: '2rem' }}>
            No chats here! Go to the writer page, and type in an idea, feeling or thought. Then, click "Find New Matches". Click on anyone's profile, where you can send them a message!
          </Typography>
        )}
      </List>
    </Box>
    </Box>
  );  
}

export default DirectMessages;
