// this function takes an array that looks like this:
// [{"id":"iG4sJRXEF5lG3VxB2ndx","quote":"You can calibrate your frequency of visitation to the site to the frequency of posting, and that's your score on the website. You could auto assign huge group chats, based on a thought bubble, or thought clustering over time. Bulk thought clustering, where each cluster is a recursive solver of similarity tunnels through time, per se. A good reason to have thoughtsandsongs.com. tutadon. 8ts an app scheduler, it's an app loader. You can set app schedules. Emails first 5 min every hour, safari next 2 mins, etc. And you have a text field out here, that's your keyboard that abstracts your phone's keyboard. It's also an app scheduler, thought portals is also an app scheduler. Miracle - Vulfpeck","createdAt":{"seconds":1699106504,"nanoseconds":477000000},"isPublic":false,"isProfile":false,"isTwitter":false,"isThreads":false},{"id":"HjrAr1vlv37KhGcRdal5","isProfile":false,"isTwitter":false,"quote":"You can calibrate your frequency of visitation to the site to the frequency of posting, and that's your score on the website. You could auto assign huge group chats, based on a thought bubble, or thought clustering over time. Bulk thought clustering, where each cluster is a recursive solver of similarity tunnels through time, per se. A good reason to have thoughtsandsongs.com. tutadon. 8ts an app scheduler, it's an app loader. You can set app schedules. Emails first 5 min every hour, safari next 2 mins, etc. And you have a text field out here, that's your keyboard that abstracts your phone's keyboard. It's also an app scheduler, thought portals is also an app scheduler.","isThreads":false,"createdAt":{"seconds":1699106048,"nanoseconds":226000000},"isPublic":false},
// it takes myThoughtsSortSettings that looks like this:
// const [myThoughtsFilterSettings, setMyThoughtsFilterSettings] = useState({
//     isPublic: true,
//     isProfile: true,
//     isPublicFalse: true,
//     isVault: true,
//     numberOfResults: 100,
//     fromDate: Timestamp.now(),
//     toDate: Timestamp.now(),
//     fromAll: true,
//     toAll: true,
// })
// and myThoughtsSortSettings that looks like this:
// const [myThoughtsSortSettings, setMyThoughtsSortSettings] = useState({
//     sortBy: "similaritySort",
//     sortThought: "Most recent thought (auto)",
// });

/// it should return a sorted and filtered array as specified by the settings
// sortBy can be any of the following:
    // similaritySort: call a function to retrieve a list of IDS then sort according to those ids
    // chronoSort: sort based on createdAt
    // reChronoSort: REVERSE sort based on createdAt
    // lengthSort: sort based on length of quote
    // reLengthSort: REVERSE sort based on length of quote
    // randomSort: sort randomly

import { Timestamp } from "@firebase/firestore";
import { FIREBASE_AUTH } from "../../../firebaseConfig";
import { useEffect } from "react";

function extractNumberFromString(str) {
  const matches = str.match(/#(\d+)/i);
  return matches ? parseInt(matches[1], 10) : null;
}

const similaritySort = async (myThoughts, searchTerm) => {
    // get the list of ids
    let quote = searchTerm;
    let docIDs = [];
    if (!searchTerm.startsWith('#')){
      console.log("Similarity sort quote:", searchTerm)
      const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/fetch_similar_private_thoughts', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              quote: searchTerm,
              userID: FIREBASE_AUTH.currentUser.uid,
          }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      docIDs = await response.json();
      console.log("DOCIDS: ", docIDs)

    }else if (searchTerm === "#recent"){

        // use that quote
        quote = myThoughts[0].quote;
        console.log("Similarity sort quote:", quote);
        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/fetch_similar_private_thoughts', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              quote: quote,
              userID: FIREBASE_AUTH.currentUser.uid,
          }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      docIDs = await response.json();

      console.log("DOCIDS: ", docIDs)
    } else {
        // get the quote number
        // use that number to search for the thought by thought number
        // use that quote

        let searchThoughtNumber = null;

        if (searchTerm !== '#') {
            searchThoughtNumber = extractNumberFromString(searchTerm);
        }

        if (searchThoughtNumber != null) {
            const thought = myThoughts.find(thought => thought.thoughtNumber === searchThoughtNumber);
            if (thought) {
                quote = thought.quote;
            }
        }

        console.log("QUOTE USED: ", quote);

        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/fetch_similar_private_thoughts', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quote: quote,
                userID: FIREBASE_AUTH.currentUser.uid,
            }),
        });

        console.log("RESPONSE: ", response)

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        docIDs = await response.json();
        console.log("DOCIDS: ", docIDs)

    }

    // sort the array
    let sortedThoughts = [];
    docIDs.forEach(id => {
        const thought = myThoughts.find(thought => thought.id === id);
        if(thought){
          sortedThoughts.push(thought);
        }
    });

    console.log("SORTED THOUGHTS: ", sortedThoughts)
    return sortedThoughts;
}


const chronoSort = (myThoughts) => {
    const sortedThoughts = myThoughts.sort((a, b) => {
        if (a.createdAt.seconds < b.createdAt.seconds) {
            return -1;
        }
        if (a.createdAt.seconds > b.createdAt.seconds) {
            return 1;
        }
        return 0;
    });
    console.log("SORTED THOUGHTS: ", sortedThoughts);
    return sortedThoughts;
}

const reChronoSort = (myThoughts) => {
    const sortedThoughts = myThoughts.sort((a, b) => {
        if (a.createdAt.seconds < b.createdAt.seconds) {
            return 1;
        }
        if (a.createdAt.seconds > b.createdAt.seconds) {
            return -1;
        }
        return 0;
    });
    return sortedThoughts;
}

const lengthSort = (myThoughts) => {
    const sortedThoughts = myThoughts.sort((a, b) => {
        if(!a.quote || !b.quote){
          return 0;
        }  
       if (a.quote.length < b.quote.length) {
            return -1;
        }
        if (a.quote.length > b.quote.length) {
            return 1;
        }
        return 0;
    });
    return sortedThoughts;
}

const reLengthSort = (myThoughts) => {
    const sortedThoughts = myThoughts.sort((a, b) => {
      if(!a.quote || !b.quote){
        return 0;
      }  
        if (a.quote.length < b.quote.length) {
            return 1;
        }
        if (a.quote.length > b.quote.length) {
            return -1;
        }
        return 0;
    });
    return sortedThoughts;
}

const randomSort = (myThoughts) => {
    const sortedThoughts = myThoughts.sort(() => Math.random() - 0.5);
    return sortedThoughts;
}






export default async function sortAndFilterMyThoughts(myThoughts, setSortedFilteredThoughts, searchTerm, myThoughtsFilterSettings) {
    try {
      // sort
      let sortedThoughts = myThoughts;
      
      switch (searchTerm) {
          case '#chronoSort':
              sortedThoughts = await chronoSort(myThoughts);
              break;
          case '#reChronoSort':
              sortedThoughts = await reChronoSort(myThoughts);
              break;
          case '#lengthSort':
              sortedThoughts = await lengthSort(myThoughts);
              break;
          case '#reLengthSort':
              sortedThoughts = await reLengthSort(myThoughts);
              break;
          case '#randomSort':
              sortedThoughts = await randomSort(myThoughts);
              break;
          default:
              sortedThoughts = await similaritySort(myThoughts, searchTerm);
              break;
      }

  
      // filter
      let filteredThoughts = sortedThoughts;

      console.log("FILTERED THOUGHTS", filteredThoughts);
  
      // Collect an array of filter functions based on the filter settings
      const filters = [];
  
      if (myThoughtsFilterSettings.isPublic) {
        filters.push(thought => thought.isPublic);
      }
      if (myThoughtsFilterSettings.isProfile) {
        filters.push(thought => thought.isProfile);
      }
      if (myThoughtsFilterSettings.isPublicFalse) {
        filters.push(thought => !thought.isPublic);
      }
      if (myThoughtsFilterSettings.isVault) {
        filters.push(thought => thought.isVault);
      }
  
      // Now, filter thoughts to keep any that meet at least one filter condition
      filteredThoughts = filteredThoughts.filter(thought => {
        if (!thought) {
          console.error("Encountered undefined thought during filtering");
          return false; // Skip this thought
        }
      
        return filters.some(filter => filter(thought));
      });
  
      // Apply date range filters only if they are set
      if (!myThoughtsFilterSettings.fromAll) {
        filteredThoughts = filteredThoughts.filter(thought => thought.createdAt.seconds >= myThoughtsFilterSettings.fromDate.seconds);
      }
      if (!myThoughtsFilterSettings.toAll) {
        filteredThoughts = filteredThoughts.filter(thought => thought.createdAt.seconds <= myThoughtsFilterSettings.toDate.seconds);
      }

      if (myThoughtsFilterSettings.limitToXResults === 'All Thoughts') {
        setSortedFilteredThoughts(filteredThoughts);
      }else{
        // Apply limitToXResults filter only if it is set
          filteredThoughts = filteredThoughts.slice(0, myThoughtsFilterSettings.limitToXResults);
          setSortedFilteredThoughts(filteredThoughts);
      }
  
      // set
      
  
  
    } catch (error) {
      console.error("An error occurred while sorting or filtering thoughts: ", error);
      setSortedFilteredThoughts([]); // Set to empty array in case of an error
    }
  }
  
