import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, getDocs, query, orderBy, where } from 'firebase/firestore';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, IconButton, Menu, MenuItem, Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { updateBlockedUsersInternally } from './BlockedUsersHelper';
import MoreVertIcon from '@mui/icons-material/MoreVert';


function OtherProfileScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const focusUID = location.state?.focusUID;
  const [profileURL, setProfileURL] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [statusText, setStatusText] = useState('');
  const [quotes, setQuotes] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const blockedColRef = collection(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid, 'Blocked Users');
  const reportedColRef = collection(FIRESTORE_DB, 'Reported Users');

  useEffect(() => {
    if (!FIREBASE_AUTH.currentUser) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (!focusUID) {
      // redirect the user or show an error
      navigate('/homescreen');
      return;
    }

    const fetchUserData = async () => {
      const docRef = doc(FIRESTORE_DB, 'Users', focusUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        setProfileURL(userData.picURI);
        setDisplayName(userData.name);
        setBio(userData.bio);
        setStatusText(userData.status)
      }
    };

    fetchUserData();

    const fetchQuotes = async () => {
      const quotesCollection = collection(FIRESTORE_DB, 'Users', focusUID, 'Profile Quotes');
      const q = query(quotesCollection, where("isArchived", "==", false), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);

      const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuotes(fetchedQuotes);
    }

    if (focusUID !== "anonymous_user_id") {
      fetchQuotes();
    }


  }, [focusUID]);

  const handleBlockConfirmed = async () => {
    if (!FIREBASE_AUTH.currentUser) {
      console.error('User not logged in');
      return;
    }
    await addDoc(blockedColRef, {
      name: displayName,
      uid: focusUID,
    });
    await updateBlockedUsersInternally();
    alert('User successfully blocked. You may need to reload the app for this change to be seen. You can now find this user in your "Blocked Users" section of settings.');
  };

  const handleBlockReportConfirmed = async () => {
    if (!FIREBASE_AUTH.currentUser) {
      console.error('User not logged in');
      return;
    }

    if (reportReason.trim() === '') {
      console.error('Report reason cannot be empty');
      return;
    }

    setIsDialogVisible(false);
    await addDoc(blockedColRef, {
      name: displayName,
      uid: focusUID,
    });
    await addDoc(reportedColRef, {
      blocked_user: focusUID,
      blocked_by: FIREBASE_AUTH.currentUser.uid,
      reason: reportReason,
    });
    await updateBlockedUsersInternally();
    alert('User successfully blocked and reported. You may need to reload the app for this change to be seen. You can now find this user in your "Blocked Users" section of settings. The report will be investigated by our team.');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Dialog open={isDialogVisible} onClose={() => setIsDialogVisible(false)}>
        <DialogTitle>Report User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a reason for reporting this user (max 200 characters):
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="report-reason"
            label="Report Reason"
            type="text"
            fullWidth
            variant="standard"
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
            multiline
            maxRows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogVisible(false)}>Cancel</Button>
          <Button onClick={handleBlockReportConfirmed}>Report</Button>
        </DialogActions>
      </Dialog>

      <IconButton
        aria-label="settings"
        style={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => { setIsDialogVisible(true); handleClose(); }}>Report User</MenuItem>
        <MenuItem onClick={() => { handleBlockConfirmed(); handleClose(); }}>Block User</MenuItem>
      </Menu>

      <Avatar
        src={profileURL}
        alt="Profile"
        style={{ width: '200px', height: '200px', borderRadius: '100px', marginTop: '1rem' }}
      />
      <h1>{displayName}</h1>


      <p style={{ width: '400px', textAlign: 'center' }}>{statusText}</p>
      <p style={{ width: '400px', textAlign: 'center' }}>{bio}</p>
      <div style={{ marginTop: '2rem', marginBottom: '20px' }}>
        {focusUID !== "anonymous_user_id" &&
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/chatscreen', { state: { focusUID: focusUID, title: displayName, profileURL: profileURL } })}
          >
            Send Message
          </Button>}
      </div>
      {quotes.map(quote => (
        <Box key={quote.id} sx={{ width: { xs: '95%', md: '75%' }, maxWidth: { xs: '100%', sm: '600px', md: '800px', lg: '1000px' }, mx: '2.5%', mb: '10px' }}>
          <Card>
            <CardContent>
              <Typography variant="body1">
                {quote.quote}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
    </div>
  );
}

export default OtherProfileScreen;

