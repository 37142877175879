import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { FIRESTORE_DB } from './firebaseConfig';
import { CircularProgress, Avatar, Box, Typography, Container, Button, Card, CardContent, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import LoadingComponent from './LoadingComponent';

function OtherIdea() {
  const navigate = useNavigate();
  const location = useLocation();
  const focusUID = location.state?.focusUID;
  
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(FIRESTORE_DB, 'Ideas', focusUID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setResult(docSnap.data());
        setLoading(false);
      } else {
        console.log('No such document!');
      }
    };

    fetchData();
  }, [focusUID]);

  const GreatIdeaClicked = () => {
    // your logic here
  };

  const questionAddClicked = () => {
    // your logic here
  };

  const beenDoneClicked = () => {
    // your logic here
  };

  const sampleQuestions = [
    {question: "(SAMPLE, STILL IN DEVELOPMENT) Is this idea sustainable?", answer: "Yes, this idea is sustainable."},
    {question: "What are the potential risks?", answer: "The potential risks are..."},
  ]; 

  const sampleBeenDoneData = [
    {text: "(SAMPLE, STILL IN DEVELOPMENT) This is very similar to Idea X.", url: "www.ideaX.com"},
    {text: "I think this has been done by Company Y.", url: "www.companyY.com"},
  ]; 

  return (
    <Box paddingBottom='1rem'>
      {result && (
        <Box sx={{ margin: 2 }}>
          <Typography variant="h5" gutterBottom>{result.title}</Typography>
          <Typography variant="body1" gutterBottom><strong>Description:</strong> {result.description}</Typography>
          <Typography variant="body1" gutterBottom><strong>Medium:</strong> {result.implementationMedium}</Typography>
          <Typography variant="body1" gutterBottom><strong>Team:</strong> {result.idealTeam}</Typography>
          <Typography variant="body1" gutterBottom><strong>Outcome:</strong> {result.desiredOutcome}</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1em' }}>
            <Typography variant="h6">
              Great Idea! - 1 {result.greatIdeaCount} votes
            </Typography>
            <Button variant="outlined" startIcon={<ThumbUpAltIcon />} onClick={GreatIdeaClicked}>
              +1
            </Button>
          </Box>

          <Typography variant="h6" gutterBottom>Questions: <IconButton onClick={questionAddClicked}><AddCircleOutlineIcon /></IconButton></Typography>
          {sampleQuestions.map((q, index) => (
            <Card key={index} sx={{ marginBottom: '1em' }}>
              <CardContent>
                <Typography variant="body1"><strong>Q:</strong> {q.question}</Typography>
                <Typography variant="body1"><strong>A:</strong> {q.answer}</Typography>
              </CardContent>
            </Card>
          ))}

          <Typography variant="h6" gutterBottom>Has this been done yet? <IconButton onClick={beenDoneClicked}><AddCircleOutlineIcon /></IconButton></Typography>
          {sampleBeenDoneData.map((d, index) => (
            <Card key={index} sx={{ marginBottom: '1em' }}>
              <CardContent>
                <Typography variant="body1">{d.text} <a href={d.url}>{d.url}</a></Typography>
              </CardContent>
            </Card>
          ))}

          <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }} onClick={()=>navigate('/otherprofilescreen', { state: { focusUID: result.userID, title: result.displayName } })}>
            {result.profilePicURL && (
              <Avatar src={result.profilePicURL} alt={result.displayName} />
            )}
            <Typography variant="body1" fontWeight="bold" sx={{ ml: 1 }}>
              {result.displayName}
            </Typography>
          </Box>
        </Box>
      )}
      { loading &&
        <LoadingComponent/>
      }
    </Box>
  );
}

export default OtherIdea;
