import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CoinIcon from '@mui/icons-material/MonetizationOn';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { FIREBASE_AUTH, FIRESTORE_DB } from "./firebaseConfig";

/** 
 * Cost popup sample implementation
 * 
 * 
 * const [cost, setCost] = useState(34);
 * const [costPopupOpen, setCostPopupOpen] = useState(false);
 * const handleCostOpen = () => {
        setCostPopupOpen(true);
    }
    const handleCostClose = () => {
        setCostPopupOpen(false);        
    } 
 * 
 * handleCostOpen(); // when you wish to open the popup, usually after checking censorship etc
 * const handleContinue = async () => {
        handleCostClose();
        // rest of logic which should happen when confirmed, calculating, calling to server, saving results etc
        // removeCoinsFromUser(cost); // call this when it is successful to subtract coins
 }
 * 
 * in return();
 * <CostPopup
            isOpen={costPopupOpen}
            cost={cost}
            onClose={handleCostClose}
            onContinue={handleContinue}
        />
 * 
 */

const CostPopup = ({ isOpen, text = "This search will cost", cost, onClose, onContinue }) => {
  const [userCoins, setUserCoins] = useState(99);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoins = async () => {
      const user = FIREBASE_AUTH.currentUser;

      if (user) {
        const uid = user.uid;
        const userDocRef = doc(FIRESTORE_DB, 'Users', uid);

        // Fetch current coin count
        const userDoc = await getDoc(userDocRef);
        setUserCoins(userDoc.data()?.coinCount || 0);
      } else {
        console.error('User is not authenticated.');
      }
    };

    if (isOpen) {
      fetchCoins();
    }
  }, [isOpen]);

  const navigateToPricingPage = () => {
    navigate('/pricingpage');
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ textAlign: 'center', paddingBottom: 3 }}>
        {text} {cost} Coins
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center', paddingBottom: 3 }}>
        <Typography gutterBottom variant="h6" sx={{ paddingBottom: 2 }}>
          You currently have {userCoins} Coins
        </Typography>
        {userCoins < cost ? (
          <div>
            <Typography gutterBottom variant="body1">
              There are many ways to get coins. Every time you share an idea or a thought, you get coins!
              You may get coins from tasks such as updating your profile picture. You may also purchase coins
              if you need to do lots of searching of ideas and thoughts!
            </Typography>
            <Button onClick={navigateToPricingPage} color="primary" variant="contained" size="large">
              Click here
            </Button>
          </div>
        ) : (
          <Typography gutterBottom variant="h6" color="primary" style={{ alignSelf: 'center' }}>
            You have enough coins to proceed!
          </Typography>
        )}
        <CoinIcon color="#FFD700" fontSize="large" sx={{color: "#FFD700"}} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            size="large"
            sx={{ width: '120px' }} // Set a specific width here
        >
            Cancel
        </Button>
        {userCoins >= cost && (
            <Button
            onClick={onContinue}
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: '120px' }} // Set the same width as the other button
            >
            Continue
            </Button>
        )}
        </DialogActions>

    </Dialog>
  );
  
};

export default CostPopup;
