import React, { useEffect } from 'react';
import { Typography, Container, IconButton } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';


const PrivacyPolicyBridge = ({ setShowPrivacyPolicyBridge }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.websitepolicies.io/lib/embed/embed.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
            <IconButton
            aria-label="settings"
            onClick={() => setShowPrivacyPolicyBridge(null)}
        >
        <BackIcon/>
    </IconButton>
      <Typography variant="h2" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <a className="wpembed-inline" href="https://app.websitepolicies.com/policies/view/y9efrme9">View Privacy Policy</a>
    </Container>
  );
};

export default PrivacyPolicyBridge;




