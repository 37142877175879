import React, { useEffect, useState } from 'react';
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import { FIRESTORE_DB } from '../firebaseConfig';
import { Button, Typography } from '@mui/material';

function BulkSend() {
  


  return (
    <div>
      It should be a continous stream of pages full of people writing on them, you catch a branch each
time you reply to an idea. The idea is static, the branch grows only as end pieces are written on
to the same thing. People can travel down whatever branch they find, but can reply in any way the
y like. When you arrive at a thought end, you get the id of that thought, then you 

It's called longGit. It's extremely long rapid git branches, also you can vector search through i
t. Rapid merge, we're talking write a note and branch it off so fast, so freely that you are weav
ing thought branches and whenever one person breaks through and wall and another person does too,
 that's a branch. Maximum 5 branches from one node. No. No stops. No stop signs, no red lights.


 If you had my search history, that would give you one hell of a place to control this formControlClasses, you would anhor on the big three SocialDistance, and helps align them nicely.BulkSend

 Then you would steer it in the right Directions, with free speech for ALL.ALL


 It should be a contrinous stream of pages full of people writing on them, you catch a branch each time you reply to an idea. It's sort of a train of thoughts

 Maybe each card should be a Train.ALL

 Or a cardActionAreaClasses.ALL

 
 // car


 Whatever you want it to be.ALL

 // I don't like walls in the social, 3 legs planted in the ground. steering us towards whoever spends the money.

 // this one guides you away from anything that looks like ads. Because those are the sharp branches that predict clicks

 // and that's where you put the ads only

 // when you get really cool idea is a good time to see an ad

 Saving a thought as your home thought is another option you can make. It's how you want to enter the app.

 Top saved thought is an important metric we will measure one day, and tell you about.

 You will benefit, as you can see a slower uptake in intelligence that is more reflective.

 Take out the noise.

 bulksendsendsend

 get domain names for each thought name

 Post ads to them and buy them before you post the thought now.

 Thats how you tell when someone had a thought. They got a 10c domain name.

 It's like a profit multiplier for domain name selling.

 Global.com

 All the smart people come together and say which way they want to shape the world, and anyone radical gets taken off.

 Just keep the average thoughts. So we know the actual average thought shared. Vs the average thought searched.

 That trend will show you way the ship will steer.

 How long does it take before people search and when they create


 A little arrow on the bottom of the card that opens a drop down of the search section. So if I factor it out, I can reuse it



    </div>
  );
}

export default BulkSend;

