import React, { useEffect, useRef, useState } from "react";
import { ArrowBack, Camera, MoreVert } from "@mui/icons-material";
import { IconButton, TextareaAutosize, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { MyThoughtMenu, saveOrUpdateThought_stateInsteadOfRef } from "./MyThoughtMenu";
import { FIREBASE_AUTH, FIRESTORE_DB } from "../../../firebaseConfig";
import { doc, onSnapshot, serverTimestamp } from "firebase/firestore";
import { CameraAlt } from "@mui/icons-material";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import EditThoughtTextArea from "./EditThoughtTextArea";
import { saveThought, shareThought } from "../../SaveShareHelpers";    
import { useTheme } from "@emotion/react";
import { act } from "react-dom/test-utils";

const uploadImageToFirebase = async (imageFile) => {
    const storage = getStorage();
    const fileName = imageFile.name; // or generate a unique filename
    const storageRef = ref(storage, `textExtractionImages/${fileName}`);
  
    try {
      await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      throw error;
    }
  };

const NewEditThought = ({
    activeThought,
    setActiveThought,
    backArrowClick,
    setDialog,
    handleCloseDialog,
    navigate,
    loading,
    setLoading,
    latitude,
    longitude,
    generalSettings,
    setMyThoughtsSortSettings,
    thoughtNumber,
    mostRecentlyClickedThoughtText
}) => {
    const [maxHeight, setMaxHeight] = useState('100vh');
    const user = FIREBASE_AUTH.currentUser;
    const textareaRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleThoughtMenuClick = (event, thoughtId) => {
        setAnchorEl(event.currentTarget);
    };
    const handleThoughtMenuClose = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();

    const [showUploadBulkNotes, setShowUploadBulkNotes] = useState(false);
    const [showDictaphone, setShowDictaphone] = useState(false);
    const [textFieldText, setTextFieldText] = useState(''); // this is the text in the textarea 
    const [textOnEntry, setTextOnEntry] = useState('');
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // use effect for image upload
        useEffect(() => {
          const processImage = async () => {
            if (image) {
              setIsLoading(true);
              try {
                const uri = await uploadImageToFirebase(image);
      
                const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/extract_text', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ uri }),
                });
      
                if (!response.ok) {
                  throw new Error('Server responded with an error!');
                }
      
                const result = await response.json();
                setTextFieldText(textFieldText + result.extractedText);
                setImage(null);
              } catch (error) {
                alert('Error: ' + error.message);
              } finally {
                setIsLoading(false);
              }
            }
        };
      
          processImage();
        }, [image]);
      

    useEffect(() => {
        const calculateMaxHeight = () => {
            const textareaTop = textareaRef.current.getBoundingClientRect().top;
            const availableHeight = window.innerHeight - textareaTop - 20; // Subtract any additional margin
            setMaxHeight(`${availableHeight}px`);
        };

        calculateMaxHeight();
        window.addEventListener('resize', calculateMaxHeight);

        return () => {
            window.removeEventListener('resize', calculateMaxHeight);
        };
    }, []);


    useEffect(() => {
        console.log('Setting up Firestore listener...');

        // Check if the necessary data is not available
        if (!activeThought?.id || !user?.uid) {
            console.log('Required data not available.');
            return;
        }

        const docRef = doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThought.id);
        console.log(`Listening to document: ${activeThought.id}`);

        const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
            console.log('Received data from Firestore:', docSnapshot.exists() ? docSnapshot.data() : 'No data');

            if (docSnapshot.exists()) {
                console.log('Updating active thought...');
                // docSnapshot.data()  + id: docSnapshot.id
                setActiveThought({ ...docSnapshot.data(), id: docSnapshot.id });
            } else {
                console.log('No such document!');
            }
        });

        // Cleanup function
        return () => {
            console.log('Cleaning up Firestore listener...');
            unsubscribe();
        };
    }, [activeThought?.id]); // Depend only on activeThought.id



    // use effect for activeThought
    // it should set the TextareaRef.current.value to activeThought.quote
    useEffect(() => {
        if (activeThought) {
            setTextFieldText(activeThought.quote);

        }
        // focus on textareaRef
        textareaRef.current.focus();
    }
        , [activeThought]);


        const memoizedThoughtMenu = React.useMemo(() => {
            return (
                <MyThoughtMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    handleThoughtMenuClose={handleThoughtMenuClose}
                    activeThought={activeThought}
                    setActiveThought={setActiveThought}
                    setDialog={setDialog}
                    handleCloseDialog={handleCloseDialog}
                    navigate={navigate}
                    loading={loading}
                    setLoading={setLoading}
                    latitude={latitude}
                    longitude={longitude}
                    generalSettings={generalSettings}
                    setMyThoughtsSortSettings={setMyThoughtsSortSettings}
                    textValue={textareaRef.current ? textareaRef.current.value : ''}
                    thoughtNumber={thoughtNumber}
                    backArrowClick={backArrowClick}
                    mostRecentlyClickedThoughtText={mostRecentlyClickedThoughtText}
                    setTextFieldText={setTextFieldText}
                />
            );
        }, [anchorEl, activeThought, setActiveThought, setDialog, handleCloseDialog, navigate, loading, setLoading, latitude, longitude, generalSettings, setMyThoughtsSortSettings, textareaRef, thoughtNumber, backArrowClick, mostRecentlyClickedThoughtText]);
        

    return (
        <Box sx={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}>

                <IconButton onClick={async() => {
                    console.log("activeThought:", activeThought);
                    console.log("textFieldText:", textFieldText);
                    if(mostRecentlyClickedThoughtText === textFieldText){
                        setTextFieldText('');
                        setShowUploadBulkNotes(false);
                        backArrowClick();
                        return;
                    }
                    if(textFieldText === '') {
                        setShowUploadBulkNotes(false);
                        backArrowClick();
                        return;
                    }
                    let result;
                    if(activeThought && activeThought?.isPublic){
                        result = await shareThought({
                            optionalDocID: activeThought ? activeThought.id : null,
                            quote: textFieldText,
                            createdAt: activeThought.createdAt,
                            shareLocation: generalSettings.shareGeolocationData,
                            lat: latitude,
                            lon: longitude,
                            isProfile: activeThought.isProfile,
                            thoughtNumber: activeThought.thoughtNumber,
                            user
                        });
                    } else {
                        console.log("HEWRHERHERHTEHTHEEHTRHERTHT")
                        result = await saveThought({
                            optionalDocID: activeThought?.id ? activeThought.id : null,
                            quote: textFieldText,
                            createdAt: activeThought?.id ? activeThought.createdAt : null,
                            isVault: activeThought?.id ? activeThought.isVault : false,
                            thoughtNumber: activeThought?.id ? activeThought.thoughtNumber : thoughtNumber + 1,
                            user
                        });

                    }
                    if(result.errorCode){
                        console.log(result.errorMessage, result.errorCode);
                    }
                    setShowUploadBulkNotes(false);
                    setTextFieldText('');
                    setShowDictaphone(false);
                    backArrowClick();
                }}
                    sx={{ padding: '10px' }}>
                    <ArrowBack />
                </IconButton>

                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                    {activeThought && activeThought.createdAt && (
                        <Typography variant="caption" sx={{ fontSize: '0.75rem' }} onClick={() => setAnchorEl(true)}>
                            {activeThought.thoughtNumber ? " #" + activeThought.thoughtNumber : ""}
                        </Typography>
                    )}

                    <Box
                        sx={{
                            width: '45px',
                            height: '45px',
                            border: activeThought && activeThought.isVault ? '1px solid' : 'none',
                            borderColor: theme.palette.text.primary,
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '15px'
                        }}
                    >
                        <img
                            onClick={() => {
                                // open a dialog to confirm that they want to save it to vault. This will remove it from their profile, remove it from profile, and add one additional step to prevent accidental sharing.
                            }}
                            src={activeThought && activeThought.isVault ? `${process.env.PUBLIC_URL}/vault.png` : `${process.env.PUBLIC_URL}/grey_vault.png`}
                            alt="Vault"
                            style={{ width: '40px', height: '40px' }}
                        />
                    </Box>

                    <Box
                        sx={{
                            width: '45px',
                            height: '45px',
                            border: activeThought && !activeThought.isPublic && !activeThought.isVault ? '1px solid' : 'none',
                            borderColor: theme.palette.text.primary,
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '15px'
                        }}
                    >
                        <img
                            onClick={() => {
                                // open a dialog to confirm that they want to save it to their thoughts. This will remove it from their profile, remove it from public, if isPublic and or isProfile. If isVault, then it will remove it from the vault. It will be private now.
                            }}
                            src={activeThought && !activeThought.isPublic && !activeThought.isVault ? `${process.env.PUBLIC_URL}/brain.png` : `${process.env.PUBLIC_URL}/grey_brain.png`}
                            alt="Brain"
                            style={{ width: '40px', height: '40px' }}
                        />
                    </Box>

                    <Box
                        sx={{
                            width: '45px',
                            height: '45px',
                            border: activeThought && activeThought.isPublic ? '1px solid' : 'none',
                            borderColor: theme.palette.text.primary,
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '15px'
                        }}
                    >
                        <img
                            onClick={() => {
                                // open a dialog to confirm that they want to share it. This will add it to their profile, add it to public to see, and means anyone can find it by searching for it.
                            }}
                            src={activeThought && activeThought.isPublic ? `${process.env.PUBLIC_URL}/globe.png` : `${process.env.PUBLIC_URL}/grey_globe.png`}
                            alt="Globe"
                            style={{ width: '40px', height: '40px' }}
                        />
                    </Box>


                    {textFieldText && textFieldText !== '' && (
                    <IconButton onClick={async(event) => {
                        const currentTarget = event.currentTarget;
                        if(!activeThought || !activeThought.id){
                                setLoading(true);
                                const newThought = {
                                    quote: textFieldText,
                                    createdAt: serverTimestamp(),
                                    isVault: false,
                                    thoughtNumber: thoughtNumber + 1,
                                    user: user
                                };
                                const newActiveThought = newThought;
                                const result = await saveThought(newThought);
                                newActiveThought.id = result.docID;
                                newActiveThought.isProfile = false;
                                newActiveThought.isPublic = false;
                                setActiveThought(newThought);
                                setLoading(false);
                                setAnchorEl(currentTarget);

                            }else{
                                setAnchorEl(currentTarget);
                            }

                        
                    }}>
                        <MoreVert />
                    </IconButton>
                    )}

                </div>
            </div>

            <EditThoughtTextArea
                setImage={setImage}
                maxHeight={maxHeight}
                ref={textareaRef}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                textFieldText={textFieldText}
                setTextFieldText={setTextFieldText}
                showUploadBulkNotes={showUploadBulkNotes}
                setShowUploadBulkNotes={setShowUploadBulkNotes}
                showDictaphone={showDictaphone}
                setShowDictaphone={setShowDictaphone}
            />

        {memoizedThoughtMenu}

        </Box>
    );
};

export default NewEditThought;
