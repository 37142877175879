import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Typography, CircularProgress } from '@mui/material';
import { getDocs, collection } from 'firebase/firestore';
import { FIRESTORE_DB, FIREBASE_AUTH } from './firebaseConfig';
import { setItem } from './storage';
import { useNavigate } from 'react-router-dom';

const BlockedUsers = () => {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const blockedUsersKey = 'blocked_users';
  const navigate = useNavigate();

  useEffect(() => {
    if (!FIREBASE_AUTH.currentUser) {
      navigate("/login");
    }
  }, [navigate]);

  const onRefresh = async () => {
    setIsRefreshing(true);
    await fetchBlockedUsers();
    setIsRefreshing(false);
  };

  const fetchBlockedUsers = async () => {
    const blockedUsersCollection = collection(
      FIRESTORE_DB,
      `Users/${FIREBASE_AUTH.currentUser?.uid}/Blocked Users`
    );
    try {
      const blockedUsersSnapshot = await getDocs(blockedUsersCollection);
      const blockedUIDs = blockedUsersSnapshot.docs.map((doc) => doc.data().uid);
      await setItem(blockedUsersKey, blockedUIDs);
      const blockedUsersData = blockedUsersSnapshot.docs.map((doc) => ({
        uid: doc.id,
        name: doc.data().name,
      }));
      setBlockedUsers(blockedUsersData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };
  

  useEffect(() => {
    fetchBlockedUsers();
  }, []);

  return (
    <div>
      <List>
        {blockedUsers.map((item) => (
          <ListItem key={item.uid}>
            <ListItemText primary={item.name}/>
          </ListItem>
        ))}
      </List>
      {isRefreshing ? <CircularProgress /> : null}
      {blockedUsers.length === 0 ? (
        <Typography variant="body1" align="center" color="textSecondary" style={{ marginTop: '20px' }}>
          No blocked users! You can block users (for violation of terms and policies, or for personal reasons) by clicking on their profile picture.
        </Typography>
      ) : null}
    </div>
  );
};

export default BlockedUsers;
