import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, TextField, Grid, Divider } from '@mui/material';

const ThoughtSelectorPopup = ({ numberOfThoughts, setSortThought, setOpen }) => {
  const [inputValue, setInputValue] = useState(numberOfThoughts);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectMostRecent = () => {
    setSortThought('Most recent thought (auto)');
    handleClose();
  };

  const handleListItemClick = () => {
    const thoughtNumber = parseInt(inputValue, 10);
    if (thoughtNumber >= 1 && thoughtNumber <= numberOfThoughts) {
      setSortThought(`Thought ${thoughtNumber}`);
      handleClose();
    } else {
      alert(`Please enter a number between 1 and ${numberOfThoughts}`);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Select a Thought</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Thought results will be sorted based on similarity to the selected thought.
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: '10px'}} alignItems="center">
            <Grid item xs={5} container direction="column" alignItems="center" justifyContent="center">
              <Typography gutterBottom>Select by Number:</Typography>
              <TextField 
                label="Thought:"
                variant="outlined"
                fullWidth
                type="number" 
                value={inputValue} 
                onChange={handleChange} 
                inputProps={{ min: 1, max: numberOfThoughts }} 
              />
              <Button variant="contained" fullWidth onClick={handleListItemClick} sx={{ mt: 1, textTransform: 'none' }}>Select</Button>
            </Grid>
            <Grid item xs={1}>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={6} container direction="column" alignItems="center" justifyContent="center">
              <Typography gutterBottom>Quick Select:</Typography>
              <Button variant="contained" fullWidth sx={{ textTransform: 'none'}} onClick={handleSelectMostRecent}>
                Use Recent
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThoughtSelectorPopup;
