import React from 'react';
import styled from 'styled-components';

// Button Component
const Button = styled.button`
  width: 250px;
  padding: 15px 30px; // Adjust padding to make the button larger
  border-radius: 10px;
  background: linear-gradient(to right, #bdc3c7, #2c3e50); // Silver gradient
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.25); // Drop shadow for 3D illusion
  transform: skewX(-20deg); // Adjust as needed
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between; // To put some space between text and image
  &:hover {
    opacity: 0.9;
  }
`;

// Text Component
const Text = styled.span`
  font-size: 1.5em; // Larger font size
  color: #fff; // White text
  text-transform: uppercase; // Adjust as needed
  transform: skewX(20deg); // Adjust as needed
`;

// Hexagon Icon Component
const Icon = styled.img`
  width: 40px; // Adjust as needed
  transform: skewX(20deg); // Adjust as needed
`;

const SkewedButton = ({ text }) => (
  <Button>
    <Text>{text}</Text>
    <Icon src="hexagon_icon.png" alt="Icon"/>
  </Button>
);

export default SkewedButton;
