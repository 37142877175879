import React, { useState, useCallback, useEffect } from 'react';
import { Box, CircularProgress, Card, CardContent, Typography, Avatar } from '@mui/material';
import { FIRESTORE_DB, FIREBASE_AUTH } from './firebaseConfig';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { checkIfUserBlocked } from './BlockedUsersHelper';
import { setItem, getItem } from './storage';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";


function Results() {
  const location = useLocation();
  const user = FIREBASE_AUTH.currentUser;
  const [showsOwnResult, setShowsOwnResult] = useState(false);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshResults, setRefreshResults] = useState(location.state?.refreshResults);
  const documentId = location.state?.documentId;
  const quote = location.state?.quote? location.state.quote : '';
  const isAnonymous = location.state?.isAnonymous? location.state.isAnonymous : '';
  const [defaultAvatar, setDefaultAvatar] = useState("");
  const color1 = location.state?.color1? location.state.color1 : '';
  const color2 = location.state?.color2? location.state.color2 : '';
  const navigate = useNavigate();
  const [isInitialFetchComplete, setIsInitialFetchComplete] = useState(false);
  console.log("Color 1: ", color1);
  console.log("Color 1: ", color1);


  useEffect(() => {
    if (!FIREBASE_AUTH.currentUser) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if(isAnonymous){
      const storage = getStorage();
      const gsReference = ref(storage, 'gs://words-of-a-feather-f196f.appspot.com/profilePictures/default_profile_pic.jpeg');
      getDownloadURL(gsReference)
        .then((url) => {
          setDefaultAvatar(url);
        })
        .catch((error) => {
          console.error("Error getting download URL: ", error);
        });
    }
    
  }, [isAnonymous]);


  const fetchResultsFromFirebase = useCallback(async (docId) => {
    // setLoading(true);
    console.log("DOCUMENT ID", docId);
    updateDoc(doc(FIRESTORE_DB, "Users", user.uid), {
      lastResultsID: docId
    });
    const fetchedResults = await fetchResults(docId);
    // Save this documentID to firebase
    
    // Get userIDs from the fetched results
    const uids = fetchedResults.map((quote) => quote.userID);
    // Check for blocked users
    const blockedUserIDs = await checkIfUserBlocked(uids);

    blockedUserIDs.push(user.uid);
    // Filter out blocked users
    const filteredResults = fetchedResults.filter(
      (quote) => !blockedUserIDs.includes(quote.userID)
    );
    // Save the filtered results to AsyncStorage and update the component state
    await setItem("lastExploreResults", filteredResults);
    if (color1 !== '' && color2 !== '' && quote !== ''){
      setShowsOwnResult(true);
      const myQuote = {
        color1: color1,
        color2: color2,
        quote: quote,
      };
      if( isAnonymous){
        myQuote.displayName = "Anonymous";
        myQuote.profilePicURL = defaultAvatar;
        myQuote.userID = "anonymous_user_id";
      } else {
        myQuote.displayName = user.displayName;
        myQuote.profilePicURL = user.photoURL;
        myQuote.userID = user.uid;
      }
      const updatedResults = [myQuote, ...filteredResults];
      const validResults = updatedResults.filter(result => result !== null && result.quote && result.userID);
      setResults(validResults);
    }else{
      const validResults = filteredResults.filter(result => result !== null && result.quote && result.userID);
      setResults(validResults);
    }
    
    setLoading(false);
    setIsInitialFetchComplete(true);
    setRefreshResults(false);
  }, [documentId]);

  const fetchResultsInternally = useCallback(async () => {
    // setLoading(true);
    // Get results from AsyncStorage
    const existingResults = await getItem("lastExploreResults");
    if (true){//!existingResults){
      getDoc(doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid)).then((userDoc)=>{
        const secondaryDocumentId = userDoc.data().lastResultsID;
        console.log("SECONDATY DOCUYMET IUD", secondaryDocumentId);
        fetchResultsFromFirebase(secondaryDocumentId);
      });
    }
    const resultsFromStorage = existingResults ? existingResults : [];
    // Get userIDs from the stored results
    const uids = resultsFromStorage.map((quote) => quote.userID);
    // Check for blocked users
    const blockedUserIDs = await checkIfUserBlocked(uids);
    // Filter out blocked users
    const filteredResults = resultsFromStorage.filter(
      (quote) => !blockedUserIDs.includes(quote.userID)
    );
    // Update the stored results internally and the component state
    await setItem("lastExploreResults", filteredResults);
    setResults(filteredResults);
    setLoading(false);
    setIsInitialFetchComplete(true);
    setRefreshResults(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (refreshResults) {
      fetchResultsFromFirebase(documentId);
    } else {
      fetchResultsInternally();
    }
  }, [refreshResults]);

  const fetchResults = async (docId) => {
    if (!docId) {
      console.log('No documentId provided');
      return [];
    }

    const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser?.uid, "ResultIDs", docId);
    let resultIdsDoc = await getDoc(docRef);
    let retryCount = 0;
    while (!resultIdsDoc.exists && retryCount < 20) {
      await new Promise(resolve => setTimeout(resolve, 100));
      resultIdsDoc = await getDoc(docRef);
      retryCount++;
    }

    if (!resultIdsDoc.exists) {
      console.log('ResultIDs document not found after retrying');
      alert(
        'No Results Found',
        'We could not find any results. Please go back and try generating new results.',
      );
      return [];
    }

    const resultIds = resultIdsDoc.data();
    const resultIdsArray = Object.values(resultIds);

    const fetchQuoteDocument = async (id) => {
      const docRefFetch = doc(FIRESTORE_DB, "Quotes", id);
      const quoteDoc = await getDoc(docRefFetch);

      if (quoteDoc.exists) {
        const quoteData = { id: quoteDoc.id, ...quoteDoc.data() };
        return quoteData;
      } else {
        console.log(`Quote document not found for id: ${id}`);
        return null;
      }
    };

    const fetchedResults = await Promise.all(resultIdsArray.map(fetchQuoteDocument));

    console.log('Fetched results:', fetchedResults);
    return fetchedResults;
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, backgroundColor: 'background.paper', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        results.map((quote, position) => (
          <Card key={quote.id} sx={{ width: '100%', mb: 2, backgroundImage: quote.color? `linear-gradient(${quote.color}, ${quote.color})` :  `linear-gradient(45deg, ${quote.color1} 30%, ${quote.color2} 90%)`, position: 'relative'}} onClick={() => navigate('/otherprofilescreen', {state: { focusUID: quote.userID, title: quote.displayName }})}>
            {showsOwnResult? 
              <Typography variant="body2" sx={{ position: 'absolute', bottom: 6, right: 6, color: 'black' }}>
                {position === 0 ? 'Your Thought' : position === 1 ? '#1 (Most Similar Thought)' : `#${position}`}
              </Typography>
              :
              <Typography variant="body2" sx={{ position: 'absolute', bottom: 6, right: 6, color: 'black' }}>
                {position === 0 ? '#1 (Most Similar Thought)' : `#${position + 1}`}
              </Typography>
            }
            <CardContent>
              <Typography variant="body2" color="black">
                {quote.quote}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
                <Avatar src={quote.profilePicURL} alt={quote.displayName} />
                <Typography variant="subtitle1" fontWeight="bold" sx={{ ml: 1, color: 'black' }}>
                  {quote.displayName}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
}

export default Results;
