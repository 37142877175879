import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, query, orderBy, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { FIRESTORE_DB, FIREBASE_AUTH } from "../../../firebaseConfig";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomDialog from "../../../CustomDialog";
import LoadingComponent from "../../../LoadingComponent";
import { Divider, Box } from "@mui/material";
import { MyThoughtMenu } from "./MyThoughtMenu";
import LockIcon from '@mui/icons-material/Lock';
import { List, ListItem } from "@mui/material";

const RenderMyThoughts = ({
    thoughts,
    dialog,
    setDialog,
    handleCloseDialog,
    longitude,
    latitude,
    generalSettings,
    setMyThoughtsSortSettings,
    thoughtNumber,
    setActiveThought,
    setShowEditThought,
    setMostRecentlyClickedThoughtText
}) => {
    const [loading, setLoading] = useState(false);
    const user = FIREBASE_AUTH.currentUser;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeThoughtId, setActiveThoughtId] = useState(null);
    const theme = useTheme();

    //use effect to log whenever thouhgts changes
    React.useEffect(() => {
        console.log("thoughts changed in RENDERMYTHOUGHTS:", thoughts);
    }, [thoughts]);


    const handleThoughtMenuClick = (event, thoughtId) => {
        setAnchorEl(event.currentTarget);
        setActiveThoughtId(thoughtId);
    };

    const handleThoughtMenuClose = () => {
        setActiveThoughtId(null);
        setAnchorEl(null);
    };



    return (
        <>
            <style>
                {`
    .div-scroll::-webkit-scrollbar {
      display: none;
    }
    `}
            </style>

            <div className="div-scroll"
                style={{
                    height: 'calc(100vh - 206px)',
                    overflowY: 'scroll', // enabling vertical scrolling
                    overflowX: 'hidden', // disabling horizontal scrolling
                    scrollbarWidth: 'none', // for Firefox
                    scrollBehavior: 'smooth',
                    msOverflowStyle: 'none', // for IE/Edge
                    WebkitOverflowScrolling: 'touch', // for touch scrolling in mobile webkit
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    minWidth: '100%',
                    // hide scrollbar for Chrome, Safari, and Opera
                    '::-webkit-scrollbar': {
                        display: 'none'
                    }
                }}>               
                {(thoughts && thoughts.length > 0) && (
                 thoughts.map((thought, index) => (
                        <div key={thought.id} style={{ marginBottom: index < thoughts.length - 1 ? '0px' : '0px' }}
                            onClick={() => {
                                setShowEditThought(true);
                                setActiveThought(thought);
                                setMostRecentlyClickedThoughtText(thought.quote);
                            }}
                        >
                            <div style={{ position: 'relative', padding: '20px 16px', minHeight: '60px' }}>


                                {thought.isVault ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/vault.png`}
                                        alt="Vault"
                                        style={{ position: 'absolute', bottom: '10px', right: '10px', width: '40px', height: '40px' }}
                                    />
                                ) : thought.isPublic === false ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/brain.png`}
                                        alt="Brain"
                                        style={{ position: 'absolute', bottom: '10px', right: '10px', width: '40px', height: '40px' }}
                                    />
                                ) : thought.isPublic ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/globe.gif`}
                                        alt="Globe"
                                        style={{ position: 'absolute', bottom: '10px', right: '10px', width: '40px', height: '40px' }}
                                    />
                                ) : null}

                                

                                


                                <Typography variant="subtitle1" style={{ width: '90%', marginBottom: '8px', marginRight: '10px' }}>
                                    {thought.quote}
                                </Typography>
                                {thought.createdAt && (
                                    <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                                        {/* Convert Firebase Timestamp to desired date format if needed */}
                                        {new Date(thought.createdAt.seconds * 1000).toLocaleDateString()}

                                        {thought.thoughtNumber? " - Thought #" + thought.thoughtNumber : ""}

                                    </Typography>
                                )}
                            </div>

                            {/* Inserting Divider after each thought except the last one */}
                            {index < thoughts.length - 1 && <Divider sx={{ backgroundColor: theme.palette.primary.main, opacity: '50%' }} />}
                        </div>
                    )))}




{/* 
                {(thoughts.length === 0 && thoughtNumber > 5 ) && (
                    <Box>
                        <Typography marginTop={'20px'}>
                        You have no thoughts according to your sort and filter criteria.
                        </Typography>    
                    </Box>
                )
                }

                {thoughts.length === 0 && thoughtNumber < 5 && (
                    <Box sx={{
                        padding: '20px', 
                        border: '1px solid', 
                        borderRadius: '4px', 
                        margin: '20px 0'
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <LockIcon sx={{ marginRight: '10px' }} />
                          <Typography variant="h6">Unlock This Section by Saving or Sharing a Thought</Typography>
                        </Box>
                  
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                          Why you should save your thoughts:
                        </Typography>
                        <List>
                          <ListItem>- They are searchable by similarity, so you'll find your 'old' ideas exactly when you need them</ListItem>
                          <ListItem>- Sortable and filterable by date, status, etc</ListItem>
                          <ListItem>- They are safe and backed up so they won't be lost</ListItem>
                          <ListItem>- They won't go to waste because we're constantly adding new ways to bring your ideas together</ListItem>
                          <ListItem>- Don't let your ideas go to waste</ListItem>
                          <ListItem>- This is the best place to come and write your random ideas throughout the day so you can use them later</ListItem>
                          <ListItem>- This platform is designed around creativity and using your thoughts, instead of consuming others</ListItem>
                        </List>
                  
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>
                          Why you should share your thoughts:
                        </Typography>
                        <List>
                          <ListItem>- Inspire others</ListItem>
                          <ListItem>- They can message you</ListItem>
                          <ListItem>- Only people who think similar thoughts will find you, so instantly find your crowd</ListItem>
                          <ListItem>- Share your ideas with people who understand them</ListItem>
                          <ListItem>- Reach people like you</ListItem>
                          <ListItem>- Strengthen the Thought Portals community</ListItem>
                        </List>
                      </Box>
                
                )} */}

                {/* Loading Component */}
                {loading && <LoadingComponent />}
                </div>
            </>

);
}

export default RenderMyThoughts;