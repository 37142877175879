import React, { createContext, useState, useEffect } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline, darkScrollbar } from '@mui/material';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);


  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme) {
      setDarkMode(localTheme === 'dark');
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#e1e2eb' : '#4C4C6D', // Adjusted the dark mode primary color to a slightly muted shade of orange.
      },
      secondary: {
        main: darkMode ? '#003366' : '#E0F4FF', // Adjusted the dark mode secondary color to a deeper shade for better contrast.
      },
      tertiary: {
        main: darkMode ? '#283848' : '#FDFBE6',
      },
      error: {
        main: '#f44336', // This remains unchanged as it's a standard error color.
      },
      background: {
        default: darkMode ? '#1e2a30' : '#FFFFFF', // Slightly darkened the dark mode background for better contrast.
        paper: darkMode ? '#1e2a30' : '#FFFFFF',
      },
      success: {
        main: darkMode ? '#3C7A5B': '#98FB98',
      },
    },
    typography: {
      fontFamily: '"Roboto", sans-serif', // Roboto has a neutral width and is adaptable to many situations.
      h1: {
        fontFamily: '"Poppins", sans-serif', // Poppins is a bit wider and has a good weight for headers.
      },
      h2: {
        fontFamily: '"Poppins", sans-serif',
      },
      h3: {
        fontFamily: '"Poppins", sans-serif',
      },
      logo: {
        fontFamily: '"Caprasimo", serif', // Keeping Caprasimo as you liked it for the logo.
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '--primary-color': darkMode ? '#FFA500' : '#003366',
            '--secondary-color': darkMode ? '#003366' : '#f7fdff',
            '--error-color': '#f44336',
            '--background-paper': darkMode ? '#1e2a30' : '#FFFFFF',
            '--background-default': darkMode ? '#1e2a30' : '#FFFFFF',
            '--background-opposite': darkMode ? '#FFFFFF' : '#1e2a30',
            '--background-hover': darkMode ? '#283848' : '#a3a3a3',
          } 
        },
      },
    }
    
    
  });
  


  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
