import React, { useState, useEffect, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

const Dictaphone = ({ textFieldText, setTextFieldText }) => {
  const [isListening, setIsListening] = useState(false);
  const theme = useTheme();
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const lastTranscriptIndex = useRef(0);
  const pauseTimer = useRef(null);

  const processText = (text) => {
    const punctuationRegex = / (comma|period|full stop|fullstop|question mark|exclamation mark) /g;
    const punctuationMap = {
      ' comma ': ', ',
      ' period ': '. ',
      ' full stop ': '. ',
      ' fullstop ': '. ',
      ' question mark ': '? ',
      ' exclamation mark ': '! '
    };

    text = text.replace(punctuationRegex, match => punctuationMap[match]);

    text = text.replace('  ', ' ');

    while (text.includes(' backspace')) {
      text = text.replace(/ \S+ backspace/, '');
    }

    while (text.includes(' back space')) {
        text = text.replace(/ \S+ back space/, '');
      }
  

    return text;
  };

  useEffect(() => {
    if (isListening) {
      const newTranscript = transcript.slice(lastTranscriptIndex.current);
      lastTranscriptIndex.current = transcript.length;

      if (newTranscript.length > 0) {
        if (pauseTimer.current) {
          clearTimeout(pauseTimer.current);
        }
        setTextFieldText(currentText => processText(currentText + newTranscript));

        pauseTimer.current = setTimeout(() => {
          setTextFieldText(currentText => currentText + ' ');
        }, 700);
      }
    }
  }, [transcript, isListening, setTextFieldText]);

  const handleListening = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
      if (pauseTimer.current) {
        clearTimeout(pauseTimer.current);
      }
    } else {
      resetTranscript();
      lastTranscriptIndex.current = 0;
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <p>Browser doesn't support speech recognition.</p>;
  }

  return (
    <div style={{ padding: '10px' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleListening}
        style={{ margin: '10px', textTransform: 'none', backgroundColor: isListening ? theme.palette.error.main : '' }}
      >
        {isListening ? 'Stop Listening' : 'Start Listening'}
      </Button>
      {isListening && <p style={{ color: theme.palette.text.secondary }}>Listening...</p>}
    </div>
  );
};

export default Dictaphone;
