/*
This is the page that people see before they log in

Should say the following

"We're building a library of thoughts, with state of the art similarity search on top."
"Ever wanted to know if someone has thought of ___insertThoughtHere___?"
"Well now you can know, with Thought Portals."
"No likes, no comments, no celebrities. No videos, no pictures, no distractions."
"Just sharing thoughts, searching thoughts, developing thoughts, and messaging people like you."

It should have interactive animated pictures and parallax scrolling.

Stars as the background. 
Some glowing neuron brain where thoughts get sucked in and added to the brain as a new connection.

It should have calls to action of share your first thought now, with the text box on screen, that then takes you to results when you share a thought
"Share a thought to see similar ones, or login to explore thoughts and so much more!"

*/

import React from 'react';
import { Button, Container, Typography, Grid } from '@mui/material';

const LandingPage = () => {
  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Attention all internet explorers!
      </Typography>

      <Typography variant="h2" gutterBottom>
        We make your thoughts work for you
      </Typography>

      <Typography variant="h4" gutterBottom>
        Find others that think like you, and develop your thoughts at the same time
      </Typography>

      <Button variant="contained" color="primary">
        LOG IN
      </Button>

      <Typography variant="body1" gutterBottom>
        If you're tired of consuming so much content on auto pilot, then you're in the right place. As a society, we now devote the majority of our time to consumption, and almost no time to creation or productivity. Surely you're tired of all the 'feeds' and 'timelines' that pretend to know you? How can you make the most of your time online?
      </Typography>

      <Typography variant="body1" gutterBottom>
        Thought Portals is here to change the way you save thoughts, search thoughts, and share thoughts. Why? Because this way, you can land among a crowd that thinks like you, with people that understand you. Isn't this what the internet was made for in the first place? Well here's the platform that uses 2023 tech to fulfil what you actually want: to understand yourself better, and to find others that you can resonate with.
      </Typography>

      <div style={{ backgroundColor: 'orange', height: '50px', marginBottom: '10px' }}>
        {/* Custom search bar will go here */}
      </div>

      <Typography variant="body1" gutterBottom>
        It's simple! Log in, write a thought (this can be literally anything), and then save, search or share.
        <br />
        If you save a thought:
        <ul>
          <li>Get a collection of your thoughts</li>
          <li>Find patterns in your thinking</li>
          <li>Bring back similar thoughts at any point</li>
        </ul>
        If you search a thought:
        <ul>
          <li>Instantly see the most similar thoughts from others</li>
          <li>Message people with similar thoughts to you</li>
          <li>Find your crowd</li>
        </ul>
        If you share a thought:
        <ul>
          <li>When people find your thought by having a similar thought, they can message you</li>
          <li>Make your voice public, in a low risk way</li>
          <li>Only people who think similarly will find it, so there's no reason not to post it!</li>
        </ul>
      </Typography>

      <Typography variant="body1" gutterBottom>
        It only takes a minute to explore Thought Portals. This platform is a world first, and you're 4 clicks away from sharing your first thought!
      </Typography>

      <Button variant="contained" color="primary">
        LOG IN
      </Button>
    </Container>
  );
};

export default LandingPage;
