// I need a search bar component
// It needs a go button or a 'search' button, and a respective function to handle that
// It needs to be able to take in a search term and pass it to the search function
// If the search term is #x where x is a number, it should retrieve that thought number, use the quote field to populate the search bar, and then search as normal from there
// It should be smooth, and when you click on it, it expands to show your whole search query. When you hit search, or click outside, it should minimise to one line with ellipses

import React, { useState, useEffect } from 'react';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../../firebaseConfig';
import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
    limit,
    getDoc,
    doc,
    Timestamp,
    addDoc
} from 'firebase/firestore';
import { TextField, Box, InputAdornment, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { checkIfUserBlocked } from '../../../BlockedUsersHelper';
import AllThoughtsFilterOptions from './AllThoughtsFilterOptions';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

function isValidQuote(quote) {
    // Add checks for required fields, e.g.,:
    return quote && quote.userID && quote.quote; // Add other required fields here
}

const fetchAndSaveAllResults = async (documentIDs = []) => {
    if (!documentIDs.length) {
        console.log('No document IDs provided');
        return [];
    }

    const fetchQuoteDocument = async (id) => {
        const docRefFetch = doc(FIRESTORE_DB, "Quotes", id);
        const quoteDoc = await getDoc(docRefFetch);

        if (quoteDoc.exists()) {
            const quoteData = { id: quoteDoc.id, ...quoteDoc.data() };
            // Check for required fields in the document
            if (isValidQuote(quoteData)) {
                return quoteData;
            } else {
                console.log(`Required fields missing in document: ${id}`);
            }
        } else {
            console.log(`Quote document not found for id: ${id}`);
        }
        return null;
    };

    const fetchedQuoteDocs = (await Promise.all(documentIDs.map(fetchQuoteDocument))).filter(Boolean);

    console.log('Fetched results:', fetchedQuoteDocs);

    const uids = fetchedQuoteDocs.map((quote) => quote.userID);
    // Check for blocked users
    const blockedUserIDs = await checkIfUserBlocked(uids);

    // blockedUserIDs.push(FIREBASE_AUTH.currentUser.uid);
    // Filter out blocked users and self
    const filteredResults = fetchedQuoteDocs.filter(
        (quote) => !blockedUserIDs.includes(quote.userID)
    );

    console.log("FILTERED RESULTS", filteredResults);
    return filteredResults;
};


const getPrecision = (maximumDistance) => {
    /*
    based on filterSettings.maximumDistance, get the length of geohash needed
    if 5000km, then precision = 1
    if 600km, then precision = 2
    150km = 3
    20km = 4
    5km = 5
    */
    if (maximumDistance === '5000km'){
        return 1;
    }else if (maximumDistance === '600km'){
        return 2;
    }else if (maximumDistance === '150km'){
        return 3;
    }else if (maximumDistance === '20km'){
        return 4;
    }else if (maximumDistance === '5km'){
        return 5;
    }else{
        return 1;
    }
}

const extractNumberFromString = (str) => {
    const matches = str.match(/#(\d+)/i);
    return matches ? parseInt(matches[1], 10) : null;
};

const getThoughtQuoteByNumber = async (thoughtNumber) => {
    // retrieve the thought with that number
    const user = FIREBASE_AUTH.currentUser;
    const myThoughtsColRef = collection(FIRESTORE_DB, 'Users', user.uid, 'myThoughts');
    // query myThoughtsColRef to find the document where thoughtNumber = thoughtNumber
    const querySnapshot = await getDocs(query(myThoughtsColRef, where('thoughtNumber', '==', thoughtNumber)));

    if (querySnapshot.docs.length > 0) {
        return querySnapshot.docs[0].data().quote;
    } else {
        return null;
    }
}

const searchThoughts = async ({
    searchTerm,
    setSearchTerm,
    filterSettings,
    lat,
    lon

}) => {
    let quote = searchTerm;
    const user = FIREBASE_AUTH.currentUser;

    // if it is, then retrieve the thought with that number, or the most recent thought
    // then set the searchTerm to the quote of that thought
    if (searchTerm.startsWith('#')) {
        if (searchTerm === '#recent') {
            // retrieve the most recent thought
            const myThoughtsColRef = collection(FIRESTORE_DB, 'Users', user.uid, 'myThoughts');
            const querySnapshot = await getDocs(query(myThoughtsColRef, orderBy('thoughtNumber', 'desc'), limit(1)));
            if (querySnapshot.docs.length > 0) {
                quote = querySnapshot.docs[0].data().quote;
                setSearchTerm(quote);
            }
        } else {
            const thoughtNumber = extractNumberFromString(searchTerm);
            if (thoughtNumber) {
                const thoughtQuote = await getThoughtQuoteByNumber(thoughtNumber);
                if (thoughtQuote) {
                    quote = thoughtQuote;
                    setSearchTerm(quote);
                }
            }
        }
    }




    const precision = getPrecision(filterSettings.maximumDistance);
    const latitude = filterSettings.anyDistance ? null : lat;
    const longitude = filterSettings.anyDistance ? null : lon;
    console.log("Precision:", precision, "Latitude:", latitude, "Longitude:", longitude);


    let docIDs = [];

    const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/fetch_similar_public_thoughts', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              quote: quote,
              lat: latitude,
              lon: longitude,
              precision: precision,
              minCreatedAtMs: filterSettings.fromAll ? null : filterSettings.fromDate.toMillis(),
              maxCreatedAtMs: filterSettings.toAll ? null : filterSettings.toDate.toMillis(),
          }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      docIDs = await response.json();

      // save to thoughtSearches collection
        const thoughtSearchesColRef = collection(FIRESTORE_DB, 'thoughtSearches');
        addDoc(thoughtSearchesColRef, {
            searchTerm: quote,
            createdAt: Timestamp.now(),
        });


    return docIDs;


    

    


};

const ThoughtsSearchBar = ({
    setSortedFilteredResults,
    lat,
    lon
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isFocused, setIsFocused] = useState(false); // New state to track focus
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const defaultFilterSettings = {
        maximumDistance: '5000km',
        anyDistance: true,
        fromDate: Timestamp.now(),
        toDate: Timestamp.now(),
        fromAll: true,
        toAll: true,
        limitToXResults: 100, // Changed from string to number for consistency
    };
    
    const getInitialFilterSettings = () => {
        const storedSettings = localStorage.getItem('allThoughtsFilterSettings');
        if (storedSettings) {
            const parsedSettings = JSON.parse(storedSettings);
            return {
                ...parsedSettings,
                fromDate: parsedSettings.fromDate ? Timestamp.fromMillis(parsedSettings.fromDate) : Timestamp.now(),
                toDate: parsedSettings.toDate ? Timestamp.fromMillis(parsedSettings.toDate) : Timestamp.now(),
            };
        } else {
            return { ...defaultFilterSettings };
        }
    };

    const [filterSettings, setFilterSettings] = useState(
        getInitialFilterSettings()
    );
    
    useEffect(() => {
        const settingsToSave = {
            ...filterSettings,
            fromDate: filterSettings.fromDate ? filterSettings.fromDate.toMillis() : null,
            toDate: filterSettings.toDate ? filterSettings.toDate.toMillis() : null,
        };
    
        localStorage.setItem('allThoughtsFilterSettings', JSON.stringify(settingsToSave));
    }, [filterSettings]);
    

    const handleSearch = async () => {
        const docIDs = await searchThoughts({searchTerm, setSearchTerm, filterSettings, lat, lon});
        const results = await fetchAndSaveAllResults(docIDs);

        setSortedFilteredResults(results);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchTermKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const commonStyle = {
        borderRadius: '4px', // example corner radius
        border: '1px solid', // example border style
        borderColor: 'rgba(0, 0, 0, 0.23)', // example border color
    };

    return (
        <Box style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
            paddingTop: '10px',
            minWidth: '100%'
        }}>
            <Box style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
            }}>
            <TextField
                    id="search-bar"
                    label="Search for similar thoughts..."
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    multiline
                    maxRows={isFocused ? 10 : 1}
                    onKeyPress={handleSearchTermKeyPress}
                    style={{
                        flexGrow: 1, // TextField takes up remaining space
                        marginRight: '8px', // Space between TextField and IconButton
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="end"
                                    style={{
                                        padding: '10px', // Adjust padding to match height
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton
                    aria-label="filter"
                    onClick={() => {
                        if(showFilterOptions){
                            handleSearch();
                        }
                        setShowFilterOptions(!showFilterOptions);
                    }}
                    edge="end"
                    style={{
                        ...commonStyle, // Apply common styles
                        marginRight: '0px',
                        height: '55px'
                    }}
                >
                    <MultipleStopIcon style={{
                        transform: showFilterOptions ? 'rotate(270deg)' : 'rotate(90deg)',
                        transition: 'transform 0.6s ease',
                    }} />
                </IconButton>
            </Box>


            {showFilterOptions &&
            <AllThoughtsFilterOptions
                filterOptions={filterSettings}
                setFilterOptions={setFilterSettings}
                setShowFilterOptions={setShowFilterOptions}
                runSearch={handleSearch}
            />  
            }

        </Box>
    );
}

export default ThoughtsSearchBar;