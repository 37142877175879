import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import Avatar from '@mui/material/Avatar';

const ImageUploader = ({ handleImageUpload, initialImage }) => {
    const [previewImage, setPreviewImage] = useState(initialImage || '/default-avatar.png');

    const resizeImage = (file) => {
        if (!file) return; // Return early if file is null or undefined

        Resizer.imageFileResizer(
            file,
            600,
            600,
            'JPEG',
            100,
            0,
            (uri) => {
                setPreviewImage(uri);
                if (handleImageUpload) handleImageUpload(uri);
            },
            'base64',
            0, // maxWidth
            0, // maxHeight
            'cover' // resizeMode: 'cover' maintains aspect ratio and crops to fill the square
        );
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            resizeImage(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()} style={{ textAlign: 'center' }}>
            <input {...getInputProps()} />
            <Avatar
                src={previewImage}
                alt="uploaded preview"
                style={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    cursor: 'pointer', // Show pointer on hover
                }}
            />
        </div>
    );
};

export default ImageUploader;

