import { Box } from '@mui/material';
import React from 'react'

const PricingPage = () => {
    return(
        <>
            <Box sx={{ paddingTop: '70px', backgroundColor: "#FFFFFF", height: 'calc(100vh - 70px)' }}>
                <stripe-pricing-table pricing-table-id="prctbl_1Nx3n1K80JZNybuQ95hThVUm"
                    publishable-key="pk_live_51MoFhpK80JZNybuQLkH58SYwWsHMKfYoXk73vkuyZjspdzXTpRkCOyKsEK2gb7PKMyeMaTGboaAkKb3AcbC4DBpY00nSxkfz4z">
                </stripe-pricing-table>
            </Box>
        </>
    );
};

export default PricingPage;