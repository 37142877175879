import React, { useEffect, useState, useContext } from 'react';
import { signOut, deleteUser } from 'firebase/auth';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { doc, setDoc, collection, getDocs, writeBatch, getDoc } from 'firebase/firestore';
import {
  Typography, Switch, Button, List, ListItem, ListItemText, ListItemSecondaryAction, Container, Box,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';


const SettingsScreen = () => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const [isVerifiedCheckVisible, setIsVerifiedCheckVisible] = useState(false);
  const [isMoreResultsPerQuery, setIsMoreResultsPerQuery] = useState(false);
  const [isFastLoading, setIsFastLoading] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isEmailSubscribed, setIsEmailSubscribed] = useState(true);
  const [open, setOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const navigate = useNavigate();
  const user = FIREBASE_AUTH.currentUser.uid;


  useEffect(() => {
    // Define async function
    const fetchSettings = async () => {
      if (!FIREBASE_AUTH.currentUser) {
        navigate("/login");
      }
    
      // get setting from local
      const localEmailSubscribed = window.localStorage.getItem('isEmailSubscribed') === 'true';
      
      // then get setting from firebase 
      const userDocSnap = await getDoc(doc(FIRESTORE_DB, "Users", user));
      
      if (userDocSnap.exists()) {
        console.log("Document data:", userDocSnap.data());
        const userDoc = userDocSnap.data();
        
        if (userDoc.hasOwnProperty('isEmailSubscribed')) {
          // If isEmailSubscribed field exists
          setIsEmailSubscribed(userDoc.isEmailSubscribed);
    
          // if localEmailSubscribed is different from value retrieved from firebase, set the local storage to this value
          if (localEmailSubscribed !== userDoc.isEmailSubscribed) {
            window.localStorage.setItem('isEmailSubscribed', userDoc.isEmailSubscribed ? 'true' : 'false');
          }
        } else {
          // If isEmailSubscribed field does not exist, create it and set it to true
          await setDoc(doc(FIRESTORE_DB, "Users", user), {
            isEmailSubscribed: true
          }, { merge: true }); // Merge true to avoid overwriting other data
          
          setIsEmailSubscribed(true);
          window.localStorage.setItem('isEmailSubscribed', 'true');
        }
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    // Call async function
    fetchSettings();
  }, [navigate, user]);

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteAccount = async () => {
    console.log("1: Starting account deletion");
    try {
      const db = FIRESTORE_DB;
      const deletedUID = FIREBASE_AUTH.currentUser.uid;
      console.log("2: Deleted UID", deletedUID);

      // update firebase storage
      const userDocRef = doc(db, "Users", deletedUID);
      await setDoc(userDocRef, { name: "Account Deleted" });

      console.log("3: Set user name to Account Deleted");

      const storage = getStorage();
      const storageRef = ref(storage, `profilePictures/${deletedUID}`);
      try {
        await deleteObject(storageRef);
        console.log("4: Deleted profile picture from storage");
      } catch (error) {
        console.error("4: Error deleting profile picture:", error.message);
      }

      const conversationsColRef = collection(db, "Users", deletedUID, "Conversations");
      const conversationsQuerySnapshot = await getDocs(conversationsColRef);
      console.log("5: Fetched user conversations");

      const batch = writeBatch(db);
      conversationsQuerySnapshot.forEach((conversationDoc) => {
        const messagesColRef = collection(db, "Users", deletedUID, "Conversations", conversationDoc.id, "Messages");
        batch.delete(conversationDoc.ref);

        try {
          messagesColRef.get().then((messagesQuerySnapshot) => {
            console.log("6: Fetched messages for conversation", conversationDoc.id);
            messagesQuerySnapshot.forEach((messageDoc) => {
              batch.delete(messageDoc.ref);
            });
          });
        } catch (error) {
          console.error("7: Error fetching messages:", error.message);
        }
      });

      await deleteUser(FIREBASE_AUTH.currentUser);
      setIsErrorDialogOpen(false);
      navigate("/firstpage");

      try {
        await batch.commit();
        console.log("8: Committed batch delete");
      } catch (error) {
        console.error("9: Error committing batch delete:", error.message);
      }
    } catch (error) {
      // handle error
      console.error("10: General error during account deletion:", error.message);
      setIsErrorDialogOpen(true);
    }
  };

  const handleSubscribe = async () => {
    const newValue = !isEmailSubscribed;
    setIsEmailSubscribed(newValue);
    window.localStorage.setItem('isEmailSubscribed', newValue ? 'true' : 'false');

    // set to firebase
    const userDocRef = doc(FIRESTORE_DB, "Users", user);
    await setDoc(userDocRef, { isEmailSubscribed: newValue }, { merge: true });
  };


  const handleSignOut = async() => {
    await signOut(FIREBASE_AUTH);
    navigate("/firstpage");
  };

  const handleTermsConditions = () => {
    navigate("/termsconditions");
  };

  const handlePrivacyPolicy = () => {
    navigate("/privacypolicy");
  };

  const handleCookiesPolicy = () => {
    navigate("/cookies");
  };

  const handleBlockedUsers = () => {
    navigate("/blockedusers");
  };

  const handleAboutUs = () => {
    navigate("/aboutus");
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper', height: '100vh' }}>
    <Container maxWidth="md">
      <Box p={2}>
        <Typography variant="h6">Premium</Typography>
        <List>
          <ListItem onClick={() => {navigate('/pricingpage')}} >
            <ListItemText primary="Upgrade to Premium or Gold: (click here!)" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Dark Mode" />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                checked={darkMode}
                onChange={() => setDarkMode(!darkMode)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Typography variant="h6">Account</Typography>
        <List>
        <ListItem>
            <ListItemText primary="Receive Email Notifications (for DMs and new features)" />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                checked={isEmailSubscribed}
                onChange={handleSubscribe}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem button onClick={handleSignOut}>
            <ListItemText primary="Sign Out" />
          </ListItem>
          <ListItem button onClick={handleClickOpen}>
            <ListItemText primary="Delete Account" />
          </ListItem>
          <ListItem button onClick={handleBlockedUsers}>
            <ListItemText primary="Blocked Users" />
          </ListItem>
        </List>
        <Typography variant="h6">About</Typography>
        <List>
          <ListItem button onClick={handleTermsConditions}>
            <ListItemText primary="Terms & Conditions" />
          </ListItem>
          <ListItem button onClick={handlePrivacyPolicy}>
            <ListItemText primary="Privacy Policy" />
          </ListItem>
          <ListItem button onClick={handleCookiesPolicy}>
            <ListItemText primary="Cookies Policy" />
          </ListItem>
          <ListItem button onClick={handleAboutUs}>
            <ListItemText primary="About Us & Contact Info" />
          </ListItem>

        </List>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteAccount} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Account Deletion Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Recent account log in required to delete account. Please sign out then try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsErrorDialogOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </Box>
  );
};

export default SettingsScreen;

