import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = ({ open, onClose, title, message, actions = [] }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      aria-labelledby="custom-dialog-title" 
      aria-describedby="custom-dialog-description"
    >
      <DialogTitle id="custom-dialog-title">
        {title}
        <IconButton 
          aria-label="close" 
          onClick={onClose} 
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        {actions.map((action, index) => (
          <Button key={index} onClick={action.onClick} color="primary">
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
