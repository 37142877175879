import React, { useState } from "react";
import { Box, TextareaAutosize, Button, useTheme, Typography} from "@mui/material";

const MinimalistThoughtShare = ({ shareThought, thoughtNumber }) => {
    const [text, setText] = useState("");
    const theme = useTheme();
    const [responseText, setResponseText] = useState("");


    const handleShare = async () => {
        setResponseText("Sharing thought...");
        const trimmedText = text.trim();  // Trim the text to remove whitespace
        if (trimmedText !== "") {
            setResponseText(await shareThought(trimmedText));
        }
    };



    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: theme.spacing(2),
            }}
        >

            <TextareaAutosize
                minRows={3}
                placeholder="What's on your mind?"
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{
                    width: '100%',
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    borderColor: theme.palette.divider,
                    borderRadius: theme.shape.borderRadius,
                    outline: 'none',
                }}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={!text.trim() || responseText === "Sharing thought..."}
                onClick={handleShare}
            >
                Share
            </Button>
            <Typography variant="h8" color="textSecondary" sx={{ marginBottom: theme.spacing(2) }}>
                {responseText}
            </Typography>
        </Box>
    );
};

export default MinimalistThoughtShare;
