import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';


export const PrimaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'black' : 'white',
  backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242',
  },
  borderRadius: '12px',
  width: '100%',
  margin: '8px 0',
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  backgroundColor: 'transparent',
  textDecoration: 'underline',
  borderRadius: '12px',
  width: '100%',
  margin: '8px 0',
}));

// This is the styled Grid component
const CenteredGrid = styled(Grid)(({ mobile }) => mobile ? {} : {
  display: 'flex',
  alignItems: 'center',
});

const HomeScreen = () => {
  const theme = useTheme();
  const { darkMode } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [logoSrc, setLogoSrc] = useState(darkMode ? "/village-image-trans.png" : "/village-image.png");

  useEffect(() => {
    setLogoSrc(darkMode ? "/village-image-trans.png" : "/village-image.png");
  }, [darkMode]);

  return (
    <Box display="flex" justifyContent="center" alignItems="flex-start" height="100%" width="100vw">
      <CenteredGrid container spacing={2} direction={isMobile ? 'column' : 'row'} sx={{ marginTop: '3rem', maxWidth: '1200px'}} mobile={isMobile}>
        <Grid item xs={12} sm={6}>
          <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="flex-start" 
            height="100%" 
            p={2}
          >
            <Typography variant="h2" align="left" paragraph>
              Everybody finds somebody, someplace
            </Typography>
            <Typography variant="h5" align="left" paragraph>
              When great people work together, great things happen. Thought Portals brings the right people together.
            </Typography>
            <PrimaryButton variant="contained" onClick={() => navigate('/newportal')}>Open a portal with just a thought!</PrimaryButton>
            <SecondaryButton onClick={() => {navigate('/tutorial')}}> Tutorial </SecondaryButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            sx={{
              aspectRatio: '1',
            }}
          >
            <img src={logoSrc} alt="logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />

          </Box>
        </Grid>
      </CenteredGrid>
    </Box>
  );
};

export default HomeScreen;
