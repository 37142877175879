import React, { useEffect, useState, useRef } from 'react';
import { doc, onSnapshot, addDoc, setDoc, getDoc, orderBy, query, collection } from 'firebase/firestore';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { TextField, Button, Box, Typography, List, ListItem, Avatar } from '@mui/material';  // Added Avatar import
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import BackArrowIcon from '@mui/icons-material/ArrowBackIosNew';

const MyMessage = styled('div')({
  backgroundColor: '#3f51b5', // primary color for Material UI default theme
  color: 'white',
  padding: '8px',
  borderRadius: '4px',
  marginBottom: '2px',
  alignSelf: 'flex-end',
  maxWidth: '80%',
});

const OtherMessage = styled('div')({
  backgroundColor: '#e0e0e0', // grey color
  padding: '8px',
  borderRadius: '4px',
  marginBottom: '2px',
  alignSelf: 'flex-start',
  maxWidth: '80%',
  color: 'black',
});

const ChatScreen = () => {
  const location = useLocation();
  let focusUID = location.state.focusUID;
  focusUID = focusUID.toString();
  const user = FIREBASE_AUTH.currentUser;
  const auth = FIREBASE_AUTH;
  const currentUserUID = auth.currentUser.uid;
  const db = FIRESTORE_DB;
  const chatRef = doc(db, "Users", currentUserUID, "Conversations", focusUID);
  const messagesRef = collection(db, "Users", currentUserUID, "Conversations", focusUID, "Messages");
  const [otherUserProfilePic, setOtherUserProfilePic] = useState(null);
  const [otherUserName, setOtherUserName] = useState("");  // New state for other user's name
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [visibleTimestampIndex, setVisibleTimestampIndex] = useState(null);


  useEffect(() => {
    if (!FIREBASE_AUTH.currentUser) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [messages]);


  useEffect(() => {
    const fetchUserInfo = async (uid) => {
      const userDocRef = doc(db, 'Users', uid);
      const userDoc = await getDoc(userDocRef);
      return userDoc.data();
    };

    const createConversation = async (user1, user2, conversation) => {
      const user1Ref = doc(db, 'Users', user1.uid, 'Conversations', user2.uid);
      const user2Ref = doc(db, 'Users', user2.uid, 'Conversations', user1.uid);
      await setDoc(user1Ref, conversation);
    };

    const checkAndCreateConversation = async () => {
      const currentUserConversationRef = doc(db, 'Users', currentUserUID, 'Conversations', focusUID);
      const currentUserConversationSnap = await getDoc(currentUserConversationRef);

      let focusedUser;
      let conversation;

      focusedUser = await fetchUserInfo(focusUID);

      if (focusedUser && focusedUser.name && focusedUser.picURI) {
        setOtherUserProfilePic(focusedUser.picURI);
        setOtherUserName(focusedUser.name);
      }

      if (
        currentUserConversationSnap.exists() &&
        currentUserConversationSnap.data().name &&
        currentUserConversationSnap.data().profilePicURL
      ) {
        console.log("Existing conversation:", currentUserConversationSnap.data());
        conversation = currentUserConversationSnap.data();

        // Fetch the latest data from the focused user and update the conversation
        focusedUser = await fetchUserInfo(focusUID);
        conversation.name = focusedUser.name;
        conversation.profilePicURL = focusedUser.picURI;

        // Update the conversation data with the fetched data
        await setDoc(currentUserConversationRef, conversation, { merge: true });

        // Set the new message flag to false only if it was true before
        if (currentUserConversationSnap.data().hasNewMessage) {
          await setDoc(
            currentUserConversationRef,
            { hasNewMessage: false },
            { merge: true }
          );
        }
      } else {
        console.log("Fetching focused user's information");
        focusedUser = await fetchUserInfo(focusUID);
        console.log('focusedUser:', focusedUser);
        console.log('focusedUser.picURI:', focusedUser.picURI);
        setOtherUserProfilePic(focusedUser.picURI);
        console.log('otherUserProfilePic state set to:', otherUserProfilePic);

        conversation = {
          hasNewMessage: false,
          lastMessage: '',
          name: focusedUser.name,
          profilePicURL: focusedUser.picURI,
          time: new Date(),
          uid: focusUID,
        };
        console.log('New conversation:', conversation);

      
        if (!currentUserConversationSnap.exists()) {
          console.log('Creating conversation for current user');
          await createConversation(user, focusedUser, conversation);
          setOtherUserName(focusedUser.name)
        } else {
          console.log('Updating conversation for current user');
          await setDoc(currentUserConversationRef, conversation, { merge: true });
        }

        const focusedUserConversationRef = doc(db, 'Users', focusUID, 'Conversations', currentUserUID);
        const focusedUserConversationSnap = await getDoc(focusedUserConversationRef);

        if (!focusedUserConversationSnap.exists()) {
          let new_conversation = {
            hasNewMessage: true,
            lastMessage: '',
            name: user.displayName,
            profilePicURL: user.photoURL,
            time: new Date(),
            uid: currentUserUID,
          };

          console.log('Creating conversation for focused user with:', new_conversation);
          await createConversation(focusedUser, user, new_conversation);
        } else {
          // ??? not really sure what should be here
        }
      }
    };
    (async () => {
      await checkAndCreateConversation();
    })();

    const messagesQuery = query(messagesRef, orderBy('sendTime', 'desc'));
    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const newMessages = snapshot.docs.map((doc) => ({
        ...doc.data(),
        createdAt: doc.data().sendTime.toDate(),
      }));
      console.log('New messages:', newMessages);
      setMessages(newMessages);
    });

    return () => unsubscribe();
  }, [currentUserUID, focusUID]);

  const onSend = async (message) => {
    console.log('Sending message:', message);
    setMessage('');

    // Add message to sender's messages collection
    await addDoc(messagesRef, {
      ...message,
      sendTime: new Date(),
      user: {
        ...message.user,
      },
    });

    // Add message to receiver's messages collection
    const receiverMessagesRef = collection(db, "Users", focusUID, "Conversations", currentUserUID, "Messages");
    await addDoc(receiverMessagesRef, {
      ...message,
      sendTime: new Date(),
      user: {
        ...message.user,
      },
    });

    const conversationUpdate = {
      lastMessage: message.text,
      time: new Date(),
    };
    console.log('Updating conversation with:', conversationUpdate);

    const currentUserConversationRef = doc(db, 'Users', currentUserUID, 'Conversations', focusUID);
    const focusedUserConversationRef = doc(db, 'Users', focusUID, 'Conversations', currentUserUID);

    await setDoc(currentUserConversationRef, conversationUpdate, { merge: true });
    await setDoc(focusedUserConversationRef, { ...conversationUpdate, hasNewMessage: true }, { merge: true });
  };
  const renderMessageItem = (item, index) => {
    const isCurrentUser = item.user._id === currentUserUID;
    const MessageBubble = isCurrentUser ? MyMessage : OtherMessage;

    const dateOptions = { 
      year: 'numeric', month: 'short', day: 'numeric', 
      hour: '2-digit', minute: '2-digit',
      hour12: true
    };
    const formattedTime = item.createdAt.toLocaleString('en-US', dateOptions);

    return (
      <ListItem 
        key={index} 
        sx={{ display: 'flex', justifyContent: isCurrentUser ? 'flex-end' : 'flex-start' }}
        onClick={() => setVisibleTimestampIndex(visibleTimestampIndex === index ? null : index)}
      >
        <MessageBubble>
          <Typography>{item.text}</Typography>
          {visibleTimestampIndex === index && 
            <Typography variant="caption" sx={{ display: 'block', textAlign: isCurrentUser ? 'right' : 'left' }}>
              {formattedTime}
            </Typography>
          }
        </MessageBubble>
      </ListItem>
    );
  };



  return (
    <Box sx={{
      height: 'calc(100vh - 70px)',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      maxWidth: '100%',
      margin: 'auto',
      '@media (min-width:1280px)': { maxWidth: '50%' },
    }}>
      <Box sx={{  // New bar at the top of the chat
        display: 'flex',
        alignItems: 'center',
        padding: 1,
        borderBottom: '1px solid #ccc',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      }}>
        <BackArrowIcon onClick={() => navigate('/directmessages')}
         sx={{marginX: '10px'}}/>
        <Avatar src={otherUserProfilePic} onClick={() => navigate('/otherprofilescreen', {state: {focusUID: focusUID, title: otherUserName}})} />
        <Typography sx={{ marginLeft: 2 }}>{otherUserName}</Typography>
      </Box>
      {/* <List sx={{
        overflow: 'auto',
        flex: '1 1 auto',
      }} ref={messagesEndRef}> */}
      <List sx={{
        overflow: 'auto',
        flex: '1 1 auto',
      }} ref={messagesEndRef}>
        {messages.length === 0 ?
          <Typography sx={{ textAlign: 'center', mt: 3 }}>
            Type a message and hit send! <br /> <br /> <br /> <br /> <br />
          </Typography> :
          messages.slice().reverse().map(renderMessageItem)
        }
      </List>


      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSend({ text: message, user: { _id: currentUserUID, name: user.displayName, avatar: user.photoURL } });
        }}
        sx={{
          display: 'flex',
          padding: 1,
          alignItems: 'center',
          borderTop: '1px solid #ccc',
          position: 'sticky',
          bottom: 0, // This is important to make sure the input field isn't transparent.
        }}
      >
        <TextField
          sx={{ flex: '1 1 auto', marginRight: 1 }}
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button type="submit">Send</Button>
      </Box>

    </Box>
  );

};

export default ChatScreen;
