import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ResponsiveAppBar from './ResponsiveAppBar';
import { UserProvider } from './UserContext';
import LoginScreen from './LoginScreen';
import HomeScreen from './HomeScreen';
import PrivacyPolicy from './PrivacyPolicy';
import TermsConditions from './TermsConditions';
import Results from './Results';
import OtherProfileScreen from './OtherProfileScreen';
import ChatScreen from './ChatScreen';
import DirectMessages from './DirectMessages';
import SettingsScreen from './SettingsScreen';
import BlockedUsers from './BlockedUsers';
import Cookies from './Cookies'
import PricingPage from './PricingPage';
import { ThemeProvider } from './ThemeContext';
import Paper from '@mui/material/Paper';
import Tutorial from './Tutorial'
import NewPortal from './NewPortal';
import CurrentPortal from './CurrentPortal';
import AllResults from './AllResults';
import Grid from './Grid'
import AboutUs from './AboutUs'
import HomePage from './HomeScreenNew';
import { Explore, Login } from '@mui/icons-material';
import ExploreThoughts from './ExploreThoughts';
import ExploreThoughtsResults from './ExploreThoughtsResults';
import ShareIdea from './ShareIdea';
import ExploreIdeasResults from './ExploreIdeasResults';
import ExploreIdeas from './ExploreIdeas';
import OtherIdea from './OtherIdea';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { FIREBASE_AUTH } from './firebaseConfig';
import FirstPage from './FirstPage';
import HexagonGrid from './FirstPage';
import ShareThought from './ShareThought';
import Thoughts2 from './V2.0/Thoughts2';
import SavedThoughts from './V2.0/SavedThoughts';
import EditThought from './V2.0/EditThought';
import BulkSend from './V2.0/BulkSend';
import HowToUse from './V2.0/HowToUse';
import WhatIsIt from './V2.0/WhatIsIt';
import LandingPage from './V3.0/LandingPage';
import Affiliates from './V3.0/Affiliates';
import HomeScreenV3 from './V3.0/HomeScreenV3';
import HotjarScript from './HotJar';
import UpgradeAccount from './UpgradeAccount';
import HomeScreenV4 from './V5.0/HomeScreenV4';
import { useTheme } from '@mui/material';
import PwaInstallPrompt from './PwaInstallPrompt';
import NewEditThought from './V5.0/Tabs/MyThoughtsTab/NewEditThought';
import ImageToText from './V5.0/ImageToText';
import ContentClassification from './V5.0/ContentClassificationTab/ContentClassification';

//adding a webpage for each domain I owned

// so you can go from one site to the other, back again etc, and never get logged out

// but they're all accessible through the same bar

//woafApp

//we're gonne merge from those .txt

// leave the txt there
// buy the domain name, open up the conversation to that topic, then say when you bought the domain name.txt.txt

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//       trackAllPureComponents: true,
//   });
// }




const App = () => {

  const [showMyProfileBridge, setShowMyProfileBridge] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([
    { toggleSave: true,
      id: 'signIn', title: "Login", content: 'Login',
      description: "Simple login screen to get you started. Signing in means your thoughts won't go away, unless you want them to.",
      prerequisites: "Available to all", enabled: true, unlocked: true, collapseDescription: false,
      sections: [
        {
          heading: 'What is it?',
          text: 'A log in screen. This is how we know who you are, and how we keep your thoughts safe. Only you can see your private thoughts, and our secure login system makes sure of that (supported by Google Authentication).'
        },
        {
          heading: 'Plans for future?',
          text: 'I think the login screen should change to reflect a user who has logged in. Perhaps it could show logins over time, or other details about how many people have logged in today? Would that be interesting?'
        },
        // ... more sections as needed
      ],
      imageUrl: '/loginPreview.png',
 },

      { id: 'zenShareSave', title: "Zen Share & Save", content: 'Zen Share & Save',
      description: "Zen inspired, with tranquil creativity at its core. Share your thoughts, and save your thoughts. That's it. No distractions, no clutter, just you and your thoughts.",
      prerequisites: "Please sign in to unlock this tab.", enabled: false, unlocked: false, collapseDescription: false,
      sections: [
        {
          heading: 'What is it?',
          text: 'Zen Share & Save is a simple, yet powerful tool for sharing your thoughts, and saving your thoughts.'
        },
        {
          heading: 'Why do I want to share and save thoughts?',
          text: 'Thoughts are the building blocks of Thought Portals. They are also the building blocks of life, where thoughts lead to actions, and actions shape ours, and everyone else\'s reality. By sharing your thoughts, you are contributing to the collective consciousness of humanity. By saving your thoughts, you are building a library of your own thoughts, which you can use to reflect on your past, and learn from your mistakes, successes and random musings. By doing it here, you are supporting openness and the liberation of our minds, encouraging a more in tune and connected future for our global society.'
        },
        {
          heading: 'How do I use it?',
          text: 'Type your thought into the text box, and then press share or save. Sharing makes a thought public, and saving makes a thought viewable only by you. Tap the colour palette icon to change the colour of this tab. Tap the plus and minus icons to control how many ThoughtBoxes™️ you have (helpful for when you have more than one idea at the same time!). Tap the prompt icon to get a random thought prompt, to help you get started.'
        },
        {
          heading: 'Plans for future?',
          text: 'I think the aesthetics are important for this page. It is important to have it easy on the eyes to let you focus, but also to make the metaphorical blank piece of paper less threatening. Furthermore, it could do with more prompts, especially ones that are more like random sentence starters than fully formed ideas?.'
        },
        {
          heading: 'Tips from the founder:',
          text: 'It is important to try and get on a roll. Just type anything and hit save. It really isn\' important what that is at first, and because momentum > motivation, you will be writing interesting stuff within minutes, if not seconds. This is the stuff that you can reflect on later and get some real value from. When you let your mind ramble, and have a structure to make that useable at a later date, you have the raw building blocks for creativity and reflection, two aspects of life that are so important to many.'
        }
      ],
      imageUrl: '/zenShareSavePreview.png',
 },

    { id: 'myThoughts', title: "My Thoughts", content: 'My Thoughts',
      description: "A complex component, that lets you manage your thoughts, create new ones, share and save your thoughts. Furthermore, all of your thoughts are searchable (based on meaning and topic, not just keyword search), sortable, and filterable. This means you can do cool stuff, for example: You're about to start designing the app you've been thinking about for years. So you make a search in thought portals, and all your similar ideas come back to you, right when you need it!",
      prerequisites: "Please share one (1) thought, and save one (1) thought to unlock this tab.", enabled: false, unlocked: false, collapseDescription: false,
      sharesRequired: 1,
      savesRequired: 1,
      sections: [
        {
          heading: 'What is it?',
          text: 'An all purpose manager for your thoughts. You can create, save and share thoughts from here. You can unshare and delete them too. You can remove them from your profile. You can search your thoughts, and sort them according to many kinds of filters and orders. This is the only notes app that lets you search your thoughts by meaning, topic, and sentiment, and not just keywords.'
        },
        {
          heading: 'How do I use it?',
          text: 'You will be greeted by your list of thoughts, sorted by date. You can add a new thought by typing in the search bar and pressing \'+\', or by pressing by the \'+\' in the bottom right.  You can sort and filter this list by clicking the sort and filter icon to the right of the search bar. You can search your thoughts by typing into the search bar and pressing the search icon. This will order your thoughts, from most similar, to least. You can also search \'#\' follwed by shortcuts to quickly sort, once you get the hang of it. Clicking on a thought will take you to that thought, where you will be able to access a menu in the top right to modify anything about that thought (share a saved thought, remove a shared thought, remove from profile, etc!). The icon system lets you know at a glance whether your thought is shared, saved, or even in the vault (a place of higher security, with extra steps put in the way of sharing, to make your thoughts extra secure. Add a thought to the vault by using the menu on a particular thought).'
        },
        {
          heading: 'Plans for future?',
          text: 'I think this page is quite useable, and is meant to just contain everything regarding your thoughts in one page. However, I do plan on improving the flow of it and the polish. All the other ideas I have for it are best suited to be separate tabs with more specific functionalities, and so I will be creating those in the future.'
        }
      ],
      imageUrl: '/myThoughtsPreview.png',

    
    },
    
    { id: 'allThoughts', title: "All Thoughts", content: 'All Thoughts',
      description: "The SOCIAL aspect to thought portals. When you share thoughts, this is where they end up. A whole database of everyones thoughts, searchable by everyone. The only place on the internet where you can search 'Hello, who want's to chat?' and find everyone who's said the most similar thing.",
      prerequisites: "Share 5 thoughts", enabled: false, unlocked: false, collapseDescription: false,
      sharesRequired: 5,
      savesRequired: 0,
      sections: [
        {
          heading: 'What is it?',
          text: 'It\'s a tool that lets you search all shared thoughts, based on meaning, topic and sentiment. You can then see the thoughts, from most to least similar, and the people who shared them. By clicking their profile, you can then send a message and see their other thoughts.'
        },
        {
          heading: 'What is it, really?',
          text: 'This is the MOST INNOVATIVE FEATURE IN THOUGHT PORTALS SO FAR. Where else on the internet can you type \'Hello\' and hit search, and then see all the people who have said that? Twitter, facebook, instagram, snapchat, even reddit don\'t let you do that. But now, with Thought Portals, you can find people who think like you, you can find people that care about what you care about, using all the technology that the big tech companies use behind the scenes, in the palm of your hands.'
        },
        {
          heading: 'How do I use it?',
          text: 'Simple. Type something in and hit search. If nothing comes to mind, type something like \'#481\' to use your 481st thought as a search term, for example. This tab also provides powerful sorting and filtering tools, allowing you to fine tune your query. Want to find the most similar thoughts to \'I like modifying cars and seeing cool stuff\'? Just type it in and hit search. Want to find the most similar thoughts to \'I like modifying cars and seeing cool stuff\' that are also about \'cars\'? Just type it in and hit search. Want to find the most similar thoughts to \'I like modifying cars and seeing cool stuff\' that are within 5km of you and shared within the last 5 days? Just type it in, modify the filters, and voila. History, baby!'
        }
  ],
      imageUrl: '/allThoughtsPreview.png',

     },

    { id: 'messengerTab', title: "Messenger", content: 'Messenger Tab',
      description: "Messenger tab, for seeing all of your conversations!",
      prerequisites: "Just be logged in, that's it. To use it, you'll have to have messaged someone by clicking on their profile.", enabled: false, unlocked: false, collapseDescription: false,
      sections: [
        {
          heading: 'What is it?',
          text: 'Stock-standard messenger tab, for keeping up with your contacts. You can see all your conversations, and click on one to message that particular person. You know the drill by now.'
        },
        {
          heading: 'Plans for future?',
          text: 'I think a fully featured messenger tab is needed for Thought Portals to enable more meaningful connections between those you already know and have brought to Thought Portals, and to those whos\' thoughts you find intriguing. Voice and video chat, sending photos and other attachments, collaborative whiteboards, shared music playback, and more. If you like what you hear (and literally 7 more surprise ideas, you\'ll see them when I program them), then please support Thought Portals.'
        },
        // ... more sections as needed
      ],
      imageUrl: '/messengerPreview.png',

     },
    
      { id: 'settingsTab', title: "Settings", content: 'Settings Tab',
      description: "One of the control panels of your Thought Portals experience. Here you can change your settings, and manage your account.",
      prerequisites: "Just be logged in, that's it.", enabled: false, unlocked: false, collapseDescription: false,
      sections: [
        {
          heading: 'What is it?',
          text: 'Just a settings page. Most important feature here is changing to Dark Mode!'
        },
        // ... more sections as needed
      ],
      imageUrl: '/settingsPreview.png',

     },

     {
      id: 'noteScannerTab', title: "Note Scanner", content: 'Note Scanner',
      description: "The note scanner tab, for scanning your handwritten notes into usable, searchable thoughts!",
      prerequisites: "Just be logged in, that's it.", enabled: true, unlocked: true, collapseDescription: false,
      sections: [
        {
          heading: 'What is it?',
          text: 'The note scanner tab is a powerful tool that lets you scan your handwritten notes into usable, searchable thoughts. It uses the latest in machine learning and computer vision to make your notes as legible as possible, and then uses the latest in natural language processing to turn your notes into thoughts. You can then save these thoughts, and share them, just like any other thought. This is a great way to digitize your notes, and make them more accessible and useful.'
        },
        {
          heading: 'How do I use it?',
          text: 'Simply take a photo of your note, and then let the note scanner do the rest. You can then edit the thought, and then save it, or share it, just like any other thought. You can also use the note scanner to scan multiple thoughts at once, and then save or share them all at once. This is a great way to digitize your notes, and make them more accessible and useful.'
        },
        // ... more sections as needed
      ],
     },
    

    { id: 'manageTabs', title: "+", content: 'Manage Tabs',
      description: "Manage your tabs",
      prerequisites: "Available to all", enabled: true, unlocked: true,
      sections: [
        {
          heading: 'Section 1',
          text: 'Text for section 1'
        },
        {
          heading: 'Section 2',
          text: 'Text for section 2'
        },
        // ... more sections as needed
      ],
      imageUrl: 'url_to_image',

     }
  ]);
  const [bridgeDisplayStack, setBridgeDisplayStack] = useState(
    tabs.reduce((acc, tab) => {
        acc[tab.id] = []; // Initialize an empty array for each tab
        return acc;
    }, {})
);


  return (
    <ThemeProvider>
      <HotjarScript />
      <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
      <UserProvider>
        <Router>
          <ResponsiveAppBar 
            setBridgeDisplayStack={setBridgeDisplayStack}
            setShowMyProfileBridge={setShowMyProfileBridge}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={tabs}
          />
          {/* <PwaInstallPrompt /> */}
          <Routes>
            <Route path="/" element={<HomeScreenV4
              bridgeDisplayStack={bridgeDisplayStack}
              setBridgeDisplayStack={setBridgeDisplayStack}
              showMyProfileBridge={showMyProfileBridge}
              setShowMyProfileBridge={setShowMyProfileBridge}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              setTabs={setTabs}
            />} />
            <Route path="/test" element={<LandingPage />} />
            <Route path="" element={<LoginScreen />} />
            <Route path="/" element={<LoginScreen />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/login" element={<LoginScreen />} /> //CAHNGED
            <Route path="/loginscreen" element={<LoginScreen />} /> //CHANGED
            <Route path="/homescreen" element={<HomeScreenV4
              bridgeDisplayStack={bridgeDisplayStack}
              setBridgeDisplayStack={setBridgeDisplayStack}
              showMyProfileBridge={showMyProfileBridge}
              setShowMyProfileBridge={setShowMyProfileBridge}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              setTabs={setTabs}
            />} />
            <Route path="/homepage" element={<HomeScreenV4 
              bridgeDisplayStack={bridgeDisplayStack}
              setBridgeDisplayStack={setBridgeDisplayStack}
              showMyProfileBridge={showMyProfileBridge}
              setShowMyProfileBridge={setShowMyProfileBridge}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              setTabs={setTabs}
            />} />
            <Route path="/firstpage" element={<LoginScreen />} />
            <Route path="/thoughts2" element={<HomeScreenV4 
              bridgeDisplayStack={bridgeDisplayStack}
              setBridgeDisplayStack={setBridgeDisplayStack}
              showMyProfileBridge={showMyProfileBridge}
              setShowMyProfileBridge={setShowMyProfileBridge}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              setTabs={setTabs}
            />} />
            <Route path="/savedthoughts" element={<SavedThoughts />} />
            <Route path="/editthought" element={<EditThought />} />



            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/homescreen" element={<HomeScreenV4 
              bridgeDisplayStack={bridgeDisplayStack}
              setBridgeDisplayStack={setBridgeDisplayStack}
              showMyProfileBridge={showMyProfileBridge}
              setShowMyProfileBridge={setShowMyProfileBridge}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              setTabs={setTabs}
            />} />
            <Route path="/results" element={<Results />} />
            <Route path="/otherprofilescreen" element={<OtherProfileScreen />} />
            <Route path="/chatscreen" element={<ChatScreen />} />
            <Route path="/directmessages" element={<DirectMessages />} />
            <Route path="/settingsscreen" element={<SettingsScreen />} />
            <Route path="/pricingpage" element={<PricingPage />} />
            <Route path="/blockedusers" element={<BlockedUsers />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/newportal" element={<NewPortal />} />
            <Route path="/sharethought" element={<ShareThought />} />
            <Route path="/currentportal" element={<CurrentPortal />} />
            <Route path="/allresults" element={<AllResults />} />
            <Route path="/grid" element={<Grid />} />
            <Route path="/explorethoughts" element={<ExploreThoughts />} />
            <Route path="/explorethoughtsresults" element={<ExploreThoughtsResults />} />
            <Route path="/exploreideas" element={<ExploreIdeas />} />
            <Route path="/exploreideasresults" element={<ExploreIdeasResults />} />
            <Route path="/otheridea" element={<OtherIdea />} />
            <Route path="/editthought" element={<NewEditThought />} />
            <Route path="/contentclassification" element={<ContentClassification />} />

            <Route path="/shareidea" element={<ShareIdea />} />


            <Route path="/homescreenv3" element={<HomeScreenV4
              bridgeDisplayStack={bridgeDisplayStack}
              setBridgeDisplayStack={setBridgeDisplayStack}
              showMyProfileBridge={showMyProfileBridge}
              setShowMyProfileBridge={setShowMyProfileBridge}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              setTabs={setTabs}
            />} />

            <Route path="/upgradeaccount" element={<UpgradeAccount />} />
            

            <Route path="/dev" element={<HomeScreenV4 />} />
            <Route path="/homescreenv4" element={<HomeScreenV4 />} />



            // pages go here.
            // world's biggest git chat
            // ..cat.com
            // duck.com
            // coolone.com
            //  elijahdecalmer.com
            // Can I bring back my old elijahdecalmer.com

            <Route path="/howtouse" element={<HowToUse />} />
            <Route path="/whatisit" element={<WhatIsIt />} />

            // affiliates
            <Route path="/affiliates" element={<Affiliates />} />


            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </UserProvider>
      </Paper>
    </ThemeProvider>
  );
};

export default App;

