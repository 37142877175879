import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Confetti from 'react-confetti';
import CoinIcon from '@mui/icons-material/MonetizationOn';

// Use Sample
// const [readyToNavigate, setReadyToNavigate] = useState(false);
//     const [coins, setCoins] = useState(75);
//     const [coinsPopupOpen, setCoinsPopupOpen] = useState(false);
//     const handleCoinsOpen = () => {
//         setCoinsPopupOpen(true);
//     }
//     const handleCoinsClose = () => {
//         setCoinsPopupOpen(false);
//     }
//
//useEffect(() => {
//   if (readyToNavigate && !coinsPopupOpen) {
//     navigate('/addroutehere');
// }
// }, [readyToNavigate, coinsPopupOpen]);
// <CoinPopup
//             onClose={handleCoinsClose}
//             isOpen={coinsPopupOpen}
//             text="You have earned some coins!"
//             title="Coins earned"
//             numberOfCoins={coins}
//             />

const CoinPopup = ({ isOpen, title, text, numberOfCoins, onClose }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(0);
    if (isOpen) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter < numberOfCoins) return prevCounter + 1;
          clearInterval(timer);
          return prevCounter;
        });
      }, 16); // Adjust the interval to make the counting faster or slower

      return () => clearInterval(timer);
    }
  }, [isOpen, numberOfCoins]);

  const spinAnimation = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{ style: { overflow: 'hidden' } }}>
        {isOpen && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={300} />}
      <DialogTitle sx={{ textAlign: 'center', paddingBottom: 1 }}>{title}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center', paddingBottom: 3 }}>
        <Typography gutterBottom variant="h6" sx={{ paddingBottom: 2 }}>
          {text}
        </Typography>
          <Typography gutterBottom variant="h3" color="primary" style={{ alignSelf: 'center' }}>
            {counter} Coins
          </Typography>
        <CoinIcon color="#FFD700" fontSize="large" sx={{color: "#FFD700", ...spinAnimation}}/>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={onClose} color="primary" variant="contained" size="large">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CoinPopup;
