/*
This is the page where users get directed when they want to make money

It should have a plain and simple description at the top:
"We appreciate whenever you tell someone about Thought Portals. It's the only way we can grow! To say thankyou,
    we make it possible to earn real money whenever you introduce someone to our site and they subscribe.

    While most affiliate programs might offer 5-10%, we are proud to say that you will receive 35% of what we earn when
    a new customer uses your affiliate code for their first month! 35%!!!
    
    That's right. If you get 1000 people to subscribe to Thought Portals, you would earn over $2000 AUD.
"

Next is our how to guide:


"How to earn:

1. Generate your affiliate code
<Button>
Generate code
</Button>
(you will always be able to find this code in your settings page)

2. Find your favourite use case

[[[This should be a table of Use Cases]]]
[Each use case should effectively be a copy pastable script that has your affiliate link at the end]

3. Share it with friends and family
Friends and family are the best place to start, especially if you know they'll find it interesting!

4. Share your favourite use case with an online community
Do you have a following on TikTok, YouTube, LinkedIn, Instagram, Reddit or an email or contacts list?
It will be even easier if you find a community that can resonate with your use case.


5. Keep going until you see results :) you'll get an email every time someone subscribes with your affiliate code
"

*/

import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Affiliates = () => {
    const code = "554545";

    const rows = [
        {
            id: 1,
            useCase: `I've been exploring this space, https://thoughtportals.com, where it's all about connecting through similar thoughts. I found a spot where I could express my morning reflections freely. It's completely free to use, and hey, if you decide to subscribe, feel free to use my personal code to get a 30% discount: ${code}`
        },
        {
            id: 2,
            useCase: `Join me at https://thoughtportals.com where we exchange reflections and insights from our meditation practices. It's a refreshing and unique community, totally free to use. And, if you wish to subscribe, here's a little gift from me - a 30% discount with this code: ${code}`
        },
        {
            id: 3,
            useCase: `I recently stumbled upon https://thoughtportals.com, a space where you can share daily musings and find others who resonate with your thoughts. It's free to use! If you're considering subscribing, use my personal code for a 30% discount: ${code}`
        },
        {
            id: 4,
            useCase: `Hey educators and learners, I found this intriguing platform, https://thoughtportals.com, where we can discuss specific subjects and ideas. It's completely free to use, but if you fancy subscribing, here's a 30% discount code: ${code}`
        },
        {
            id: 5,
            useCase: `Are you into research? Join me at https://thoughtportals.com, a collaborative platform to share and brainstorm on groundbreaking studies. It's free to use! And guess what? You can get a 30% discount on subscription using this code: ${code}`
        },
        {
            id: 6,
            useCase: `If you're grappling with homework, come over to https://thoughtportals.com. It's a friendly space where you can find help and discussions on various topics. It's free to use, but a subscription with my 30% discount code: ${code}, might spice things up!`
        },
        {
            id: 7,
            useCase: `Do you have a business idea bubbling within? Share it at https://thoughtportals.com and find like-minded collaborators. It's free to use! If you wish to subscribe, enjoy a 30% discount with my code: ${code}`
        },
        {
            id: 8,
            useCase: `Join me at https://thoughtportals.com where we gather valuable customer insights and feedback. It's a great place to understand user perspectives, and it's completely free to use. Feel free to subscribe with a 30% discount using this code: ${code}`
        },
        {
            id: 9,
            useCase: `I found this space, https://thoughtportals.com, where we can share and nurture our career aspirations together. It's a nurturing community, completely free to use. If you decide to subscribe, use my personal code for a 30% discount: ${code}`
        },
        {
            id: 10,
            useCase: `Calling all book lovers! Share your reflections on your favorite books at https://thoughtportals.com. It's a wonderful community and free to use. Plus, enjoy a 30% discount on subscription with this code: ${code}`
        },
        {
            id: 11,
            useCase: `Hey movie enthusiasts, let's discuss our interpretations and thoughts on different movies at https://thoughtportals.com. It's a fantastic space, free to use! Considering subscribing? Use my code for a 30% discount: ${code}`
        },
        {
            id: 12,
            useCase: `Are you passionate about travel? Share and discover travel ideas at https://thoughtportals.com. It's a vibrant community and free to join. For a deeper dive, subscribe with a 30% discount using this code: ${code}`
        },
        {
            id: 13,
            useCase: `Are you into creative writing? I found this amazing community at https://thoughtportals.com where you can share your pieces and get feedback. It's free to use! If you'd like, subscribe with a 30% discount using my code: ${code}`
        },
        {
            id: 14,
            useCase: `Artists, I found our hub! Join me at https://thoughtportals.com to share your projects and find collaborators. It's completely free to use, but if you'd like to subscribe, here's a 30% discount code: ${code}`
        },
        {
            id: 15,
            useCase: `If DIY is your thing, you've got to check out https://thoughtportals.com. A community of enthusiasts sharing cool projects. It's free to use! Plus, if you decide to subscribe, use my code to get a 30% discount: ${code}`
        },
        {
            id: 16,
            useCase: `Join a delightful community at https://thoughtportals.com where we share exciting cooking recipes and tips. It's completely free to use! Thinking about subscribing? Enjoy a 30% discount with my code: ${code}`
        },
        {
            id: 17,
            useCase: `Fitness enthusiasts, I've found our spot! Join me at https://thoughtportals.com to share workouts and tips. It's free to use, and if you're considering subscribing, here's a 30% discount code: ${code}`
        },
        {
            id: 18,
            useCase: `Tech enthusiasts, let's converge at https://thoughtportals.com to discuss the latest trends and insights. It's a free platform to use! Also, you can enjoy a 30% discount on subscription using this code: ${code}`
        },
        {
            id: 19,
            useCase: `Let's discuss global issues and share thoughts at https://thoughtportals.com, a space fostering insightful conversations. It's free to use! And, here's a 30% subscription discount code if you're interested: ${code}`
        },
        {
            id: 20,
            useCase: `I've been sharing personal development tips at https://thoughtportals.com. It's a free platform to connect and grow together. If you're considering subscribing, here's a 30% discount code for you: ${code}`
        }
    ];
    

    
    

  const columns = [
    { 
      field: 'useCase', 
      headerName: 'Use Cases', 
      flex: 6,
      cellClassName: 'bordered-cell', 
      headerClassName: 'bordered-cell',
    },
    {
      field: 'copy',
      headerName: 'Copy',
      flex: 1,
      renderCell: (params) => (
        <ContentCopyIcon
        onClick={(event) => {
            event.stopPropagation();
            navigator.clipboard.writeText(params.row.useCase.replace('${code}', code)).then(() => {
            alert('Copied to clipboard!');
            }, (error) => {
            alert('Copy failed! Please try again.');
            });
        }}
        style={{ cursor: 'pointer' }}
        />

      ),
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'bordered-cell', 
      headerClassName: 'bordered-cell',
    },
  ];

  return (
    <div>
        {/* This is the page where users get directed when they want to make money */}
        <div style={{ margin: '20px 0', padding: '10px', backgroundColor: '#f8f9fa', borderRadius: '5px', border: '1px solid #e9ecef' }}>
            <h2>Make Money with Thought Portals</h2>
            <p>
                We appreciate whenever you tell someone about Thought Portals. It's the only way we can grow! To say thank you,
                we make it possible to earn real money whenever you introduce someone to our site and they subscribe.
            </p>
            <p>
                While most affiliate programs might offer 5-10%, we are proud to say that you will receive 35% of what we earn when
                a new customer uses your affiliate code for their first month! 35%!!!
            </p>
            <p>
                That's right. If you get 1000 people to subscribe to Thought Portals, you would earn over $2000 AUD.
            </p>
        </div>
        
        {/* Next is our how to guide: */}
        <div style={{ margin: '20px 0', padding: '10px', backgroundColor: '#f8f9fa', borderRadius: '5px', border: '1px solid #e9ecef' }}>
            <h2>How to Earn</h2>
            <ol>
                <li>
                    Generate your affiliate code
                    <button style={{ margin: '0 10px' }}>Generate code</button>
                    (you will always be able to find this code in your settings page)
                </li>
                <li>Find your favourite use case</li>
                <li>Share it with friends and family (Friends and family are the best place to start, especially if you know they'll find it interesting!)</li>
                <li>Share your favourite use case with an online community (Do you have a following on TikTok, YouTube, LinkedIn, Instagram, Reddit or an email or contacts list? It will be even easier if you find a community that can resonate with your use case.)</li>
                <li>Keep going until you see results :) you'll get an email every time someone subscribes with your affiliate code</li>
            </ol>
        </div>

        {/* This should be a table of Use Cases */}
        {/* Each use case should effectively be a copy pastable script that has your affiliate link at the end */}
        <div style={{ height: 400, width: '100%', border: '1px solid black' }}>
            <DataGrid
                rows={rows}
                columns={columns.map((col) => ({
                    ...col,
                    cellClassName: 'bordered-cell',
                    headerClassName: 'bordered-cell',
                }))}
                pageSize={5}
                hideFooterPagination
                hideFooter
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                className="bordered-grid"
                rowHeight={150} // Adjust as necessary
                componentsProps={{
                    cell: {
                        style: {
                            whiteSpace: 'normal',
                            lineBreak: 'anywhere',
                            lineHeight: '1.3',
                            maxHeight: '100%',
                            overflow: 'visible',
                        },
                    },
                }}
            />
        </div>
    </div>
);
};

export default Affiliates;