import { FIREBASE_AUTH } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp, doc, getDocs, query, where, limit, orderBy, setDoc } from "firebase/firestore";
import { FIRESTORE_DB } from "../firebaseConfig";
import { useRef } from "react";
import { incrementThoughtSaves } from "../IncrementStats";



export const saveThought = async ({ thoughtNumber, inputValue, user }) => {

    try {
        if (!user) {
            console.log("User not found. Redirecting to /login...");
            return { errorCode: 694, errorMessage: "User not found" };
        }

        if (inputValue === "") {
            console.log("Thought input is empty");
            return { errorCode: 690, errorMessage: "Thought input is empty" };
        }


        const newThoughtNumber = thoughtNumber + 1;



        const myThoughtsEntry = {
            quote: inputValue,
            createdAt: serverTimestamp(),
            isPublic: false,
            isProfile: false,
            isVault: false,
            thoughtNumber: newThoughtNumber,    
        };

        // docRef to the Users -> user.uid -> myThoughts collection random new empty document
        const docRef = doc(collection(FIRESTORE_DB, "Users", user.uid, "myThoughts"));



        console.log("Sending data to node server...");
        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/save_thought', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quote: inputValue,
                seedID: docRef.id,
                userID: user.uid,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Thought shared with Flask server:", data);

        // The function was successful, no error codes needed.
        await setDoc(docRef, myThoughtsEntry);

        await incrementThoughtSaves();

        return { errorCode: 0, errorMessage: "Success", data, docID: docRef.id };
    } catch (error) {
        console.error('Error during sharing thought:', error);
        // Return an error code that indicates an unknown error occurred.
        return { errorCode: 700, errorMessage: "An error occurred while sharing the thought" };
    } finally {
        // If you have any cleanup code or you need to reset state, do it here.
    }
};

