import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection, serverTimestamp, increment } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import checkString from './ContentModeration';
import { setItem } from './storage';
import { checkIfUserBlocked } from './BlockedUsersHelper';
import LoadingComponent from './LoadingComponent';
import addCoinsToUser from './AddCoinsToUser';
import CoinPopup from './CoinPopup';



const Section = ({ title, max, helpText, fieldName, errorMessage, value, onChange, handleClickOpen, placeholder }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography>{title}</Typography>
          {value && <Box sx={{width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#00FF00'}} />}
        </Box>
        <IconButton variant="contained" onClick={() => handleClickOpen(helpText)}>
          <HelpOutlineIcon />
        </IconButton>
      </Box>
      <TextField
        error={!!errorMessage}
        helperText={errorMessage}
        fullWidth
        variant="outlined"
        inputProps={{ maxLength: max }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Box>
  );
}

export const fetchAndSaveAllResults = async (documentIDs = []) => {
  if (!documentIDs.length) {
    console.log('No document IDs provided');
    return [];
  }


  const fetchIdeaDocument = async (id) => {
    const docRefFetch = doc(FIRESTORE_DB, "Ideas", id);
    const ideaDoc = await getDoc(docRefFetch);

    if (ideaDoc.exists) {
      const ideaData = { id: ideaDoc.id, ...ideaDoc.data() };
      return ideaData;
    } else {
      console.log(`Idea document not found for id: ${id}`);
      return null;
    }
  };

  const fetchedIdeaDocs = await Promise.all(documentIDs.map(fetchIdeaDocument));

  console.log('Fetched results:', fetchedIdeaDocs);

  const uids = fetchedIdeaDocs.map((quote) => quote.userID);
  // Check for blocked users
  const blockedUserIDs = await checkIfUserBlocked(uids);

  // blockedUserIDs.push(FIREBASE_AUTH.currentUser.uid); ADD THIS BACK LATER TO BLOCK YOUR OWN IDEAS, WAIT no LEAVE THIS LIKE THIS
  // Filter out blocked users and self
  const filteredResults = fetchedIdeaDocs.filter(
    (idea) => !blockedUserIDs.includes(idea.userID)
  );

  console.log("FILTERED RESULTS", filteredResults);
  return filteredResults;
};

const ShareIdea = () => {
  const [open, setOpen] = useState(false);
  const [helpText, setHelpText] = useState('');
  const user = FIREBASE_AUTH.currentUser;
  const statsRef = doc(FIRESTORE_DB, 'Users', user.uid, 'Stats', 'writes');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const latitudeRef = useRef(null);
  const longitudeRef = useRef(null);

  const [formData, setFormData] = useState({
    title: '',
    implementationMedium: '',
    description: '',
    idealTeam: '',
    desiredOutcome: '',
  });

  const [titleError, setTitleError] = useState('');
  const [implementationMediumError, setImplementationMediumError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [idealTeamError, setIdealTeamError] = useState('');
  const [desiredOutcomeError, setDesiredOutcomeError] = useState('');

  const [readyToNavigate, setReadyToNavigate] = useState(false);
    const [coins, setCoins] = useState(183);
    const [coinsPopupOpen, setCoinsPopupOpen] = useState(false);
    const handleCoinsOpen = () => {
        setCoinsPopupOpen(true);
    }
    const handleCoinsClose = () => {
        setCoinsPopupOpen(false);
    }

    useEffect(() => {
      if (readyToNavigate && !coinsPopupOpen) {
          navigate('/exploreideasresults');
      }
  }, [readyToNavigate, coinsPopupOpen]);

  const handleLocationCheckboxChange = (e) => {
    if (e.target.checked) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lon = position.coords.longitude;
                    latitudeRef.current = lat; // Update ref
                    longitudeRef.current = lon; // Update ref
                },
                (error) => {
                    console.error("Error obtaining location:", error);
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    } else {
        // Reset the latitude and longitude if the checkbox is unchecked
        latitudeRef.current = null; // Update ref
        longitudeRef.current = null; // Update ref
    }
};

  const handleChange = fieldName => event => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
  };

  const handleClickOpen = (text) => {
    setOpen(true);
    setHelpText(text);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    let hasErrors = false;

    // check for every field having something in it, else show warnings for each relevant section
    if (!formData.title || formData.title.trim() === "") {
      setTitleError('Title cannot be empty');
      hasErrors = true;
    } else {
      const isTitleAccepted = await checkString(formData.title);
      if (!isTitleAccepted) {
        setTitleError('Please follow the terms and conditions of the app, and keep your input safe and respectful.');
        hasErrors = true;
      } else {
        setTitleError('');
      }
    }

    if (!formData.implementationMedium || formData.implementationMedium.trim() === "") {
      setImplementationMediumError('Implementation Medium cannot be empty');
      hasErrors = true;
    } else {
      const isImplementationMediumAccepted = await checkString(formData.implementationMedium);
      if (!isImplementationMediumAccepted) {
        setImplementationMediumError('Please follow the terms and conditions of the app, and keep your input safe and respectful.');
        hasErrors = true;
      } else {
        setImplementationMediumError('');
      }
    }

    if (!formData.description || formData.description.trim() === "") {
      setDescriptionError('Description cannot be empty');
      hasErrors = true;
    } else {
      const isDescriptionAccepted = await checkString(formData.description);
      if (!isDescriptionAccepted) {
        setDescriptionError('Please follow the terms and conditions of the app, and keep your input safe and respectful.');
        hasErrors = true;
      } else {
        setDescriptionError('');
      }
    }

    if (!formData.idealTeam || formData.idealTeam.trim() === "") {
      setIdealTeamError('Ideal Team cannot be empty');
      hasErrors = true;
    } else {
      const isIdealTeamAccepted = await checkString(formData.idealTeam);
      if (!isIdealTeamAccepted) {
        setIdealTeamError('Please follow the terms and conditions of the app, and keep your input safe and respectful.');
        hasErrors = true;
      } else {
        setIdealTeamError('');
      }
    }

    if (!formData.desiredOutcome || formData.desiredOutcome.trim() === "") {
      setDesiredOutcomeError('Desired Outcome cannot be empty');
      hasErrors = true;
    } else {
      const isDesiredOutcomeAccepted = await checkString(formData.desiredOutcome);
      if (!isDesiredOutcomeAccepted) {
        setDesiredOutcomeError('Please follow the terms and conditions of the app, and keep your input safe and respectful.');
        hasErrors = true;
      } else {
        setDesiredOutcomeError('');
      }
    }

    if (hasErrors) {
      setLoading(false);
      return;
    }

    // save idea to firestore and get seedID
    // call api endpoint upsert_idea with each field etc aprams
    // save result to local storage before navigating to next page, or scrolling down to reveal results
    if (!user) {
      navigate("/login");
      setLoading(false);
      return;
    }

    const statsSnapshot = await getDoc(statsRef);
    if (statsSnapshot.exists()) {
      const statsData = statsSnapshot.data();

      if (statsData.last_write_date.toDate().toDateString() !== new Date().toDateString()) {
        await setDoc(statsRef, {
          quotes_writes: 0,
          last_write_date: serverTimestamp()
        });
      } else if (statsData.quotes_writes >= 50) {
        // If it's the same day and the user has already written 50 quotes
        handleClickOpen("You have already written 50 times today, come back tommorrow please. We apologise for this limit, but we need it to ensure quality and safety. Thank you for understanding");
        setLoading(false);
        return;
      }
    }

    const checkbox = document.getElementById('locationCheckbox');
    const asyncLocationCheck = async () => {
      return new Promise((resolve, reject) => {
          const startTime = Date.now();
          if (checkbox.checked === false) {
              latitudeRef.current = null;
              longitudeRef.current = null;
              reject('Checkbox was unchecked'); // Reject the Promise with a specific message
          } else if (Date.now() - startTime < 15000) {
  
              // Define a function that will check for the latitude and longitude
              const checkLocation = () => {
                  console.log("Entered checkLocation");
                  if (latitudeRef.current && longitudeRef.current) { // refs are triggering when they should be null?
                      console.log("latitudeRef", latitudeRef);
                      console.log("longitudeRef", longitudeRef);
                      // Latitude and longitude are set, resolve the Promise
                      console.log("HWELL YEAH");
                      resolve();
                  } else if (Date.now() - startTime < 15000) { // Continue checking for up to 15 seconds
                      setTimeout(checkLocation, 25); // Check again in 25ms
                  } else {
                      // 15 seconds has passed without getting the location
                      setLoading(false);
                      checkbox.checked = false;
                      reject(); // Reject the Promise
                  }
              };
              
              // Continue checking for up to 15 seconds
              setTimeout(checkLocation, 25); // Check again in 25ms
              console.log("Hmmmm line 304");
              checkLocation();

          } else {
              // 15 seconds has passed without getting the location
              setLoading(false);
              checkbox.checked = false;
              reject('Location timeout'); // Reject the Promise with a specific message
          }
  
      });
  };

  try {
      await asyncLocationCheck();
      // Proceed with your code, since latitude and longitude are available
      console.log("directly after waiting for asyncLocationCheck()");
  } catch (error) {
      if (error === 'Location timeout') {
          console.log('Location was not obtained within 15 seconds.');
          setLoading(false);
          return;
      } else {
          console.log('The checkbox was unchecked.');
      }
  }

    handleCoinsOpen();

    const idea = {
      createdAt: serverTimestamp(),
      title: formData.title,
      implementationMedium: formData.implementationMedium,
      description: formData.description,
      idealTeam: formData.idealTeam,
      desiredOutcome: formData.desiredOutcome,
      userID: user.uid,
      displayName: user.displayName,
      profilePicURL: user.photoURL
    };



    // Save to Firestore
    addDoc(collection(FIRESTORE_DB, 'Ideas'), idea)
      .then(async (documentReference) => {
        console.log('DocumentSnapshot written with ID: ', documentReference.id);
        const seedID = documentReference.id;

        updateDoc(doc(FIRESTORE_DB, "Ideas", seedID), {
          seedID: seedID
        });

        const History = {
          createdAt: serverTimestamp(),
          title: formData.title,
          implementationMedium: formData.implementationMedium,
          description: formData.description,
          idealTeam: formData.idealTeam,
          desiredOutcome: formData.desiredOutcome,
          type: "ShareIdea",
        };
        setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'History', seedID), History)

        // Increment counter by 1
        const statsSnapshot = await getDoc(statsRef);
        if (statsSnapshot.exists()) {
          // Document exists, update it
          await updateDoc(statsRef, {
            quotes_writes: increment(1),
            last_write_date: serverTimestamp()
          });
        } else {
          // Document doesn't exist, create it
          await setDoc(statsRef, {
            quotes_writes: 1,
            last_write_date: serverTimestamp()
          });
        }

        console.log("LAT: ", latitudeRef.current);
        console.log("LON: ", longitudeRef.current);

        // Send data to the Flask server
        fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/upsert_idea', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: formData.title,
            implementationMedium: formData.implementationMedium,
            description: formData.description,
            idealTeam: formData.idealTeam,
            desiredOutcome: formData.desiredOutcome,
            seedID: seedID,
            lat: latitudeRef.current,
            lon: longitudeRef.current,
          }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            console.log('Data directly receieved from API:', data);
            console.log(seedID)

            const allResults = await fetchAndSaveAllResults(data);

            // STORE IN LOCAL STORAGE
            setItem('ideasResults', JSON.stringify(allResults));
            console.log("All results saved to ideasResults in local: ", JSON.stringify(allResults));
            addCoinsToUser(coins);
            setReadyToNavigate(true);
          })
          .catch((error) => {
            console.error('Error publishing data:', error);
            setLoading(false);
            return;
          });

      })
      .catch((error) => {
        handleClickOpen('Quote not sent (FIREBASE ERROR). Please check your internet connection and try again. Please note that there is a 50 search limit daily.');
      });

  };


  return (
    <Box sx={{ marginX: '1rem' }}>

      <form onSubmit={handleSubmit}>
        <Section
          title="Title"
          max={40}
          helpText="Give a title to your idea, try to be descriptive and interesting. This field can be used for indexing purposes."
          fieldName='title'
          errorMessage={titleError}
          value={formData.title}
          onChange={handleChange('title')}
          handleClickOpen={handleClickOpen}
          placeholder="Thought Portals - Idea Sharing Platform"
        />
        <Section
          title="Implementation Medium"
          max={100}
          helpText="Explain the medium that your idea will be implemented in. If your idea is for a website, then the Implementation Medium might be 'Website' or 'Online Website as a business'. This field can be used for indexing purposes."
          fieldName='implementationMedium'
          errorMessage={implementationMediumError}
          value={formData.implementationMedium}
          onChange={handleChange('implementationMedium')}
          handleClickOpen={handleClickOpen}
          placeholder="Website and Mobile Application"

        />
        <Section
          title="Describe"
          max={1200}
          helpText="Describe what your idea is about. Focus on the product or end result. What will it do, what will it look like, who is it for, what will it sound like, what will it feel like to use/ observe/ understand. This field can be used for indexing purposes."
          fieldName='description'
          errorMessage={descriptionError}
          value={formData.description}
          onChange={handleChange('description')}
          handleClickOpen={handleClickOpen}
          placeholder="The first platform for sharing thoughts and ideas, allowing users to see most similar thoughts and ideas from others"

        />
        <Section
          title="Ideal Team"
          max={250}
          helpText="If there was an 'ideal team' of people that would do the best job, or be best suited to bringing this idea to life, who would it be? It might be an individual or a group, or even an entire community. This field can be used for indexing purposes."
          fieldName='idealTeam'
          errorMessage={idealTeamError}
          value={formData.idealTeam}
          onChange={handleChange('idealTeam')}
          handleClickOpen={handleClickOpen}
          placeholder="Elijah De Calmer"

        />
        <Section
          title="What do you want to happen with this idea?"
          max={200}
          helpText="What do you want to happen with this idea? Is this something that you want someone to build and give you credit for the idea? Do you want to be a part of the team that brings it to life? Do you want people to do whatever good they please with this idea, but make sure not to use it for evil? Do not assume that other users will, must or even should follow this. It is only a guideline. All ideas published are done so knowing that they can and will be used however a user sees fit. This field can be used for indexing purposes."
          fieldName='desiredOutcome'
          errorMessage={desiredOutcomeError}
          value={formData.desiredOutcome}
          onChange={handleChange('desiredOutcome')}
          handleClickOpen={handleClickOpen}
          placeholder="I want it to grow because people find it useful"

        />
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {helpText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box>
            <label>
                Enable location:
                <input type="checkbox" id="locationCheckbox" onChange={handleLocationCheckboxChange} />
            </label>
        </Box>
        <Button type="submit" variant="contained" sx={{marginY: '1rem'}}>
          Share this idea!
        </Button>
      </form>
      {loading &&
        <LoadingComponent />
      }
       <CoinPopup
            onClose={handleCoinsClose}
            isOpen={coinsPopupOpen}
            text="Thank you for sharing this idea, who knows the good that will come from it. To say thank you on behalf of everyone, here's some coins that you can use!"
            title="Coins earned"
            numberOfCoins={coins}
            />
    </Box>
  );

}

export default ShareIdea;
