import React, { useState, useEffect } from "react";

const Square = ({ color, onClick }) => {
  const squareStyles = {
    width: "200px",
    height: "200px",
    backgroundColor: color,
  };

  return <div style={squareStyles} onClick={onClick}></div>;
};

const GridContainer = ({ children }) => {
  const containerStyle = {
    position: 'fixed', // This will make the container take the whole viewport
    top: '70px', // The height of your app bar
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  };

  return (
    <div style={containerStyle}>
      {children}
    </div>
  );
};


const TestGrid = () => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [centerPosition, setCenterPosition] = useState(null);
  const [colors, setColors] = useState([]);
  const transitionDuration = 500; // transition duration in ms

  useEffect(() => {
    const newColors = Array.from({ length: 20 }, () =>
      Array.from({ length: 20 }, () => `#${Math.floor(Math.random() * 16777215).toString(16)}`)
    );
    setColors(newColors);

    const handleResize = () => {
      setCenterPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSquareClick = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const squareCenterX = rect.left + rect.width / 2;
    const squareCenterY = rect.top + rect.height / 2;
    const offsetX = squareCenterX - centerPosition.x;
    const offsetY = squareCenterY - centerPosition.y;
    setOffset(prevOffset => ({ x: prevOffset.x - offsetX, y: prevOffset.y - offsetY }));
  };

  const containerStyles = {
    position: "absolute",
    top: `calc(50% + ${offset.y}px)`,
    left: `calc(50% + ${offset.x}px)`,
    transform: "translate(-50%, -50%)",
    transition: `top ${transitionDuration}ms ease-out, left ${transitionDuration}ms ease-out`,
    display: "grid",
    gridTemplateColumns: "repeat(20, 200px)",
    gridTemplateRows: "repeat(20, 200px)",
    gap: "10px",
  };

  return (
    <GridContainer>
    <div style={containerStyles}>
      {colors.map((rowColors, rowIndex) =>
        rowColors.map((color, colIndex) => (
          <Square key={`${rowIndex}-${colIndex}`} color={color} onClick={handleSquareClick} />
        ))
      )}
    </div>
    </GridContainer>
  );
};

export default TestGrid;
