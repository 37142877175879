import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Card, CardContent, Typography, Avatar, Grid } from '@mui/material';
import { getItem } from './storage';
import { FIREBASE_AUTH } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';

function ExploreIdeasResults() {
    const navigate = useNavigate();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            console.log('Fetching data...');
            setLoading(true);

            const allResultsString = await getItem('ideasResults');
            console.log('Fetched allResultsString:', allResultsString);
            let allResults;
            try {
                allResults = allResultsString ? JSON.parse(allResultsString) : [];
            } catch (e) {
                console.error('Error parsing allResults', e);
                allResults = [];
            }
            const validResults = allResults.filter(result => result !== null && result.title);
            console.log('Constructed validResults:', validResults);
            setResults(validResults);

            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            results.map((result, resultIndex) => (
              <Card
                key={resultIndex}
                sx={{ margin: 2 }}
                onClick={() =>
                  navigate('/otheridea', {
                    state: { focusUID: result.seedID},
                  })
                }
              >
                <CardContent>
                  <Typography style={{ fontWeight: 'bold' }} variant="h6">{result.title}</Typography>
                  <Typography variant="subtitle2">
                  <span style={{ fontWeight: 'bold' }}>Description:</span> {result.description}</Typography>
                  <Typography variant="subtitle2">
                    <span style={{ fontWeight: 'bold' }}>Medium:</span> {result.implementationMedium}
                  </Typography>
                  <Typography variant="subtitle2">
                    <span style={{ fontWeight: 'bold' }}>Team:</span> {result.idealTeam}
                  </Typography>
                  <Typography variant="subtitle2">
                    <span style={{ fontWeight: 'bold' }}>Outcome:</span> {result.desiredOutcome}
                  </Typography>
                  { result.geocode &&
                    <Typography variant="subtitle2">
                      <span style={{ fontWeight: 'bold' }}>Location:</span> {result.geocode}
                    </Typography>
                  }
                  <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
                    {result.profilePicURL && (
                      <Avatar src={result.profilePicURL} alt={result.displayName} />
                    )}
      
                    <Typography variant="subtitle1" fontWeight="bold" sx={{ ml: 1 }}>
                      {result.displayName}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))
          )}
        </Box>
      );
      
}

export default ExploreIdeasResults;
