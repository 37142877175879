import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Checkbox, FormControlLabel, Slider } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { doc, getDoc, setDoc, updateDoc, addDoc, collection, serverTimestamp, increment } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import checkString from './ContentModeration';
import { setItem } from './storage';
import { checkIfUserBlocked } from './BlockedUsersHelper';
import LoadingComponent from './LoadingComponent';
import CostPopup from './CostPopup';
import removeCoinsFromUser from './RemoveCoinsFromUser';

const Section = ({ title, max, helpText, fieldName, errorMessage, value, onChange, handleClickOpen, isChecked, toggleCheckbox, placeholder}) => {
  return (
    <Box sx={{ }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={toggleCheckbox} />}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography>{title}</Typography>
          {value && <Box sx={{width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#00FF00'}} />}
        </Box>
        <IconButton variant="contained" onClick={() => handleClickOpen(helpText)}>
          <HelpOutlineIcon />
        </IconButton>
      </Box>
      {isChecked &&
        <TextField
        error={!!errorMessage}
        helperText={errorMessage}
        fullWidth
        variant="outlined"
        inputProps={{ maxLength: max }}
        value={value}
        onChange={onChange}
        disabled={!isChecked}
        sx={{ mb: '1rem'}}
        placeholder={placeholder}
      />
      }
      
    </Box>
  );
}
  
  

export const fetchAndSaveAllResults = async (documentIDs = []) => {
  if (!documentIDs.length) {
    console.log('No document IDs provided');
    return [];
  }


  const fetchIdeaDocument = async (id) => {
    const docRefFetch = doc(FIRESTORE_DB, "Ideas", id);
    const ideaDoc = await getDoc(docRefFetch);

    if (ideaDoc.exists) {
      const ideaData = { id: ideaDoc.id, ...ideaDoc.data() };
      return ideaData;
    } else {
      console.log(`Idea document not found for id: ${id}`);
      return null;
    }
  };

  const fetchedIdeaDocs = await Promise.all(documentIDs.map(fetchIdeaDocument));

  console.log('Fetched results:', fetchedIdeaDocs);

  const uids = fetchedIdeaDocs.map((quote) => quote.userID);
  // Check for blocked users
  const blockedUserIDs = await checkIfUserBlocked(uids);

  // blockedUserIDs.push(FIREBASE_AUTH.currentUser.uid); ADD THIS BACK LATER TO BLOCK YOUR OWN IDEAS, WAIT no LEAVE THIS LIKE THIS
  // Filter out blocked users and self
  const filteredResults = fetchedIdeaDocs.filter(
    (idea) => !blockedUserIDs.includes(idea.userID)
  );

  console.log("FILTERED RESULTS", filteredResults);
  return filteredResults;
};

const ExploreIdeas = () => {
  const [open, setOpen] = useState(false);
  const [helpText, setHelpText] = useState('');
  const user = FIREBASE_AUTH.currentUser;
  const statsRef = doc(FIRESTORE_DB, 'Users', user.uid, 'Stats', 'writes');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [titleChecked, setTitleChecked] = useState(false);
  const [implementationMediumChecked, setImplementationMediumChecked] = useState(false);
  const [descriptionChecked, setDescriptionChecked] = useState(false);
  const [idealTeamChecked, setIdealTeamChecked] = useState(false);
  const [desiredOutcomeChecked, setDesiredOutcomeChecked] = useState(false);

  const latitudeRef = useRef(null);
  const longitudeRef = useRef(null);
  const [sliderValue, setSliderValue] = useState(0); // Assuming the initial value is the middle point
  const sliderValueRef = useRef(0); // Ref for immediate access
  const sliderTexts = ["Searching based on similarity of ideas only", "Searching within 2500km", "Searching within 600km", "Searching within 70km", "Searching within 20km", "Searching within 2km"];

  const handleSliderChange = (event, newValue) => {
      setSliderValue(newValue);
      sliderValueRef.current = newValue;

      if (newValue === 0){
          latitudeRef.current = null;
          longitudeRef.current = null;
      }else{
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                  (position) => {
                      const lat = position.coords.latitude;
                      const lon = position.coords.longitude;
                      latitudeRef.current = lat; // Update ref
                      longitudeRef.current = lon; // Update ref
                  },
                  (error) => {
                      console.error("Error obtaining location:", error);
                  }
              );
          } else {
              console.log('Geolocation is not supported by this browser.');
          }
      }

  };

  const [cost, setCost] = useState(67);
  const [costPopupOpen, setCostPopupOpen] = useState(false);
  const handleCostOpen = () => {
    setCostPopupOpen(true);
  }
  const handleCostClose = () => {
      setCostPopupOpen(false);        
      setLoading(false);
  }


  const [formData, setFormData] = useState({
    title: '',
    implementationMedium: '',
    description: '',
    idealTeam: '',
    desiredOutcome: '',
  });

  const [titleError, setTitleError] = useState('');
  const [implementationMediumError, setImplementationMediumError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [idealTeamError, setIdealTeamError] = useState('');
  const [desiredOutcomeError, setDesiredOutcomeError] = useState('');

  const handleChange = fieldName => event => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
  };

  const handleClickOpen = (text) => {
    setOpen(true);
    setHelpText(text);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    handleCostOpen();

  };

  const handleContinue = async () => {
    let fieldsFilled = [];
    let namespace = '';
    setLoading(true);

    // Iterate through the formData keys
  for (const key of Object.keys(formData)) {
    if (formData[key]) {
      // Content moderation
      if (await checkString(formData[key])) {
        fieldsFilled.push(key);
      } else {
        handleClickOpen(`Your ${key} contains inappropriate content. Please revise and try again.`);
        handleCostClose();
        setLoading(false);
        return;
      }
    }
  }

  if (!fieldsFilled.length) {
    handleClickOpen('Please fill at least one field to search for ideas.');
    handleCostClose();
    setLoading(false);
    return;
  }

    

    // Creating the namespace string
    const fieldsMapping = {
      title: 'tit',
      implementationMedium: 'med',
      description: 'des',
      idealTeam: 'tea',
      desiredOutcome: 'out'
    };

    namespace = fieldsFilled.map(field => fieldsMapping[field]).join('-');


    // save result to local storage before navigating to next page, or scrolling down to reveal results
    if (!user) {
      navigate("/login");
      setLoading(false);
      handleCostClose();
      return;
    }

    const statsSnapshot = await getDoc(statsRef);
    if (statsSnapshot.exists()) {
      const statsData = statsSnapshot.data();

      if (statsData.last_write_date.toDate().toDateString() !== new Date().toDateString()) {
        await setDoc(statsRef, {
          quotes_writes: 0,
          last_write_date: serverTimestamp()
        });
      } else if (statsData.quotes_writes >= 50) {
        // If it's the same day and the user has already written 50 quotes
        handleClickOpen("You have already written 50 times today, come back tommorrow please. We apologise for this limit, but we need it to ensure quality and safety. Thank you for understanding");
        setLoading(false);
        handleCostClose();
        return;
      }
    }

    handleCostClose();

    const History = {
      createdAt: serverTimestamp(),
      title: formData.title,
      implementationMedium: formData.implementationMedium,
      description: formData.description,
      idealTeam: formData.idealTeam,
      desiredOutcome: formData.desiredOutcome,
      type: "ExploreIdea",
  };


  const searchObject = {
    createdAt: serverTimestamp(),
    title: formData.title,
    implementationMedium: formData.implementationMedium,
    description: formData.description,
    idealTeam: formData.idealTeam,
    desiredOutcome: formData.desiredOutcome,
    type: "ExploreIdea",
    userID: user.uid,
    displayName: user.displayName,
  }
  addDoc(collection(FIRESTORE_DB, 'IdeaSearches'), searchObject);



  addDoc(collection(FIRESTORE_DB, 'Users', user.uid, 'History'), History)
    .then(async (documentReference) => {
      console.log('DocumentSnapshot written with ID: ', documentReference.id);
      const seedID = documentReference.id;

      updateDoc(doc(FIRESTORE_DB, "Users", user.uid), {
        lastExploreIdeasID: seedID
      });

      // Increment counter by 1
      const statsSnapshot = await getDoc(statsRef);
      if (statsSnapshot.exists()) {
        // Document exists, update it
        await updateDoc(statsRef, {
          quotes_writes: increment(1),
          last_write_date: serverTimestamp()
        });
      } else {
        // Document doesn't exist, create it
        await setDoc(statsRef, {
          quotes_writes: 1,
          last_write_date: serverTimestamp()
        });
      }

      const asyncLocationCheck = async () => {
        return new Promise((resolve, reject) => {
            const startTime = Date.now();
            if (sliderValueRef.current === 0) {
                latitudeRef.current = null;
                longitudeRef.current = null;
                reject('Location not required'); // Reject the Promise with a specific message
            } else if (Date.now() - startTime < 15000) {
    
                // Define a function that will check for the latitude and longitude
                const checkLocation = () => {
                    console.log("Entered checkLocation");
                    if (latitudeRef.current && longitudeRef.current) {
                        // Latitude and longitude are set, resolve the Promise
                        console.log("latitudeRef", latitudeRef.current);
                        console.log("longitudeRef", longitudeRef.current);
                        console.log("HWELL YEAH");
                        resolve();
                    } else if (Date.now() - startTime < 15000) { // Continue checking for up to 15 seconds
                        setTimeout(checkLocation, 25); // Check again in 25ms
                    } else {
                        // 15 seconds has passed without getting the location
                        setLoading(false);
                        reject(); // Reject the Promise
                    }
                };
                
                // Continue checking for up to 15 seconds
                setTimeout(checkLocation, 25); // Check again in 25ms
            } else {
                // 15 seconds has passed without getting the location
                setLoading(false);
                reject('Location timeout'); // Reject the Promise with a specific message
            }
    
        });
    };

    try {
        await asyncLocationCheck();
        // Proceed with your code, since latitude and longitude are available
        console.log("directly after waiting for asyncLocationCheck()");
    } catch (error) {
        if (error === 'Location timeout') {
            console.log('Location was not obtained within 15 seconds.');
            setLoading(false);
            return;
        } else {
            console.log('The checkbox was unchecked.');
        }
    }

      // Send data to the Flask server
      fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/search_idea', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: fieldsFilled.map(field => formData[field]).join(' '),
          namespace: namespace,
          lat: latitudeRef.current,
          lon: longitudeRef.current,
          precision: sliderValueRef.current,
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          console.log('Data directly receieved from API:', data);
          console.log(seedID)

          const allResults = await fetchAndSaveAllResults(data);

          // STORE IN LOCAL STORAGE
          setItem('ideasResults', JSON.stringify(allResults));
          console.log("All results saved to ideasResults in local: ", JSON.stringify(allResults));
          removeCoinsFromUser(cost);
          navigate('/exploreideasresults');
        })
        .catch((error) => {
          console.error('Error publishing data:', error);
          setLoading(false);
          handleCostClose();
          return;
        });

    })
    .catch((error) => {
      handleClickOpen('Idea not sent (FIREBASE ERROR). Please check your internet connection and try again. Please note that there is a 50 search limit daily.');
    });
    
  }


  return (
    <Box sx={{ marginX: '1rem' }}>
      <Typography>
        Please choose at least one field to use for searching
      </Typography>

      <form onSubmit={handleSubmit}>
      <Section
          title="Title"
          max={40}
          helpText="Give a title to your idea. This could be descriptive, or a fancy name that you came up with."
          fieldName='title'
          errorMessage={titleError}
          value={formData.title}
          onChange={handleChange('title')}
          handleClickOpen={handleClickOpen}
          isChecked={titleChecked}
          toggleCheckbox={() => setTitleChecked(!titleChecked)}
          placeholder="Thought Portals - Idea Sharing Platform"
        />
        <Section
          title="Implementation Medium"
          max={100}
          helpText="Explain the medium that your idea should be implemented in. The simple answer to 'What is it?'. For example: 'Software, app website' or 'Oil painting, large'"
          fieldName='implementationMedium'
          errorMessage={implementationMediumError}
          value={formData.implementationMedium}
          onChange={handleChange('implementationMedium')}
          handleClickOpen={handleClickOpen}
          isChecked={implementationMediumChecked}
          toggleCheckbox={() => setImplementationMediumChecked(!implementationMediumChecked)}
          placeholder="Website and Mobile Application"
        />
        <Section
          title="Describe"
          max={1200}
          helpText="Describe what your idea is about, and describe what it is. This is the most important field, and where the details should be."
          fieldName='description'
          errorMessage={descriptionError}
          value={formData.description}
          onChange={handleChange('description')}
          handleClickOpen={handleClickOpen}
          isChecked={descriptionChecked}
          toggleCheckbox={() => setDescriptionChecked(!descriptionChecked)}
          placeholder="The first platform for sharing thoughts and ideas, allowing users to see most similar thoughts and ideas from others"
        />
        <Section
          title="Ideal Team"
          max={250}
          helpText="If there was an 'ideal team' of people, who would it be? For example: 'Uni students who need a project to work on' or 'experienced artists that are into surrealism'."
          fieldName='idealTeam'
          errorMessage={idealTeamError}
          value={formData.idealTeam}
          onChange={handleChange('idealTeam')}
          handleClickOpen={handleClickOpen}
          isChecked={idealTeamChecked}
          toggleCheckbox={() => setIdealTeamChecked(!idealTeamChecked)}
          placeholder="Elijah De Calmer"
        />
        <Section
          title="What do you want to happen with this idea?"
          max={200}
          helpText="What do you want to happen with this idea? For example: 'Just credit me, Elijah De Calmer' or 'I want to build this with someone, message me!'"
          fieldName='desiredOutcome'
          errorMessage={desiredOutcomeError}
          value={formData.desiredOutcome}
          onChange={handleChange('desiredOutcome')}
          handleClickOpen={handleClickOpen}
          isChecked={desiredOutcomeChecked}
          toggleCheckbox={() => setDesiredOutcomeChecked(!desiredOutcomeChecked)}
          placeholder="I want it to grow because people find it useful"
        />
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {helpText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Box sx={{width: "90%", maxWidth: "800px"}}>
                    <Slider
                    defaultValue={sliderValue}
                    step={1}
                    marks
                    min={0}
                    max={5}
                    valueLabelDisplay="off"
                    onChange={handleSliderChange}
                    />
                    
                </Box>
                <Typography sx={{ marginBottom: "1rem"}}>
                    {sliderTexts[sliderValue]}
                </Typography>
        <Button type="submit" variant="contained">
          Search for ideas!
        </Button>
      </form>
      {loading &&
        <LoadingComponent />
      }
      <CostPopup
            isOpen={costPopupOpen}
            cost={cost}
            onClose={handleCostClose}
            onContinue={handleContinue}
            text="The quality of this platform relies on an abundance of new ideas and thoughts from people like you! To maintain the ratio of sharing ideas to exploring them, this will cost "
        />
    </Box>
    
  );

}

export default ExploreIdeas;
