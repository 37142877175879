import { useEffect } from 'react';
import { FIRESTORE_DB } from '../../../firebaseConfig';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";


// Helper functions to manage local storage
const saveThoughtsToLocal = (thoughts) => {
  localStorage.setItem('myThoughts', JSON.stringify(thoughts));
};

const getThoughtsFromLocal = () => {
  const thoughts = localStorage.getItem('myThoughts');
  return thoughts ? JSON.parse(thoughts) : [];
};

export default function useListenToMyThoughts(user, setMyThoughts, setThoughtNumber) {
  useEffect(() => {
    if (!user) {
      setMyThoughts([]);
      return () => {}; // Return an empty cleanup function
    }

    // Initially load the thoughts from local storage
    const localThoughts = getThoughtsFromLocal();
    if (localThoughts.length > 0) {
      setMyThoughts(localThoughts);
    }

    const myThoughtsRef = collection(FIRESTORE_DB, `Users/${user.uid}/myThoughts`);
    const q = query(myThoughtsRef, orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedThoughts = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      // add a thought to fetchedThoughts
      fetchedThoughts.push({
        createdAt: new Date(),
      });

      setMyThoughts(fetchedThoughts);
      console.log("Update myThoughts from useListenToMyThoughts:", fetchedThoughts)
      saveThoughtsToLocal(fetchedThoughts); // Save the fetched thoughts to local storage

      if (fetchedThoughts.length > 0) {
        const latestThoughtNumber = fetchedThoughts[0].thoughtNumber; // Assuming `thoughtNumber` is a field in your document
        setThoughtNumber(latestThoughtNumber ? latestThoughtNumber : 0);
      }

    }, error => {
      console.error("Error listening to saved thoughts:", error);
    });

    // Return the unsubscribe function which can be called to stop listening to changes
    return unsubscribe;
  }, [user, setMyThoughts, setThoughtNumber]);
}
