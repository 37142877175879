import { FIREBASE_AUTH, FIRESTORE_DB } from "./firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const removeCoinsFromUser = async (coins) => {
  const user = FIREBASE_AUTH.currentUser;

  if (user) {
    const uid = user.uid;
    const userDocRef = doc(FIRESTORE_DB, 'Users', uid);

    // Fetch current coin count
    const userDoc = await getDoc(userDocRef);
    const currentCoins = userDoc.data()?.coinCount || 0;

    // Update coin count with new coins
    await updateDoc(userDocRef, {
      coinCount: currentCoins - coins
    });
  } else {
    console.error('User is not authenticated.');
  }
};

export default removeCoinsFromUser;
