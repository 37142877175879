import React, { useEffect, useState } from 'react';

import { ManageTabsTab } from '../Tabs/ManageTabsTab/ManageTabsTab';
import { Typography } from '@mui/material';
import LoginScreen from '../../LoginScreen';
import AllThoughtsTab from '../Tabs/AllThoughtsTab/AllThoughtsTab';
import MyThoughtsTab from '../Tabs/MyThoughtsTab/MyThoughtsTab';
import { Box, display } from '@mui/system';
import MinimalistThoughtShare from '../MinimalistThoughtShare/MinimalistThoughtShare';
import MinimalistThoughtSave from '../MinimalistThoughtSave/MinimalistThoughtSave';
import { useTheme } from '@emotion/react';
import ZenShareSaveTab from '../Tabs/ZenShareSaveTab/ZenShareSaveTab';
import MessengerTab from '../MessengerTab/MessengerTab';
import SettingsTab from '../SettingsTab/SettingsTab';
import ChatScreenBridge from '../Bridges/ChatScreenBridge/ChatScreenBridge';
import OtherProfileBridge from '../Bridges/OtherProfileBridge/OtherProfileBridge';
import BlockedUsersBridge from '../Bridges/BlockedUsersBridge/BlockedUsersBridge';
import TermsAndConditionsBridge from '../Bridges/TermsAndConditionsBridge/TermsAndConditionsBridge';
import PrivacyPolicyBridge from '../Bridges/PrivacyPolicyBridge/PrivacyPolicyBridge';
import CookiesPolicyBridge from '../Bridges/CookiesPolicyBridge/CookiesPolicyBridge';
import AboutUsAndContactBridge from '../Bridges/AboutUsAndContactBridge/AboutUsAndContactBridge';
import MyProfileBridge from '../Bridges/MyProfileBridge/MyProfileBridge';
import MyThoughtsTabLocked from '../Tabs/MyThoughtsTab/MyThoughtsTabLocked';
import AllThoughtsTabLocked from '../Tabs/AllThoughtsTab/AllThoughtsTabLocked';
import EditProfileBridge from '../Bridges/EditProfileBridge/EditProfileBridge';
import NoteScannerTab from '../Tabs/NoteScannerTab/NoteScannerTab';

const DisplaySelectedTab = ({
    selectedTab,
    setSelectedTab,
    tabs,
    setTabs,
    text,
    setText,
    generalSettings,
    setGeneralSettings,
    saveThought,
    shareThought,
    thoughtNumber,
    setThoughtNumber,
    latitude,
    longitude,
    dialog,
    setDialog,
    handleCloseDialog,
    myThoughts,
    setMyThoughts,
    chatScreenFocusUID,
    setChatScreenFocusUID,
    otherProfileFocusUID,
    setOtherProfileFocusUID,
    showBlockedUsersBridge,
    setShowBlockedUsersBridge,
    showTermsAndConditionsBridge,
    setShowTermsAndConditionsBridge,
    showPrivacyPolicyBridge,
    setShowPrivacyPolicyBridge,
    showCookiesPolicyBridge,
    setShowCookiesPolicyBridge,
    showAboutUsAndContactBridge,
    setShowAboutUsAndContactBridge,
    bridgeDisplayStack = [],
    setBridgeDisplayStack= () => {},
    showMyProfileBridge,
    setShowMyProfileBridge,
    setShowDotOnManageTab,
    showEditProfileBridge,
    setShowEditProfileBridge,
 }) => {
    const theme = useTheme();

    const manageBridgeStack = (bridgeId, component, shouldAdd) => {
        const currentTabId = tabs.filter(tab => tab.enabled)[selectedTab]?.id;
        setBridgeDisplayStack(prev => {
            // Get the current stack for the selected tab or an empty array if none
            const currentStack = prev[currentTabId] || [];
    
            let updatedStack;
            if (shouldAdd) {
                // Check if the bridge already exists in the stack
                const isBridgePresent = currentStack.some(bridge => bridge.componentID === bridgeId);
                updatedStack = isBridgePresent ? currentStack : [...currentStack, { componentID: bridgeId, component }];
            } else {
                // Remove the bridge from the stack
                updatedStack = currentStack.filter(bridge => bridge.componentID !== bridgeId);
            }
    
            return { ...prev, [currentTabId]: updatedStack };
        });
    };
    

    useEffect(() => {
        if (chatScreenFocusUID) {
            manageBridgeStack('ChatScreenBridge', 
                <ChatScreenBridge focusUID={chatScreenFocusUID} setFocusUID={setChatScreenFocusUID}
                    setOtherProfileFocusUID={setOtherProfileFocusUID} />, 
                true
            );
        } else {
            manageBridgeStack('ChatScreenBridge', null, false);
        }
    }, [chatScreenFocusUID]);

    useEffect(() => {
        if (otherProfileFocusUID) {
            manageBridgeStack('OtherProfileBridge',
                <OtherProfileBridge otherProfileFocusUID={otherProfileFocusUID} setOtherProfileFocusUID={setOtherProfileFocusUID} setChatScreenFocusUID={setChatScreenFocusUID} />,
                true
            );
        } else {
            manageBridgeStack('OtherProfileBridge', null, false);
        }
    }, [otherProfileFocusUID]);

    useEffect(() => {
        console.log("showBlockedUsersBridge:", showBlockedUsersBridge);
        if (showBlockedUsersBridge) {
            manageBridgeStack('BlockedUsersBridge',
                <BlockedUsersBridge setShowBlockedUsersBridge={setShowBlockedUsersBridge} />,
                true
            );
        } else {
            // Remove BlockedUsersBridge from the stack
            manageBridgeStack('BlockedUsersBridge', null, false);
        }
    }, [showBlockedUsersBridge]);

    useEffect(() => {
        console.log("showTermsAndConditionsBridge:", showTermsAndConditionsBridge);
        if (showTermsAndConditionsBridge) {
            manageBridgeStack('TermsAndConditionsBridge',
                <TermsAndConditionsBridge setShowTermsAndConditionsBridge={setShowTermsAndConditionsBridge} />,
                true
            );
        } else {
            // Remove TermsAndConditionsBridge from the stack
            manageBridgeStack('TermsAndConditionsBridge', null, false);
        }
    }, [showTermsAndConditionsBridge]);

    useEffect(() => {
        console.log("showPrivacyPolicyBridge:", showPrivacyPolicyBridge);
        if (showPrivacyPolicyBridge) {
            manageBridgeStack('PrivacyPolicyBridge',
                <PrivacyPolicyBridge setShowPrivacyPolicyBridge={setShowPrivacyPolicyBridge} />,
                true
            );
        } else {
            // Remove PrivacyPolicyBridge from the stack
            manageBridgeStack('PrivacyPolicyBridge', null, false);
        }
    }, [showPrivacyPolicyBridge]);

    useEffect(() => {
        console.log("showCookiesPolicyBridge:", showCookiesPolicyBridge);
        if (showCookiesPolicyBridge) {
            manageBridgeStack('CookiesPolicyBridge',
                <CookiesPolicyBridge setShowCookiesPolicyBridge={setShowCookiesPolicyBridge} />,
                true
            );
        } else {
            // Remove CookiesPolicyBridge from the stack
            manageBridgeStack('CookiesPolicyBridge', null, false);
        }
    }, [showCookiesPolicyBridge]);

    useEffect(() => {
        console.log("showAboutUsAndContactBridge:", showAboutUsAndContactBridge);
        if (showAboutUsAndContactBridge) {
            manageBridgeStack('AboutUsAndContactBridge',
                <AboutUsAndContactBridge setShowAboutUsAndContactBridge={setShowAboutUsAndContactBridge} />,
                true
            );
        } else {
            // Remove AboutUsAndContactBridge from the stack
            manageBridgeStack('AboutUsAndContactBridge', null, false);
        }
    }, [showAboutUsAndContactBridge]);

    useEffect(() => {
        console.log("showMyProfileBridge:", showMyProfileBridge);
        if (showMyProfileBridge) {
            manageBridgeStack('MyProfileBridge',
                <MyProfileBridge
                    setShowMyProfileBridge={setShowMyProfileBridge}
                    setShowEditProfileBridge={setShowEditProfileBridge}
                    />,
                true
            );
        } else {
            // Remove MyProfileBridge from the stack
            manageBridgeStack('MyProfileBridge', null, false);
        }
    }, [showMyProfileBridge]);

    useEffect(() => {
        console.log("showEditProfileBridge:", showEditProfileBridge);
        if (showEditProfileBridge) {
            manageBridgeStack('showEditProfileBridge',
                <EditProfileBridge
                    setShowEditProfileBridge={setShowEditProfileBridge}
                    />,
                true
            );
        } else {
            manageBridgeStack('showEditProfileBridge', null, false);
        }
    }, [showEditProfileBridge]);

    const tabComponents = {
        signIn: {
            component: <LoginScreen/>,
            fallback: <LoginScreen/>
        },
        myThoughts:{
            component:  <MyThoughtsTab
                            text={text}
                            setText={setText}
                            generalSettings={generalSettings}
                            setGeneralSettings={setGeneralSettings}
                            saveThought={saveThought}
                            shareThought={shareThought}
                            thoughtNumber={thoughtNumber}
                            setThoughtNumber={setThoughtNumber}
                            latitude={latitude}
                            longitude={longitude}
                            dialog={dialog}
                            setDialog={setDialog}
                            handleCloseDialog={handleCloseDialog}
                            myThoughts={myThoughts}
                            setMyThoughts={setMyThoughts}
                        />,
            fallback: <MyThoughtsTabLocked
                            isVisible={selectedTab === tabs.filter(tab => tab.enabled).findIndex(tab => tab.id === 'myThoughts')}
            />
        },
        allThoughts: {
            component: <AllThoughtsTab
                            text={text}
                            setText={setText}
                            generalSettings={generalSettings}
                            setGeneralSettings={setGeneralSettings}
                            saveThought={saveThought}
                            shareThought={shareThought}
                            thoughtNumber={thoughtNumber}
                            myThoughts={myThoughts}
                            latitude={latitude}
                            longitude={longitude}
                            setOtherProfileFocusUID={setOtherProfileFocusUID}
                        />,
            fallback: <AllThoughtsTabLocked
                            isVisible={selectedTab === tabs.filter(tab => tab.enabled).findIndex(tab => tab.id === 'allThoughts')}
            />
        },
        zenShareSave: {
            component:<ZenShareSaveTab
                        saveThought={saveThought}
                        shareThought={shareThought}
                        thoughtNumber={thoughtNumber}
                        />,
            fallback: <Typography>Zen Share Save</Typography>
        },
        messengerTab:{
            component: <MessengerTab
                        setChatScreenFocusUID={setChatScreenFocusUID}
                        />,
            fallback: <MessengerTab
                        setChatScreenFocusUID={setChatScreenFocusUID}
                        />,
        },
        settingsTab:{
            component: <SettingsTab
            setShowBlockedUsersBridge={setShowBlockedUsersBridge}
            setShowTermsAndConditionsBridge={setShowTermsAndConditionsBridge}
            setShowPrivacyPolicyBridge={setShowPrivacyPolicyBridge}
            setShowCookiesPolicyBridge={setShowCookiesPolicyBridge}
            setShowAboutUsAndContactBridge={setShowAboutUsAndContactBridge}
        />,
            fallback: <SettingsTab
            setShowBlockedUsersBridge={setShowBlockedUsersBridge}
            setShowTermsAndConditionsBridge={setShowTermsAndConditionsBridge}
            setShowPrivacyPolicyBridge={setShowPrivacyPolicyBridge}
            setShowCookiesPolicyBridge={setShowCookiesPolicyBridge}
            setShowAboutUsAndContactBridge={setShowAboutUsAndContactBridge}
        />,
        },
        noteScannerTab:{
            component: <NoteScannerTab
            saveThought={saveThought}
            shareThought={shareThought}
            thoughtNumber={thoughtNumber}
            />,
            fallback: <NoteScannerTab
            saveThought={saveThought}
            shareThought={shareThought}
            thoughtNumber={thoughtNumber}
            />,
        },
        manageTabs:{
            component:<ManageTabsTab tabs={tabs} setTabs={setTabs} setSelectedTab={setSelectedTab} selectedTab={selectedTab}
                            setDialog={setDialog}
                            dialog={dialog}
                            handleCloseDialog={handleCloseDialog}
                            setShowDotOnManageTab={setShowDotOnManageTab}
                        />,
            fallback:<ManageTabsTab tabs={tabs} setTabs={setTabs} setSelectedTab={setSelectedTab} selectedTab={selectedTab}
                            setDialog={setDialog}
                            dialog={dialog}
                            handleCloseDialog={handleCloseDialog}
                            setShowDotOnManageTab={setShowDotOnManageTab}
                        />,
        } 
    };

    console.log("bridgeDisplayStack:", bridgeDisplayStack);
    console.log("tabs:", tabs);

    const getCurrentTabTopBridge = () => {
        const currentTabId = tabs.filter(tab => tab.enabled)[selectedTab]?.id;
        console.log("currentTabId:", currentTabId);
        const currentTabStack = bridgeDisplayStack[currentTabId];
        console.log("currentTabStack:", currentTabStack);
        console.log("currentTabStack[currentTabStack.length - 1]:", currentTabStack[currentTabStack.length - 1])
        return currentTabStack?.length ? currentTabStack[currentTabStack.length - 1].component : null;
    };

    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor: theme.palette.background.default }}>
            {tabs.filter(tab => tab.enabled).map((tab, index) => {
                const TabContent = tabComponents[tab.id];
                const isVisible = selectedTab === index;
                const currentTabId = tabs.filter(tab => tab.enabled)[selectedTab]?.id;
                const currentTabStack = bridgeDisplayStack[currentTabId] || [];
    
                // Determine whether to render the tab content or the bridge
                const shouldRenderTabContent = tab.id === currentTabId && currentTabStack.length === 0;
                const shouldRenderBridge = tab.id === currentTabId && currentTabStack.length > 0;
    
                return (
                    <div key={tab.id} style={{ display: isVisible ? 'flex' : 'none', flexDirection: 'column', flexGrow: 1 }}>
                        {/* Render the tab content only if there are no bridges for the selected tab */}

                            <div style={{ display: shouldRenderTabContent ? 'flex' : 'none', flexDirection: 'column', flexGrow: 1 }}>
                                {tab.unlocked ? TabContent.component : TabContent.fallback}
                            </div>
                        {/* Render the bridges for the currently selected tab */}
                        {shouldRenderBridge && currentTabStack[currentTabStack.length - 1].component}
                    </div>
                );
            })}
        </Box>
    );
    
    
}

export default DisplaySelectedTab;
