// I need a search bar component
// It needs a go button or a 'search' button, and a respective function to handle that
// It needs to be able to take in a search term and pass it to the search function
// If the search term is #x where x is a number, it should retrieve that thought number, use the quote field to populate the search bar, and then search as normal from there
// It should be smooth, and when you click on it, it expands to show your whole search query. When you hit search, or click outside, it should minimise to one line with ellipses

import React, { useState, useEffect } from 'react';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../../firebaseConfig';
import {
    collection,
    getDocs,
    query,
    where,
    orderBy,
    limit,
    getDoc,
    doc,
    Timestamp,
    addDoc
} from 'firebase/firestore';
import { TextField, Box, InputAdornment, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { checkIfUserBlocked } from '../../../BlockedUsersHelper';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import MyThoughtsSortAndFilter from './MyThoughtsSortAndFilter';
import sortAndFilterMyThoughts from './SortAndFilterMyThoughts';
import AddIcon from '@mui/icons-material/Add';


function isValidQuote(quote) {
    // Add checks for required fields, e.g.,:
    return quote && quote.userID && quote.quote; // Add other required fields here
}


const MyThoughtsSearchBar = ({
    setSortedFilteredResults,
    generalSettings,
    setGeneralSettings,
    lat,
    lon,
    thoughtNumber,
    myThoughts,
    setActiveThought,
    setShowEditThought
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isFocused, setIsFocused] = useState(false); // New state to track focus
    const [showSortAndFilter, setShowSortAndFilter] = useState(false);




    const defaultSortSettings = {
        sortBy: "similaritySort",
        sortThought: "Most recent thought (auto)",
    };

    const defaultFilterSettings = {
        isPublic: true,
        isProfile: true,
        isPublicFalse: true,
        isVault: true,
        limitToXResults: 100,
        fromDate: Timestamp.now(), // Assuming Timestamp.now() is a valid default
        toDate: Timestamp.now(),
        fromAll: true,
        toAll: true,
    };

    const getInitialSettings = (key, defaultValue) => {
        const storedSettings = localStorage.getItem(key);
        return storedSettings ? JSON.parse(storedSettings) : defaultValue;
    };

    const getInitialFilterSettings = () => {
        const storedSettings = localStorage.getItem('myThoughtsFilterSettings');
        if (storedSettings) {
            const parsedSettings = JSON.parse(storedSettings);
            return {
                ...parsedSettings,
                fromDate: Timestamp.fromMillis(parsedSettings.fromDate),
                toDate: Timestamp.fromMillis(parsedSettings.toDate),
            };
        } else {
            return {
                ...defaultFilterSettings,
                fromDate: Timestamp.now(),
                toDate: Timestamp.now(),
            };
        }
    };
    

    const [myThoughtsSortSettings, setMyThoughtsSortSettings] = useState(() => 
        getInitialSettings('myThoughtsSortSettings', defaultSortSettings));

    const [myThoughtsFilterSettings, setMyThoughtsFilterSettings] = useState( 
        getInitialFilterSettings());

    // Cache 'myThoughtsSortSettings'
    useEffect(() => {
        localStorage.setItem('myThoughtsSortSettings', JSON.stringify(myThoughtsSortSettings));
    }, [myThoughtsSortSettings]);

    // Cache 'myThoughtsFilterSettings'
    useEffect(() => {
        const settingsToSave = {
            ...myThoughtsFilterSettings,
            fromDate: myThoughtsFilterSettings.fromDate.toMillis(),
            toDate: myThoughtsFilterSettings.toDate.toMillis(),
        };

        localStorage.setItem('myThoughtsFilterSettings', JSON.stringify(settingsToSave));
    }, [myThoughtsFilterSettings]);   
    

    useEffect(() => {
        console.log("Entered useEffect in myThoughtsSearchBar");
        if (myThoughtsSortSettings.sortBy === 'similaritySort') {
            if (myThoughtsSortSettings.sortThought === 'Most recent thought (auto)') {
                setSearchTerm('#recent');
            } else {
                setSearchTerm('#' + myThoughtsSortSettings.sortThought.slice(8));
            }
        }
        if (myThoughtsSortSettings.sortBy === 'chronoSort') {
            setSearchTerm('#chronoSort');
        }
        if (myThoughtsSortSettings.sortBy === 'reChronoSort') {
            setSearchTerm('#reChronoSort');
        }
        if (myThoughtsSortSettings.sortBy === 'lengthSort') {
            setSearchTerm('#lengthSort');
        }
        if (myThoughtsSortSettings.sortBy === 'reLengthSort') {
            setSearchTerm('#reLengthSort');
        }
        if (myThoughtsSortSettings.sortBy === 'randomSort') {
            setSearchTerm('#randomSort');
        }
        if (myThoughtsSortSettings.sortBy === 'searchSort') {
            if (searchTerm != '#'){
                setSearchTerm('');

            }
        }


    }, [myThoughtsSortSettings]);

    

    const handleSearch = async () => {
        sortAndFilterMyThoughts(myThoughts, setSortedFilteredResults, searchTerm, myThoughtsFilterSettings)
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchTermKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const commonStyle = {
        borderRadius: '4px', // example corner radius
        border: '1px solid', // example border style
        borderColor: 'rgba(0, 0, 0, 0.23)', // example border color
    };

    return (
        <Box style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
            paddingTop: '10px',
            minWidth: '100%'
        }}>
            <Box style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
            }}>
                <TextField
                    id="search-bar"
                    label="Search for similar thoughts..."
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    multiline
                    maxRows={isFocused ? 10 : 1}
                    onKeyPress={handleSearchTermKeyPress}
                    style={{
                        flexGrow: 1, // TextField takes up remaining space
                        marginRight: '8px', // Space between TextField and IconButton
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search"
                                    onClick={handleSearch}
                                    edge="end"
                                    style={{
                                        padding: '10px', // Adjust padding to match height
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="add_note"
                                    onClick={()=>{
                                        setActiveThought({
                                            quote: searchTerm,
                                        });
                                        setShowEditThought(true);

                                    }}
                                    edge="end"
                                    style={{
                                        padding: '10px', // Adjust padding to match height
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <IconButton
                    aria-label="filter"
                    onClick={() => setShowSortAndFilter(!showSortAndFilter)}
                    edge="end"
                    style={{
                        ...commonStyle, // Apply common styles
                        marginRight: '0px',
                        height: '55px'
                    }}
                >
                    <MultipleStopIcon style={{
                        transform: showSortAndFilter ? 'rotate(270deg)' : 'rotate(90deg)',
                        transition: 'transform 0.6s ease',
                    }} />
                </IconButton>
            </Box>
            {(showSortAndFilter || searchTerm.startsWith('#')) &&
                <MyThoughtsSortAndFilter
                    sortSettings={myThoughtsSortSettings}
                    filterSettings={myThoughtsFilterSettings}
                    generalSettings={generalSettings}
                    setSortSettings={setMyThoughtsSortSettings}
                    setFilterSettings={setMyThoughtsFilterSettings}
                    setGeneralSettings={setGeneralSettings}
                    thoughtNumber={thoughtNumber}
                    showSortAndFilter={showSortAndFilter}
                    setShowSortAndFilter={setShowSortAndFilter}
                    runSearch={handleSearch}
                    searchTerm={searchTerm}
                />

            }
        </Box>
    );
}

export default MyThoughtsSearchBar;