import { Box, Typography, TextField, Button, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PrimaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'black' : 'white',
  backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242',
  },
  borderRadius: '12px',
  margin: '8px 0',
}));

const Tutorial = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDarkMode = theme.palette.mode === 'dark';
  const [text, setText] = useState('');
  const exampleText = 'eg. I think rainy days are nice sometimes, especially when you can stay inside';

  const typingRef = useRef();  // New reference

  useEffect(() => {
    let i = 0;

    // Clear previous interval if exists
    if (typingRef.current) {
      clearInterval(typingRef.current);
    }

    typingRef.current = setInterval(() => {
      if (i < exampleText.length) {
        setText((prevText) => prevText + exampleText[i]);
        i++;
      } else {
        clearInterval(typingRef.current);
      }
    }, 23);
    return () => clearInterval(typingRef.current);  // Now clears interval using ref
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gridGap: 2,
        width: '90%',
        maxWidth: 1000,
        margin: '0 auto',
        padding: '1rem',
        transition: 'background-color 0.5s',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom sx={{ marginLeft: '1rem'}}>
        How it works...
      </Typography>
      <Typography variant="body1" align="left" gutterBottom sx={{ marginLeft: '1rem', marginTop: '2rem' }}>
        1. Type your thought:
      </Typography>
      <TextField
        variant="filled"
        multiline
        value={text}
        fullWidth
        InputProps={{
          disableUnderline: true,
          readOnly: true,
          style: {
            borderRadius: 12,
            border: `1px solid ${isDarkMode ? 'white' : 'black'}`,
            pointerEvents: 'none',
          },
        }}
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      />
      <Typography variant="body1" align="left" gutterBottom sx={{ marginLeft: '1rem', marginTop: '2rem' }}>
        2. The thought wizard will build you a portal at just the right location.
      </Typography>
      <Box
        sx={{
          width: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
          marginLeft: '1rem',
          marginRight: '1rem',

        }}>
            <img src="/wizard-portal-trans.png" alt="Wizard Portal" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Box>
      <Typography variant="body1" align="left" gutterBottom sx={{ marginLeft: '1rem', marginTop: '0rem' }}>
        3. Tap on the portal to go to similar thoughts! From here you can find people that think like you, get a fresh
        perspective or some new ideas, and understand the world from your point of view!
      </Typography>
      <PrimaryButton onClick={() => navigate('/newportal')} variant="contained" fullWidth sx={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem', marginBottom: '5rem' }}>
        Now, try it with your own thought!
      </PrimaryButton>
    </Box>
  );
};

export default Tutorial;
