import React, { useEffect, useState } from "react";
import {
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from "@mui/material";
import { Box } from "@mui/system";
import MyThoughtsSortAndFilter from "./MyThoughtsSortAndFilter";
import ThoughtsTextField from "../../InBothTabs/ThoughtsTextField";
import { Timestamp } from 'firebase/firestore';
import useListenToMyThoughts from "./useListenToMyThoughts";
import { FIREBASE_AUTH } from "../../../firebaseConfig";
import RenderMyThoughts from "./RenderMyThoughts";
import sortAndFilterMyThoughts from "./SortAndFilterMyThoughts";
import CustomDialog from "../../../CustomDialog";
import LoadingComponent from "../../../LoadingComponent";
import { useNavigate } from "react-router-dom";
import NewEditThought from "./NewEditThought";
import { saveOrUpdateThought } from "./MyThoughtMenu";
import { Settings } from "@mui/icons-material";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from "@mui/material";
import MyThoughtsSearchBar from "./MyThoughtsSearchBar";

export default function MyThoughtsTab({
    text,
    setText,
    generalSettings,
    setGeneralSettings,
    saveThought,
    shareThought,
    sortThoughts,
    filterThoughts,
    thoughtNumber,
    setThoughtNumber,
    latitude,
    longitude,
    dialog,
    setDialog,
    handleCloseDialog,
    myThoughts,
    setMyThoughts
}) {
    const theme = useTheme();
    const user = FIREBASE_AUTH.currentUser;
    const navigate = useNavigate();
    useListenToMyThoughts(user, setMyThoughts, setThoughtNumber);

    const [showSortAndFilter, setShowSortAndFilter] = useState(true);
    const [mostRecentlyClickedThoughtText, setMostRecentlyClickedThoughtText] = useState('');

    const defaultSortSettings = {
        sortBy: "similaritySort",
        sortThought: "Most recent thought (auto)",
    };

    const defaultFilterSettings = {
        isPublic: true,
        isProfile: true,
        isPublicFalse: true,
        isVault: true,
        limitToXResults: 100,
        fromDate: Timestamp.now(), // Assuming Timestamp.now() is a valid default
        toDate: Timestamp.now(),
        fromAll: true,
        toAll: true,
    };

    const getInitialSettings = (key, defaultValue) => {
        const storedSettings = localStorage.getItem(key);
        return storedSettings ? JSON.parse(storedSettings) : defaultValue;
    };

    const getInitialFilterSettings = () => {
        const storedSettings = localStorage.getItem('myThoughtsFilterSettings');
        if (storedSettings) {
            const parsedSettings = JSON.parse(storedSettings);
            return {
                ...parsedSettings,
                fromDate: Timestamp.fromMillis(parsedSettings.fromDate),
                toDate: Timestamp.fromMillis(parsedSettings.toDate),
            };
        } else {
            return {
                ...defaultFilterSettings,
                fromDate: Timestamp.now(),
                toDate: Timestamp.now(),
            };
        }
    };
    

    const [myThoughtsSortSettings, setMyThoughtsSortSettings] = useState(() => 
        getInitialSettings('myThoughtsSortSettings', defaultSortSettings));

    const [myThoughtsFilterSettings, setMyThoughtsFilterSettings] = useState( 
        getInitialFilterSettings());

    // Cache 'myThoughtsSortSettings'
    useEffect(() => {
        localStorage.setItem('myThoughtsSortSettings', JSON.stringify(myThoughtsSortSettings));
    }, [myThoughtsSortSettings]);

    // Cache 'myThoughtsFilterSettings'
    useEffect(() => {
        const settingsToSave = {
            ...myThoughtsFilterSettings,
            fromDate: myThoughtsFilterSettings.fromDate.toMillis(),
            toDate: myThoughtsFilterSettings.toDate.toMillis(),
        };

        localStorage.setItem('myThoughtsFilterSettings', JSON.stringify(settingsToSave));
    }, [myThoughtsFilterSettings]);

    const [sortedFilteredThoughts, setSortedFilteredThoughts] = useState([]);
    
    // cache 'myThoughts' and on mount, set 'sortedFilteredThoughts' to 'myThoughts'
    useEffect(() => {
        setSortedFilteredThoughts(myThoughts);
    }, [myThoughts]);

    // cache myThoughts to local storage so it is very fast to retrieve on first load when myThoughts is null
    useEffect(() => {
        localStorage.setItem('myThoughts', JSON.stringify(myThoughts));
    }, [myThoughts]);

    // on load, when myThoughts is null, set it to the cached version
    useEffect(() => {
        if (myThoughts === null) {
            console.log("myThoughts is null, setting it to cached version");
            const cachedMyThoughts = localStorage.getItem('myThoughts');
            if (cachedMyThoughts) {
                setSortedFilteredThoughts(JSON.parse(cachedMyThoughts));
            }
        }
    }
    , []);





    
    const [activeThought, setActiveThought] = useState(null);
    const [showEditThought, setShowEditThought] = useState(false);
    const [loading, setLoading] = useState(false);

    const memoizedRenderMyThoughts = React.useMemo(() => {
        return(
            <RenderMyThoughts
            thoughts={myThoughts}
            dialog={dialog}
            setDialog={setDialog}
            handleCloseDialog={handleCloseDialog}
            longitude={longitude}
            latitude={latitude}
            generalSettings={generalSettings}
            thoughtNumber={thoughtNumber}
            setActiveThought={setActiveThought}
            setShowEditThought={setShowEditThought}
        />
        )
    }, [myThoughts, dialog, longitude, latitude, generalSettings]);

    const timestampToDisplayDate = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
    
        // Array of month abbreviations
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
        // Extract the year, month, and day
        const year = date.getFullYear();
        const month = months[date.getMonth()]; // Get the month abbreviation
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${day}-${month}-${year}`;
    };

    const backArrowClick = (activeThought, user, textValue, thoughtNumber) => {
        setShowEditThought(false);
        setActiveThought(null);
    }

    useEffect(() => {
        if(!setShowEditThought) {
            setActiveThought(null);
        }
    }
    , [setShowEditThought]);
    

    return (
        <Box fullWidth sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%', // Make the box full width
            maxWidth: '100%', // Make the box full width
            marginX: 'auto', // Center the box with auto margins on the sides
            paddingX: '10px',
            paddingTop: '10px',
            backgroundColor: theme.palette.background.default,

        }}>
            
        {!showEditThought &&
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

        <Fab 
                color="primary" 
                aria-label="add" 
                sx={{ 
                    position: 'fixed', 
                    bottom: 16, 
                    right: 16,
                    borderRadius: '10px', // Optional, if you want rounded corners
                    boxShadow: 2 // Optional, for shadow effect
                }}
                onClick={() => {
                    // Logic when FAB is clicked
                    setShowEditThought(true);
                }}
            >
                <AddIcon />
            </Fab>

            <MyThoughtsSearchBar
                    setSortedFilteredResults={setSortedFilteredThoughts}
                    generalSettings={generalSettings}
                    setGeneralSettings={setGeneralSettings}
                    lat={latitude}
                    lon={longitude}
                    thoughtNumber={thoughtNumber}
                    showSortAndFilter={showSortAndFilter}
                    setShowSortAndFilter={setShowSortAndFilter}
                    myThoughts={myThoughts}
                    setActiveThought={setActiveThought}
                    setShowEditThought={setShowEditThought}
            
            />

        </div>
        }

        <div style={{ display: showEditThought ? 'block' : 'none', minWidth: '100%' }}>
        <NewEditThought
            activeThought={activeThought}
            setActiveThought={setActiveThought}
            backArrowClick={backArrowClick}
            setDialog={setDialog}
            handleCloseDialog={handleCloseDialog}
            navigate={navigate}
            loading={false}
            setLoading={setLoading}
            latitude={latitude}
            longitude={longitude}
            generalSettings={generalSettings}
            setMyThoughtsSortSettings={null}
            thoughtNumber={thoughtNumber}
            mostRecentlyClickedThoughtText={mostRecentlyClickedThoughtText}

        />

        
        </div>


            {!showEditThought && (
                <div style={{ minWidth: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'column'}}>

                            <RenderMyThoughts
                            thoughts={sortedFilteredThoughts}
                            dialog={dialog}
                            setDialog={setDialog}
                            handleCloseDialog={handleCloseDialog}
                            longitude={longitude}
                            latitude={latitude}
                            generalSettings={generalSettings}
                            thoughtNumber={thoughtNumber}
                            setActiveThought={setActiveThought}
                            setShowEditThought={setShowEditThought}
                            setMostRecentlyClickedThoughtText={setMostRecentlyClickedThoughtText}
                        />
                        </div>
            )}


        {!sortedFilteredThoughts && <LoadingComponent />}

        </Box>


    );
}