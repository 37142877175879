import React, { useEffect, useState } from "react";
import { Button, ThemeProvider, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import AllThoughtsSortAndFilter from "./AllThoughtsSortAndFilter";
import ThoughtsTextField from "../../InBothTabs/ThoughtsTextField";
import { Timestamp } from 'firebase/firestore';
import RenderAllThoughts from "./RenderAllThoughts";
import getAllThoughtsResults from "./GetAllThoughtsResults";
import ThoughtsSearchBar from "./ThoughtsSearchBar";

export default function AllThoughtsTab({
    text,
    setText,
    generalSettings,
    setGeneralSettings,
    saveThought,
    shareThought,
    thoughtNumber,
    myThoughts,
    latitude,
    longitude,
    setOtherProfileFocusUID,
}) {
    const [sortedFilteredResults, setSortedFilteredResults] = useState([]);
    const [showSortAndFilter, setShowSortAndFilter] = useState(true);
    const theme = useTheme();

    const defaultSortSettings = {
        sortBy: 'similaritySort',
        sortThought: 'Most recent thought (auto)',
    };
    
    const defaultFilterSettings = {
        maximumDistance: '5000km',
        anyDistance: true,
        fromDate: Timestamp.now(),
        toDate: Timestamp.now(),
        fromAll: true,
        toAll: true,
        limitToXResults: 100, // Changed from string to number for consistency
    };
    
    const getInitialSettings = (key, defaultValue) => {
        const storedSettings = localStorage.getItem(key);
        return storedSettings ? JSON.parse(storedSettings) : defaultValue;
    };
    
    const getInitialFilterSettings = () => {
        const storedSettings = localStorage.getItem('allThoughtsFilterSettings');
        if (storedSettings) {
            const parsedSettings = JSON.parse(storedSettings);
            return {
                ...parsedSettings,
                fromDate: parsedSettings.fromDate ? Timestamp.fromMillis(parsedSettings.fromDate) : Timestamp.now(),
                toDate: parsedSettings.toDate ? Timestamp.fromMillis(parsedSettings.toDate) : Timestamp.now(),
            };
        } else {
            return { ...defaultFilterSettings };
        }
    };
    
    const [allThoughtsSortSettings, setAllThoughtsSortSettings] = useState(() => 
        getInitialSettings('allThoughtsSortSettings', defaultSortSettings));
    
    const [allThoughtsFilterSettings, setAllThoughtsFilterSettings] = useState(
        getInitialFilterSettings()
    );
    
    // Cache 'allThoughtsSortSettings'
    useEffect(() => {
        localStorage.setItem('allThoughtsSortSettings', JSON.stringify(allThoughtsSortSettings));
    }, [allThoughtsSortSettings]);
    
    // Cache 'allThoughtsFilterSettings'
    useEffect(() => {
        const settingsToSave = {
            ...allThoughtsFilterSettings,
            fromDate: allThoughtsFilterSettings.fromDate ? allThoughtsFilterSettings.fromDate.toMillis() : null,
            toDate: allThoughtsFilterSettings.toDate ? allThoughtsFilterSettings.toDate.toMillis() : null,
        };
    
        localStorage.setItem('allThoughtsFilterSettings', JSON.stringify(settingsToSave));
    }, [allThoughtsFilterSettings]);
    

    const timestampToDate = (timestampObj) => {
        if (timestampObj && timestampObj.seconds) {
            return new Date(timestampObj.seconds * 1000);
        }
        return null;
    };


    useEffect(() => {
        const fetchData = async () => {
            const allResults = await getAllThoughtsResults(
                allThoughtsSortSettings, 
                allThoughtsFilterSettings, 
                myThoughts, 
                latitude, 
                longitude
            );
            setSortedFilteredResults(allResults);
        };

        // Call the async function
        fetchData();
    }, [allThoughtsSortSettings, allThoughtsFilterSettings, myThoughts, latitude, longitude]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100%', // Make the box full width
            maxWidth: '100%', // Make the box full width
            marginTop: '10px', // Add some margin at the top
            marginX: 'auto', // Center the box with auto margins on the sides
            padding: '0 10px' // Add padding on the sides for maintaining internal spacing
        }}>
            
        {/* <ThoughtsTextField
            text={text}
            setText={setText}
            generalSettings={generalSettings}
            saveThought={saveThought}
            shareThought={shareThought}
            showSortAndFilter={showSortAndFilter}
            setShowSortAndFilter={setShowSortAndFilter}
        /> */}
        <ThoughtsSearchBar
            setSortedFilteredResults={setSortedFilteredResults}
            filterSettings={allThoughtsFilterSettings}
            lat={latitude}
            lon={longitude}
        />

        <RenderAllThoughts
            results={sortedFilteredResults}
            theme={theme}
            setOtherProfileFocusUID={setOtherProfileFocusUID}
            />
        
        {/* this is just for testing purposes*/}
        {/* <div>
            <Typography>
                Sort By: {allThoughtsSortSettings.sortBy}   
            </Typography>
            <Typography>
                Sort Thought: {allThoughtsSortSettings.sortThought}
            </Typography>
            <Typography>
                Maximum Distance: {allThoughtsFilterSettings.maximumDistance}
            </Typography>
            <Typography>
                Any Distance: {allThoughtsFilterSettings.anyDistance.toString()}
            </Typography>
            {allThoughtsFilterSettings.fromDate && allThoughtsFilterSettings.toDate &&(
                <div>
                <Typography>
                    From Date: {timestampToDate(allThoughtsFilterSettings.fromDate).toString()}
                </Typography>
                <Typography>
                    To Date: {timestampToDate(allThoughtsFilterSettings.toDate).toString()}
                </Typography>
                </div>
            )}
            <Typography>
                From All: {allThoughtsFilterSettings.fromAll.toString()}
            </Typography>
            <Typography>
                To All: {allThoughtsFilterSettings.toAll.toString()}
            </Typography>
            <Typography>
                Limit to X Results: {allThoughtsFilterSettings.limitToXResults.toString()}
            </Typography>
        </div> */}

        </Box>


    );
}