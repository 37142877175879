import { FIRESTORE_DB, FIREBASE_AUTH } from './firebaseConfig';
import { doc, setDoc, increment } from 'firebase/firestore';

const incrementFieldInDocument = async (ref, fieldName) => {
    const data = { [fieldName]: increment(1) };
    await setDoc(ref, data, { merge: true });
};

const incrementThoughtSearches = async () => {
    // update individual user stats for thoughtSearches
    const userStatsRef = doc(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid);
    await incrementFieldInDocument(userStatsRef, 'thoughtSearches');

    const date = getTodayAsString();
    // update global daily stats for thoughtSearches
    const globalStatsRef = doc(FIRESTORE_DB, 'Stats', date);
    await incrementFieldInDocument(globalStatsRef, 'thoughtSearches');
};

const incrementThoughtShares = async () => {
    // update individual user stats for thoughtShares
    const userStatsRef = doc(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid);
    await incrementFieldInDocument(userStatsRef, 'thoughtShares');

    const date = getTodayAsString();
    // update global daily stats for thoughtShares
    const globalStatsRef = doc(FIRESTORE_DB, 'Stats', date);
    await incrementFieldInDocument(globalStatsRef, 'thoughtShares');
};

const incrementThoughtSaves = async () => {
    // update individual user stats for thoughtSaves
    const userStatsRef = doc(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid);
    await incrementFieldInDocument(userStatsRef, 'thoughtSaves');

    const date = getTodayAsString();
    // update global daily stats for thoughtSaves
    const globalStatsRef = doc(FIRESTORE_DB, 'Stats', date);
    await incrementFieldInDocument(globalStatsRef, 'thoughtSaves');
};

const getTodayAsString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
};

export { incrementThoughtSearches, incrementThoughtShares, incrementThoughtSaves };
