import { Typography, useTheme } from "@mui/material";
import ResponsiveTabs from "./ResponsiveTabs";
import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import './HomeScreenStyles4.css'
import MyThoughtsTab from "./Tabs/MyThoughtsTab/MyThoughtsTab";
import { shareThought } from "./ShareThought";
import { useLocation, useNavigate } from "react-router-dom";
import { FIREBASE_AUTH, FIRESTORE_DB } from "../firebaseConfig";
import CustomDialog from "../CustomDialog";
import { useMemo } from "react";
import { saveThought } from "./SaveThought";
import TabsTopBar from "./TabsManagement/TabsTopBar";
import DisplaySelectedTab from "./TabsManagement/DisplaySelectedTab";
import useListenToMyThoughts from "./Tabs/MyThoughtsTab/useListenToMyThoughts";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";

export default function HomeScreenV4({
  bridgeDisplayStack,
  setBridgeDisplayStack,
  showMyProfileBridge,
  setShowMyProfileBridge,
  selectedTab,
  setSelectedTab,
  tabs,
  setTabs,
}) {
  const user = FIREBASE_AUTH.currentUser;
  const location = useLocation();
  const navigate = useNavigate();

  const [chatScreenFocusUID, setChatScreenFocusUID] = useState(null);
  const [otherProfileFocusUID, setOtherProfileFocusUID] = useState(null);
  const [showBlockedUsersBridge, setShowBlockedUsersBridge] = useState(false);
  const [showEditProfileBridge, setShowEditProfileBridge] = useState(false);

  const [showTermsAndConditionsBridge, setShowTermsAndConditionsBridge] = useState(false);
  const [showPrivacyPolicyBridge, setShowPrivacyPolicyBridge] = useState(false);
  const [showCookiesPolicyBridge, setShowCookiesPolicyBridge] = useState(false);
  const [showAboutUsAndContactBridge, setShowAboutUsAndContactBridge] = useState(false);
  const [showDotOnManageTab, setShowDotOnManageTab] = useState(false);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [tabActiveStatus, setTabActiveStatus] = useState({ myThoughts: true, allThoughts: false });
  const [myThoughtsTextFieldContent, setMyThoughtsTextFieldContent] = useState('');
  const [allThoughtsTextFieldContent, setAllThoughtsTextFieldContent] = useState('');
  const defaultSettings = {
    saveDefault: true,
    shareDefault: true,
    shareGeolocationData: true,
    syncTextFields: true
  };
  const getInitialSettings = (key, defaultValue) => {
    const storedSettings = localStorage.getItem(key);
    return storedSettings ? JSON.parse(storedSettings) : defaultValue;
  };


  useEffect(() => {
    console.log("Show edit profile bridge:", showEditProfileBridge);
  }, [showEditProfileBridge]);



  const [myThoughtsGeneralSettings, setMyThoughtsGeneralSettings] = useState(() =>
    getInitialSettings('myThoughtsGeneralSettings', defaultSettings));

  const [allThoughtsGeneralSettings, setAllThoughtsGeneralSettings] = useState(() =>
    getInitialSettings('allThoughtsGeneralSettings', defaultSettings));
  const theme = useTheme();
  const [fromUpgradeAccountThoughtID, setFromUpgradeAccountThoughtID] = useState(location.state ? location.state.thoughtID : null);
  const [fromUpgradeAccountThoughtText, setFromUpgradeAccountThoughtText] = useState(location.state ? location.state.thoughtText : null);
  const [anonPostAllowed, setAnonPostAllowed] = useState(false);
  const hasPostUseEffectRun = useRef(false);
  const [thoughtNumber, setThoughtNumber] = useState(0);
  const [myThoughts, setMyThoughts] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    message: '',
    title: '',
    actions: [{
      label: "Cancel",
      onClick: () => { handleCloseDialog(); }

    }],
  });
  const handleCloseDialog = () => {
    setDialog((prev) => ({ ...prev, open: false }));
  };

  useListenToMyThoughts(user, setMyThoughts, setThoughtNumber);

  const updateStreakData = async (docRef) => {
    console.log("Updating streak data");
  
    try {
      const docSnapshot = await getDoc(docRef);
      if (!docSnapshot.exists()) {
        console.error('Document does not exist');
        return;
      }
  
      const userData = docSnapshot.data();
      const lastLogin = userData?.lastLogin?.toDate();
      const currentDate = new Date();
  
      // Extract just the date part (ignoring the time)
      const lastLoginDate = lastLogin ? lastLogin.toISOString().split('T')[0] : null;
      const currentDateDate = currentDate.toISOString().split('T')[0];
  
      let newStreak = userData?.streak || 0;
  
      if (lastLoginDate) {
        if (currentDateDate !== lastLoginDate) {
          // Convert the dates to actual Date objects for comparison
          const lastLoginDateTime = new Date(lastLoginDate);
          const currentDateDateTime = new Date(currentDateDate);
  
          // Add one day to the last login date
          lastLoginDateTime.setDate(lastLoginDateTime.getDate() + 1);
  
          if (lastLoginDateTime.toISOString().split('T')[0] === currentDateDateTime.toISOString().split('T')[0]) {
            // Increment streak if the last login was exactly one day ago
            newStreak++;
          } else {
            // Reset streak if the last login was more than one day ago
            newStreak = 1;
          }
        }
        // If currentDateDate is same as lastLoginDate, do not change the streak
      } else {
        // First-time login
        newStreak = 1;
      }
  
      // Update the document
      await updateDoc(docRef, {
        streak: newStreak,
        lastLogin: serverTimestamp(),
      }, { merge: true });
  
      console.log("Streak data updated");
    } catch (error) {
      console.error('Error updating user data: ', error);
    }
  };

  useEffect(() => {
    if (user) {
      const docRef = doc(FIRESTORE_DB, 'Users', user.uid);
      updateStreakData(docRef);
    }
  }, [user]);


  useEffect(() => {
    if (hasPostUseEffectRun.current) {
      return;
    }
    hasPostUseEffectRun.current = true;
    console.log('Thought Text: ', fromUpgradeAccountThoughtText);
    if (fromUpgradeAccountThoughtText) {
      startShareThought(fromUpgradeAccountThoughtText);
    }
    console.log('Thought ID: ', fromUpgradeAccountThoughtID);
    if (fromUpgradeAccountThoughtID) {
      //shareExistingThought(fromUpgradeAccountThoughtID);
    }
  }, []);


  useEffect(() => {
    // if myThoughtsGeneralSettings.shareGeolocationData, then populate latitude and longitude
    if (myThoughtsGeneralSettings.shareGeolocationData) {
      console.log("myThoughtsGeneralSettings.shareGeolocationData is true");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }
  }, [myThoughtsGeneralSettings.shareGeolocationData]);


  // Cache 'myThoughtsGeneralSettings'
  useEffect(() => {
    localStorage.setItem('myThoughtsGeneralSettings', JSON.stringify(myThoughtsGeneralSettings));
  }, [myThoughtsGeneralSettings]);

  // Cache 'allThoughtsGeneralSettings'
  useEffect(() => {
    localStorage.setItem('allThoughtsGeneralSettings', JSON.stringify(allThoughtsGeneralSettings));
  }, [allThoughtsGeneralSettings]);



  const startSaveThought = async (text) => {
    const result = await saveThought({ thoughtNumber: thoughtNumber, inputValue: text, user: user });
    console.log("Result from shareThought:", result);

    let message = "";
    if (result.errorCode === 0) {
      message = "You have successfully saved this thought.";
      // setDialog({
      //   open: true,
      //   message: message,
      //   title: "Thought saved",
      //   actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
      // });
      return message;
    } else if (result.errorCode === 690) {
      message = "Thought input is empty";
      console.log(message);
      setDialog({
        open: true,
        message: "Thought input is empty",
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;
    } else if (result.errorCode === 692) {
      message = "Thought is too long";
      console.log(message);
      setDialog({
        open: true,
        message: "Thought is too long",
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;

    } else if (result.errorCode === 694) {
      message = "User not found";
      console.log(message);
      setDialog({
        open: true,
        message: "User not found",
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;
    } else {
      // Unknown error
      console.log("Unknown error");
    }

    return result.errorMessage;

  }

  const startShareThought = async (text) => {
    console.log("HomeScreenv4 level: Share thought called with: ", text);


    const result = await shareThought({ thoughtNumber: thoughtNumber, inputValue: text, anonPostAllowed: anonPostAllowed, setAnonPostAllowed: setAnonPostAllowed, shareLocation: myThoughtsGeneralSettings.shareGeolocationData, latitude, longitude, user: user });

    console.log("Result from shareThought:", result);

    let message = '';
    if (result.errorCode === 0) {
      message = "Thank you for sharing a thought.";
      // setDialog({
      //   open: true,
      //   message: message,
      //   title: "Thought shared",
      //   actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
      // });
      return message;
    } else if (result.errorCode === 690) {
      // Thought input is empty
      console.log("Thought input is empty");
      message = "Thought input is empty";
      setDialog({
        open: true,
        message: message,
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;

    } else if (result.errorCode === 691) {
      // Thought is not unique
      console.log("Thought is not unique");
      message = "Thought is not unique";
      setDialog({
        open: true,
        message: message,
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;


    } else if (result.errorCode === 692) {
      // Thought is too long
      console.log("Thought is too long");
      message = "Thought is too long";
      setDialog({
        open: true,
        message: message,
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;

    } else if (result.errorCode === 693) {
      // Thought contains inappropriate words
      console.log("Thought contains inappropriate words");
      message = "Thought contains inappropriate words";
      setDialog({
        open: true,
        message: message,
        title: "Error",
        actions: [{ label: "Sorry", onClick: () => { handleCloseDialog(); } }]
      });
      return message;

    } else if (result.errorCode === 694) {
      // User not found
      console.log("User not found");
      message = "User not found";
      setDialog({
        open: true,
        message: message,
        title: "Error",
        actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
      });
      return message;

    } else if (result.errorCode === 695) {
      // User is anonymous and not yet allowed, redirect them to upgrade account
      console.log("User is anonymous and not yet allowed, redirect them to upgrade account");
      navigate("/upgradeaccount", { state: result.state });
    } else {
      // Unknown error
      console.log("Unknown error");
    }

    return result.errorMessage;

  }

  useEffect(() => {
    // This effect syncs shareGeolocationData from myThoughts to allThoughts
    if (myThoughtsGeneralSettings.shareGeolocationData !== allThoughtsGeneralSettings.shareGeolocationData) {
      setAllThoughtsGeneralSettings((prevSettings) => ({
        ...prevSettings,
        shareGeolocationData: myThoughtsGeneralSettings.shareGeolocationData,
      }));
    }
    // Only re-run this effect if myThoughtsGeneralSettings.shareGeolocationData changes
  }, [myThoughtsGeneralSettings.shareGeolocationData]);

  useEffect(() => {
    // This effect syncs syncTextFields from myThoughts to allThoughts
    if (myThoughtsGeneralSettings.syncTextFields !== allThoughtsGeneralSettings.syncTextFields) {
      setAllThoughtsGeneralSettings((prevSettings) => ({
        ...prevSettings,
        syncTextFields: myThoughtsGeneralSettings.syncTextFields,
      }));
    }
    // Only re-run this effect if myThoughtsGeneralSettings.syncTextFields changes
  }, [myThoughtsGeneralSettings.syncTextFields]);

  useEffect(() => {
    // This effect syncs shareGeolocationData from allThoughts to myThoughts
    if (allThoughtsGeneralSettings.shareGeolocationData !== myThoughtsGeneralSettings.shareGeolocationData) {
      setMyThoughtsGeneralSettings((prevSettings) => ({
        ...prevSettings,
        shareGeolocationData: allThoughtsGeneralSettings.shareGeolocationData,
      }));
    }
    // Only re-run this effect if allThoughtsGeneralSettings.shareGeolocationData changes
  }, [allThoughtsGeneralSettings.shareGeolocationData]);

  useEffect(() => {
    // This effect syncs syncTextFields from allThoughts to myThoughts
    if (allThoughtsGeneralSettings.syncTextFields !== myThoughtsGeneralSettings.syncTextFields) {
      setMyThoughtsGeneralSettings((prevSettings) => ({
        ...prevSettings,
        syncTextFields: allThoughtsGeneralSettings.syncTextFields,
      }));
    }
    // Only re-run this effect if allThoughtsGeneralSettings.syncTextFields changes
  }, [allThoughtsGeneralSettings.syncTextFields]);




  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>

      <TabsTopBar
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        showDotOnManageTab={showDotOnManageTab}
        setShowDotOnManageTab={setShowDotOnManageTab}
      />
      <DisplaySelectedTab
        tabs={tabs}
        setTabs={setTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        text={myThoughtsTextFieldContent}
        setText={setMyThoughtsTextFieldContent}
        generalSettings={myThoughtsGeneralSettings}
        setGeneralSettings={setMyThoughtsGeneralSettings}
        saveThought={startSaveThought}
        shareThought={startShareThought}
        thoughtNumber={thoughtNumber}
        setThoughtNumber={setThoughtNumber}
        latitude={latitude}
        longitude={longitude}
        dialog={dialog}
        setDialog={setDialog}
        handleCloseDialog={handleCloseDialog}
        myThoughts={myThoughts}
        setMyThoughts={setMyThoughts}
        chatScreenFocusUID={chatScreenFocusUID}
        setChatScreenFocusUID={setChatScreenFocusUID}
        otherProfileFocusUID={otherProfileFocusUID}
        setOtherProfileFocusUID={setOtherProfileFocusUID}
        showBlockedUsersBridge={showBlockedUsersBridge}
        setShowBlockedUsersBridge={setShowBlockedUsersBridge}
        showTermsAndConditionsBridge={showTermsAndConditionsBridge}
        setShowTermsAndConditionsBridge={setShowTermsAndConditionsBridge}
        showPrivacyPolicyBridge={showPrivacyPolicyBridge}
        setShowPrivacyPolicyBridge={setShowPrivacyPolicyBridge}
        showCookiesPolicyBridge={showCookiesPolicyBridge}
        setShowCookiesPolicyBridge={setShowCookiesPolicyBridge}
        showAboutUsAndContactBridge={showAboutUsAndContactBridge}
        setShowAboutUsAndContactBridge={setShowAboutUsAndContactBridge}
        bridgeDisplayStack={bridgeDisplayStack}
        setBridgeDisplayStack={setBridgeDisplayStack}
        showMyProfileBridge={showMyProfileBridge}
        setShowMyProfileBridge={setShowMyProfileBridge}
        setShowDotOnManageTab={setShowDotOnManageTab}
        showEditProfileBridge={showEditProfileBridge}
        setShowEditProfileBridge={setShowEditProfileBridge}
      />
      <CustomDialog
        open={dialog.open}
        onClose={handleCloseDialog}
        title={dialog.title}
        message={dialog.message}
        actions={dialog.actions}
      />
    </Box>




  );
}