import { FIREBASE_AUTH } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp, doc, getDocs, query, where, limit, orderBy, setDoc, getDoc, increment } from "firebase/firestore";
import { FIRESTORE_DB } from "../firebaseConfig";
import { useRef } from "react";
import { incrementThoughtShares } from "../IncrementStats";

 
const checkThatThoughtIsUnique = async (inputValue) => {
    console.log("checkThatThoughtIsUnique entered with inputValue:", inputValue);
    const quotesRef = collection(FIRESTORE_DB, 'Quotes');
    const q = query(quotesRef, where("quote", "==", inputValue));
    const querySnapshot = await getDocs(q);
    const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log("Fetched quotes:", fetchedQuotes);
    if (fetchedQuotes.length > 0) {
        return false;
    }else{
        return true;
    }
}

    

/**
 * This function only shares the thoughts. It should not fetch results or anything like that. It should only share the thought and return the seedID.
 */
export const shareExistingThought = async ({ thoughtID, shareLocation, latitude, longitude, user }) => {
    console.log("shareThought entered with thoughtID:", thoughtID, "shareLocation:", shareLocation, "user ID", user?.uid);

    try {
        // using thoughtID, get thought from myThoughts collection
        const thoughtRef = doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', thoughtID);
        const thoughtDoc = await getDoc(thoughtRef);
        const thoughtData = thoughtDoc.data();




        if (!user) {
            console.log("User not found. Redirecting to /login...");
            return { errorCode: 694, errorMessage: "User not found" };
        }

        if (thoughtData.quote === "") {
            console.log("Thought input is empty");
            return { errorCode: 690, errorMessage: "Thought input is empty" };
        }

        const thoughtIsUnique = await checkThatThoughtIsUnique(thoughtData.quote);
        if (!thoughtIsUnique) {
            return { errorCode: 691, errorMessage: "Thought is not unique" };
        }

        const thought = {
            createdAt: thoughtData.createdAt ? thoughtData.createdAt : serverTimestamp(),
            quote: thoughtData.quote,
            userID: user.isAnonymous ? "anonymous_user_id" : user.uid,
            displayName: user.isAnonymous ? "Anon" : user.displayName,
            profilePicURL: user.isAnonymous ? "" : user.photoURL,
        };

        await setDoc(doc(FIRESTORE_DB, 'Quotes', thoughtID), thought);
        console.log('Thought added to quotes collection with ID:', thoughtID);

        // Update sub-collections for myThoughts and Profile Quotes
        // set merge true
        const myThoughtsEntry = {
            isPublic: true,
            isProfile: true,
            isVault: false,
        };
        const profileThoughtEntry = {
            createdAt: thoughtData.createdAt ? thoughtData.createdAt : serverTimestamp(),
            quote: thoughtData.quote,
            isArchived: false,
            id: thoughtID,
            thoughtNumber: thoughtData.thoughtNumber,
        };

        await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', thoughtID), profileThoughtEntry, { merge: true });

        // convert thoughtData.createdAt to createdAtMs (just ms since epoch or whatever)
        // send to node server
        // createdAt is a firebase timestamp so it needs to be converted to a number
        const createdAtMs = thoughtData.createdAt ? thoughtData.createdAt.toMillis() : Date.now();

        console.log("Sending data to node server...");
        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/share_thought', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quote: thoughtData.quote,
                seedID: thoughtID,
                userID: user.uid,
                alsoUpsertPrivately: false,
                lat: latitude,
                lon: longitude,
                createdAtMs: createdAtMs,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Thought shared with Flask server:", data);

        // The function was successful, no error codes needed.
        await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', thoughtID), myThoughtsEntry, { merge: true });
        incrementThoughtShares();

        return { errorCode: 0, errorMessage: "Success", data, docID: thoughtID };
    } catch (error) {
        console.error('Error during sharing thought:', error);
        // Return an error code that indicates an unknown error occurred.
        return { errorCode: 700, errorMessage: "An error occurred while sharing the thought" };
    } finally {
        // If you have any cleanup code or you need to reset state, do it here.
    }
};

