// const defaultSortSettings = {
//     sortBy: 'similaritySort',
//     sortThought: 'Most recent thought (auto)',
// };

// const defaultFilterSettings = {
//     maximumDistance: '5000km',
//     anyDistance: true,
//     fromDate: Timestamp.now(),
//     toDate: Timestamp.now(),
//     fromAll: true,
//     toAll: true,
//     limitToXResults: 100, // Changed from string to number for consistency
// };

import { collection, doc, getDoc, getDocs, query, limit, Timestamp, where } from "firebase/firestore";
import { FIRESTORE_DB } from "../../../firebaseConfig";
import { checkIfUserBlocked } from "../../../BlockedUsersHelper";

function extractNumberFromString(str) {
    const matches = str.match(/Thought (\d+)/i);
    return matches ? parseInt(matches[1], 10) : null;
  }

const getPrecision = (maximumDistance) => {
    /*
    based on filterSettings.maximumDistance, get the length of geohash needed
    if 5000km, then precision = 1
    if 600km, then precision = 2
    150km = 3
    20km = 4
    5km = 5
    */
    if (maximumDistance === '5000km'){
        return 1;
    }else if (maximumDistance === '600km'){
        return 2;
    }else if (maximumDistance === '150km'){
        return 3;
    }else if (maximumDistance === '20km'){
        return 4;
    }else if (maximumDistance === '5km'){
        return 5;
    }else{
        return 1;
    }
}


const getRandomTimestamp = () => {
    const now = new Date();
    const fourWeeksAgo = new Date(now.getTime() - (4 * 7 * 24 * 60 * 60 * 1000)); // 4 weeks in milliseconds

    const randomTime = new Date(fourWeeksAgo.getTime() + Math.random() * (now.getTime() - fourWeeksAgo.getTime()));
    return Timestamp.fromDate(randomTime);
};


const getRandomDocIDs = async () => {
    const randomTimestamp = getRandomTimestamp();
    console.log("Random Timestamp:", randomTimestamp.toDate());

    // Query with inequality on createdAt field
    const q = query(collection(FIRESTORE_DB, "Quotes"), where("createdAt", ">", randomTimestamp), limit(15));
    const querySnapshot = await getDocs(q);

    // Filter out the documents with the specific userID
    const filteredDocIDs = querySnapshot.docs
        .filter(doc => doc.data().userID !== "V3U89UxQ9GbYHlt9zXUQ9gko9sD3")
        .map(doc => doc.id);

    console.log("Filtered Document IDs:", filteredDocIDs);
    return filteredDocIDs;
};



const getSimilarDocIDs = async (myThoughts, sortSettings, filterSettings, lat, lon) => {
    // get the list of ids



    const precision = getPrecision(filterSettings.maximumDistance);
    const latitude = filterSettings.anyDistance ? null : lat;
    const longitude = filterSettings.anyDistance ? null : lon;
    console.log("Precision:", precision, "Latitude:", latitude, "Longitude:", longitude);


    let docIDs = [];
    let quote = "";
    if (sortSettings.sortThought === "Most recent thought (auto)"){
        if (myThoughts.length != 0){
            quote = myThoughts[0].quote;
        }else{
            quote = "This is a test quote";
        }
    }else{
        const searchThoughtNumber = extractNumberFromString(sortSettings.sortThought);
        const thought = myThoughts.find(thought => thought.thoughtNumber === searchThoughtNumber);
        if (!thought || !thought.quote) {
            return []; // Return empty array or handle as needed
        }
        quote = thought.quote;
    }

    console.log("minCreatedAtMs: ", filterSettings.fromAll ? null : filterSettings.fromDate.toMillis());
    console.log("maxCreatedAtMs: ", filterSettings.toAll ? null : filterSettings.toDate.toMillis());

    const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/fetch_similar_public_thoughts', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              quote: quote,
              lat: latitude,
              lon: longitude,
              precision: precision,
              minCreatedAtMs: filterSettings.fromAll ? null : filterSettings.fromDate.toMillis(),
              maxCreatedAtMs: filterSettings.toAll ? null : filterSettings.toDate.toMillis(),
          }),
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      docIDs = await response.json();
    return docIDs;
}


export default async function getAllThoughtsResults(sortSettings, filterSettings, myThoughts, latitude, longitude){
    let docIDs = [];
    if (sortSettings.sortBy === "randomSort"){
        docIDs = await getRandomDocIDs(filterSettings, latitude, longitude);
        // limit = filterSettings.limitToXResults
        // where createdAt > fromDate (if fromAll == false)
        // where createdAt < toDate (if toAll == false)
        // where geocode matches geocode of mine to a certain number of locations (if anyDistance == false)
        // build this query, and get from firestore
    }else{
        docIDs = await getSimilarDocIDs(myThoughts, sortSettings, filterSettings, latitude, longitude)



    }

    const allResults = await fetchAndSaveAllResults(docIDs);
    console.log("All results:", allResults);
    return allResults;
}

const fetchAndSaveAllResults = async (documentIDs = []) => {
    if (!documentIDs.length) {
        console.log('No document IDs provided');
        return [];
    }

    const fetchQuoteDocument = async (id) => {
        const docRefFetch = doc(FIRESTORE_DB, "Quotes", id);
        const quoteDoc = await getDoc(docRefFetch);

        if (quoteDoc.exists()) {
            const quoteData = { id: quoteDoc.id, ...quoteDoc.data() };
            // Check for required fields in the document
            if (isValidQuote(quoteData)) {
                return quoteData;
            } else {
                console.log(`Required fields missing in document: ${id}`);
            }
        } else {
            console.log(`Quote document not found for id: ${id}`);
        }
        return null;
    };

    const fetchedQuoteDocs = (await Promise.all(documentIDs.map(fetchQuoteDocument))).filter(Boolean);

    console.log('Fetched results:', fetchedQuoteDocs);

    const uids = fetchedQuoteDocs.map((quote) => quote.userID);
    // Check for blocked users
    const blockedUserIDs = await checkIfUserBlocked(uids);

    // blockedUserIDs.push(FIREBASE_AUTH.currentUser.uid);
    // Filter out blocked users and self
    const filteredResults = fetchedQuoteDocs.filter(
        (quote) => !blockedUserIDs.includes(quote.userID)
    );

    console.log("FILTERED RESULTS", filteredResults);
    return filteredResults;
};

function isValidQuote(quote) {
    // Add checks for required fields, e.g.,:
    return quote && quote.userID && quote.quote; // Add other required fields here
}