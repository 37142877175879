import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Box, useTheme, TextField, Button, Switch, Card, CardContent, Typography, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import checkString from './ContentModeration';
import { addDoc, collection, getDoc, setDoc, doc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { setItem } from './storage';
import { fetchAndSaveAllResults } from './ExploreThoughts';
import CoinPopup from './CoinPopup';
import addCoinsToUser from './AddCoinsToUser'

const COLORS = ['#FFD1DC', '#C3E8EC', '#C8FFC8', '#FFF0B3', '#E7C8FF', '#FFCCA5'];
const PASTEL_COLORS = ['#FFEEEE', '#DCEFF1', '#E9FFE9', '#FFF7E6', '#F0E4FF', '#FFE9D9'];


const SpeechBubble = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '100%',
    border: `1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`,
    borderRadius: '12px',
    padding: '1rem',
    margin: '0',
    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
    boxSizing: 'border-box',
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? 'black' : 'white',
    backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242',
    },
    borderRadius: '12px',
    width: 'auto',
    whiteSpace: 'no-wrap',
    maxWidth: '100%',
    padding: '1rem',
    marginTop: '1rem',
}));


const NewPortal = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDarkMode = theme.palette.mode === 'dark';
    const [text, setText] = useState('Type your thought here...');
    const [wizardSpeech, setWizardSpeech] = useState("Tell me a thought, and I\'ll build you a portal! I love thoughts, and I know the precise coordinates of every one, in the Thought Realm. I\'ll build you a portal based on the topic of your thought!");
    const [QuestionsDisplayed, setQuestionsDisplayed] = useState(false);
    const [ThoughtEditable, setThoughtEditable] = useState(true);
    const [isAnonymous, setIsAnonymous] = useState(false);  // Post as anonymous, can't be messaged, can't be added to profile
    const [postToProfile, setPostToProfile] = useState(true);
    const [defaultAvatar, setDefaultAvatar] = useState("");
    const statsRef = doc(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid, 'Stats', 'writes');
    const [color1, setColor1] = useState('#FFFFF1'); // New state
    const [color2, setColor2] = useState('#FFFFE5'); // New state
    const [gradient, setGradient] = useState(`linear-gradient(45deg, ${color1} 30%, ${color2 ? color2 : color1} 90%)`);

    const [readyToNavigate, setReadyToNavigate] = useState(false);
    const [coins, setCoins] = useState(75);
    const [coinsPopupOpen, setCoinsPopupOpen] = useState(false);
    const handleCoinsOpen = () => {
        setCoinsPopupOpen(true);
    }
    const handleCoinsClose = () => {
        setCoinsPopupOpen(false);
    }

    useEffect(() => {
        if (readyToNavigate && !coinsPopupOpen) {
            navigate('/explorethoughtsresults');
        }
    }, [readyToNavigate, coinsPopupOpen]);

    useEffect(() => {
        const storage = getStorage();
        const gsReference = ref(storage, 'gs://words-of-a-feather-f196f.appspot.com/profilePictures/default_profile_pic.jpeg');
        getDownloadURL(gsReference)
            .then((url) => {
                setDefaultAvatar(url);
            })
            .catch((error) => {
                console.error("Error getting download URL: ", error);
            });
    }, []);

    const handleAnonToggleChange = (event) => {
        setIsAnonymous(event.target.checked);
        setPostToProfile(!event.target.checked);
    }

    const handleProfileToggleChange = (event) => {
        if (!isAnonymous) {
            setPostToProfile(event.target.checked);
        }

    }

    const handleColorPress = (color) => {
        if (!color1) {
            setColor1(color);
            setGradient(`linear-gradient(45deg, ${color} 30%, ${color} 90%)`);
            return;
        } else if (!color2) {
            if (color !== color1) {
                setColor2(color);
                setGradient(`linear-gradient(45deg, ${color1} 30%, ${color} 90%)`);
                return;
            } else {
                return;
            }
        } else {
            if (color === color1) {
                setGradient(`linear-gradient(45deg, ${color2} 30%, ${color2} 90%)`);
                setColor1(color2);
                setColor2(null);
                return;
            } else if (color === color2) {
                setColor2(null);
                setGradient(`linear-gradient(45deg, ${color1} 30%, ${color1} 90%)`);
                return;
            } else {
                setGradient(`linear-gradient(45deg, ${color2} 30%, ${color} 90%)`);
                setColor1(color2);
                setColor2(color);
                return;
            }
        }

    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        const wordCount = input.split(" ").length;
        if (input.length <= 1800 && wordCount <= 230) {
            setText(input);
        }
    };

    const handleInputClick = () => {
        if (text === 'Type your thought here...') {
            setText('');
        }
    };

    const handleFirstButtonClick = async () => {
        setWizardSpeech("...")
        // if thought text is empty, say, "the thought cannot be empty!", return
        if (text === "") {
            setWizardSpeech("What thought? There's nothing there! Write something please...");
            return;
        }
        if (text === "Type your thought here...") {
            setWizardSpeech("Please type your own thought, in the bubble above. It could be anything: an idea for a song or artwork, a feeling, even a dream that you had, where you wonder if anyone else has thought of something similar!");
            return;
        }

        // Check to see if writes today are less than 50
        const statsSnapshot = await getDoc(statsRef);
        if (statsSnapshot.exists()) {
            const statsData = statsSnapshot.data();

            if (statsData.last_write_date.toDate().toDateString() !== new Date().toDateString()) {
                await setDoc(statsRef, {
                    quotes_writes: 0,
                    last_write_date: serverTimestamp()
                });
            } else if (statsData.quotes_writes >= 50) {
                // If it's the same day and the user has already written 50 quotes
                setWizardSpeech("It seems like you've written enough quotes for today. If this is a mistake, we aim for 50 thoughts per day for unpaid users. If you believe that you have not exceeded this limit, take it up with the guy who wrote it, elijahdecalmer@gmail.com")
                return;
            }
        }

        // if thought is not accepted by content moderation, say, "Maybe I'm misunderstanding, but that thought doesn't seem appropriate. Please try something different", return
        const isAccepted = await checkString(text);
        if (!isAccepted) {
            setWizardSpeech("Maybe I'm misunderstanding, but that thought doesn't seem appropriate. Please try something different, and make sure you're following our T&Cs");
            return;
        } else {
            setWizardSpeech("Thankyou! Very interesting. Just a couple more technical questions. Please answer, then press done, so I can make this portal...")
            setQuestionsDisplayed(true);
            setThoughtEditable(false);
        }
        return;
    };

    const handleFinalButtonClick = async () => {
        const user = FIREBASE_AUTH.currentUser;
        if (!user) {
            navigate("/login");
            return;
        }

        // TOGGLE REWARD COINS OPEN
        handleCoinsOpen();

        const colorA = color1;
        let colorB = color2;
        if (!colorB) {
            colorB = color1;
        }

        const userEntry = {
            createdAt: serverTimestamp(),
            quote: text,
            color1: colorA,
            color2: colorB,
        };

        if (!isAnonymous) { // if face shown
            userEntry.userID = user.uid;
            userEntry.displayName = user.displayName;
            userEntry.profilePicURL = user.photoURL;
        } else { // if anonymous
            userEntry.userID = "anonymous_user_id";
            userEntry.displayName = "Anonymous";
            userEntry.profilePicURL = 'gs://words-of-a-feather-f196f.appspot.com/profilePictures/default_profile_pic.jpeg';
        }

        // Save to Firestore
        addDoc(collection(FIRESTORE_DB, 'Quotes'), userEntry)
            .then(async (documentReference) => {
                console.log('DocumentSnapshot written with ID: ', documentReference.id);
                const seedID = documentReference.id;

                updateDoc(doc(FIRESTORE_DB, "Users", user.uid), {
                    lastResultsID: seedID
                });

                if (postToProfile && !isAnonymous) {
                    // save to personal profile quotes collection
                    const profileQuoteEntry = {
                        createdAt: serverTimestamp(),
                        quote: text,
                        color1: colorA,
                        color2: colorB,
                        isArchived: false,
                    };
                    setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', seedID), profileQuoteEntry)
                } else if (!isAnonymous) {
                    const profileQuoteEntry = {
                        createdAt: serverTimestamp(),
                        quote: text,
                        color1: colorA,
                        color2: colorB,
                        isArchived: true,
                    };
                    setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', seedID), profileQuoteEntry)
                }

                // Increment counter by 1
                const statsSnapshot = await getDoc(statsRef);
                if (statsSnapshot.exists()) {
                    // Document exists, update it
                    await updateDoc(statsRef, {
                        quotes_writes: increment(1),
                        last_write_date: serverTimestamp()
                    });
                } else {
                    // Document doesn't exist, create it
                    await setDoc(statsRef, {
                        quotes_writes: 1,
                        last_write_date: serverTimestamp()
                    });
                }

                // Send data to the Flask server
                fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/process_quote', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        quote: userEntry.quote,
                        seedID: seedID,
                        userID: user.uid,
                    }),
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        console.log('Data published:', data);
                        console.log(seedID)

                        const allResults = await fetchAndSaveAllResults(data);
                        // STORE IN LOCAL STORAGE
                        setItem('exploreThoughtsResults', JSON.stringify(allResults));
                        setItem('exploreThoughtsSeedID', seedID);
                        // SET AddCoinsToUser(coins) REWARD
                        addCoinsToUser(coins);
                        setReadyToNavigate(true);
                    })
                    .catch((error) => {
                        console.error('Error publishing data:', error);
                        setWizardSpeech('Quote not sent (URL ERROR). Please check your internet connection and try again.', { variant: 'error' });
                        return;
                    });

            })
            .catch((error) => {
                setWizardSpeech('Quote not sent (FIREBASE ERROR). Please check your internet connection and try again. Please note that there is a 50 search limit daily.');
            });

    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '90%',
                maxWidth: 700,
                margin: '0 auto',
                padding: '1rem',
            }}
        >
            <Card sx={{ width: '100%', mb: 2, backgroundImage: gradient }}>
                <CardContent>
                    <TextField
                        multiline
                        maxRows={10}
                        variant="outlined"
                        value={text}
                        onChange={(e) => handleInputChange(e)}
                        onFocus={handleInputClick}
                        fullWidth
                        sx={{ borderRadius: 1, multiline: { color: 'black' } }}
                        inputProps={{ style: { color: 'black' }, maxLength: 1800, readOnly: !ThoughtEditable }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
                        <React.Fragment>
                            <Avatar src={isAnonymous ? defaultAvatar : FIREBASE_AUTH.currentUser.photoURL} alt={FIREBASE_AUTH.currentUser.displayName} />
                            <Typography variant="subtitle1" fontWeight="bold" sx={{ ml: 1, color: 'black' }}>
                                {isAnonymous ? "Anonymous" : FIREBASE_AUTH.currentUser.displayName}
                            </Typography>
                        </React.Fragment>
                    </Box>
                </CardContent>
            </Card>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    gap: '1rem',
                }}
            >
                <Box
                    sx={{
                        aspectRatio: '1/1',
                        width: '30%',
                    }}
                >
                    <img src="/wizard-transparent.png" alt="Wizard" style={{ maxWidth: '100%', maxHeight: '100%' }} />

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                        width: '70%',
                        justifyContent: 'space-between',
                    }}
                >
                    <SpeechBubble>
                        <Typography variant="body1" align="center">
                            '{wizardSpeech}' - Wizard
                        </Typography>
                    </SpeechBubble>
                    {!QuestionsDisplayed &&
                        <PrimaryButton onClick={handleFirstButtonClick} variant="contained">
                            Click to open new portal
                        </PrimaryButton>}
                </Box>
            </Box>
            {QuestionsDisplayed &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '70%',
                        justifyContent: 'space-between',
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                            <Typography variant="body1">Post thought anonymously</Typography>
                            <Switch checked={isAnonymous} onChange={handleAnonToggleChange} name="anonymousSwitch" />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                            <Typography variant="body1">Publish to my Profile</Typography>
                            <Switch checked={postToProfile} onChange={handleProfileToggleChange} name="anonymousSwitch" />
                        </Box>
                    </Box>
                    <Typography>
                        Colour picker:
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 0, mb: 0 }}>
                        {COLORS.map((color, index) => (
                            <Box
                                key={index}
                                sx={{
                                    bgcolor: color,
                                    width: 48,
                                    height: 48,
                                    borderRadius: '50%',
                                    m: 1,
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                                onClick={() => handleColorPress(color)}
                            >
                                {color === color1 &&
                                    <Typography sx={{ color: 'black', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>A</Typography>
                                }
                                {color === color2 &&
                                    <Typography sx={{ color: 'black', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>Z</Typography>
                                }
                            </Box>
                        ))}
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 0, mb: 0 }}>
                        {PASTEL_COLORS.map((color, index) => (
                            <Box
                                key={index}
                                sx={{
                                    bgcolor: color,
                                    width: 48,
                                    height: 48,
                                    borderRadius: '50%',
                                    m: 1,
                                    cursor: 'pointer',
                                    position: 'relative'
                                }}
                                onClick={() => handleColorPress(color)}
                            >
                                {color === color1 &&
                                    <Typography sx={{ color: 'black', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>A</Typography>
                                }
                                {color === color2 &&
                                    <Typography sx={{ color: 'black', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>Z</Typography>
                                }
                            </Box>
                        ))}
                    </Box>
                    <PrimaryButton onClick={handleFinalButtonClick}>
                        Generate Portal
                    </PrimaryButton>

                </Box>
            }
            <CoinPopup
            onClose={handleCoinsClose}
            isOpen={coinsPopupOpen}
            text="Thank you for sharing this thought, people can resonate with it when they search it in the future. To say thank you on behalf of everyone, here's some coins that you can use!"
            title="Coins earned"
            numberOfCoins={coins}
            />
        </Box>
    );
};

export default NewPortal;
