// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCYDtSemgxdHIYKYDHFRClW7jKTOZvAtQk",
    authDomain: "thoughtportals.com",
    projectId: "words-of-a-feather-f196f",
    storageBucket: "words-of-a-feather-f196f.appspot.com",
    messagingSenderId: "1089041184121",
    appId: "1:1089041184121:web:7540edc11c15b0c9a76ba8",
    measurementId: "G-8ZHHJYRQM6"
  };

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIRESTORE_DB = getFirestore(FIREBASE_APP);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);