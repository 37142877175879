import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    collection,
    doc,
    getDoc,
    updateDoc,
    deleteDoc
} from 'firebase/firestore';
import { FIRESTORE_DB, FIREBASE_AUTH } from '../firebaseConfig';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextareaAutosize, Box } from '@mui/material';


function EditThought() {
    const navigate = useNavigate();
    const location = useLocation();
    const thoughtId = location.state.id;
    const [thought, setThought] = useState('');
    const user = FIREBASE_AUTH.currentUser;

    console.log('thoughtID', thoughtId)

    useEffect(() => {
        const fetchThought = async () => {
            const thoughtRef = doc(FIRESTORE_DB, `Users/${user.uid}/myThoughts`, thoughtId);
            const thoughtDoc = await getDoc(thoughtRef);
            if (thoughtDoc.exists()) {
                setThought(thoughtDoc.data().quote);
            }
        };

        fetchThought();
    }, [thoughtId, user]);

    const handleSave = async () => {
        const thoughtRef = doc(FIRESTORE_DB, `Users/${user.uid}/myThoughts`, thoughtId);
        await updateDoc(thoughtRef, {
            quote: thought
        });
        navigate('/homescreenv3');
    };


    const handleCancel = () => {
        navigate('/homescreenv3');
    };

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 2,
                boxShadow: 3,
                height: 'calc(100vh - 70px)'
            }}
        >
            <TextareaAutosize
                minRows={5}
                value={thought}
                onChange={(e) => setThought(e.target.value)}
                style={{
                    padding: '8px',
                    resize: 'vertical'
                }}
            />
            <Box 
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between'
                }}
            >

                <div>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="outlined" color="primary" onClick={handleCancel} style={{ marginLeft: '8px' }}>
                        Cancel
                    </Button>
                </div>
            </Box>
        </Box>
    );
}

export default EditThought;
