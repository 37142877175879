import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, Typography, Alert, Box, Tab, Tabs, Snackbar, CircularProgress, ThemeProvider, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { linkWithCredential, signInWithEmailAndPassword, EmailAuthProvider, signInAnonymously, sendPasswordResetEmail as firebaseSendPasswordResetEmail, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, fetchSignInMethodsForEmail, updateProfile } from 'firebase/auth'
import { useUser } from './UserContext';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { updateDoc, serverTimestamp } from 'firebase/firestore';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';



const LoginScreen = () => {
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { setLoggedIn } = useUser();
  const [loading, setLoading] = useState(true); // add this line
  const navigate = useNavigate();
  const isAnon = FIREBASE_AUTH.currentUser && FIREBASE_AUTH.currentUser.isAnonymous;
  const theme = useTheme();
  const [showLoginErrorModal, setShowLoginErrorModal] = useState(false);
  const [showSignupErrorModal, setShowSignupErrorModal] = useState(false);
const [signupErrorMessage, setSignupErrorMessage] = useState('');
const [googleSignInLoading, setGoogleSignInLoading] = useState(false);
const [googleSignInError, setGoogleSignInError] = useState('');
const [showGoogleSignInErrorModal, setShowGoogleSignInErrorModal] = useState(false);



  const handleCreateAccountFromModal = () => {
    setShowLoginErrorModal(false);
    setTabValue(1); // Switch to the Sign-Up tab
  };  


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, async (user) => {
      if (user && !user.isAnonymous) {
        console.log('User is signed in');
        setLoggedIn(true);
        navigate('/thoughts2');
      } else {
        console.log('No user is signed in');
      }
      setLoading(false); // set loading to false after checking the user's sign-in state
    });

    return () => unsubscribe();
  }, [navigate, setLoggedIn]);


  const handleEmailLoginClick = () => {
    setShowEmailLogin(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const sendPasswordResetEmail = async (email) => {
    console.log('Sending password reset email...');
    if (email) {
      try {
        await firebaseSendPasswordResetEmail(FIREBASE_AUTH, email);
        console.log('Password reset email sent.');
        setError({ type: 'success', message: 'Password reset email sent. Please check your inbox.' });
      } catch (error) {
        console.log('Error sending password reset email: ', error.message);
        setError({ type: 'error', message: error.message });
      }
    } else {
      console.log('Please enter your email address.');
      setError({ type: 'warning', message: 'Please enter your email address.' });
    }
  };


  const handleSignUp = async () => {  
    if (!displayName.trim()) {
      setSignupErrorMessage('Please choose a display name.');
      setShowSignupErrorModal(true);
      return;
    }
    if (!email.trim()) {
      setSignupErrorMessage('Please enter an email address.');
      setShowSignupErrorModal(true);
      return;
    }
    if (!password.trim()) {
      setSignupErrorMessage('Please enter a password.');
      setShowSignupErrorModal(true);
      return;
    }
    try {
      // If the user is signed in anonymously, try upgrading the account.
      if (isAnon) {
        const emailCredential = EmailAuthProvider.credential(email, password);
        await linkWithCredential(FIREBASE_AUTH.currentUser, emailCredential);
        console.log('Anonymous account successfully upgraded to email/password account.');
      } else {
        // If the user isn't signed in or isn't signed in anonymously, create a new account.
        await createUserWithEmailAndPassword(FIREBASE_AUTH, email, password);
        console.log('New email/password account created.');
      }

      const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid);
      const docSnapshot = await getDoc(docRef);

      if (!docSnapshot.exists()) {
        updateProfile(FIREBASE_AUTH.currentUser, {
          displayName: displayName,
          photoURL: "https://firebasestorage.googleapis.com/v0/b/words-of-a-feather-f196f.appspot.com/o/profilePictures%2F0ADXUFIpkGPEgFT6PIm762RUo8P2?alt=media&token=2a6c08c5-0fc2-4772-90f4-0a9a03b044cc",
        }).then(() => {
            const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser?.uid)
            setDoc(docRef, {
              "name": displayName,
              "picURI": "https://firebasestorage.googleapis.com/v0/b/words-of-a-feather-f196f.appspot.com/o/profilePictures%2F0ADXUFIpkGPEgFT6PIm762RUo8P2?alt=media&token=2a6c08c5-0fc2-4772-90f4-0a9a03b044cc",
              "uid": FIREBASE_AUTH.currentUser?.uid,
              "bio": "I haven't written a bio yet!",
              "streak": 1,
              "lastLogin": serverTimestamp(),
            }, { merge: true })
            .then(() => {
                navigate('/thoughts2')
            })
            .catch((error) => {
                console.error('Failed to update Firestore document: ', error);
            });
        })
        .catch((error) => {
            console.error('Failed to update profile: ', error);
        });
        setLoggedIn(true);
        navigate('/thoughts2');
        
      } else {
        setLoggedIn(true);
        navigate('/thoughts2');
      }
  
    } catch (error) {
      setError({ type: 'error', message: 'Error signing up: ' + error.message });
      let errorMessage = error.message;
      if (error.message == 'Firebase: Error (auth/invalid-email).'){
        errorMessage = 'Please enter a valid email address.'
      }
      setSignupErrorMessage(errorMessage);
      setShowSignupErrorModal(true);
    }
  };


  

  const handleSignIn = () => {
    signInWithEmailAndPassword(FIREBASE_AUTH, email, password)
      .then(async userCredentials => {
        const user = userCredentials.user;
        console.log("LOGGED INTO ", FIREBASE_AUTH.currentUser?.email)
        console.log('Logged in successfully');
        setLoggedIn(true);
        navigate('/thoughts2');
      })
      .catch(error => {
        console.log('Error signing in: ', error.code);
        if (error.code === 'auth/invalid-email') {
          setShowLoginErrorModal(true);
        } else {
          setError({ type: 'error', message: 'Wrong email or password, try again!' });
        }
      });
  }

  const handleAnonymousSignIn = async () => {
    signInAnonymously(FIREBASE_AUTH)
      .then(async () => {
        console.log('Logged in anonymously');
        
        const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid);
        const docSnapshot = await getDoc(docRef);

          if (!docSnapshot.exists()) {
            updateProfile(FIREBASE_AUTH.currentUser, {
              displayName: "Anon",
              photoURL: "https://firebasestorage.googleapis.com/v0/b/words-of-a-feather-f196f.appspot.com/o/profilePictures%2F0ADXUFIpkGPEgFT6PIm762RUo8P2?alt=media&token=2a6c08c5-0fc2-4772-90f4-0a9a03b044cc",
            }).then(() => {
                const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser?.uid)
                setDoc(docRef, {
                  "name": "Anon",
                  "picURI": "https://firebasestorage.googleapis.com/v0/b/words-of-a-feather-f196f.appspot.com/o/profilePictures%2F0ADXUFIpkGPEgFT6PIm762RUo8P2?alt=media&token=2a6c08c5-0fc2-4772-90f4-0a9a03b044cc",
                  "uid": FIREBASE_AUTH.currentUser?.uid,
                  "bio": "Anonymous. I'll sign up later!",
                }, { merge: true })
                .then(() => {
                    navigate('/thoughts2')
                })
                .catch((error) => {
                    console.error('Failed to update Firestore document: ', error);
                });
            })
            .catch((error) => {
                console.error('Failed to update profile: ', error);
            });
            setLoggedIn(true);
            navigate('/thoughts2');
            
          } else {
            setLoggedIn(true);
            navigate('/thoughts2');
          }
      })
      .catch(error => {
        setError({ type: 'error', message: 'Error signing in anonymously: ' + error.message });
      });
  };
  

  const handleGoogleSignIn = async () => {
    const wasAnonymouslySignedIn = FIREBASE_AUTH.currentUser?.isAnonymous;
    setGoogleSignInLoading(true);
    const provider = new GoogleAuthProvider();
  
    try {
      const result = await signInWithPopup(FIREBASE_AUTH, provider);
      const user = result.user;
  
      if (wasAnonymouslySignedIn && result && result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
        await linkWithCredential(user, result.credential);
        console.log('Anonymous account successfully upgraded:', user);
      }
  
      const signInMethods = await fetchSignInMethodsForEmail(FIREBASE_AUTH, user.email);
      if (signInMethods.length > 1 || signInMethods[0] !== 'google.com') {
        setError({
          type: 'error', 
          message: 'This email is already associated with another sign-in method. Please use that to sign in.'
        });
        return;
      }
  
      console.log('User logged in with Google:', user);
  
      const docRef = doc(FIRESTORE_DB, "Users", user.uid);
      const docSnapshot = await getDoc(docRef);
  
      if (!docSnapshot.exists()) {
        await setDoc(docRef, {
          "name": user.displayName,
          "picURI": user.photoURL,
          "uid": user.uid,
          "bio": "",
        }, { merge: true });
        navigate('/thoughts2');
      } else {
        navigate('/thoughts2');
      }
    } catch (error) {
      setGoogleSignInError('Failed to sign in with Google: ' + error.message);
      setShowGoogleSignInErrorModal(true);
    }
    setGoogleSignInLoading(false);
  };
  


  
  // want the overall height to fill its parent height, even if the parent height is dynamic

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,

      }}>


    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',     }}
    >
    <Typography sx={{ fontWeight: 'bold', fontSize: '30px', paddingBottom: 4, color: theme.palette.primary.main, maxWidth: '80%',
      textAlign: 'center',
    }}>
      What is Thought Portals?
    </Typography>
    <Typography sx={{ fontSize: "20px", paddingBottom: 4, color: theme.palette.primary.main, maxWidth: '80%',
        textAlign: 'center',
      }}>
      It's like a notes app, mixed with social media. Build connections with others based on your thoughts, and get more from your ideas.
    </Typography>
    <Typography sx={{ fontSize: "20px", paddingBottom: 4, color: theme.palette.primary.main, maxWidth: '80%',
        textAlign: 'center',
      }}>
      Signing in lets us secure your thoughts. Get started now!
    </Typography>
      {!loading ? (
        <>
<Button
    variant="outlined"
    disabled={googleSignInLoading}
    style={{
        textTransform: 'none',  // prevents automatic capitalization
        fontSize: '16px',
        height: '41px',
        backgroundColor: theme.palette.secondary.main,  // white background
        width: '300px', // making it same width as other buttons
        marginBottom: '10px', // spacing between buttons
        display: 'flex', // using flexbox
        justifyContent: 'center', // center items horizontally
        alignItems: 'center', // center items vertically
        padding: '0 16px' // default MUI padding for buttons
    }}
    onClick={handleGoogleSignIn}
>
{googleSignInLoading ? (
    <CircularProgress size={24} />
  ) : (

    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <img src={`${process.env.PUBLIC_URL}/google_icon.png`} alt="Google Icon" style={{ width: '30px', height: '30px', marginRight: '8px', position: 'absolute', left: '12px', background: '#ffffff', borderRadius: '20%' }} />
        <div>
            Sign in with Google
        </div>
    </div>
  )}
</Button>



      {!showEmailLogin ?
        <>
        <Button
    variant="outlined"
    color='primary'
    style={{
        textTransform: 'none',  // prevents automatic capitalization
        fontSize: '16px',
        height: '41px',
        backgroundColor: theme.palette.secondary.main,  // white background
        width: '300px', // making it same width as other buttons
        marginBottom: '10px', // spacing between buttons
        display: 'flex', // using flexbox
        justifyContent: 'center', // center items horizontally
        alignItems: 'center', // center items vertically
        padding: '0 16px' // default MUI padding for buttons
    }}
    onClick={handleEmailLoginClick}
>
    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        <MailOutlineIcon style={{ width: '24px', height: '24px', marginRight: '8px', position: 'absolute', left: '16px' }} />
        <div>
            Sign in with Email
        </div>
    </div>
</Button>
        </>
        :
        <>

          <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Login" />
        <Tab label="Sign Up" />
      </Tabs>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {tabValue === 0 && (
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12} sm={8} md={6} lg={4}
          >
            <TextField
        label="Email"
        value={email}
        onChange={event => setEmail(event.target.value)}
        variant="outlined"
        margin="normal"
        sx={{
            width: '100%',
            maxWidth: '90%',
            mx: 'auto',
        }}
    />
            <TextField
              fullWidth
              label="Password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              type="password"
              variant="outlined"
              margin="normal"
              sx={{
                width: '100%',
                maxWidth: '90%',
                mx: 'auto',
            }}            />
          </Grid>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSignIn}>Log in</Button>
          </Box>

          <Box mt={1}>
            <Button color="primary" onClick={() => sendPasswordResetEmail(email)}>Forgot Password?</Button>
          </Box>
        </>
      )}
      {tabValue === 1 && (
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12} sm={8} md={6} lg={4}
          >
            <TextField
              fullWidth
              label="Display Name"
              value={displayName}
              onChange={event => setDisplayName(event.target.value)}
              variant="outlined"
              margin="normal"
              sx={{
                width: '100%',
                maxWidth: '90%',
                mx: 'auto',
            }}            />
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              variant="outlined"
              margin="normal"
              sx={{
                width: '100%',
                maxWidth: '90%',
                mx: 'auto',
            }}            />
            <TextField
              fullWidth
              label="Password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              type="password"
              variant="outlined"
              margin="normal"
              sx={{
                width: '100%',
                maxWidth: '90%',
                mx: 'auto',
            }}            />
          </Grid>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSignUp}>Sign Up</Button>
          </Box>
          <Box mt={1}>
            <Button style={{ visibility: 'hidden' }}>Placeholder</Button>
          </Box>
        </>
      )}
        </>
      }
<Button
    variant="outlined"
    color='primary'
    style={{
        textTransform: 'none',  // prevents automatic capitalization
        fontSize: '16px',
        height: '41px',
        backgroundColor: theme.palette.secondary.main,  // white background
        width: '300px', // making it same width as other buttons
        marginBottom: '10px', // spacing between buttons
        display: 'flex', // using flexbox
        justifyContent: 'center', // center items horizontally
        alignItems: 'center', // center items vertically
        padding: '0 16px', // default MUI padding for buttons
        marginBottom: '100px'
    }}
    onClick={handleAnonymousSignIn}
>
    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <PersonOffIcon style={{ width: '24px', height: '24px', marginRight: '8px', position: 'absolute', left: '16px' }} />
        <div>
            Sign in anonymously
        </div>
    </div>
</Button>
        </>
      ) : (
        <CircularProgress /> // display loading spinner if loading is true
      )}
      
      {error && (
        <Alert severity={error.type} sx={{ width: '100%', mt: 2 }}>
          {error.message}
        </Alert>
      )}
    </Grid>
    {showLoginErrorModal && (
  <Dialog
    open={showLoginErrorModal}
    onClose={() => setShowLoginErrorModal(false)}
  >
    <DialogTitle>{"Login Failed"}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        That email isn't associated with an account. Would you like to create an account, or try again?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCreateAccountFromModal} color="primary">
        Create account!
      </Button>
      <Button onClick={() => setShowLoginErrorModal(false)} color="primary" autoFocus>
        Try to log in
      </Button>
    </DialogActions>
  </Dialog>
)}

{showSignupErrorModal && (
  <Dialog
    open={showSignupErrorModal}
    onClose={() => setShowSignupErrorModal(false)}
  >
    <DialogTitle>{"Signup Error"}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {signupErrorMessage}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setShowSignupErrorModal(false)} color="primary">
        Okay
      </Button>
    </DialogActions>
  </Dialog>
)}

    </Box>
  );

};

export default LoginScreen;


