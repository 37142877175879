import { FIREBASE_AUTH } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp, doc, getDocs, query, where, limit, orderBy, setDoc, increment } from "firebase/firestore";
import { FIRESTORE_DB } from "../firebaseConfig";
import { useRef } from "react";
import { incrementThoughtShares } from "../IncrementStats";

 
const checkThatThoughtIsUnique = async (inputValue) => {
    console.log("checkThatThoughtIsUnique entered with inputValue:", inputValue);
    const quotesRef = collection(FIRESTORE_DB, 'Quotes');
    const q = query(quotesRef, where("quote", "==", inputValue));
    const querySnapshot = await getDocs(q);
    const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log("Fetched quotes:", fetchedQuotes);
    if (fetchedQuotes.length > 0) {
        return false;
    }else{
        return true;
    }
}

    

/**
 * This function only shares the thoughts. It should not fetch results or anything like that. It should only share the thought and return the seedID.
 */
export const shareThought = async ({ thoughtNumber, inputValue, anonPostAllowed, setAnonPostAllowed, shareLocation, latitude, longitude, user }) => {
    console.log("shareThought entered with inputValue:", inputValue, "anonPostAllowed:", anonPostAllowed, "shareLocation:", shareLocation, "user ID", user?.uid);

    try {
        if (!user) {
            console.log("User not found. Redirecting to /login...");
            return { errorCode: 694, errorMessage: "User not found" };
        }

        // if (user.isAnonymous && !anonPostAllowed) {
        //     return { errorCode: 695, errorMessage: "Anonymous posting not allowed", state: { thoughtText: inputValue } };
        // }

        if (inputValue === "") {
            console.log("Thought input is empty");
            return { errorCode: 690, errorMessage: "Thought input is empty" };
        }

        const thoughtIsUnique = await checkThatThoughtIsUnique(inputValue);
        if (!thoughtIsUnique) {
            return { errorCode: 691, errorMessage: "Thought is not unique" };
        }

        const newThoughtNumber = thoughtNumber + 1;

        const thought = {
            createdAt: serverTimestamp(),
            quote: inputValue,
            userID: user.isAnonymous ? "anonymous_user_id" : user.uid,
            displayName: user.isAnonymous ? "Anon" : user.displayName,
            profilePicURL: user.isAnonymous ? "" : user.photoURL,
        };

        const docRef = await addDoc(collection(FIRESTORE_DB, 'Quotes'), thought);
        console.log('Thought added with ID:', docRef.id);

        // Update sub-collections for myThoughts and Profile Quotes
        const myThoughtsEntry = {
            quote: inputValue,
            createdAt: serverTimestamp(),
            isPublic: true,
            isProfile: true,
            isVault: false,
            id: docRef.id,
            thoughtNumber: newThoughtNumber,    
        };
        const profileThoughtEntry = {
            createdAt: serverTimestamp(),
            quote: inputValue,
            isArchived: false,
            id: docRef.id,
            thoughtNumber: newThoughtNumber,
        };

        await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', docRef.id), profileThoughtEntry);

        console.log("Sending data to Flask server...");
        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/share_thought', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quote: inputValue,
                seedID: docRef.id,
                userID: user.uid,
                alsoUpsertPrivately: true,
                lat: latitude,
                lon: longitude,
                createdAtMs: Date.now(),
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Thought shared with Flask server:", data);

        // The function was successful, no error codes needed.
        await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', docRef.id), myThoughtsEntry);

        incrementThoughtShares();

        return { errorCode: 0, errorMessage: "Success", data, docID: docRef.id };
    } catch (error) {
        console.error('Error during sharing thought:', error);
        // Return an error code that indicates an unknown error occurred.
        return { errorCode: 700, errorMessage: "An error occurred while sharing the thought" };
    } finally {
        // If you have any cleanup code or you need to reset state, do it here.
        setAnonPostAllowed(false); // This assumes you want to reset this state no matter what happens.
    }
};

