import { collection, getDocs } from 'firebase/firestore';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { getItem, setItem } from './storage';

export const updateBlockedUsersInternally = async () => {
  // Get all blocked users from Firestore
  const db = FIRESTORE_DB;
  const currentUserUID = FIREBASE_AUTH.currentUser.uid;
  const blockedUsersKey = 'blocked_users';
  const blockedUsersRef = collection(db, 'Users', currentUserUID, 'Blocked Users');
  const blockedUsersSnapshot = await getDocs(blockedUsersRef);

  // Extract the UID of each blocked user and store it in an array
  const blockedUIDs = blockedUsersSnapshot.docs.map((doc) => doc.data().uid);

  // Save the array of blocked user UIDs to local storage
  await setItem(blockedUsersKey, blockedUIDs);
};

export const checkIfUserBlocked = async (uids) => {
  const blockedUsersKey = 'blocked_users';
  // Get the list of blocked user UIDs from local storage
  const blockedUIDs = await getItem(blockedUsersKey) || [];

  // Filter the provided UIDs array and return only the UIDs that are in the list of blocked UIDs
  return uids.filter((uid) => blockedUIDs.includes(uid));
};
