
import React, { useEffect, useState } from 'react';
import { FIRESTORE_DB, FIREBASE_AUTH } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Typography, Box } from '@mui/material';
import RenderAllThoughts from '../Tabs/AllThoughtsTab/RenderAllThoughts';
import { useTheme } from '@emotion/react';



export default function CategoryNavigator() {
    const theme = useTheme();

  const [currentCategories, setCurrentCategories] = useState(categories);
  const [breadcrumb, setBreadcrumb] = useState(['Root']);
  const [results, setResults] = useState([]); 

  const handleClick = (category) => {
    setCurrentCategories(category.subcategories);
    setBreadcrumb([...breadcrumb, category.name]);
  };

  const handleBack = () => {
    const newBreadcrumb = breadcrumb.slice(0, -1);
    setBreadcrumb(newBreadcrumb);

    // Navigate back logic
    let newCurrentCategories = categories;
    newBreadcrumb.slice(1).forEach(name => {
      newCurrentCategories = newCurrentCategories.find(cat => cat.name === name).subcategories;
    });

    setCurrentCategories(newCurrentCategories);
  };

  const handleBreadcrumbClick = (index) => {
    const newBreadcrumb = breadcrumb.slice(0, index + 1);
    setBreadcrumb(newBreadcrumb);

    let newCurrentCategories = categories;
    newBreadcrumb.slice(1).forEach(name => {
      newCurrentCategories = newCurrentCategories.find(cat => cat.name === name).subcategories;
    });

    setCurrentCategories(newCurrentCategories);
  };

  const fetchCategoryResults = async (totalBreadcrumbTrail) => {
    try {
      const q = query(
        collection(FIRESTORE_DB, 'Quotes'),
        where('contentClassification', 'array-contains', totalBreadcrumbTrail)
      );
      const querySnapshot = await getDocs(q);
      console.log("querySnapshot: ", querySnapshot);
  
      const results = [];
      querySnapshot.forEach(doc => {
        results.push(doc.data());
      });
      return results;
    } catch (error) {
      console.error("Error getting documents: ", error);
      return [];
    }
  };
  
  

  useEffect(() => {
    console.log("current categories", currentCategories);
    if (currentCategories.length === 0) {
      // Now we need to search Firebase to retrieve the results for that category
      let totalBreadcrumbTrail = '/' + breadcrumb.slice(1).join('/');
      console.log("Total breadcrumb trail: ", totalBreadcrumbTrail);
  
      // Call the async function and wait for the results before setting state
      const getResults = async () => {
        const results = await fetchCategoryResults(totalBreadcrumbTrail);
        setResults(results);
      };
  
      getResults();
    }
  }, [currentCategories, breadcrumb]);
  

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%', // Make the box full width
        marginTop: '10px', // Add some margin at the top
        marginX: 'auto', // Center the box with auto margins on the sides
        padding: '0 10px' // Add padding on the sides for maintaining internal spacing
    }}>
      <div>
        {breadcrumb.map((crumb, index) => (
          <span key={index}>
            <button onClick={() => handleBreadcrumbClick(index)}>{crumb}</button>
            {index < breadcrumb.length - 1 ? ' > ' : ''}
          </span>
        ))}
      </div>
      <h2>{breadcrumb[breadcrumb.length - 1]}</h2>
      <ul>
        {currentCategories.map(category => (
          <li key={category.name} onClick={() => handleClick(category)}>
            {category.name}
          </li>
        ))}
      </ul>

        {currentCategories.length === 0 && (
        results.length !== 0 ? 
        <RenderAllThoughts results={results} theme={theme}/>
        :
        <Typography variant="subtitle1">
        No results found for this category. Please try another category. Or... you could always share your thoughts on this matter, and they'll get auto classified into the appropriate category! This provides a richer network of thoughts for everyone to explore. Thank you, for caring. Sharing, is caring.
        </Typography>
        
        )}
        



    </Box>
  );
}
 

const categories =[
    {
      "name": "Adult",
      "subcategories": []
    },
    {
      "name": "Arts & Entertainment",
      "subcategories": [
        {
          "name": "Celebrities & Entertainment News",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Comics & Animation",
          "subcategories": [
            {
              "name": "Anime & Manga",
              "subcategories": []
            },
            {
              "name": "Cartoons",
              "subcategories": []
            },
            {
              "name": "Comics",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Entertainment Industry",
          "subcategories": [
            {
              "name": "Film & TV Industry",
              "subcategories": []
            },
            {
              "name": "Recording Industry",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Events & Listings",
          "subcategories": [
            {
              "name": "Bars, Clubs & Nightlife",
              "subcategories": []
            },
            {
              "name": "Concerts & Music Festivals",
              "subcategories": []
            },
            {
              "name": "Event Ticket Sales",
              "subcategories": []
            },
            {
              "name": "Expos & Conventions",
              "subcategories": []
            },
            {
              "name": "Film Festivals",
              "subcategories": []
            },
            {
              "name": "Food & Beverage Events",
              "subcategories": []
            },
            {
              "name": "Live Sporting Events",
              "subcategories": []
            },
            {
              "name": "Movie Listings & Theater Showtimes",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Fun & Trivia",
          "subcategories": [
            {
              "name": "Flash-Based Entertainment",
              "subcategories": []
            },
            {
              "name": "Fun Tests & Silly Surveys",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Humor",
          "subcategories": [
            {
              "name": "Funny Pictures & Videos",
              "subcategories": []
            },
            {
              "name": "Live Comedy",
              "subcategories": []
            },
            {
              "name": "Political Humor",
              "subcategories": []
            },
            {
              "name": "Spoofs & Satire",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Movies",
          "subcategories": [
            {
              "name": "Action & Adventure Films",
              "subcategories": []
            },
            {
              "name": "Animated Films",
              "subcategories": []
            },
            {
              "name": "Bollywood & South Asian Films",
              "subcategories": []
            },
            {
              "name": "Classic Films",
              "subcategories": []
            },
            {
              "name": "Comedy Films",
              "subcategories": []
            },
            {
              "name": "Cult & Indie Films",
              "subcategories": []
            },
            {
              "name": "Documentary Films",
              "subcategories": []
            },
            {
              "name": "Drama Films",
              "subcategories": []
            },
            {
              "name": "DVD & Video Shopping",
              "subcategories": []
            },
            {
              "name": "Family Films",
              "subcategories": []
            },
            {
              "name": "Horror Films",
              "subcategories": []
            },
            {
              "name": "Movie Memorabilia",
              "subcategories": []
            },
            {
              "name": "Movie Reference",
              "subcategories": []
            },
            {
              "name": "Musical Films",
              "subcategories": []
            },
            {
              "name": "Romance Films",
              "subcategories": []
            },
            {
              "name": "Science Fiction & Fantasy Films",
              "subcategories": []
            },
            {
              "name": "Thriller, Crime & Mystery Films",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Music & Audio",
          "subcategories": [
            {
              "name": "CD & Audio Shopping",
              "subcategories": []
            },
            {
              "name": "Classical Music",
              "subcategories": []
            },
            {
              "name": "Country Music",
              "subcategories": []
            },
            {
              "name": "Dance & Electronic Music",
              "subcategories": []
            },
            {
              "name": "Experimental & Industrial Music",
              "subcategories": []
            },
            {
              "name": "Folk & Traditional Music",
              "subcategories": []
            },
            {
              "name": "Jazz & Blues",
              "subcategories": []
            },
            {
              "name": "Music Art & Memorabilia",
              "subcategories": []
            },
            {
              "name": "Music Education & Instruction",
              "subcategories": []
            },
            {
              "name": "Music Equipment & Technology",
              "subcategories": []
            },
            {
              "name": "Music Reference",
              "subcategories": []
            },
            {
              "name": "Music Streams & Downloads",
              "subcategories": []
            },
            {
              "name": "Music Videos",
              "subcategories": []
            },
            {
              "name": "Podcasts",
              "subcategories": []
            },
            {
              "name": "Pop Music",
              "subcategories": []
            },
            {
              "name": "Radio",
              "subcategories": []
            },
            {
              "name": "Religious Music",
              "subcategories": []
            },
            {
              "name": "Rock Music",
              "subcategories": []
            },
            {
              "name": "Soundtracks",
              "subcategories": []
            },
            {
              "name": "Urban & Hip-Hop",
              "subcategories": []
            },
            {
              "name": "Vocals & Show Tunes",
              "subcategories": []
            },
            {
              "name": "World Music",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Offbeat",
          "subcategories": [
            {
              "name": "Occult & Paranormal",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Online Media",
          "subcategories": [
            {
              "name": "Online Image Galleries",
              "subcategories": []
            },
            {
              "name": "Virtual Tours",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Performing Arts",
          "subcategories": [
            {
              "name": "Acting & Theater",
              "subcategories": []
            },
            {
              "name": "Broadway & Musical Theater",
              "subcategories": []
            },
            {
              "name": "Circus",
              "subcategories": []
            },
            {
              "name": "Dance",
              "subcategories": []
            },
            {
              "name": "Magic",
              "subcategories": []
            },
            {
              "name": "Opera",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "TV & Video",
          "subcategories": [
            {
              "name": "Online Video",
              "subcategories": []
            },
            {
              "name": "TV Commercials",
              "subcategories": []
            },
            {
              "name": "TV Guides & Reference",
              "subcategories": []
            },
            {
              "name": "TV Networks & Stations",
              "subcategories": []
            },
            {
              "name": "TV Shows & Programs",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Visual Art & Design",
          "subcategories": [
            {
              "name": "Architecture",
              "subcategories": []
            },
            {
              "name": "Art Museums & Galleries",
              "subcategories": []
            },
            {
              "name": "Design",
              "subcategories": []
            },
            {
              "name": "Painting",
              "subcategories": []
            },
            {
              "name": "Photographic & Digital Arts",
              "subcategories": []
            },
            {
              "name": "Sculpture",
              "subcategories": []
            },
            {
              "name": "Visual Arts & Design Education",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Autos & Vehicles",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Bicycles & Accessories",
          "subcategories": [
            {
              "name": "Bike Accessories",
              "subcategories": []
            },
            {
              "name": "Bike Frames",
              "subcategories": []
            },
            {
              "name": "Bike Helmets & Protective Gear",
              "subcategories": []
            },
            {
              "name": "Bike Parts & Repair",
              "subcategories": []
            },
            {
              "name": "BMX Bikes",
              "subcategories": []
            },
            {
              "name": "Cruiser Bicycles",
              "subcategories": []
            },
            {
              "name": "Electric Bicycles",
              "subcategories": []
            },
            {
              "name": "Kids' Bikes",
              "subcategories": []
            },
            {
              "name": "Mountain Bikes",
              "subcategories": []
            },
            {
              "name": "Road Bikes",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Boats & Watercraft",
          "subcategories": []
        },
        {
          "name": "Campers & RVs",
          "subcategories": []
        },
        {
          "name": "Classic Vehicles",
          "subcategories": []
        },
        {
          "name": "Commercial Vehicles",
          "subcategories": [
            {
              "name": "Cargo Trucks & Trailers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Custom & Performance Vehicles",
          "subcategories": []
        },
        {
          "name": "Motor Vehicles (By Brand)",
          "subcategories": [
            {
              "name": "Audi",
              "subcategories": []
            },
            {
              "name": "Bentley",
              "subcategories": []
            },
            {
              "name": "BMW",
              "subcategories": []
            },
            {
              "name": "Buick",
              "subcategories": []
            },
            {
              "name": "Cadillac",
              "subcategories": []
            },
            {
              "name": "Chevrolet",
              "subcategories": []
            },
            {
              "name": "Chrysler",
              "subcategories": []
            },
            {
              "name": "Citroën",
              "subcategories": []
            },
            {
              "name": "Daewoo Motors",
              "subcategories": []
            },
            {
              "name": "Dodge",
              "subcategories": []
            },
            {
              "name": "Ferrari",
              "subcategories": []
            },
            {
              "name": "Fiat",
              "subcategories": []
            },
            {
              "name": "Ford",
              "subcategories": []
            },
            {
              "name": "GMC",
              "subcategories": []
            },
            {
              "name": "Honda",
              "subcategories": []
            },
            {
              "name": "Hummer",
              "subcategories": []
            },
            {
              "name": "Hyundai",
              "subcategories": []
            },
            {
              "name": "Isuzu",
              "subcategories": []
            },
            {
              "name": "Jaguar",
              "subcategories": []
            },
            {
              "name": "Jeep",
              "subcategories": []
            },
            {
              "name": "Kia",
              "subcategories": []
            },
            {
              "name": "Lamborghini",
              "subcategories": []
            },
            {
              "name": "Land Rover",
              "subcategories": []
            },
            {
              "name": "Lincoln",
              "subcategories": []
            },
            {
              "name": "Maserati",
              "subcategories": []
            },
            {
              "name": "Mazda",
              "subcategories": []
            },
            {
              "name": "Mercedes-Benz",
              "subcategories": []
            },
            {
              "name": "Mercury",
              "subcategories": []
            },
            {
              "name": "Mini",
              "subcategories": []
            },
            {
              "name": "Mitsubishi",
              "subcategories": []
            },
            {
              "name": "Nissan",
              "subcategories": []
            },
            {
              "name": "Peugeot",
              "subcategories": []
            },
            {
              "name": "Pontiac",
              "subcategories": []
            },
            {
              "name": "Porsche",
              "subcategories": []
            },
            {
              "name": "Ram Trucks",
              "subcategories": []
            },
            {
              "name": "Renault",
              "subcategories": []
            },
            {
              "name": "Rolls-Royce",
              "subcategories": []
            },
            {
              "name": "Saab",
              "subcategories": []
            },
            {
              "name": "Saturn",
              "subcategories": []
            },
            {
              "name": "SEAT",
              "subcategories": []
            },
            {
              "name": "Skoda",
              "subcategories": []
            },
            {
              "name": "Subaru",
              "subcategories": []
            },
            {
              "name": "Suzuki",
              "subcategories": []
            },
            {
              "name": "Tesla Motors",
              "subcategories": []
            },
            {
              "name": "Toyota",
              "subcategories": []
            },
            {
              "name": "Vauxhall-Opel",
              "subcategories": []
            },
            {
              "name": "Volkswagen",
              "subcategories": []
            },
            {
              "name": "Volvo",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Motor Vehicles (By Type)",
          "subcategories": [
            {
              "name": "Autonomous Vehicles",
              "subcategories": []
            },
            {
              "name": "Compact Cars",
              "subcategories": []
            },
            {
              "name": "Convertibles",
              "subcategories": []
            },
            {
              "name": "Coupes",
              "subcategories": []
            },
            {
              "name": "Diesel Vehicles",
              "subcategories": []
            },
            {
              "name": "Hatchbacks",
              "subcategories": []
            },
            {
              "name": "Hybrid & Alternative Vehicles",
              "subcategories": []
            },
            {
              "name": "Luxury Vehicles",
              "subcategories": []
            },
            {
              "name": "Microcars & Subcompacts",
              "subcategories": []
            },
            {
              "name": "Motorcycles",
              "subcategories": []
            },
            {
              "name": "Off-Road Vehicles",
              "subcategories": []
            },
            {
              "name": "Scooters & Mopeds",
              "subcategories": []
            },
            {
              "name": "Sedans",
              "subcategories": []
            },
            {
              "name": "Sports Cars",
              "subcategories": []
            },
            {
              "name": "Station Wagons",
              "subcategories": []
            },
            {
              "name": "Trucks, Vans & SUVs",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Personal Aircraft",
          "subcategories": []
        },
        {
          "name": "Vehicle Codes & Driving Laws",
          "subcategories": [
            {
              "name": "Drunk Driving Law",
              "subcategories": []
            },
            {
              "name": "Vehicle Licensing & Registration",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Vehicle Parts & Services",
          "subcategories": [
            {
              "name": "Gas Prices & Vehicle Fueling",
              "subcategories": []
            },
            {
              "name": "Towing & Roadside Assistance",
              "subcategories": []
            },
            {
              "name": "Vehicle Modification & Tuning",
              "subcategories": []
            },
            {
              "name": "Vehicle Parts & Accessories",
              "subcategories": []
            },
            {
              "name": "Vehicle Repair & Maintenance",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Vehicle Shopping",
          "subcategories": [
            {
              "name": "Used Vehicles",
              "subcategories": []
            },
            {
              "name": "Vehicle Dealers & Retailers",
              "subcategories": []
            },
            {
              "name": "Vehicle Specs, Reviews & Comparisons",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Vehicle Shows",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Beauty & Fitness",
      "subcategories": [
        {
          "name": "Beauty Pageants",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Beauty Services & Spas",
          "subcategories": [
            {
              "name": "Cosmetic Procedures",
              "subcategories": []
            },
            {
              "name": "Manicures & Pedicures",
              "subcategories": []
            },
            {
              "name": "Massage Therapy",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Body Art",
          "subcategories": []
        },
        {
          "name": "Cosmetology & Beauty Professionals",
          "subcategories": []
        },
        {
          "name": "Face & Body Care",
          "subcategories": [
            {
              "name": "Clean Beauty",
              "subcategories": []
            },
            {
              "name": "Hygiene & Toiletries",
              "subcategories": []
            },
            {
              "name": "Make-Up & Cosmetics",
              "subcategories": []
            },
            {
              "name": "Perfumes & Fragrances",
              "subcategories": []
            },
            {
              "name": "Skin & Nail Care",
              "subcategories": []
            },
            {
              "name": "Sun Care & Tanning Products",
              "subcategories": []
            },
            {
              "name": "Unwanted Body & Facial Hair Removal",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Fashion & Style",
          "subcategories": [
            {
              "name": "Fashion Designers & Collections",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Fitness",
          "subcategories": [
            {
              "name": "Bodybuilding",
              "subcategories": []
            },
            {
              "name": "Fitness Equipment & Accessories",
              "subcategories": []
            },
            {
              "name": "Fitness Instruction & Personal Training",
              "subcategories": []
            },
            {
              "name": "Gyms & Health Clubs",
              "subcategories": []
            },
            {
              "name": "High Intensity Interval Training",
              "subcategories": []
            },
            {
              "name": "Yoga & Pilates",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Hair Care",
          "subcategories": [
            {
              "name": "Hair Loss",
              "subcategories": []
            },
            {
              "name": "Shampoos & Conditioners",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Weight Loss",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Books & Literature",
      "subcategories": [
        {
          "name": "Audiobooks",
          "subcategories": []
        },
        {
          "name": "Book Retailers",
          "subcategories": []
        },
        {
          "name": "Children's Literature",
          "subcategories": []
        },
        {
          "name": "E-Books",
          "subcategories": []
        },
        {
          "name": "Fan Fiction",
          "subcategories": []
        },
        {
          "name": "Literary Classics",
          "subcategories": []
        },
        {
          "name": "Poetry",
          "subcategories": []
        },
        {
          "name": "Writers Resources",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Business & Industrial",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Advertising & Marketing",
          "subcategories": [
            {
              "name": "Brand Management",
              "subcategories": []
            },
            {
              "name": "Marketing",
              "subcategories": []
            },
            {
              "name": "Public Relations",
              "subcategories": []
            },
            {
              "name": "Sales",
              "subcategories": []
            },
            {
              "name": "Telemarketing",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Aerospace & Defense",
          "subcategories": [
            {
              "name": "Aviation Industry",
              "subcategories": []
            },
            {
              "name": "Space Technology",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Agriculture & Forestry",
          "subcategories": [
            {
              "name": "Agricultural Equipment",
              "subcategories": []
            },
            {
              "name": "Aquaculture",
              "subcategories": []
            },
            {
              "name": "Crops & Seed",
              "subcategories": []
            },
            {
              "name": "Farms & Ranches",
              "subcategories": []
            },
            {
              "name": "Forestry",
              "subcategories": []
            },
            {
              "name": "Livestock",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Automotive Industry",
          "subcategories": []
        },
        {
          "name": "Business Education",
          "subcategories": []
        },
        {
          "name": "Business Finance",
          "subcategories": [
            {
              "name": "Commercial Lending",
              "subcategories": []
            },
            {
              "name": "Investment Banking",
              "subcategories": []
            },
            {
              "name": "Risk Management",
              "subcategories": []
            },
            {
              "name": "Venture Capital",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Business Operations",
          "subcategories": [
            {
              "name": "Business Plans & Presentations",
              "subcategories": []
            },
            {
              "name": "Flexible Work Arrangements",
              "subcategories": []
            },
            {
              "name": "Human Resources",
              "subcategories": []
            },
            {
              "name": "Management",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Business Services",
          "subcategories": [
            {
              "name": "Commercial Distribution",
              "subcategories": []
            },
            {
              "name": "Consulting",
              "subcategories": []
            },
            {
              "name": "Corporate Events",
              "subcategories": []
            },
            {
              "name": "E-Commerce Services",
              "subcategories": []
            },
            {
              "name": "Fire & Security Services",
              "subcategories": []
            },
            {
              "name": "Knowledge Management",
              "subcategories": []
            },
            {
              "name": "Office Services",
              "subcategories": []
            },
            {
              "name": "Office Supplies",
              "subcategories": []
            },
            {
              "name": "Outsourcing",
              "subcategories": []
            },
            {
              "name": "Physical Asset Management",
              "subcategories": []
            },
            {
              "name": "Quality Control & Tracking",
              "subcategories": []
            },
            {
              "name": "Shared Workspaces",
              "subcategories": []
            },
            {
              "name": "Signage",
              "subcategories": []
            },
            {
              "name": "Warehousing",
              "subcategories": []
            },
            {
              "name": "Writing & Editing Services",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Chemicals Industry",
          "subcategories": [
            {
              "name": "Agrochemicals",
              "subcategories": []
            },
            {
              "name": "Cleaning Agents",
              "subcategories": []
            },
            {
              "name": "Coatings & Adhesives",
              "subcategories": []
            },
            {
              "name": "Dyes & Pigments",
              "subcategories": []
            },
            {
              "name": "Plastics & Polymers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Construction & Maintenance",
          "subcategories": [
            {
              "name": "Building Materials & Supplies",
              "subcategories": []
            },
            {
              "name": "Civil Engineering",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Energy & Utilities",
          "subcategories": [
            {
              "name": "Electricity",
              "subcategories": []
            },
            {
              "name": "Nuclear Energy",
              "subcategories": []
            },
            {
              "name": "Oil & Gas",
              "subcategories": []
            },
            {
              "name": "Renewable & Alternative Energy",
              "subcategories": []
            },
            {
              "name": "Waste Management",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Hospitality Industry",
          "subcategories": [
            {
              "name": "Event Planning",
              "subcategories": []
            },
            {
              "name": "Event Venue Rentals",
              "subcategories": []
            },
            {
              "name": "Food Service",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Industrial Materials & Equipment",
          "subcategories": [
            {
              "name": "Fluid Handling",
              "subcategories": []
            },
            {
              "name": "Generators",
              "subcategories": []
            },
            {
              "name": "Heavy Machinery",
              "subcategories": []
            },
            {
              "name": "Industrial Handling & Processing Equipment",
              "subcategories": []
            },
            {
              "name": "Industrial Measurement & Control Equipment",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Manufacturing",
          "subcategories": [
            {
              "name": "Factory Automation",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Metals & Mining",
          "subcategories": [
            {
              "name": "Precious Metals",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Pharmaceuticals & Biotech",
          "subcategories": []
        },
        {
          "name": "Printing & Publishing",
          "subcategories": [
            {
              "name": "Document & Printing Services",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Retail Trade",
          "subcategories": [
            {
              "name": "Retail Equipment & Technology",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Shipping & Logistics",
          "subcategories": [
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Freight Transport",
              "subcategories": [
                {
                  "name": "Maritime Transport",
                  "subcategories": []
                },
                {
                  "name": "Rail Freight",
                  "subcategories": []
                },
                {
                  "name": "Other",
                  "subcategories": []
                }
              ]
            },
            {
              "name": "Import & Export",
              "subcategories": []
            },
            {
              "name": "Mail & Package Delivery",
              "subcategories": []
            },
            {
              "name": "Moving & Relocation",
              "subcategories": []
            },
            {
              "name": "Packaging",
              "subcategories": []
            },
            {
              "name": "Self Storage",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Small Business",
          "subcategories": [
            {
              "name": "Business Formation",
              "subcategories": []
            },
            {
              "name": "Home Office",
              "subcategories": []
            },
            {
              "name": "MLM & Business Opportunities",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Textiles & Nonwovens",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Computers & Electronics",
      "subcategories": [
        {
          "name": "CAD & CAM",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Computer Hardware",
          "subcategories": [
            {
              "name": "Computer Components",
              "subcategories": []
            },
            {
              "name": "Computer Drives & Storage",
              "subcategories": []
            },
            {
              "name": "Computer Peripherals",
              "subcategories": []
            },
            {
              "name": "Computer Servers",
              "subcategories": []
            },
            {
              "name": "Desktop Computers",
              "subcategories": []
            },
            {
              "name": "Hardware Modding & Tuning",
              "subcategories": []
            },
            {
              "name": "Laptops & Notebooks",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Computer Security",
          "subcategories": [
            {
              "name": "Antivirus & Malware",
              "subcategories": []
            },
            {
              "name": "Hacking & Cracking",
              "subcategories": []
            },
            {
              "name": "Network Security",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Consumer Electronics",
          "subcategories": [
            {
              "name": "Audio Equipment",
              "subcategories": []
            },
            {
              "name": "Camera & Photo Equipment",
              "subcategories": []
            },
            {
              "name": "Car Electronics",
              "subcategories": []
            },
            {
              "name": "Drones & RC Aircraft",
              "subcategories": []
            },
            {
              "name": "Electronic Accessories",
              "subcategories": []
            },
            {
              "name": "Gadgets & Portable Electronics",
              "subcategories": []
            },
            {
              "name": "Game Systems & Consoles",
              "subcategories": []
            },
            {
              "name": "GPS & Navigation",
              "subcategories": []
            },
            {
              "name": "Home Automation",
              "subcategories": []
            },
            {
              "name": "Media Streaming Devices",
              "subcategories": []
            },
            {
              "name": "TV & Video Equipment",
              "subcategories": []
            },
            {
              "name": "Virtual Reality Devices",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Electronics & Electrical",
          "subcategories": [
            {
              "name": "Data Sheets & Electronics Reference",
              "subcategories": []
            },
            {
              "name": "Electrical Test & Measurement",
              "subcategories": []
            },
            {
              "name": "Electromechanical Devices",
              "subcategories": []
            },
            {
              "name": "Electronic Components",
              "subcategories": []
            },
            {
              "name": "Optoelectronics & Fiber",
              "subcategories": []
            },
            {
              "name": "Power Supplies",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Enterprise Technology",
          "subcategories": [
            {
              "name": "Customer Relationship Management (CRM)",
              "subcategories": []
            },
            {
              "name": "Data Management",
              "subcategories": []
            },
            {
              "name": "Enterprise Resource Planning (ERP)",
              "subcategories": []
            },
            {
              "name": "Helpdesk & Customer Support Systems",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Networking",
          "subcategories": [
            {
              "name": "Data Formats & Protocols",
              "subcategories": []
            },
            {
              "name": "Distributed & Cloud Computing",
              "subcategories": []
            },
            {
              "name": "Network Monitoring & Management",
              "subcategories": []
            },
            {
              "name": "Networking Equipment",
              "subcategories": []
            },
            {
              "name": "VPN & Remote Access",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Programming",
          "subcategories": [
            {
              "name": "C & C++",
              "subcategories": []
            },
            {
              "name": "Development Tools",
              "subcategories": []
            },
            {
              "name": "Java (Programming Language)",
              "subcategories": []
            },
            {
              "name": "Scripting Languages",
              "subcategories": []
            },
            {
              "name": "Windows & .NET",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Software",
          "subcategories": [
            {
              "name": "Business & Productivity Software",
              "subcategories": []
            },
            {
              "name": "Device Drivers",
              "subcategories": []
            },
            {
              "name": "Educational Software",
              "subcategories": []
            },
            {
              "name": "Freeware & Shareware",
              "subcategories": []
            },
            {
              "name": "Intelligent Personal Assistants",
              "subcategories": []
            },
            {
              "name": "Internet Software",
              "subcategories": []
            },
            {
              "name": "Monitoring Software",
              "subcategories": []
            },
            {
              "name": "Multimedia Software",
              "subcategories": []
            },
            {
              "name": "Open Source",
              "subcategories": []
            },
            {
              "name": "Operating Systems",
              "subcategories": []
            },
            {
              "name": "Software Utilities",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Finance",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Accounting & Auditing",
          "subcategories": [
            {
              "name": "Billing & Invoicing",
              "subcategories": []
            },
            {
              "name": "Bookkeeping",
              "subcategories": []
            },
            {
              "name": "Tax Preparation & Planning",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Banking",
          "subcategories": [
            {
              "name": "ATMs & Branch Locations",
              "subcategories": []
            },
            {
              "name": "Debit & Checking Services",
              "subcategories": []
            },
            {
              "name": "Mobile Payments & Digital Wallets",
              "subcategories": []
            },
            {
              "name": "Money Transfer & Wire Services",
              "subcategories": []
            },
            {
              "name": "Savings Accounts",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Credit & Lending",
          "subcategories": [
            {
              "name": "Credit Cards",
              "subcategories": []
            },
            {
              "name": "Credit Reporting & Monitoring",
              "subcategories": []
            },
            {
              "name": "Debt Collection & Repossession",
              "subcategories": []
            },
            {
              "name": "Debt Management",
              "subcategories": []
            },
            {
              "name": "Loans",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Crowdfunding",
          "subcategories": []
        },
        {
          "name": "Financial Planning & Management",
          "subcategories": [
            {
              "name": "Asset & Portfolio Management",
              "subcategories": []
            },
            {
              "name": "Inheritance & Estate Planning",
              "subcategories": []
            },
            {
              "name": "Retirement & Pension",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Grants, Scholarships & Financial Aid",
          "subcategories": [
            {
              "name": "Government Grants",
              "subcategories": []
            },
            {
              "name": "Study Grants & Scholarships",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Insurance",
          "subcategories": [
            {
              "name": "Health Insurance",
              "subcategories": []
            },
            {
              "name": "Home Insurance",
              "subcategories": []
            },
            {
              "name": "Life Insurance",
              "subcategories": []
            },
            {
              "name": "Travel Insurance",
              "subcategories": []
            },
            {
              "name": "Vehicle Insurance",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Investing",
          "subcategories": [
            {
              "name": "Brokerages & Day Trading",
              "subcategories": []
            },
            {
              "name": "Commodities & Futures Trading",
              "subcategories": []
            },
            {
              "name": "Currencies & Foreign Exchange",
              "subcategories": []
            },
            {
              "name": "Derivatives",
              "subcategories": []
            },
            {
              "name": "Funds",
              "subcategories": []
            },
            {
              "name": "Real Estate Investment Trusts",
              "subcategories": []
            },
            {
              "name": "Socially Responsible Investing",
              "subcategories": []
            },
            {
              "name": "Stocks & Bonds",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Food & Drink",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Beverages",
          "subcategories": [
            {
              "name": "Alcohol-Free Beverages",
              "subcategories": []
            },
            {
              "name": "Alcoholic Beverages",
              "subcategories": []
            },
            {
              "name": "Bottled Water",
              "subcategories": []
            },
            {
              "name": "Coffee & Tea",
              "subcategories": []
            },
            {
              "name": "Energy Drinks",
              "subcategories": []
            },
            {
              "name": "Juice",
              "subcategories": []
            },
            {
              "name": "Nutrition Drinks & Shakes",
              "subcategories": []
            },
            {
              "name": "Soft Drinks",
              "subcategories": []
            },
            {
              "name": "Sports Drinks",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Cooking & Recipes",
          "subcategories": [
            {
              "name": "BBQ & Grilling",
              "subcategories": []
            },
            {
              "name": "Cuisines",
              "subcategories": []
            },
            {
              "name": "Culinary Training",
              "subcategories": []
            },
            {
              "name": "Desserts",
              "subcategories": []
            },
            {
              "name": "Healthy Eating",
              "subcategories": []
            },
            {
              "name": "Salads",
              "subcategories": []
            },
            {
              "name": "Soups & Stews",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Food",
          "subcategories": [
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Baked Goods",
              "subcategories": []
            },
            {
              "name": "Breakfast Foods",
              "subcategories": []
            },
            {
              "name": "Candy & Sweets",
              "subcategories": []
            },
            {
              "name": "Condiments & Dressings",
              "subcategories": []
            },
            {
              "name": "Cooking Fats & Oils",
              "subcategories": []
            },
            {
              "name": "Dairy & Egg Substitutes",
              "subcategories": []
            },
            {
              "name": "Dairy & Eggs",
              "subcategories": []
            },
            {
              "name": "Fruits & Vegetables",
              "subcategories": []
            },
            {
              "name": "Gourmet & Specialty Foods",
              "subcategories": []
            },
            {
              "name": "Grains & Pasta",
              "subcategories": []
            },
            {
              "name": "Herbs & Spices",
              "subcategories": []
            },
            {
              "name": "Jams, Jellies & Preserves",
              "subcategories": []
            },
            {
              "name": "Meat & Seafood",
              "subcategories": []
            },
            {
              "name": "Meat & Seafood Substitutes",
              "subcategories": []
            },
            {
              "name": "Organic & Natural Foods",
              "subcategories": []
            },
            {
              "name": "Snack Foods",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Food & Grocery Delivery",
          "subcategories": [
            {
              "name": "Grocery Delivery Services",
              "subcategories": []
            },
            {
              "name": "Meal Kits",
              "subcategories": []
            },
            {
              "name": "Restaurant Delivery Services",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Food & Grocery Retailers",
          "subcategories": [
            {
              "name": "Bakeries",
              "subcategories": []
            },
            {
              "name": "Butchers",
              "subcategories": []
            },
            {
              "name": "Convenience Stores",
              "subcategories": []
            },
            {
              "name": "Delicatessens",
              "subcategories": []
            },
            {
              "name": "Farmers' Markets",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Restaurants",
          "subcategories": [
            {
              "name": "Catering",
              "subcategories": []
            },
            {
              "name": "Fast Food",
              "subcategories": []
            },
            {
              "name": "Fine Dining",
              "subcategories": []
            },
            {
              "name": "Pizzerias",
              "subcategories": []
            },
            {
              "name": "Restaurant Reviews & Reservations",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Games",
      "subcategories": [
        {
          "name": "Arcade & Coin-Op Games",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Board Games",
          "subcategories": [
            {
              "name": "Chess & Abstract Strategy Games",
              "subcategories": []
            },
            {
              "name": "Miniatures & Wargaming",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Card Games",
          "subcategories": [
            {
              "name": "Collectible Card Games",
              "subcategories": []
            },
            {
              "name": "Poker & Casino Games",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Computer & Video Games",
          "subcategories": [
            {
              "name": "Action & Platform Games",
              "subcategories": []
            },
            {
              "name": "Adventure Games",
              "subcategories": []
            },
            {
              "name": "Browser Games",
              "subcategories": []
            },
            {
              "name": "Casual Games",
              "subcategories": []
            },
            {
              "name": "Competitive Video Gaming",
              "subcategories": []
            },
            {
              "name": "Driving & Racing Games",
              "subcategories": []
            },
            {
              "name": "Fighting Games",
              "subcategories": []
            },
            {
              "name": "Gaming Reference & Reviews",
              "subcategories": []
            },
            {
              "name": "Massively Multiplayer Games",
              "subcategories": []
            },
            {
              "name": "Music & Dance Games",
              "subcategories": []
            },
            {
              "name": "Sandbox Games",
              "subcategories": []
            },
            {
              "name": "Shooter Games",
              "subcategories": []
            },
            {
              "name": "Simulation Games",
              "subcategories": []
            },
            {
              "name": "Sports Games",
              "subcategories": []
            },
            {
              "name": "Strategy Games",
              "subcategories": []
            },
            {
              "name": "Video Game Development",
              "subcategories": []
            },
            {
              "name": "Video Game Emulation",
              "subcategories": []
            },
            {
              "name": "Video Game Mods & Add-Ons",
              "subcategories": []
            },
            {
              "name": "Video Game Retailers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Dice Games",
          "subcategories": []
        },
        {
          "name": "Educational Games",
          "subcategories": []
        },
        {
          "name": "Family-Oriented Games & Activities",
          "subcategories": [
            {
              "name": "Drawing & Coloring",
              "subcategories": []
            },
            {
              "name": "Dress-Up & Fashion Games",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Gambling",
          "subcategories": [
            {
              "name": "Lottery",
              "subcategories": []
            },
            {
              "name": "Sports Betting",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Party Games",
          "subcategories": []
        },
        {
          "name": "Puzzles & Brainteasers",
          "subcategories": []
        },
        {
          "name": "Roleplaying Games",
          "subcategories": []
        },
        {
          "name": "Table Games",
          "subcategories": [
            {
              "name": "Billiards",
              "subcategories": []
            },
            {
              "name": "Table Tennis",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Tile Games",
          "subcategories": []
        },
        {
          "name": "Word Games",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Health",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Aging & Geriatrics",
          "subcategories": [
            {
              "name": "Alzheimer's Disease",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Alternative & Natural Medicine",
          "subcategories": [
            {
              "name": "Acupuncture & Chinese Medicine",
              "subcategories": []
            },
            {
              "name": "Cleansing & Detoxification",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Health Conditions",
          "subcategories": [
            {
              "name": "AIDS & HIV",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Allergies",
              "subcategories": [
                {
                  "name": "Environmental Allergies",
                  "subcategories": []
                },
                {
                  "name": "Food Allergies",
                  "subcategories": []
                },
                {
                  "name": "Other",
                  "subcategories": []
                }
              ]
            },
            {
              "name": "Arthritis",
              "subcategories": []
            },
            {
              "name": "Blood Sugar & Diabetes",
              "subcategories": []
            },
            {
              "name": "Cancer",
              "subcategories": []
            },
            {
              "name": "Ear Nose & Throat",
              "subcategories": []
            },
            {
              "name": "Eating Disorders",
              "subcategories": []
            },
            {
              "name": "Endocrine Conditions",
              "subcategories": []
            },
            {
              "name": "Genetic Disorders",
              "subcategories": []
            },
            {
              "name": "GERD & Digestive Disorders",
              "subcategories": []
            },
            {
              "name": "Heart & Hypertension",
              "subcategories": []
            },
            {
              "name": "Infectious Diseases",
              "subcategories": [
                {
                  "name": "Covid-19",
                  "subcategories": []
                },
                {
                  "name": "Vaccines & Immunizations",
                  "subcategories": []
                },
                {
                  "name": "Other",
                  "subcategories": []
                }
              ]
            },
            {
              "name": "Injury",
              "subcategories": []
            },
            {
              "name": "Neurological Conditions",
              "subcategories": []
            },
            {
              "name": "Obesity",
              "subcategories": []
            },
            {
              "name": "Pain Management",
              "subcategories": []
            },
            {
              "name": "Respiratory Conditions",
              "subcategories": []
            },
            {
              "name": "Skin Conditions",
              "subcategories": []
            },
            {
              "name": "Sleep Disorders",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Health Education & Medical Training",
          "subcategories": []
        },
        {
          "name": "Health Foundations & Medical Research",
          "subcategories": []
        },
        {
          "name": "Medical Devices & Equipment",
          "subcategories": [
            {
              "name": "Assistive Technology",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Medical Facilities & Services",
          "subcategories": [
            {
              "name": "Doctors' Offices",
              "subcategories": []
            },
            {
              "name": "Hospitals & Treatment Centers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Medical Procedures",
              "subcategories": [
                {
                  "name": "Other",
                  "subcategories": []
                },
                {
                  "name": "Surgery",
                  "subcategories": [
                    {
                      "name": "Cosmetic Surgery",
                      "subcategories": []
                    }
                  ]
                }
              ]
            },
            {
              "name": "Physical Therapy",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Medical Literature & Resources",
          "subcategories": [
            {
              "name": "Medical Photos & Illustration",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Men's Health",
          "subcategories": []
        },
        {
          "name": "Mental Health",
          "subcategories": [
            {
              "name": "Anxiety & Stress",
              "subcategories": []
            },
            {
              "name": "Compulsive Gambling",
              "subcategories": []
            },
            {
              "name": "Counseling Services",
              "subcategories": []
            },
            {
              "name": "Depression",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Nursing",
          "subcategories": [
            {
              "name": "Assisted Living & Long Term Care",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Nutrition",
          "subcategories": [
            {
              "name": "Special & Restricted Diets",
              "subcategories": []
            },
            {
              "name": "Vitamins & Supplements",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Oral & Dental Care",
          "subcategories": []
        },
        {
          "name": "Pediatrics",
          "subcategories": []
        },
        {
          "name": "Pharmacy",
          "subcategories": [
            {
              "name": "Drugs & Medications",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Public Health",
          "subcategories": [
            {
              "name": "Health Policy",
              "subcategories": []
            },
            {
              "name": "Occupational Health & Safety",
              "subcategories": []
            },
            {
              "name": "Toxic Substances & Poisoning",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Reproductive Health",
          "subcategories": [
            {
              "name": "Birth Control",
              "subcategories": []
            },
            {
              "name": "Infertility",
              "subcategories": []
            },
            {
              "name": "Male Impotence",
              "subcategories": []
            },
            {
              "name": "OBGYN",
              "subcategories": []
            },
            {
              "name": "Sex Education & Counseling",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Substance Abuse",
          "subcategories": [
            {
              "name": "Drug & Alcohol Testing",
              "subcategories": []
            },
            {
              "name": "Drug & Alcohol Treatment",
              "subcategories": []
            },
            {
              "name": "Smoking & Smoking Cessation",
              "subcategories": []
            },
            {
              "name": "Steroids & Performance-Enhancing Drugs",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Vision Care",
          "subcategories": [
            {
              "name": "Eye Exams & Optometry",
              "subcategories": []
            },
            {
              "name": "Eyeglasses & Contacts",
              "subcategories": []
            },
            {
              "name": "Laser Vision Correction",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Women's Health",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Hobbies & Leisure",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Clubs & Organizations",
          "subcategories": [
            {
              "name": "Youth Organizations & Resources",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Crafts",
          "subcategories": [
            {
              "name": "Art & Craft Supplies",
              "subcategories": []
            },
            {
              "name": "Ceramics & Pottery",
              "subcategories": []
            },
            {
              "name": "Fiber & Textile Arts",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Merit Prizes & Contests",
          "subcategories": []
        },
        {
          "name": "Outdoors",
          "subcategories": [
            {
              "name": "Fishing",
              "subcategories": []
            },
            {
              "name": "Hiking & Camping",
              "subcategories": []
            },
            {
              "name": "Hunting & Shooting",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Paintball",
          "subcategories": []
        },
        {
          "name": "Radio Control & Modeling",
          "subcategories": [
            {
              "name": "Model Trains & Railroads",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Recreational Aviation",
          "subcategories": []
        },
        {
          "name": "Special Occasions",
          "subcategories": [
            {
              "name": "Anniversaries",
              "subcategories": []
            },
            {
              "name": "Holidays & Seasonal Events",
              "subcategories": []
            },
            {
              "name": "Weddings",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Sweepstakes & Promotional Giveaways",
          "subcategories": []
        },
        {
          "name": "Water Activities",
          "subcategories": [
            {
              "name": "Boating",
              "subcategories": []
            },
            {
              "name": "Diving & Underwater Activities",
              "subcategories": []
            },
            {
              "name": "Surf & Swim",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Home & Garden",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Bed & Bath",
          "subcategories": [
            {
              "name": "Bathroom",
              "subcategories": []
            },
            {
              "name": "Bedroom",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Domestic Services",
          "subcategories": [
            {
              "name": "Cleaning Services",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Home & Interior Decor",
          "subcategories": []
        },
        {
          "name": "Home Appliances",
          "subcategories": [
            {
              "name": "Vacuums & Floor Care",
              "subcategories": []
            },
            {
              "name": "Water Filters & Purifiers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Home Furnishings",
          "subcategories": [
            {
              "name": "Clocks",
              "subcategories": []
            },
            {
              "name": "Countertops",
              "subcategories": []
            },
            {
              "name": "Curtains & Window Treatments",
              "subcategories": []
            },
            {
              "name": "Kitchen & Dining Furniture",
              "subcategories": []
            },
            {
              "name": "Lamps & Lighting",
              "subcategories": []
            },
            {
              "name": "Living Room Furniture",
              "subcategories": []
            },
            {
              "name": "Outdoor Furniture",
              "subcategories": []
            },
            {
              "name": "Rugs & Carpets",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Home Improvement",
          "subcategories": [
            {
              "name": "Construction & Power Tools",
              "subcategories": []
            },
            {
              "name": "Doors & Windows",
              "subcategories": []
            },
            {
              "name": "Flooring",
              "subcategories": []
            },
            {
              "name": "House Painting & Finishing",
              "subcategories": []
            },
            {
              "name": "Locks & Locksmiths",
              "subcategories": []
            },
            {
              "name": "Plumbing",
              "subcategories": []
            },
            {
              "name": "Roofing",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Home Safety & Security",
          "subcategories": [
            {
              "name": "Home Alarm & Security Systems",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Home Storage & Shelving",
          "subcategories": [
            {
              "name": "Cabinetry",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Home Swimming Pools, Saunas & Spas",
          "subcategories": []
        },
        {
          "name": "Household Supplies",
          "subcategories": [
            {
              "name": "Household Batteries",
              "subcategories": []
            },
            {
              "name": "Household Cleaning Supplies",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "HVAC & Climate Control",
          "subcategories": [
            {
              "name": "Air Conditioners",
              "subcategories": []
            },
            {
              "name": "Air Filters & Purifiers",
              "subcategories": []
            },
            {
              "name": "Fireplaces & Stoves",
              "subcategories": []
            },
            {
              "name": "Heaters",
              "subcategories": []
            },
            {
              "name": "Household Fans",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Kitchen & Dining",
          "subcategories": [
            {
              "name": "Cookware & Diningware",
              "subcategories": []
            },
            {
              "name": "Major Kitchen Appliances",
              "subcategories": []
            },
            {
              "name": "Small Kitchen Appliances",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Laundry",
          "subcategories": [
            {
              "name": "Washers & Dryers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Patio, Lawn & Garden",
          "subcategories": [
            {
              "name": "Barbecues & Grills",
              "subcategories": []
            },
            {
              "name": "Garden Structures",
              "subcategories": []
            },
            {
              "name": "Gardening",
              "subcategories": []
            },
            {
              "name": "Landscape Design",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Yard Maintenance",
              "subcategories": [
                {
                  "name": "Lawn Mowers",
                  "subcategories": []
                },
                {
                  "name": "Other",
                  "subcategories": []
                }
              ]
            }
          ]
        },
        {
          "name": "Pest Control",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Internet & Telecom",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Communications Equipment",
          "subcategories": [
            {
              "name": "Radio Equipment",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Email & Messaging",
          "subcategories": [
            {
              "name": "Electronic Spam",
              "subcategories": []
            },
            {
              "name": "Email",
              "subcategories": []
            },
            {
              "name": "Text & Instant Messaging",
              "subcategories": []
            },
            {
              "name": "Voice & Video Chat",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Mobile & Wireless",
          "subcategories": [
            {
              "name": "Mobile & Wireless Accessories",
              "subcategories": []
            },
            {
              "name": "Mobile Apps & Add-Ons",
              "subcategories": []
            },
            {
              "name": "Mobile Phones",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Search Engines",
          "subcategories": [
            {
              "name": "People Search",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Service Providers",
          "subcategories": [
            {
              "name": "Cable & Satellite Providers",
              "subcategories": []
            },
            {
              "name": "ISPs",
              "subcategories": []
            },
            {
              "name": "Phone Service Providers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Teleconferencing",
          "subcategories": []
        },
        {
          "name": "Web Services",
          "subcategories": [
            {
              "name": "Affiliate Programs",
              "subcategories": []
            },
            {
              "name": "Cloud Storage",
              "subcategories": []
            },
            {
              "name": "Search Engine Optimization & Marketing",
              "subcategories": []
            },
            {
              "name": "Web Design & Development",
              "subcategories": []
            },
            {
              "name": "Web Stats & Analytics",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Jobs & Education",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Education",
          "subcategories": [
            {
              "name": "Academic Conferences & Publications",
              "subcategories": []
            },
            {
              "name": "Alumni & Reunions",
              "subcategories": []
            },
            {
              "name": "Colleges & Universities",
              "subcategories": []
            },
            {
              "name": "Computer Education",
              "subcategories": []
            },
            {
              "name": "Distance Learning",
              "subcategories": []
            },
            {
              "name": "Early Childhood Education",
              "subcategories": []
            },
            {
              "name": "Homeschooling",
              "subcategories": []
            },
            {
              "name": "Open Online Courses",
              "subcategories": []
            },
            {
              "name": "Primary & Secondary Schooling (K-12)",
              "subcategories": []
            },
            {
              "name": "Private Tutoring Services",
              "subcategories": []
            },
            {
              "name": "Special Education",
              "subcategories": []
            },
            {
              "name": "Standardized & Admissions Tests",
              "subcategories": []
            },
            {
              "name": "Study Abroad",
              "subcategories": []
            },
            {
              "name": "Teaching & Classroom Resources",
              "subcategories": []
            },
            {
              "name": "Training & Certification",
              "subcategories": []
            },
            {
              "name": "Vocational & Continuing Education",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Internships",
          "subcategories": []
        },
        {
          "name": "Jobs",
          "subcategories": [
            {
              "name": "Career Resources & Planning",
              "subcategories": []
            },
            {
              "name": "Job Listings",
              "subcategories": []
            },
            {
              "name": "Resumes & Portfolios",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Law & Government",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Government",
          "subcategories": [
            {
              "name": "Courts & Judiciary",
              "subcategories": []
            },
            {
              "name": "Embassies & Consulates",
              "subcategories": []
            },
            {
              "name": "Executive Branch",
              "subcategories": []
            },
            {
              "name": "Government Contracting & Procurement",
              "subcategories": []
            },
            {
              "name": "Intelligence & Counterterrorism",
              "subcategories": []
            },
            {
              "name": "Legislative Branch",
              "subcategories": []
            },
            {
              "name": "Lobbying",
              "subcategories": []
            },
            {
              "name": "Public Policy",
              "subcategories": []
            },
            {
              "name": "Royalty",
              "subcategories": []
            },
            {
              "name": "Visa & Immigration",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Legal",
          "subcategories": [
            {
              "name": "Accident & Personal Injury Law",
              "subcategories": []
            },
            {
              "name": "Bankruptcy",
              "subcategories": []
            },
            {
              "name": "Business & Corporate Law",
              "subcategories": []
            },
            {
              "name": "Constitutional Law & Civil Rights",
              "subcategories": []
            },
            {
              "name": "Family Law",
              "subcategories": []
            },
            {
              "name": "Intellectual Property",
              "subcategories": []
            },
            {
              "name": "Labor & Employment Law",
              "subcategories": []
            },
            {
              "name": "Legal Education",
              "subcategories": []
            },
            {
              "name": "Legal Services",
              "subcategories": []
            },
            {
              "name": "Product Liability",
              "subcategories": []
            },
            {
              "name": "Real Estate Law",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Military",
          "subcategories": [
            {
              "name": "Air Force",
              "subcategories": []
            },
            {
              "name": "Army",
              "subcategories": []
            },
            {
              "name": "Marines",
              "subcategories": []
            },
            {
              "name": "Navy",
              "subcategories": []
            },
            {
              "name": "Veterans",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Public Safety",
          "subcategories": [
            {
              "name": "Crime & Justice",
              "subcategories": []
            },
            {
              "name": "Emergency Services",
              "subcategories": []
            },
            {
              "name": "Law Enforcement",
              "subcategories": []
            },
            {
              "name": "Security Products & Services",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Social Services",
          "subcategories": [
            {
              "name": "Welfare & Unemployment",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "News",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Business News",
          "subcategories": [
            {
              "name": "Company News",
              "subcategories": []
            },
            {
              "name": "Economy News",
              "subcategories": []
            },
            {
              "name": "Financial Markets News",
              "subcategories": []
            },
            {
              "name": "Fiscal Policy News",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Gossip & Tabloid News",
          "subcategories": [
            {
              "name": "Scandals & Investigations",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Health News",
          "subcategories": []
        },
        {
          "name": "Local News",
          "subcategories": []
        },
        {
          "name": "Politics",
          "subcategories": [
            {
              "name": "Campaigns & Elections",
              "subcategories": []
            },
            {
              "name": "Media Critics & Watchdogs",
              "subcategories": []
            },
            {
              "name": "Political Polls & Surveys",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Sports News",
          "subcategories": []
        },
        {
          "name": "Technology News",
          "subcategories": []
        },
        {
          "name": "Weather",
          "subcategories": []
        },
        {
          "name": "World News",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Online Communities",
      "subcategories": [
        {
          "name": "Blogging Resources & Services",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Dating & Personals",
          "subcategories": [
            {
              "name": "Matrimonial Services",
              "subcategories": []
            },
            {
              "name": "Personals",
              "subcategories": []
            },
            {
              "name": "Photo Rating Sites",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "File Sharing & Hosting",
          "subcategories": []
        },
        {
          "name": "Online Goodies",
          "subcategories": [
            {
              "name": "Clip Art & Animated GIFs",
              "subcategories": []
            },
            {
              "name": "Skins, Themes & Wallpapers",
              "subcategories": []
            },
            {
              "name": "Social Network Apps & Add-Ons",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Online Journals & Personal Sites",
          "subcategories": []
        },
        {
          "name": "Photo & Video Sharing",
          "subcategories": [
            {
              "name": "Photo & Image Sharing",
              "subcategories": []
            },
            {
              "name": "Video Sharing",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Social Networks",
          "subcategories": []
        },
        {
          "name": "Virtual Worlds",
          "subcategories": []
        }
      ]
    },
    {
      "name": "People & Society",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Family & Relationships",
          "subcategories": [
            {
              "name": "Etiquette",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Family",
              "subcategories": [
                {
                  "name": "Other",
                  "subcategories": []
                },
                {
                  "name": "Parenting",
                  "subcategories": [
                    {
                      "name": "Babies & Toddlers",
                      "subcategories": [
                        {
                          "name": "Nursery & Playroom",
                          "subcategories": []
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "name": "Marriage",
              "subcategories": []
            },
            {
              "name": "Romance",
              "subcategories": []
            },
            {
              "name": "Troubled Relationships",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Kids & Teens",
          "subcategories": [
            {
              "name": "Children's Interests",
              "subcategories": []
            },
            {
              "name": "Teen Interests",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Religion & Belief",
          "subcategories": []
        },
        {
          "name": "Self-Help & Motivational",
          "subcategories": []
        },
        {
          "name": "Seniors & Retirement",
          "subcategories": []
        },
        {
          "name": "Social Issues & Advocacy",
          "subcategories": [
            {
              "name": "Charity & Philanthropy",
              "subcategories": []
            },
            {
              "name": "Discrimination & Identity Relations",
              "subcategories": []
            },
            {
              "name": "Drug Laws & Policy",
              "subcategories": []
            },
            {
              "name": "Ethics",
              "subcategories": []
            },
            {
              "name": "Green Living & Environmental Issues",
              "subcategories": []
            },
            {
              "name": "Housing & Development",
              "subcategories": []
            },
            {
              "name": "Human Rights & Liberties",
              "subcategories": []
            },
            {
              "name": "Poverty & Hunger",
              "subcategories": []
            },
            {
              "name": "Work & Labor Issues",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Social Sciences",
          "subcategories": [
            {
              "name": "Anthropology",
              "subcategories": []
            },
            {
              "name": "Archaeology",
              "subcategories": []
            },
            {
              "name": "Communications & Media Studies",
              "subcategories": []
            },
            {
              "name": "Demographics",
              "subcategories": []
            },
            {
              "name": "Economics",
              "subcategories": []
            },
            {
              "name": "Political Science",
              "subcategories": []
            },
            {
              "name": "Psychology",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Subcultures & Niche Interests",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Pets & Animals",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Animal Products & Services",
          "subcategories": [
            {
              "name": "Animal Welfare",
              "subcategories": []
            },
            {
              "name": "Pet Food & Pet Care Supplies",
              "subcategories": []
            },
            {
              "name": "Veterinarians",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Pets",
          "subcategories": [
            {
              "name": "Birds",
              "subcategories": []
            },
            {
              "name": "Cats",
              "subcategories": []
            },
            {
              "name": "Dogs",
              "subcategories": []
            },
            {
              "name": "Exotic Pets",
              "subcategories": []
            },
            {
              "name": "Fish & Aquaria",
              "subcategories": []
            },
            {
              "name": "Horses",
              "subcategories": []
            },
            {
              "name": "Rabbits & Rodents",
              "subcategories": []
            },
            {
              "name": "Reptiles & Amphibians",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Wildlife",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Real Estate",
      "subcategories": [
        {
          "name": "Property Development",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Real Estate Listings",
          "subcategories": [
            {
              "name": "Bank-Owned & Foreclosed Properties",
              "subcategories": []
            },
            {
              "name": "Commercial Properties",
              "subcategories": []
            },
            {
              "name": "Lots & Land",
              "subcategories": []
            },
            {
              "name": "Residential Rentals",
              "subcategories": []
            },
            {
              "name": "Residential Sales",
              "subcategories": []
            },
            {
              "name": "Timeshares & Vacation Properties",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Real Estate Services",
          "subcategories": [
            {
              "name": "Property Inspections & Appraisals",
              "subcategories": []
            },
            {
              "name": "Property Management",
              "subcategories": []
            },
            {
              "name": "Real Estate Agencies",
              "subcategories": []
            },
            {
              "name": "Real Estate Title & Escrow",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Reference",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Directories & Listings",
          "subcategories": [
            {
              "name": "Business & Personal Listings",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "General Reference",
          "subcategories": [
            {
              "name": "Biographies & Quotations",
              "subcategories": []
            },
            {
              "name": "Calculators & Reference Tools",
              "subcategories": []
            },
            {
              "name": "Dictionaries & Encyclopedias",
              "subcategories": []
            },
            {
              "name": "Educational Resources",
              "subcategories": []
            },
            {
              "name": "Forms Guides & Templates",
              "subcategories": []
            },
            {
              "name": "How-To, DIY & Expert Content",
              "subcategories": []
            },
            {
              "name": "Public Records",
              "subcategories": []
            },
            {
              "name": "Time & Calendars",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Geographic Reference",
          "subcategories": [
            {
              "name": "Maps",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Humanities",
          "subcategories": [
            {
              "name": "History",
              "subcategories": []
            },
            {
              "name": "Myth & Folklore",
              "subcategories": []
            },
            {
              "name": "Philosophy",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Language Resources",
          "subcategories": [
            {
              "name": "Foreign Language Resources",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Libraries & Museums",
          "subcategories": [
            {
              "name": "Libraries",
              "subcategories": []
            },
            {
              "name": "Museums",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Technical Reference",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Science",
      "subcategories": [
        {
          "name": "Astronomy",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Biological Sciences",
          "subcategories": [
            {
              "name": "Genetics",
              "subcategories": []
            },
            {
              "name": "Neuroscience",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Chemistry",
          "subcategories": []
        },
        {
          "name": "Computer Science",
          "subcategories": [
            {
              "name": "Machine Learning & Artificial Intelligence",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Earth Sciences",
          "subcategories": [
            {
              "name": "Atmospheric Science",
              "subcategories": []
            },
            {
              "name": "Geology",
              "subcategories": []
            },
            {
              "name": "Paleontology",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Ecology & Environment",
          "subcategories": [
            {
              "name": "Climate Change & Global Warming",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Engineering & Technology",
          "subcategories": [
            {
              "name": "Augmented & Virtual Reality",
              "subcategories": []
            },
            {
              "name": "Robotics",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Mathematics",
          "subcategories": [
            {
              "name": "Statistics",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Physics",
          "subcategories": []
        },
        {
          "name": "Scientific Equipment",
          "subcategories": []
        },
        {
          "name": "Scientific Institutions",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Sensitive Subjects",
      "subcategories": [
        {
          "name": "Accidents & Disasters",
          "subcategories": []
        },
        {
          "name": "Death & Tragedy",
          "subcategories": []
        },
        {
          "name": "Firearms & Weapons",
          "subcategories": []
        },
        {
          "name": "Recreational Drugs",
          "subcategories": []
        },
        {
          "name": "Self-Harm",
          "subcategories": []
        },
        {
          "name": "Violence & Abuse",
          "subcategories": []
        },
        {
          "name": "War & Conflict",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Shopping",
      "subcategories": [
        {
          "name": "Antiques & Collectibles",
          "subcategories": []
        },
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Apparel",
          "subcategories": [
            {
              "name": "Apparel Services",
              "subcategories": []
            },
            {
              "name": "Athletic Apparel",
              "subcategories": []
            },
            {
              "name": "Casual Apparel",
              "subcategories": []
            },
            {
              "name": "Children's Clothing",
              "subcategories": []
            },
            {
              "name": "Clothing Accessories",
              "subcategories": []
            },
            {
              "name": "Costumes",
              "subcategories": []
            },
            {
              "name": "Eyewear",
              "subcategories": []
            },
            {
              "name": "Footwear",
              "subcategories": []
            },
            {
              "name": "Formal Wear",
              "subcategories": []
            },
            {
              "name": "Headwear",
              "subcategories": []
            },
            {
              "name": "Men's Clothing",
              "subcategories": []
            },
            {
              "name": "Outerwear",
              "subcategories": []
            },
            {
              "name": "Pants & Shorts",
              "subcategories": []
            },
            {
              "name": "Shirts & Tops",
              "subcategories": []
            },
            {
              "name": "Sleepwear",
              "subcategories": []
            },
            {
              "name": "Suits & Business Attire",
              "subcategories": []
            },
            {
              "name": "Swimwear",
              "subcategories": []
            },
            {
              "name": "Undergarments",
              "subcategories": []
            },
            {
              "name": "Uniforms & Workwear",
              "subcategories": []
            },
            {
              "name": "Women's Clothing",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Auctions",
          "subcategories": []
        },
        {
          "name": "Classifieds",
          "subcategories": []
        },
        {
          "name": "Consumer Resources",
          "subcategories": [
            {
              "name": "Consumer Advocacy & Protection",
              "subcategories": []
            },
            {
              "name": "Coupons & Discount Offers",
              "subcategories": []
            },
            {
              "name": "Customer Services",
              "subcategories": []
            },
            {
              "name": "Identity Theft Protection",
              "subcategories": []
            },
            {
              "name": "Product Reviews & Price Comparisons",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Discount & Outlet Stores",
          "subcategories": []
        },
        {
          "name": "Entertainment Media",
          "subcategories": [
            {
              "name": "Entertainment Media Rentals",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Gifts & Special Event Items",
          "subcategories": [
            {
              "name": "Custom & Personalized Items",
              "subcategories": []
            },
            {
              "name": "Flowers",
              "subcategories": []
            },
            {
              "name": "Gifts",
              "subcategories": []
            },
            {
              "name": "Greeting Cards",
              "subcategories": []
            },
            {
              "name": "Party & Holiday Supplies",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Green & Eco-Friendly Shopping",
          "subcategories": []
        },
        {
          "name": "Luxury Goods",
          "subcategories": []
        },
        {
          "name": "Mass Merchants & Department Stores",
          "subcategories": []
        },
        {
          "name": "Photo & Video Services",
          "subcategories": [
            {
              "name": "Event & Studio Photography",
              "subcategories": []
            },
            {
              "name": "Photo Printing Services",
              "subcategories": []
            },
            {
              "name": "Stock Photography",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Shopping Portals",
          "subcategories": []
        },
        {
          "name": "Swap Meets & Outdoor Markets",
          "subcategories": []
        },
        {
          "name": "Tobacco & Vaping Products",
          "subcategories": []
        },
        {
          "name": "Toys",
          "subcategories": [
            {
              "name": "Action Figures",
              "subcategories": []
            },
            {
              "name": "Building Toys",
              "subcategories": []
            },
            {
              "name": "Die-cast & Toy Vehicles",
              "subcategories": []
            },
            {
              "name": "Dolls & Accessories",
              "subcategories": []
            },
            {
              "name": "Educational Toys",
              "subcategories": []
            },
            {
              "name": "Outdoor Toys & Play Equipment",
              "subcategories": []
            },
            {
              "name": "Puppets",
              "subcategories": []
            },
            {
              "name": "Ride-On Toys & Wagons",
              "subcategories": []
            },
            {
              "name": "Stuffed Toys",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Wholesalers & Liquidators",
          "subcategories": []
        }
      ]
    },
    {
      "name": "Sports",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Animal Sports",
          "subcategories": [
            {
              "name": "Equestrian",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "College Sports",
          "subcategories": []
        },
        {
          "name": "Combat Sports",
          "subcategories": [
            {
              "name": "Boxing",
              "subcategories": []
            },
            {
              "name": "Fencing",
              "subcategories": []
            },
            {
              "name": "Martial Arts",
              "subcategories": []
            },
            {
              "name": "Wrestling",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Extreme Sports",
          "subcategories": [
            {
              "name": "Climbing & Mountaineering",
              "subcategories": []
            },
            {
              "name": "Drag & Street Racing",
              "subcategories": []
            },
            {
              "name": "Stunts & Dangerous Feats",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Fantasy Sports",
          "subcategories": []
        },
        {
          "name": "Individual Sports",
          "subcategories": [
            {
              "name": "Bowling",
              "subcategories": []
            },
            {
              "name": "Cycling",
              "subcategories": []
            },
            {
              "name": "Golf",
              "subcategories": []
            },
            {
              "name": "Gymnastics",
              "subcategories": []
            },
            {
              "name": "Racquet Sports",
              "subcategories": []
            },
            {
              "name": "Running & Walking",
              "subcategories": []
            },
            {
              "name": "Skate Sports",
              "subcategories": []
            },
            {
              "name": "Track & Field",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "International Sports Competitions",
          "subcategories": [
            {
              "name": "Olympics",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Motor Sports",
          "subcategories": [
            {
              "name": "Auto Racing",
              "subcategories": []
            },
            {
              "name": "Motorcycle Racing",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Sport Scores & Statistics",
          "subcategories": []
        },
        {
          "name": "Sporting Goods",
          "subcategories": [
            {
              "name": "American Football Equipment",
              "subcategories": []
            },
            {
              "name": "Baseball Equipment",
              "subcategories": []
            },
            {
              "name": "Basketball Equipment",
              "subcategories": []
            },
            {
              "name": "Bowling Equipment",
              "subcategories": []
            },
            {
              "name": "Combat Sports Equipment",
              "subcategories": []
            },
            {
              "name": "Cricket Equipment",
              "subcategories": []
            },
            {
              "name": "Electric Skateboards & Scooters",
              "subcategories": []
            },
            {
              "name": "Equestrian Equipment & Tack",
              "subcategories": []
            },
            {
              "name": "Golf Equipment",
              "subcategories": []
            },
            {
              "name": "Gymnastics Equipment",
              "subcategories": []
            },
            {
              "name": "Hockey Equipment",
              "subcategories": []
            },
            {
              "name": "Ice Skating Equipment",
              "subcategories": []
            },
            {
              "name": "Roller Skating & Rollerblading Equipment",
              "subcategories": []
            },
            {
              "name": "Skateboarding Equipment",
              "subcategories": []
            },
            {
              "name": "Soccer Equipment",
              "subcategories": []
            },
            {
              "name": "Sports Memorabilia",
              "subcategories": []
            },
            {
              "name": "Squash & Racquetball Equipment",
              "subcategories": []
            },
            {
              "name": "Table Tennis Equipment",
              "subcategories": []
            },
            {
              "name": "Tennis Equipment",
              "subcategories": []
            },
            {
              "name": "Volleyball Equipment",
              "subcategories": []
            },
            {
              "name": "Water Sports Equipment",
              "subcategories": []
            },
            {
              "name": "Winter Sports Equipment",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Sports Coaching & Training",
          "subcategories": []
        },
        {
          "name": "Sports Fan Gear & Apparel",
          "subcategories": []
        },
        {
          "name": "Team Sports",
          "subcategories": [
            {
              "name": "American Football",
              "subcategories": []
            },
            {
              "name": "Australian Football",
              "subcategories": []
            },
            {
              "name": "Baseball",
              "subcategories": []
            },
            {
              "name": "Basketball",
              "subcategories": []
            },
            {
              "name": "Cheerleading",
              "subcategories": []
            },
            {
              "name": "Cricket",
              "subcategories": []
            },
            {
              "name": "Handball",
              "subcategories": []
            },
            {
              "name": "Hockey",
              "subcategories": []
            },
            {
              "name": "Rugby",
              "subcategories": []
            },
            {
              "name": "Soccer",
              "subcategories": []
            },
            {
              "name": "Volleyball",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Water Sports",
          "subcategories": [
            {
              "name": "Surfing",
              "subcategories": []
            },
            {
              "name": "Swimming",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Winter Sports",
          "subcategories": [
            {
              "name": "Ice Skating",
              "subcategories": []
            },
            {
              "name": "Skiing & Snowboarding",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        }
      ]
    },
    {
      "name": "Travel & Transportation",
      "subcategories": [
        {
          "name": "Other",
          "subcategories": []
        },
        {
          "name": "Hotels & Accommodations",
          "subcategories": [
            {
              "name": "Vacation Rentals & Short-Term Stays",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Luggage & Travel Accessories",
          "subcategories": [
            {
              "name": "Backpacks & Utility Bags",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Specialty Travel",
          "subcategories": [
            {
              "name": "Adventure Travel",
              "subcategories": []
            },
            {
              "name": "Agritourism",
              "subcategories": []
            },
            {
              "name": "Business Travel",
              "subcategories": []
            },
            {
              "name": "Ecotourism",
              "subcategories": []
            },
            {
              "name": "Family Travel",
              "subcategories": []
            },
            {
              "name": "Honeymoons & Romantic Getaways",
              "subcategories": []
            },
            {
              "name": "Low Cost & Last Minute Travel",
              "subcategories": []
            },
            {
              "name": "Luxury Travel",
              "subcategories": []
            },
            {
              "name": "Medical Tourism",
              "subcategories": []
            },
            {
              "name": "Religious Travel",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Tourist Destinations",
          "subcategories": [
            {
              "name": "Beaches & Islands",
              "subcategories": []
            },
            {
              "name": "Historical Sites & Buildings",
              "subcategories": []
            },
            {
              "name": "Mountain & Ski Resorts",
              "subcategories": []
            },
            {
              "name": "Regional Parks & Gardens",
              "subcategories": []
            },
            {
              "name": "Theme Parks",
              "subcategories": []
            },
            {
              "name": "Vineyards & Wine Tourism",
              "subcategories": []
            },
            {
              "name": "Zoos, Aquariums & Preserves",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Transportation",
          "subcategories": [
            {
              "name": "Air Travel",
              "subcategories": []
            },
            {
              "name": "Car Rentals",
              "subcategories": []
            },
            {
              "name": "Carpooling",
              "subcategories": []
            },
            {
              "name": "Chartered Transportation Rentals",
              "subcategories": []
            },
            {
              "name": "Cruises & Charters",
              "subcategories": []
            },
            {
              "name": "Long Distance Bus & Rail",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            },
            {
              "name": "Parking",
              "subcategories": [
                {
                  "name": "Airport Parking & Transportation",
                  "subcategories": []
                },
                {
                  "name": "Other",
                  "subcategories": []
                }
              ]
            },
            {
              "name": "Scooter & Bike Rentals",
              "subcategories": []
            },
            {
              "name": "Taxi & Ride Hail Services",
              "subcategories": []
            },
            {
              "name": "Traffic & Route Planners",
              "subcategories": []
            },
            {
              "name": "Urban Transit",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Travel Agencies & Services",
          "subcategories": [
            {
              "name": "Guided Tours & Escorted Vacations",
              "subcategories": []
            },
            {
              "name": "Sightseeing Tours",
              "subcategories": []
            },
            {
              "name": "Vacation Offers",
              "subcategories": []
            },
            {
              "name": "Other",
              "subcategories": []
            }
          ]
        },
        {
          "name": "Travel Guides & Travelogues",
          "subcategories": []
        }
      ]
    }
  ];
