import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Tab, Box, CardMedia, Button, Icon, Grid, CardActionArea} from '@mui/material';
import LoginScreen from '../../../LoginScreen';
// sign in icon from mui
import AddReactionIcon from '@mui/icons-material/AddReaction';
import SearchIcon from '@mui/icons-material/Search';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../../firebaseConfig';
import { updateDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import LoadingComponent from '../../../LoadingComponent';
import { Card, CardContent, Switch, Typography, IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import InfoIcon from '@mui/icons-material/Info';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmblaCarousel from './EmblaCarousel';
import './embla.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ManageTabsTab = ({
  tabs,
  setTabs,
  setSelectedTab,
  selectedTab,
  dialog,
  setDialog,
  handleCloseDialog,
  setShowDotOnManageTab
}) => {

  const user = FIREBASE_AUTH.currentUser;
  const theme = useTheme();
  const [thoughtShares, setThoughtShares] = useState(0);
  const [thoughtSaves, setThoughtSaves] = useState(0);
  const [streak, setStreak] = useState(0);
  const [unlockedTabs, setUnlockedTabs] = useState([]);
  const [lockedTabs, setLockedTabs] = useState([]);


  // Update unlockedTabs and lockedTabs when tabs array changes
  useEffect(() => {
    setUnlockedTabs(tabs.filter(tab => tab.unlocked).filter(tab => tab.id !== 'manageTabs'));
    setLockedTabs(tabs.filter(tab => !tab.unlocked).filter(tab => tab.id !== 'manageTabs'));
  }, [tabs]);

  // State for image preview modal
  const [previewImage, setPreviewImage] = useState(null);
  // Function to open image preview modal
  const openPreview = (imageUrl) => {
    setPreviewImage(imageUrl);
  };
  // Function to close image preview modal
  const closePreview = () => {
    setPreviewImage(null);
  };

  const toggleDescription = async (tabId) => {
    const newTabs = tabs.map((tab) => {
      if (tab.id === tabId) {
        return {
          ...tab,
          collapseDescription: !tab.collapseDescription,
        };
      }
      return tab;
    });
    setTabs(newTabs);

    // Save to Firestore
    if (user && user.uid) {
      await updateDoc(doc(FIRESTORE_DB, 'Users', user.uid), { allTabs: newTabs });
    }
  };

  useEffect(() => {
    // this entire use effect disables the login tab when a user becomes logged in
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, async (user) => {
      if (user) {
        const userDocRef = doc(FIRESTORE_DB, 'Users', user.uid);

        // Fetch the current state from Firestore
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {

          let userTabs = docSnap.data().allTabs;

          // Check if the login screen is already disabled
          if (userTabs && userTabs.length > 0 && userTabs[0].enabled) {
            // Modify the necessary parts of the array
            userTabs = userTabs.map((tab, idx) => ({
              ...tab,
              enabled: idx === 0 ? false : tab.enabled,
            }));

            // Update Firestore with the modified array
            await updateDoc(userDocRef, { allTabs: userTabs });
          }
        } else {
          console.log("No such document in Firestore!");
          const tabsDisableLogin = tabs.map((tab, idx) => ({
            ...tab,
            enabled: idx === 0 ? false : tab.enabled,
          }));
          await updateDoc(userDocRef, { allTabs: tabsDisableLogin });
        }
      }
    });

    return () => unsubscribe();
  }, []);


  let userDocRef;
  useEffect(() => {


    if (!user || !user.uid) {
      // Handle the case when user is not logged in
      // For example, show a login prompt or disable certain features
      console.log("User is not logged in");
      setShowDotOnManageTab(false);
      return; // Exit the useEffect if user is not logged in
    }

    userDocRef = doc(FIRESTORE_DB, 'Users', user.uid);

    // Firestore listener and other logic for authenticated users
    const unsubscribe = onSnapshot(doc(FIRESTORE_DB, 'Users', user.uid), (doc) => {
      const userData = doc.data();
      // check if the ids are all the same please
      if (userData && userData.allTabs && userData.allTabs[0].toggleSave == tabs[0].toggleSave) {
        let userTabs = userData.allTabs;
        const thoughtSaves = userData.thoughtSaves ? userData.thoughtSaves : 0;
        const thoughtShares = userData.thoughtShares ? userData.thoughtShares : 0;
        setThoughtSaves(thoughtSaves);
        setThoughtShares(thoughtShares);
        setStreak(userData.streak);
        setTabs(userTabs);

        for (let i = 0; i < userTabs.length; i++) {
          const tab = userTabs[i];

          if (!tab.unlocked) {
            console.log(tabs);
            // check if it is time to unlock it

            switch (tab.id) {
              case 'zenShareSave':
                // USER MUST BE LOGGED IN TO UNLOCK THIS TAB
                if (!userTabs[i].unlocked) {
                  userTabs.find(tab => tab.id === 'signIn').enabled = false; // DISBALE SIGN IN
                  // ENABLE ALL THOUGHTS AND MY THOUGHTS
                  userTabs.find(tab => tab.id === 'allThoughts').enabled = true;
                  userTabs.find(tab => tab.id === 'myThoughts').enabled = true;
                  // ENABLE ALL THOUGHTS AND MY THOUGHTS

                  userTabs[i].unlocked = true;
                  userTabs[i].enabled = true;
                  updateDoc(userDocRef, { allTabs: userTabs });
                  setDialog({
                    open: true,
                    message: "Unlock the next, way cooler tab, by sharing 1 thought and saving 1 thought.",
                    title: "'Zen Share & Save' unlocked",
                    actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
                  });
                  setShowDotOnManageTab(true);
                }
                break;
              // case 'minimalistThoughtShare':
              //   // USER MUST BE LOGGED IN TO UNLOCK THIS TAB
              //   if (!userTabs[i].unlocked) {
              //     userTabs[i].unlocked = true;
              //     userTabs[i].enabled = true;
              //     updateDoc(userDocRef, { allTabs: userTabs });
              //       setDialog({
              //         open: true,
              //         message: "Thank you for signing in! You have now unlocked the Thought Share tab. This is the simplest and fastest way to share your thoughts. Others who think like you will be able to find them. Share 1 thought to unlock another tab!",
              //         title: "New tab unlocked!",
              //         actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
              //       });
              //   }
              //   break;
              // case 'minimalistThoughtSave':
              //   // USER MUST HAVE SHARED A THOUGHT TO UNLOCK THIS TAB
              //   if (!userTabs[i].unlocked && thoughtShares > 0) {
              //     userTabs[i].unlocked = true;
              //     userTabs[i].enabled = true;
              //     updateDoc(userDocRef, { allTabs: userTabs });
              //       setDialog({
              //         open: true,
              //         message: "Thank you for sharing a thought! You have now unlocked the Thought Save tab. This is the simplest and fastest way to save a thought. Thoughts that you save are completely private to you. You can manage your thoughts in a later tab. Try saving 1 thought to unlock another tab!",
              //         title: "New tab unlocked!",
              //         actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
              //       });
              //   }
              //   // SAVE TO FIREBASE
              //   break;
              case 'myThoughts':
                if (!userTabs[i].unlocked && thoughtSaves > 0 && thoughtShares > 0) {
                  userTabs[i].unlocked = true;
                  userTabs[i].enabled = true;
                  updateDoc(userDocRef, { allTabs: userTabs });
                  setDialog({
                    open: true,
                    message: "This is the first ever fully searchable notes app (better than just keyword search). Share 5 thoughts to unlock the 'ALL THOUGHTS' tab, where you can search EVERYONE's THOUGHTS!",
                    title: "'My Thoughts' tab unlocked!",
                    actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
                  });
                  setShowDotOnManageTab(true);
                }

                break;
              case 'allThoughts':
                if (!userTabs[i].unlocked && thoughtShares > 4) {
                  userTabs[i].unlocked = true;
                  userTabs[i].enabled = true;
                  updateDoc(userDocRef, { allTabs: userTabs });
                  setDialog({
                    open: true,
                    message: "Thank you for sharing 5 thoughts! You have now unlocked the All Thoughts tab. This is where you can see what everyone else is thinking. You can also search for thoughts by similarity (keywords don't matter, meaning does!). Try searching for 'love' or 'hate' to see what people are thinking about those topics! Or search #2 to find thoughts that are most similar to your second thought, for example.",
                    title: "New tab unlocked!",
                    actions: [{ label: "Great!", onClick: () => { handleCloseDialog(); } }]
                  });
                  setShowDotOnManageTab(true);
                }
                break;
              case 'messengerTab':
                if (!userTabs[i].unlocked) {
                  userTabs[i].unlocked = true;
                  updateDoc(userDocRef, { allTabs: userTabs });
                  setShowDotOnManageTab(true);
                }
              case 'settingsTab':
                if (!userTabs[i].unlocked) {
                  userTabs[i].unlocked = true;
                  updateDoc(userDocRef, { allTabs: userTabs });
                  setShowDotOnManageTab(true);
                }
                break;
              default:
                break;

            }
          }
        }
      } else {
        // Handle cases where userData or userData.allTabs is undefined
        console.log("TABS", tabs);
        console.log("USER TABS", userDocRef);
        if (userDocRef) {
          updateDoc(userDocRef,
            {
              allTabs: tabs
            }
          );
        }
      }
    }, error => {
      console.error("Error fetching user data:", error);
    });

    return () => unsubscribe();
  }, [user?.uid]);

  const toggleTab = (tabId) => {
    const tabToToggle = tabs.find(tab => tab.id === tabId);



    if (tabToToggle.id === 'manageTabs') return;
    const tabWasDisabled = !tabToToggle.enabled;
    const newTabs = tabs.map((tab) => {
      if (tab.id === tabId) {
        return {
          ...tab,
          enabled: !tab.enabled,
        };
      }
      return tab;
    });
    setTabs(newTabs);
    // save to FIRESTORE
    if (user && user.uid) {
      updateDoc(doc(FIRESTORE_DB, 'Users', user.uid), { allTabs: newTabs });
    }

    if (tabWasDisabled) {
      setSelectedTab(selectedTab + 1);
    } else {
      setSelectedTab(selectedTab - 1);
    }


  };

  // There will show and Avatar on the left. To the right will be the name, level, progress bars.
  // It will say 'Level 1' for example
  // Below that there will be a level shown as two linear progress bars, one for shares, one for saves
  // To the right of each progress bar will be 55/100 for example. If it is greater than 100, it will turn green. And show an icon of success
  // Below all of this will be a row of: icon, text, icon, text, icon, text, icon. Each text will actually be a stack of Number (bold), then word undernweath, for example 3;; Day streak
  const levels = [
    {
      level: 0,
      levelName: 'Beginner',
      levelDescription: 'You are just starting out!',
      shares: 0,
      saves: 0,
    },
    {
      level: 1,
      levelName: 'Novice',
      levelDescription: 'You are a novice!',
      shares: 1,
      saves: 1,
    },
    {
      level: 2,
      levelName: 'Intermediate',
      levelDescription: 'You are an intermediate!',
      shares: 5,
      saves: 5,
    },
    {
      level: 3,
      levelName: 'Advanced',
      levelDescription: 'You are advanced!',
      shares: 20,
      saves: 20,
    },
    {
      level: 4,
      levelName: 'Expert',
      levelDescription: 'You are an expert!',
      shares: 50,
      saves: 50,
    },
    {
      level: 5,
      levelName: 'Master',
      levelDescription: 'You are a master!',
      shares: 100,
      saves: 100,
    },
    {
      level: 6,
      levelName: 'Grandmaster',
      levelDescription: 'You are a grandmaster!',
      shares: 200,
      saves: 200,
    },
    {
      level: 7,
      levelName: 'Legend',
      levelDescription: 'You are a legend!',
      shares: 500,
      saves: 500,

    },
    {
      level: 8,
      levelName: 'Myth',
      levelDescription: 'You are a myth!',
      shares: 1000,
      saves: 1000,

    }

  ];



  const UserProgress = ({ user, shares, saves }) => {
    const currentLevel = levels.slice().reverse().find(level => shares >= level.shares && saves >= level.saves) || levels[0];
    const nextLevel = levels[currentLevel.level + 1] || currentLevel;

    const shareProgress = Math.min(100, (shares / nextLevel.shares) * 100);
    const saveProgress = Math.min(100, (saves / nextLevel.saves) * 100);
    const isShareComplete = shares >= nextLevel.shares;
    const isSaveComplete = saves >= nextLevel.saves;
    const isLevelUp = isShareComplete && isSaveComplete;

    return (
      <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px', padding: '20px' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '16px' }}>
          <Avatar src={user?.photoURL} style={{ width: '100px', height: '100px', marginRight: '16px' }} />
          <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>{user?.displayName}</Typography>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px', fontWeight: 'bold' }}>
              Level {currentLevel.level}: {currentLevel.levelName}
            </Typography>

            {renderProgressBar('Shares', shares, nextLevel.shares, currentLevel.level + 1, isShareComplete)}
            {renderProgressBar('Saves', saves, nextLevel.saves, currentLevel.level + 1, isSaveComplete)}
            {isLevelUp && <Typography style={{ marginTop: '8px' }} color="primary">Level Up!</Typography>}
          </Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '16px' }}>
          <IconAndText icon={process.env.PUBLIC_URL + '/globe.png'} number={shares} text="Shares" />
          <IconAndText icon={process.env.PUBLIC_URL + '/brain.png'} number={saves} text="Saves" />
          <IconAndText icon={process.env.PUBLIC_URL + '/star.png'} number={streak + (streak == 1 ? ' day' : ' days')} text="Streak" />
        </Box>
      </Box>
    );
  };

  const IconAndText = ({ icon, number, text }) => (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '16px' }}>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img src={icon} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '8px' }}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>{number}</Typography>
          <Typography variant="body2" color="textSecondary">{text}</Typography>
        </Box>
      </Box>
    </Box>
  );



  const renderProgressBar = (title, value, maxValue, nextLevelNumber, isComplete) => (
    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '8px', position: 'relative' }}>
      <Typography variant="body2" color="textSecondary" sx={{ minWidth: 45 }} >
        {title}
      </Typography>

      <LinearProgress
        variant="determinate"
        value={Math.min((value / maxValue) * 100, 100)}
        color={isComplete ? 'success' : 'primary'}
        style={{ width: '100%', height: '10px', borderRadius: '5px', marginLeft: '8px', }}
      />

      <Box>
        <Typography sx={{ position: 'relative', top: -15, left: -3 }} variant="body2">
          {nextLevelNumber}
        </Typography>



      </Box>
      <Box
        sx={{
          minWidth: 55,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isComplete ? (
          <CheckIcon sx={{ marginLeft: 'auto', marginRight: '8px' }} />
        ) : (
          <Typography variant="body2" color="textSecondary" style={{ marginLeft: '8px', marginRight: '8px' }}>
            {value}/{maxValue}
          </Typography>
        )}
      </Box>

    </Box>
  );

  const boxStyle = {
    background: 'black',
    borderTop: 'none',
    borderLeft: 'none',
    borderColor: theme.palette.divider,
    borderRadius: '6px',
    paddingRight: '6px',
    paddingBottom: '6px',
    paddingTop: '1px',
    paddingLeft: '1px',
    boxShadow: 'none', // no shadow
    position: 'relative',
    overflow: 'hidden', // to maintain the border-radius effect

  };

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid', // thicker border on the right
    borderBottom: '1px solid', // thicker border on the bottom
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRadius: '6px',
    borderColor: theme.palette.divider,
    boxShadow: 'none', // no shadow
    height: 0,
    paddingTop: '100%', // top padding is 100% of the width to maintain a square shape
    position: 'relative',

  };




return (
<div className="div-scroll"
                style={{
                    height: 'calc(100vh - 110px)',
                    overflowY: 'scroll', // enabling vertical scrolling
                    overflowX: 'hidden', // disabling horizontal scrolling
                    scrollbarWidth: 'none', // for Firefox
                    scrollBehavior: 'smooth',
                    msOverflowStyle: 'none', // for IE/Edge
                    WebkitOverflowScrolling: 'touch', // for touch scrolling in mobile webkit
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                    minWidth: '100%',
                    // hide scrollbar for Chrome, Safari, and Opera
                    '::-webkit-scrollbar': {
                        display: 'none'
                    }
                }}>
  <style>
</style>

    <UserProgress user={user}
      shares={thoughtShares}
      saves={thoughtSaves}
      nextMilestoneDescription="Share 5 thoughts to unlock the 'ALL THOUGHTS' tab!"
    />

    <Typography variant="h6" style={{ fontWeight: 'bold', marginTop: '16px', paddingLeft: '20px', paddingRight: '20px' }}>
      Tabs
    </Typography>

    <Grid container spacing={1} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          {tabs.filter((tab) => tab.id !== 'manageTabs').map((tab, index) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
              <Box style={boxStyle} onClick={() => {
                toggleTab(tab.id);
              }}>
                <Card style={{
                  ...cardStyle,
                  backgroundColor: tabs[index].enabled ? theme.palette.success.main : ''
                }}>
                  <CardActionArea style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
                    <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                      {tab.title}
                    </Typography>
                    {!tab.unlocked &&
                      <>
                      <LockIcon sx={{ height: '40px'}}/>
                      <Typography>
                        (Tap to learn!)
                      </Typography>
                      <Typography sx={{textAlign: 'center'}}>
                        Unlock by
                        {tab.sharesRequired && tab.sharesRequired > thoughtShares && (
                          <>
                            {' sharing ' + (tab.sharesRequired - thoughtShares) + ' more thought' + (tab.sharesRequired - thoughtShares === 1 ? '' : 's')}
                          </>
                        )}
                        {(tab.savesRequired !== undefined && tab.savesRequired !== null && tab.savesRequired > thoughtSaves && tab.sharesRequired && tab.sharesRequired > thoughtShares) && ' and '}
                        {(tab.savesRequired !== undefined && tab.savesRequired !== null && tab.savesRequired > thoughtSaves) && (
                          <>
                            {'saving ' + (tab.savesRequired - thoughtSaves) + ' more thought' + (tab.savesRequired - thoughtSaves === 1 ? '' : 's') + '!'}
                          </>
                        )}
                        {!(tab.savesRequired !== undefined && tab.savesRequired !== null && tab.savesRequired > thoughtSaves) && '!'}
                      </Typography>


                      </>
                    }
                    </Box>
                    
                  </CardActionArea>
                </Card>
              </Box> 
            </Grid>
          ))}
        </Grid>
  </div>
);
}

// export all
export { ManageTabsTab }