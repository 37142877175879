import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CoinPopup from "./CoinPopup";
import addCoinsToUser from "./AddCoinsToUser";
import { increment, serverTimestamp, doc, setDoc, getDoc, updateDoc, addDoc, collection } from "firebase/firestore";
import checkString from "./ContentModeration";
import { FIREBASE_AUTH, FIRESTORE_DB } from "./firebaseConfig";
import LoadingComponent from "./LoadingComponent";
import { fetchAndSaveAllResults } from "./ExploreThoughts";
import { setItem } from "./storage";
import { Box, Typography, TextField, IconButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

const ShareThought = () => {
    const navigate = useNavigate();
    const [speech, setSpeech] = useState('');
    const [loading, setLoading] = useState(false);
    const statsRef = doc(FIRESTORE_DB, 'Users', FIREBASE_AUTH.currentUser.uid, 'Stats', 'writes');
    const user = FIREBASE_AUTH.currentUser;
    const [text, setText] = useState("");
    const [readyToNavigate, setReadyToNavigate] = useState(false);
    const [coins, setCoins] = useState(74);
    const [coinsPopupOpen, setCoinsPopupOpen] = useState(false);
    const latitudeRef = useRef(null);
    const longitudeRef = useRef(null);

    const handleCoinsOpen = () => {
        setCoinsPopupOpen(true);
    }
    const handleCoinsClose = () => {
        setCoinsPopupOpen(false);
    }

    

    const handleInputChange = (event) => {
        const input = event.target.value;
        const wordCount = input.split(" ").length;
        if (input.length <= 1800 && wordCount <= 230) {
            setText(input);
        }
    };

    useEffect(() => {
        if (readyToNavigate && !coinsPopupOpen) {
            navigate('/explorethoughtsresults');
        }
    }, [readyToNavigate, coinsPopupOpen]);

    const handleLocationCheckboxChange = (e) => {
        if (e.target.checked) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const lat = position.coords.latitude;
                        const lon = position.coords.longitude;
                        latitudeRef.current = lat; // Update ref
                        longitudeRef.current = lon; // Update ref
                    },
                    (error) => {
                        console.error("Error obtaining location:", error);
                    }
                );
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        } else {
            // Reset the latitude and longitude if the checkbox is unchecked
            latitudeRef.current = null; // Update ref
            longitudeRef.current = null; // Update ref
        }
    };
    


    const handleTickClick = async () => {
        setLoading(true);
        if (!user) {
            navigate("/login");
            setLoading(false);
            return;
        }

        // check for something in the box, otherwise show error
        if (text === "") {
            setSpeech("What thought? There's nothing there! Write something please...");
            setLoading(false);
            return;
        }
        if (text === "Type a thought here, any thought!") {
            setSpeech("Please type your own thought, in the bubble above. It could be anything: an idea for a song or artwork, a feeling, even a dream that you had, where you wonder if anyone else has thought of something similar!");
            setLoading(false);
            return;
        }

        // check for content moderation okay, otherwise show error

        const isAccepted = await checkString(text);
        if (!isAccepted) {
            setSpeech("Hmmm, try something more appropriate, and please follow our Terms and Conditions. We endeavour to be a safe space for everybody");
            setLoading(false);
            return;
        }

        // check that if the checkbox is ticked, then lat and long are available, otherwise wait...

        const checkbox = document.getElementById('locationCheckbox')
        console.log("CHECKBOX TICKED?: ", checkbox.checked);
        const asyncLocationCheck = async () => {
            return new Promise((resolve, reject) => {
                const startTime = Date.now();
                if (checkbox.checked === false) {
                    latitudeRef.current = null;
                    longitudeRef.current = null;
                    reject('Checkbox was unchecked'); // Reject the Promise with a specific message
                } else if (Date.now() - startTime < 15000) {
        
                    // Define a function that will check for the latitude and longitude
                    const checkLocation = () => {
                        console.log("Entered checkLocation");
                        if (latitudeRef.current && longitudeRef.current) {
                            // Latitude and longitude are set, resolve the Promise
                            console.log("HWELL YEAH");
                            resolve();
                        } else if (Date.now() - startTime < 15000) { // Continue checking for up to 15 seconds
                            setTimeout(checkLocation, 25); // Check again in 25ms
                        } else {
                            // 15 seconds has passed without getting the location
                            setLoading(false);
                            checkbox.checked = false;
                            reject(); // Reject the Promise
                        }
                    };
                    
                    // Continue checking for up to 15 seconds
                    setTimeout(checkLocation, 25); // Check again in 25ms
                } else {
                    // 15 seconds has passed without getting the location
                    setLoading(false);
                    checkbox.checked = false;
                    reject('Location timeout'); // Reject the Promise with a specific message
                }
        
            });
        };

        try {
            await asyncLocationCheck();
            // Proceed with your code, since latitude and longitude are available
            console.log("directly after waiting for asyncLocationCheck()");
        } catch (error) {
            if (error === 'Location timeout') {
                console.log('Location was not obtained within 15 seconds.');
                setLoading(false);
                return;
            } else {
                console.log('The checkbox was unchecked.');
            }
        }
        
        console.log("after waiting for asyncLocationCheck()");
        // check for writes less than 50 today
        const statsSnapshot = await getDoc(statsRef);
        if (statsSnapshot.exists()) {
            const statsData = statsSnapshot.data();

            if (statsData.last_write_date.toDate().toDateString() !== new Date().toDateString()) {
                await setDoc(statsRef, {
                    quotes_writes: 0,
                    last_write_date: serverTimestamp()
                });
            } else if (statsData.quotes_writes >= 50) {
                // If it's the same day and the user has already written 50 quotes
                setSpeech("It seems like you've written enough quotes for today. If this is a mistake, we aim for 50 thoughts per day for unpaid users. If you believe that you have not exceeded this limit, take it up with the guy who wrote it, elijahdecalmer@gmail.com")
                setLoading(false);
                return;
            } else {
                // increment counter
                await updateDoc(statsRef, {
                    quotes_writes: increment(1),
                    last_write_date: serverTimestamp()
                })
            }
        } else {
            // document of statsRef does not exist, create it
            await setDoc(statsRef, {
                quotes_writes: 1,
                last_write_date: serverTimestamp()
            })
        }

        // handleCoinsOpen();
        handleCoinsOpen();

        // choose two random colours

        const colorA = await getRandomColor();
        const colorB = await getRandomColor();

        // set to firebase as necessary
        const thought = {
            createdAt: serverTimestamp(),
            quote: text,
            color1: colorA,
            color2: colorB,
            userID: user.uid,
            displayName: user.displayName,
            profilePicURL: user.photoURL
        };

        addDoc(collection(FIRESTORE_DB, 'Quotes'), thought)
            .then(async (documentReference) => {
                console.log('DocumentSnapshot written with ID: ', documentReference.id);
                const seedID = documentReference.id;

                updateDoc(doc(FIRESTORE_DB, "Users", user.uid), {
                    lastResultsID: seedID
                });


                const profileThoughtEntry = {
                    createdAt: serverTimestamp(),
                    quote: text,
                    color1: colorA,
                    color2: colorB,
                    isArchived: true,
                };
                setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', seedID), profileThoughtEntry)

                // Send data to the Flask server
                fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/share_thought', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        quote: text,
                        seedID: seedID,
                        userID: user.uid,
                        lat: latitudeRef.current,
                        lon: longitudeRef.current,
                        createdAtMs: Date.now(),
                    }),
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        console.log('Data published:', data);
                        console.log(seedID)

                        const allResults = await fetchAndSaveAllResults(data);
                        // STORE IN LOCAL STORAGE
                        setItem('exploreThoughtsResults', JSON.stringify(allResults));
                        setItem('exploreThoughtsSeedID', seedID);
                        // SET AddCoinsToUser(coins) REWARD
                        addCoinsToUser(coins);
                        setReadyToNavigate(true);
                    })
                    .catch((error) => {
                        console.error('Error publishing data:', error);
                        setSpeech('Quote not sent (URL ERROR). Please check your internet connection and try again.', { variant: 'error' });
                        setLoading(false);
                        return;
                    });

            })
            .catch((error) => {
                setSpeech('Quote not sent (FIREBASE ERROR). Please check your internet connection and try again. Please note that there is a 50 search limit daily.');
            });

    }

    return (
        <Box
            className="big-box"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="calc(100vh-70px)"
            gap={2}
        >
            <Box className="text-button-box" display="flex" alignItems="center" gap={2}>
                <TextField
                className="text-box"
                    placeholder="Your thought here..."
                    value={text}
                    onChange={handleInputChange}
                    multiline
                    maxRows={4}
                    sx={{
                        minWidth: 280,
                        fontSize: '20px',
                        boxShadow: '2px 2px 3px 1px rgba(0,0,0,0.15)',
                        borderRadius: 2, // set this as per your requirement
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent', // removes the border
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent', // removes the border on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // removes the border when the input is focused
                            },
                        },
                    }}
                />

                <Box maxWidth="50px" maxHeight="50px" display="flex" alignItems="center">
                    <IconButton
                    className="submit-button"
                        onClick={handleTickClick}
                        color="white"

                        sx={{
                            boxShadow: '2px 2px 3px 1px rgba(0,0,0,0.15)',
                            borderRadius: '5px',
                            backgroundColor: '#5CDB95', // PaleGreen color
                            '&:hover': {
                                backgroundColor: '#90EE90', // LightGreen color
                            },
                        }}
                    >
                        <CheckIcon fontSize="large" color="inherit" />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <Typography>
                    Enable location:
                </Typography>

                    <label 
                className="location-button">
                        
                        <input type="checkbox" id="locationCheckbox" onChange={handleLocationCheckboxChange} />
                    </label>
                </Box>
            {speech &&
                <Box maxWidth="80%">
                    <Typography sx={{ color: 'red' }}>
                        ERROR:
                    </Typography>
                    <Typography>
                        {speech}
                    </Typography>
                </Box>

            }
            {loading &&
                <LoadingComponent />
            }
            <CoinPopup
                onClose={handleCoinsClose}
                isOpen={coinsPopupOpen}
                text="Thank you so much for sharing your thoughts, here's some tokens to show our appreciation!"
                title="Coins earned"
                numberOfCoins={coins}
            />
            <Box sx={{height: '10px'}}>
            </Box>
        </Box>
    );
}


export default ShareThought;

export const getRandomColor = async () => {
    const randomIndex = Math.floor(Math.random() * PastelColors.length);
    return PastelColors[randomIndex];
}

const PastelColors = [
    '#FFD1DC', '#AEC6CF', '#B5EAD7', '#FDFD96', '#FF6961',
    '#77DD77', '#779ECB', '#F49AC2',
    '#FFB347', '#B19CD9', '#FF6961', '#03C03C', '#FDFD96', '#779ECB', '#966FD6', '#F49AC2', '#CB99C9',
    '#DEA5A4', '#AEC6CF', '#CFCFC4', '#B39EB5', '#FF6961',
    '#779ECB', '#FDFD96', '#F49AC2'
  ];

