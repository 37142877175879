import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { timestampToDate } from '../../InBothTabs/ThoughtsTextField';
import DatePicker from 'react-datepicker';
import { Timestamp } from 'firebase/firestore';


const AllThoughtsFilterOptions = ({ filterOptions, setFilterOptions, setShowFilterOptions, runSearch }) => {
    const theme = useTheme();
    const limitOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const distanceOptions = ['5km', '20km', '150km', '600km', '5000km'];
    const backgroundStyle = (stateVariable, borderColor) => {
        return {
            position: 'absolute',
            height: '100%',
            border: '1px solid',
            borderColor: borderColor,
            backgroundColor: 'transparent', // Adjust the color as needed
            transition: '0.3s',
            left: stateVariable ? '50%' : '0%', // Position change based on the state
            width: '50%', // Fixed width
            borderTopLeftRadius: stateVariable ? '0' : '10px', // Adjust the radius as needed
            borderBottomLeftRadius: stateVariable ? '0' : '10px',
            borderTopRightRadius: stateVariable ? '10px' : '0',
            borderBottomRightRadius: stateVariable ? '10px' : '0',
        };
    };


    const selectStyle = ({ width = "100px" }) => ({
        width: width, // Adjust width as needed
        height: '35px', // Adjust height as needed
        fontSize: '16px', // Adjust font size as needed
        margin: '0 10px', // Adjust margin as needed (equivalent to marginX)
        borderRadius: '5px', // Adjust border radius for rounded corners
        border: '1px solid #ccc', // Adjust border as needed
        zIndex: 2, // Ensures the select is on top of the background style div
    });

    const timestampToDate = (timestamp) => {
        // If no timestamp is provided, return null.
        if (!timestamp) {
            return null;
        }
        // If the timestamp does not have the toDate method, throw an error.
        if (typeof timestamp.toDate !== 'function') {
            throw new Error('Invalid timestamp provided. Expected an object with a toDate() method.');
        }
        // Convert the timestamp to a date.
        return timestamp.toDate();
    };
    
    const dateToTimestamp = (date) => {
        // If no date is provided, return null.
        if (!date) {
            return null;
        }
        // If the date is not an instance of the Date object, throw an error.
        if (!(date instanceof Date)) {
            throw new Error('Invalid date provided. Expected a JavaScript Date object.');
        }
        // If the Timestamp utility is not available or doesn't have the fromDate method, throw an error.
        if (!Timestamp || typeof Timestamp.fromDate !== 'function') {
            throw new Error('Timestamp utility is not available or does not have a fromDate() method.');
        }
        // Convert the date to a timestamp.
        return Timestamp.fromDate(date);
    };
    

    const handleLimitChange = (event) => {
        setFilterOptions(prevState => ({
            ...prevState,
            limitToXResults: event.target.value,
        }));
    };

    const handleDistanceChange = (event) => {
        setFilterOptions(prevState => ({
            ...prevState,
            maximumDistance: event.target.value,
            anyDistance: false,
        }));
    };


    const handleFromDateChange = (date) => {
        setFilterOptions(prevState => ({
            ...prevState,
            fromDate: dateToTimestamp(date),
            fromAll: false,
        }));
    };
    const handleToDateChange = (date) => {
        setFilterOptions(prevState => ({
            ...prevState,
            toDate: dateToTimestamp(date),
            toAll: false,
        }));
    };

    const handleFilterButton = () => {
        setShowFilterOptions(false);
        runSearch();
    }


    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginTop: '10px',
            borderRadius: '10px',
            boxShadow: 2,
            border: '1px solid',
            borderColor: 'primary.main',
            padding: '20px',

        }}>

            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>

                {/* Div for "Start date" typography */}
                <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>
                    <Typography>Thoughts within:</Typography>
                </div>

                {/* Div for DatePicker, Button, and background style */}
                <div style={{ position: 'relative', flex: 1, display: 'flex' }}>

                    {/* Background style div */}
                    <div style={backgroundStyle(filterOptions.anyDistance, theme.palette.primary.main)}></div>

                    {/* Div for max distance picker, centered within its half */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '5px' }}>
                        <select value={filterOptions.maximumDistance} onChange={handleDistanceChange} style={selectStyle({ width: '100px' })}>
                            {distanceOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Div for Button, centered within its half */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '5px' }}>
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                            }}
                            onClick={() => setFilterOptions(prevState => ({ ...prevState, anyDistance: true }))}
                        >
                            Any Distance
                        </Button>
                    </div>
                </div>

            </div>


            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px' }}>

                {/* Div for "Start date" typography */}
                <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>
                    <Typography>Thoughts after:</Typography>
                </div>

                {/* Div for DatePicker, Button, and background style */}
                <div style={{ position: 'relative', flex: 1, display: 'flex' }}>

                    {/* Background style div */}
                    <div style={backgroundStyle(filterOptions.fromAll, theme.palette.primary.main)}></div>

                    {/* Div for DatePicker, centered within its half */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '5px' }}>
                        <DatePicker
                            selected={timestampToDate(filterOptions.fromDate)}
                            onChange={handleFromDateChange}
                            onTap={() => setFilterOptions(prevState => ({ ...prevState, fromAll: false }))}
                        />
                    </div>

                    {/* Div for Button, centered within its half */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '5px' }}>
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                            }}
                            onClick={() => setFilterOptions(prevState => ({ ...prevState, fromAll: true }))}
                        >
                            Any Start Date
                        </Button>
                    </div>
                </div>

            </div>




            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px' }}>

                {/* Div for "Start date" typography */}
                <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>
                    <Typography>Thoughts before:</Typography>
                </div>

                {/* Div for DatePicker, Button, and background style */}
                <div style={{ position: 'relative', flex: 1, display: 'flex' }}>

                    {/* Background style div */}
                    <div style={backgroundStyle(filterOptions.toAll, theme.palette.primary.main)}></div>

                    {/* Div for DatePicker, centered within its half */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '5px' }}>
                        <DatePicker
                            selected={timestampToDate(filterOptions.toDate)}
                            onChange={handleToDateChange}
                            onTap={() => setFilterOptions(prevState => ({ ...prevState, toAll: false }))}
                        />
                    </div>

                    {/* Div for Button, centered within its half */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '5px' }}>
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                boxShadow: 'none',
                            }}
                            onClick={() => setFilterOptions(prevState => ({ ...prevState, toAll: true }))}
                        >
                            Any End Date
                        </Button>
                    </div>
                </div>

            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between', // Changed to space-between
                width: '100%',
                marginTop: '20px',
                marginBottom: '20px',
            }}>
                <Typography>Limit number of results: </Typography>
                <select value={filterOptions.limitToXResults} onChange={handleLimitChange} style={selectStyle('140px')}>
                    {limitOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <Box
                sx={{
                    display: 'flex', // Ensures the buttons are in a flex row
                    justifyContent: 'space-between', // Spaces out the buttons
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '20px',
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setShowFilterOptions(false);
                    }}
                    sx={{
                        color: 'primary.main',
                        backgroundColor: 'transparent',
                        textTransform: 'none',
                        textDecoration: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        boxShadow: 'none',
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            textDecoration: 'none',
                            boxShadow: 'none',
                        },
                        '&:active': {
                            backgroundColor: 'secondary.main',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                        '&:focus': {
                            backgroundColor: 'secondary.main',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                    }}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => { handleFilterButton() }}
                    sx={{
                        color: 'primary.main',
                        backgroundColor: 'transparent',
                        textTransform: 'none',
                        textDecoration: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        boxShadow: 'none',
                        border: '1px solid',
                        borderColor: 'primary.main',
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            textDecoration: 'none',
                            boxShadow: 'none',
                        },
                        '&:active': {
                            backgroundColor: 'secondary.main',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                        '&:focus': {
                            backgroundColor: 'secondary.main',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                    }}
                >
                    Apply Filter
                </Button>
            </Box>

        </Box>

    );
}

export default AllThoughtsFilterOptions;
