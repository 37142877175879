import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc, deleteDoc, serverTimestamp, collection } from "firebase/firestore";
import { FIRESTORE_DB, FIREBASE_AUTH } from "../../../firebaseConfig";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { shareExistingThought } from "../../ShareExistingThought";
import CheckBox from "@mui/icons-material/CheckBox";
import { saveThought, shareThought } from "../../SaveShareHelpers";
import LoadingComponent from "../../../LoadingComponent";
import { Box } from "@mui/system";
import { Avatar } from "@mui/material";
import { incrementThoughtSaves, incrementThoughtShares } from "../../../IncrementStats";


const MyThoughtMenu = ({
    anchorEl,
    setAnchorEl,
    handleThoughtMenuClose,
    activeThought,
    setActiveThought,
    setDialog,
    handleCloseDialog,
    navigate,
    loading,
    setLoading,
    latitude,
    longitude,
    generalSettings,
    setMyThoughtsSortSettings,
    textValue,
    thoughtNumber,
    backArrowClick,
    mostRecentlyClickedThoughtText,
    setTextFieldText

}) => {
    const user = FIREBASE_AUTH.currentUser;
    
    const activeThoughtRef = useRef(activeThought);

    useEffect(() => {
        activeThoughtRef.current = activeThought;
    }, [activeThought]);


    


    const startShareExistingThought = async () => {

        const shareThoughtParams = {
            optionalDocID: activeThought? activeThought.id : null,
            quote: textValue,
            createdAt: activeThought? activeThought.createdAt : serverTimestamp(),
            shareLocation: generalSettings.shareGeolocationData,
            lat: latitude,
            lon: longitude,
            isProfile: true,
            thoughtNumber: activeThought? activeThought.thoughtNumber : thoughtNumber + 1,
            user: user
        }
        console.log("shareThoughtParams:", shareThoughtParams);
        const result = await shareThought(shareThoughtParams);
        console.log("Result from shareThought:", result);
        
        if (result.errorCode === 0) {
          setDialog({
              open: true,
              message: "You have successfully shared this thought.",
              title: "Thought shared",
              actions: [{ label: "Woohoo", onClick: () => { handleCloseDialog(); } }]
          });
        }else if (result.errorCode === 690) {
            // Thought input is empty
            console.log("Thought input is empty");
            setDialog({
                open: true,
                message: "Thought input is empty",
                title: "Error",
                actions: [{ label: "I see, let me write something", onClick: () => { handleCloseDialog(); } }]
            });

        }else if (result.errorCode === 691) {
            // Thought is not unique
            console.log("Thought is not unique");
            setDialog({
                open: true,
                message: "Thought is not unique",
                title: "Error",
                actions: [{ label: "I will think of something else", onClick: () => { handleCloseDialog(); } }]
            });

        }else if (result.errorCode === 694) {
            // User not found
            console.log("User not found");
            setDialog({
                open: true,
                message: "User not found",
                title: "Error",
                actions: [{ label: "Who am I?", onClick: () => { handleCloseDialog(); } }]
            });
        }else{
            // Unknown error
            console.log("Unknown error");
        }
    }

    const deleteFromProfile = async () => {
        handleCloseDialog();
        if (!activeThoughtRef.current.isProfile) {
            setDialog({
                open: true,
                message: "No need to remove this thought from your profile, it isn't on there!",
                title: 'This thought is not on your profile.',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } else {
            try {
                setLoading(true);
                // delete the doc with id activeThoughtRef.id from the collection 'Profile Quotes'
                await deleteDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', activeThoughtRef.current.id));
                // set isProfile to false in the doc with id activeThoughtRef.id in the collection 'myThoughts'
                await updateDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThoughtRef.current.id), {
                    isProfile: false
                });
                // show a congrats dialog
                // showCongrats('Thought Removed', 'You have successfully removed this thought from your profile.');
                setDialog({
                    open: true,
                    message: "You have successfully removed this thought from your profile.",
                    title: 'Thought Removed',
                    actions: [{ label: "Okay", onClick: () => {
                        handleCloseDialog();
                        backArrowClick();
                        setTextFieldText('');
                    } }]
                });
                // close the dialog
                // close the menu
            } catch (error) {
                console.error('Error deleting thought:', error);
                setDialog({
                    open: true,
                    message: "Error deleting thought. Please try again.",
                    title: 'Error',
                    actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const deleteFromPublicAndProfile = async () => {
        handleCloseDialog();
        // ALSO NEEDS TO DELETE FROM FIREBASE 'Quotes' Collection
        // try catch block for deletion from 'Quotes' collection and also 'Profile Quotes' collection and setting isProfile and isPublic to false in my thought
        try {
            setLoading(true);
            // delete the doc with id activeThoughtRef.id from the collection 'Profile Quotes'
            await deleteDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', activeThoughtRef.current.id));
            // delete the doc with id activeThoughtRef.id from the collection 'Quotes'
            await deleteDoc(doc(FIRESTORE_DB, 'Quotes', activeThoughtRef.current.id));

            // set isProfile and isPublic to false in the doc with id activeThoughtRef.id in the collection 'myThoughts'
            await updateDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThoughtRef.current.id), {
                isProfile: false,
                isPublic: false,
            });
            // show a congrats dialog
            setDialog({
                open: true,
                message: "You have successfully removed this thought from public results, and from your profile. It is now private, and only viewable to you.",
                title: 'Thought Removed',
                actions: [{ label: "Okay", onClick: () => { 
                    handleCloseDialog();
                    backArrowClick();
                    setTextFieldText('');
                } }]
            });
            // close the dialog
            // close the menu
        } catch (error) {
            console.error('Error deleting thought:', error);
            setDialog({
                open: true,
                message: "Error deleting thought. Please try again.",
                title: 'Error',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } finally {
            setLoading(false);
        }
    };

    const deleteFromPublic = async () => {
        handleCloseDialog();
        // ALSO NEEDS TO DELETE FROM FIREBASE 'Quotes' Collection
        // try catch block for deletion from 'Quotes' collection and also setting isProfile and isPublic to false in my thought
        try {
            setLoading(true);
            // delete the doc with id activeThoughtRef.id from the collection 'Quotes'
            await deleteDoc(doc(FIRESTORE_DB, 'Quotes', activeThoughtRef.current.id));

            // delete from Profile Quotes also
            await deleteDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', activeThoughtRef.current.id));

            // set isPublic to false in the doc with id activeThoughtRef.id in the collection 'myThoughts'
            await updateDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThoughtRef.current.id), {
                isPublic: false,
                isProfile: false,
            });
            // show a congrats dialog
            // showCongrats('Thought Removed', 'You have successfully removed this thought from public results. It is now private, and only viewable to you');
            setDialog({
                open: true,
                message: "You have successfully removed this thought from public results. It is now private, and only viewable to you.",
                title: 'Thought Removed',
                actions: [{ label: "Okay", onClick: () => { handleCloseDialog();
                    backArrowClick();
                    setTextFieldText('');
                } }]
            });
            // close the dialog
            // close the menu
        } catch (error) {

            console.error('Error deleting thought:', error);
            setDialog({
                open: true,
                message: "Error deleting thought. Please try again.",
                title: 'Error',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } finally {
            setLoading(false);
        }
    };

    const deleteFromMyThoughts = async () => {
        handleCloseDialog();
        // needs to delete from myThoughts collection under 'Users' -> user.uid -> myThoughts
        try {
            setLoading(true);
            // delete the doc with id activeThoughtRef.id from the collection 'myThoughts'
            await deleteDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThoughtRef.current.id));

            // show a congrats dialog
            // showCongrats('Thought Deleted', 'You have successfully deleted this thought from your saved thoughts.');
            setDialog({
                open: true,
                message: "You have successfully deleted this thought from your saved thoughts.",
                title: 'Thought Deleted',
                actions: [{ label: "Okay", onClick: () => {
                    handleCloseDialog();
                    backArrowClick();
                    setTextFieldText('');
                } }]
            });

            // close the dialog
            // close the menu
        } catch (error) {
            console.error('Error deleting thought:', error);
            setDialog({
                open: true,
                message: "Error deleting thought. Please try again.",
                title: 'Error',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } finally {
            setLoading(false);
        }
    };

    const removeFromVault = async () => {
        handleCloseDialog();
        // needs to change myThoughts -> activeThoughtRef.id -> isVault to false
        try {
            setLoading(true);
            // set isVault to false in the doc with id activeThoughtRef.id in the collection 'myThoughts'
            await updateDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThoughtRef.current.id), {
                isVault: false,
            });
            // show a congrats dialog
            // showCongrats('Thought Removed', 'You have successfully removed this thought from your vault.');
            setDialog({
                open: true,
                message: "You have successfully removed this thought from your vault.",
                title: 'Thought Removed from Vault',
                actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
            });
            // close the dialog
            // close the menu
        } catch (error) {
            console.error('Error deleting thought:', error);
            setDialog({
                open: true,
                message: "Error deleting thought. Please try again.",
                title: 'Error',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } finally {
            setLoading(false);
        }
    };

    const saveToVault = async () => {
        console.log("DSHJFALDJSFH ADSHF SDAKJU ASDKJFH ASDJKFH SADKJF SDAHFSDAKJHG ?SADKFSDAFHSAD HKFA HS HFDSA hfASKFA DSHaFDS Hfads Hfdas Hfdsa HFdsa Hdsfa Hsfdah Kfsadh kdsf HKDSFA Hdsaf HDSAF HDSAF HSADf KDSAF HSADF H khSDAF hkadsfkh dsaf Hdsfa HKDSfa Hsdaf Hsdaf Hsdf HAHDSKF KHDSF  HKDSF KHSDFA KHDSF KHFds HKDSAFHKSFADKHSFADKHSFAH")
        // SAVE or UPDATE THIS THOUGHT
        console.log("DONE saving or updating thought");
        handleCloseDialog();
        // needs to change myThoughts -> activeThoughtRef.id -> isVault to true
        try {
            setLoading(true);
            // set isVault to true in the doc with id activeThoughtRef.id in the collection 'myThoughts'
            await updateDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', activeThoughtRef.current.id), {
                isVault: true,
            });
            // show a congrats dialog
            // showCongrats('Thought Saved', 'You have successfully saved this thought to your vault.');
            setDialog({
                open: true,
                message: "You have successfully saved this thought to your vault.",
                title: 'Thought Saved to Vault',
                actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
            });
            // close the menu
        } catch (error) {
            console.error('Error saving thought:', error);
            setDialog({
                open: true,
                message: "Error saving thought. Please try again.",
                title: 'Error',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } finally {
            setLoading(false);
        }
    };

    const shareToPublic = async () => {
        // SAVE OR UPDATE THIS THOUGHT

        handleCloseDialog();
        startShareExistingThought();
    };

    const shareExistingThoughtToProfile = async () => {
        // SAVE OR UPDATE THIS THOUGHT

        handleCloseDialog();
        // add it to Profile Quotes
        // update isProfile field in myThoughts
        try {
            setLoading(true);
            // add it to Profile Quotes
            const profileThoughtEntry = {
                createdAt: serverTimestamp(),
                quote: textValue,
                isArchived: false,
                id: activeThought? activeThought.id : null,
            };
            setDoc(
                doc(FIRESTORE_DB, "Users", user.uid, "Profile Quotes", activeThought.id),
                profileThoughtEntry
            );

            // update isProfile field in myThoughts
            await updateDoc(doc(FIRESTORE_DB, "Users", user.uid, "myThoughts", activeThought.id), {
                isProfile: true,
            });

            // show a congrats dialog
            // showCongrats('Thought Shared', 'You have successfully shared this thought to your profile.');
            setDialog({
                open: true,
                message: "You have successfully shared this thought to your profile.",
                title: 'Thought Shared',
                actions: [{ label: "Okay", onClick: () => { handleCloseDialog(); } }]
            });

            // close the menu
        } catch (error) {
            console.error('Error sharing thought:', error);
            setDialog({
                open: true,
                message: "Error sharing thought. Please try again.",
                title: 'Error',
                actions: [{ label: "Cancel", onClick: () => { handleCloseDialog(); } }]
            });
        } finally {
            setLoading(false);
        }

    };



    return (
        <Menu
            style={{
                display: loading? 'none' : 'block'
            }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleThoughtMenuClose}
        >
            {activeThought?.isVault ? (
                [
                    <MenuItem key="removeFromVault"
                        onClick={() => {
                            handleCloseDialog();
                            // confirm delete dialog for vault, if yes call confirmRemoveFromVault
                            // if no, close dialog
                            setDialog(
                                {
                                    open: true,
                                    message: "Are you sure you want to remove this thought from your vault? It will still be a private thought, which you can then share, etc.",
                                    title: "Remove from vault?",
                                    actions: [
                                        {
                                            label: "Yes",
                                            onClick: () => {
                                                handleCloseDialog();
                                                removeFromVault();
                                                handleThoughtMenuClose();
                                            }
                                        },
                                        {
                                            label: "No",
                                            onClick: () => {
                                                handleCloseDialog();
                                            }
                                        }
                                    ]
                                }
                            );
                        }}
                    >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/vault.png`}
                                        alt="Vault"
                                        style={{ width: '25px', height: '25px', marginRight: '10px' }}
                                    />
                                    Remove from vault

                    </MenuItem>,

                ]
            ) : (
                [

                    activeThought?.isPublic ? (
                        <MenuItem key="shareToPublicChecked">
                            <CheckBox color="success" sx={{ marginRight: 1 }} />
                            <img
                                        src={`${process.env.PUBLIC_URL}/globe.png`}
                                        alt="Vault"
                                        style={{ width: '25px', height: '25px', marginRight: '10px' }}
                                    />
                            <Typography color="text.disabled"
                                onClick={() => {
                                    handleCloseDialog();
                                    // confirm that you wish to remove this thought from public, it will also be removed from your profile
                                    // if yes, call deleteFromPublicAndProfile
                                    // if no, close dialog
                                    setDialog(
                                        {
                                            open: true,
                                            message: `This thought is currently viewable on public results. Do you want to remove this thought from public results? It will no longer be findable, but it may have already been seen and read by others. It will become a private thought, viewable by you only. ${activeThought.isProfile ? "It will still be findable on your profile." : ""}`,
                                            title: "Remove from public?",
                                            actions: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                        deleteFromPublicAndProfile();
                                                        handleThoughtMenuClose();
                                                    }
                                                },
                                                {
                                                    label: "No",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                    }
                                                }
                                            ]
                                        }
                                    );
                                }}
                            >
                                    
                                Share to public</Typography>
                        </MenuItem>
                    ) : (
                        [
                            <MenuItem key="shareToPublicUnChecked"
                                onClick={() => {
                                    handleCloseDialog();
                                    // confirm that you wish to share this thought to public, it will also be on your profile (you can remove this later), and others will be able to find it by searching a similar thought
                                    // if yes, call confirmShareToPublic
                                    // if no, close dialog
                                    setDialog(
                                        {
                                            open: true,
                                            message: "Do you want to share this thought to public? It will be viewable on your profile (you can remove this later), and others will be able to find it by searching a similar thought.",
                                            title: "Share to public?",
                                            actions: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                        shareToPublic();
                                                        handleThoughtMenuClose();
                                                    }
                                                },
                                                {
                                                    label: "No",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                    }
                                                }
                                            ]
                                        }

                                    );
                                }}
                            ><img
                            src={`${process.env.PUBLIC_URL}/globe.png`}
                            alt="Vault"
                            style={{ width: '25px', height: '25px', marginRight: '10px' }}
                        />
                        Share to public</MenuItem>,
                        ]
                    ),
                    activeThought?.isPublic && (
                        activeThought?.isProfile ? (
                            <MenuItem key="shareToProfileChecked">
                                <CheckBox color="success" sx={{ marginRight: 1 }} />
                                <Typography color="text.disabled"
                                    onClick={() => {
                                        handleCloseDialog();
                                        // confirm that you wish to remove this thought from your profile, it will still be publicly findable
                                        // if yes, call deleteFromProfile
                                        // if no, close dialog
                                        setDialog(
                                            {
                                                open: true,
                                                message: "This thought is currently viewable on your profile. Do you want to remove this thought from your profile? It will still be publicly findable when other users search with a similar thought.",
                                                title: "Remove from profile?",
                                                actions: [
                                                    {
                                                        label: "Yes",
                                                        onClick: () => {
                                                            handleCloseDialog();
                                                            deleteFromProfile();
                                                            handleThoughtMenuClose();
                                                        }
                                                    },
                                                    {
                                                        label: "No",
                                                        onClick: () => {
                                                            handleCloseDialog();
                                                        }
                                                    }
                                                ]
                                            }
                                        );
                                    }}
                                >
                                    <div style={{
                                        flexDirection: 'row',
                                        display: 'flex',
                                    }}>
                                    <Avatar
                src={FIREBASE_AUTH.currentUser ? FIREBASE_AUTH.currentUser.photoURL : null}
                alt="Profile"
                sx={{ width: 22, height: 22, marginRight: '12px', marginLeft: '2px' }}
              />
                                    Share to profile
                                    </div>

</Typography>
                            </MenuItem>
                        ) : (
                            <MenuItem key="shareToProfileUnChecked"
                                onClick={
                                    () => {
                                        handleCloseDialog();
                                        // confirm that you wish to share this thought to your profile
                                        // if yes, call confirmShareToProfile
                                        // if no, close dialog
                                        setDialog(
                                            {
                                                open: true,
                                                message: "Do you want to share this thought to your profile? It is already public and findable by others, but it is not viewable on your profile.",
                                                title: "Share to profile?",
                                                actions: [
                                                    {
                                                        label: "Yes",
                                                        onClick: () => {
                                                            handleCloseDialog();
                                                            shareExistingThoughtToProfile();
                                                            handleThoughtMenuClose();
                                                        }
                                                    },
                                                    {
                                                        label: "No",
                                                        onClick: () => {
                                                            handleCloseDialog();
                                                        }
                                                    }
                                                ]
                                            }

                                        );
                                    }
                                }
                            >                                    <div style={{
                                flexDirection: 'row',
                                display: 'flex',
                            }}>
                            <Avatar
        src={FIREBASE_AUTH.currentUser ? FIREBASE_AUTH.currentUser.photoURL : null}
        alt="Profile"
        sx={{ width: 22, height: 22, marginRight: '12px', marginLeft: '2px' }}
      />
                            Share to profile
                            </div></MenuItem>
                        )
                    )

                ]
            )}
            <MenuItem
                key="deleteFrom"
                onClick={() =>
                    setDialog({
                        open: true,
                        message: "Select where this thought should be deleted from",
                        title: "Delete from ...",
                        actions: [
                            (activeThought.isPublic && !activeThought.isProfile) && {
                                label: "Public",
                                onClick: () => {
                                    handleCloseDialog();
                                    // confirm delete dialog for public, if yes call confirmDeleteThoughtFromPublic
                                    // if no, close dialog
                                    setDialog(
                                        {
                                            open: true,
                                            message: "Are you sure you want to delete this thought from public? It will no longer be findable, but it may have already been seen and read by others. It will become a private thought, viewable by you only.",
                                            title: "Delete from public?",
                                            actions: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                        deleteFromPublic();
                                                        handleThoughtMenuClose();
                                                    }
                                                },
                                                {
                                                    label: "No",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                    }
                                                }
                                            ]
                                        }
                                    );
                                },
                            },
                            (activeThought.isPublic && activeThought.isProfile) && {
                                label: "Public & Profile",
                                onClick: () => {
                                    handleCloseDialog();
                                    setDialog(
                                        // confirm delete dialog for public and profile
                                        // if accepted, call function of deleteThoughtFromPublicAndProfile();
                                        {
                                            open: true,
                                            message: "Are you sure you want to delete this thought from public results, and from your profile? It will no longer be findable, but it may have already been seen and read by others. It will become a private thought, viewable by you only.",
                                            title: "Delete from public and profile?",
                                            actions: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                        deleteFromPublicAndProfile();
                                                        handleThoughtMenuClose();
                                                    },
                                                },
                                                {
                                                    label: "No",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                    },
                                                },
                                            ],
                                        }

                                    );
                                },
                            },
                            activeThought.isProfile && {
                                label: "Profile",
                                onClick: () => {
                                    handleCloseDialog();
                                    // confirm delete dialog for profile, if yes call confirmDeleteThoughtFromProfile
                                    // if no, close dialog
                                    setDialog(
                                        {
                                            open: true,
                                            message: "Are you sure you want to remove this thought from your profile? It will still be publicly findable, but will not show on your profile unless you add it again.",
                                            title: "Delete from profile?",
                                            actions: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                        deleteFromProfile();
                                                        handleThoughtMenuClose();
                                                    }
                                                },
                                                {
                                                    label: "No",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                    }
                                                }
                                            ]
                                        }
                                    );
                                },
                            },
                            {
                                label: "My Thoughts",
                                onClick: () => {
                                    handleCloseDialog();
                                    // here we will confirm that they want to delete it from myThoughts, they will no longer be able to edit it or see it, but it will remain in public and/ or on profile if it is currently there

                                    setDialog(
                                        {
                                            open: true,
                                            // message: "Are you sure you want to delete this thought from your thoughts? {if activeThought.isPublic}It will still be publicly findable. {if activeThought.isProfile} It will still be findable on your profile.",
                                            // message should change based on isPublic and isProfile
                                            message: `Are you sure you want to delete this thought from your saved thoughts? ${activeThought.isPublic ? "It will still be publicly findable. " : ""}${activeThought.isProfile ? "It will still be viewable on your profile." : ""}`,
                                            title: "Delete from my thoughts?",
                                            actions: [
                                                {
                                                    label: "Yes",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                        deleteFromMyThoughts();
                                                        handleThoughtMenuClose();
                                                    }
                                                },
                                                {
                                                    label: "No",
                                                    onClick: () => {
                                                        handleCloseDialog();
                                                    }
                                                }
                                            ]
                                        }

                                    )
                                },
                            },
                        ],
                    })
                }
            >
                <img
                    src={`${process.env.PUBLIC_URL}/trash.png`}
                    alt="Vault"
                    style={{ width: '17px', height: '20px', marginRight: '15px', marginLeft: '4px' }}
                />
                Delete from ...
            </MenuItem>
            {(!activeThought?.isPublic && !activeThought?.isVault) && (
                <MenuItem key="saveToVault"
                    onClick={() => {
                        handleCloseDialog();
                        // confirm delete dialog for vault, if yes call confirmRemoveFromVault
                        // if no, close dialog
                        setDialog(
                            {
                                open: true,
                                message: "Do you want to save this thought to your vault? It is already private, but the vault makes it one step further from sharing, for extra protection.",
                                title: "Save to vault?",
                                actions: [
                                    {
                                        label: "Yes",
                                        onClick: () => {
                                            saveToVault();
                                            handleCloseDialog();
                                            handleThoughtMenuClose();
                                        }
                                    },
                                    {
                                        label: "No",
                                        onClick: () => {
                                            handleCloseDialog();
                                        }
                                    }
                                ]
                            }
                        );
                    }}
                >                                    <img
                src={`${process.env.PUBLIC_URL}/vault.png`}
                alt="Vault"
                style={{ width: '25px', height: '25px', marginRight: '10px' }}
            />Save to vault</MenuItem>
            )}
        </Menu>

    );
}

export { MyThoughtMenu };
