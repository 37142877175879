import React, { useState, useEffect } from 'react';
import { Typography, Box, Card, Button, Dialog, DialogContent } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Hub } from '@mui/icons-material'; // Example icon, replace with desired ones
import { keyframes } from '@emotion/react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MemoryIcon from '@mui/icons-material/Memory';
import HubIcon from '@mui/icons-material/Hub';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useTheme } from '@mui/material/styles';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInAndUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function MyThoughtsTabLocked({
    isVisible
}) {
    const originalText = 'There is no software that can:';
    const [text, setText] = useState(originalText);
    const [animate, setAnimate] = useState(false);
    const [fadeInActive, setFadeInActive] = useState(false);
    const [startFullAnimation, setStartFullAnimation] = useState(false);
    const [delayedStartAnimation, setDelayedStartAnimation] = useState(false);
    const theme = useTheme();
    const lightModeColors = [
        '#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff',
        '#a0c4ff', '#bdb2ff', '#ffc6ff', '#fffffc', '#e4c1f9',
        '#e0f9b5', '#f9f7ae', '#f7dbe6', '#f5cac3', '#f0a6ca'
    ];

    const darkModeColors = [
        '#0b3c5d', '#1b6ca8', '#133b5c', '#1e5f74', '#34515e', 
        '#145051', '#0b525b', '#014f43', '#02231c', '#0b3429',
        '#433a3f', '#5b556f', '#423b54', '#321e4e', '#240046'
    ];
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    useEffect(() => {
        if (openModal) {
            const script = document.createElement('script');
            script.src = 'https://js.stripe.com/v3/pricing-table.js';
            script.async = true;
            document.body.appendChild(script);
        }
    }, [openModal]);

    


    useEffect(() => {
        if (isVisible && !animate) {
            setTimeout(() => {
                setAnimate(true);
                setTimeout(() => {
                    let currentText = originalText;
                    const backspaceInterval = setInterval(() => {
                        currentText = currentText.slice(0, -1);
                        setText(currentText);
                        if (currentText === '') {
                            clearInterval(backspaceInterval);
                            setStartFullAnimation(true);
                        }
                    }, 100); // Speed of backspacing
                }, 1000); // Duration for strikethrough animation
            }, 1000); // Initial delay
        }
    }, [isVisible]);

    useEffect(() => {
        if (startFullAnimation) {
            setTimeout(() => {
                setDelayedStartAnimation(true);
            }, 1000); // Initial delay after startFullAnimation
        }
    }, [startFullAnimation]);





    const getListItemStyle = (index) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        opacity: startFullAnimation ? 1 : 0, // Directly using state to control opacity
        transition: 'opacity 0.5s ease, transform 0.5s ease',
        transform: startFullAnimation ? 'translateY(0)' : 'translateY(20px)',
        transitionDelay: `${index * 0.9 + 3}s`, // Staggered delay for each item
    });


    const imageStyle = {
        width: '50px',
        height: '50px',
        marginRight: '10px',
        opacity: startFullAnimation ? 1 : 0,
        transition: 'opacity 0.5s ease',
    };

    const basicListStyle = {
        opacity: animate ? 0 : 1,
        transition: 'opacity 1s ease',
    };

    const iconStyles = {
        marginLeft: '10px',
        marginRight: '10px',
        height: '100px',
        width: '100px',
    };

    const typographyBoxStyles = {
        marginLeft: '10px',
        marginRight: '10px',
    };

    const getCardStyle = (index) => ({
        backgroundColor: theme.palette.mode == 'dark' ? darkModeColors[index % darkModeColors.length] : lightModeColors[index % lightModeColors.length],
        padding: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    });

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ my: 2 }}>


            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogContent style={{ backgroundColor: '#ffffff'}}>
                    <stripe-pricing-table pricing-table-id="prctbl_1OcLwWK80JZNybuQH3whEYlt"
                        publishable-key="pk_live_51MoFhpK80JZNybuQLkH58SYwWsHMKfYoXk73vkuyZjspdzXTpRkCOyKsEK2gb7PKMyeMaTGboaAkKb3AcbC4DBpY00nSxkfz4z">
                    </stripe-pricing-table>
                </DialogContent>
            </Dialog>
                {startFullAnimation ?
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginBottom: '20px',
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Typography variant="h4" style={{
                            fontWeight: 'bold',
                            opacity: delayedStartAnimation ? 1 : 0,
                            transition: 'opacity 0.5s ease',
                            marginLeft: '10px',

                        }}>
                            My Thoughts Tab
                        </Typography>
                        <Typography sx={{
                            marginBottom: '20px',
                            opacity: delayedStartAnimation ? 1 : 0,
                            transition: 'opacity 0.5s ease',
                            transitionDelay: '0.5s',
                            marginLeft: '10px',
                        }}>by Thought Portals</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={handleOpenModal}
                        style={{ margin: '10px', textTransform: 'none', display: 'flex', backgroundColor: theme.palette.success.main,
                        opacity: delayedStartAnimation ? 1 : 0,
                        transition: 'opacity 0.5s ease',
                    }}
                    >
                        <Typography fontWeight={'bold'}>
                            Click Here to Unlock!
                        </Typography>
                    </Button>
                    </Box>
                    :
                    <Typography variant="h6" gutterBottom style={{ textDecoration: animate ? 'line-through' : 'none', marginBottom: '20px' }}>
                        {text}
                    </Typography>
                }
                {!delayedStartAnimation &&
                    <ul style={basicListStyle}>
                        <li>
                            <Typography>Transform your notes into a fully searchable and sortable database, beyond basic keyword search.</Typography>
                        </li>
                        <li>
                            <Typography>Automatically organise your notes to save you time and frustration.</Typography>
                        </li>
                        <li>
                            <Typography>Act as a digital long term memory that you can call upon tomorrow, or 5 years from now.</Typography>
                        </li>
                        <li>
                            <Typography>Help you connect your ideas by showing similar thoughts all in one place.</Typography>
                        </li>
                        <li>
                            <Typography>Let you share your thoughts to others who think the most similarly to you.</Typography>
                        </li>
                        <li>
                            <Typography>Provide value for people with 50 notes, or 10000.</Typography>
                        </li>
                    </ul>

                }
                                <ul style={{ listStyleType: 'none' }}>
                    <li style={getListItemStyle(0)}>
                        <Card style={getCardStyle(0)}>

                            <ManageSearchIcon style={iconStyles} />
                            <Box style={typographyBoxStyles}>
                                <Typography variant='h6' fontWeight={'bold'}>Top Quality Search for Notes</Typography>
                                <Typography>Transform your notes into a fully searchable and sortable database, beyond basic keyword search</Typography>
                            </Box>
                        </Card>
                    </li>
                    <li style={getListItemStyle(1)}>
                        <Card style={getCardStyle(1)}>

                            <Box style={typographyBoxStyles}>
                                <Typography variant='h6' fontWeight={'bold'}>Instantly Organised Notes</Typography>
                                <Typography>Automatically organise your notes to save you time and frustration</Typography>
                            </Box>
                            <SwapVertIcon style={iconStyles} />
                        </Card>
                    </li>
                    <li style={getListItemStyle(2)}>
                        <Card style={getCardStyle(2)}>

                            <MemoryIcon style={iconStyles} />
                            <Box style={typographyBoxStyles}>
                                <Typography variant='h6' fontWeight={'bold'}>Infinite Memory</Typography>
                                <Typography>Act as a digital long term memory that you can call upon tomorrow, or 5 years from now</Typography>
                            </Box>
                        </Card>
                    </li>
                    <li style={getListItemStyle(3)}>
                        <Card style={getCardStyle(3)}>
                            <Box style={typographyBoxStyles}>
                                <Typography variant='h6' fontWeight={'bold'}>Connect The Dots</Typography>
                                <Typography>Help you connect your ideas by showing similar thoughts all in one place</Typography>
                            </Box>
                            <HubIcon style={iconStyles} />
                        </Card>
                    </li>
                    <li style={getListItemStyle(4)}>
                        <Card style={getCardStyle(4)}>

                            <img src="/globe.png" alt="Description" style={iconStyles} />
                            <Box style={typographyBoxStyles}>
                                <Typography variant='h6' fontWeight={'bold'}>Global Connection to Likeminded People</Typography>
                                <Typography>Let you share your thoughts to others who think the most similarly to you.</Typography>
                            </Box>
                        </Card>
                    </li>
                    <li style={getListItemStyle(5)}>
                        <Card style={getCardStyle(5)}>

                            <Box style={typographyBoxStyles}>
                                <Typography variant='h6' fontWeight={'bold'}>We Love Large Numbers</Typography>
                                <Typography>Works great if you have 50 notes, and works even better if you have way more!</Typography>
                            </Box>
                            <ReceiptLongIcon style={iconStyles} />
                        </Card>
                    </li>

                </ul>
                <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: 'row',
                        paddingTop: '20px',
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Typography variant="h4" style={{
                            fontWeight: 'bold',
                            opacity: startFullAnimation ? 1 : 0,
                            transition: 'opacity 0.5s ease, transform 0.5s ease',
                            transform: startFullAnimation ? 'translateY(0)' : 'translateY(20px)',
                            transitionDelay: `7.5s`, // Staggered delay for each item
                            marginLeft: '10px',

                        }}>
                            My Thoughts Tab
                        </Typography>
                        <Typography sx={{
                            marginBottom: '20px',
                            opacity: startFullAnimation ? 1 : 0,
                            transition: 'opacity 0.5s ease, transform 0.5s ease',
                            transform: startFullAnimation ? 'translateY(0)' : 'translateY(20px)',
                            transitionDelay: `7.5s`, // Staggered delay for each item
                            marginLeft: '10px',
                        }}>by Thought Portals</Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={handleOpenModal}
                        style={{ margin: '10px', textTransform: 'none', display: 'flex', backgroundColor: theme.palette.success.main,
                        opacity: startFullAnimation ? 1 : 0,
                        transition: 'opacity 0.5s ease, transform 0.5s ease',
                        transform: startFullAnimation ? 'translateY(0)' : 'translateY(20px)',
                        transitionDelay: `7.5s`, // Staggered delay for each item
                    }}
                    >
                        <Typography fontWeight={'bold'}>
                            Click Here to Unlock!
                        </Typography>
                    </Button>
                    </Box>
            </Box>
        </Box>
    );
}

