import React, { useState, useEffect } from 'react';
import { IconButton, Avatar, Typography, Card, CardContent, Box } from '@mui/material';
import { Edit as EditIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../../firebaseConfig';
import { doc, getDoc, collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { useTheme } from '@mui/material/styles';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

function MyProfileBridge({
    setShowMyProfileBridge,
    setShowEditProfileBridge
}) {
  const [user, setUser] = useState(FIREBASE_AUTH.currentUser);
  const [bioText, setBioText] = useState('');
  const [statusText, setStatusText] = useState('');
  const [quotes, setQuotes] = useState([]); // add this line to maintain quotes in state
  const theme = useTheme();

  useEffect(() => {


    const fetchUserBio = async () => {
      const userDocRef = doc(FIRESTORE_DB, 'Users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        console.error(`No document for user ${user.uid}`);
        return;
      }

      setBioText(userDoc.data().bio);
      setStatusText(userDoc.data().status);

    };

    fetchUserBio();

    const fetchQuotes = async () => {
      const quotesCollection = collection(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes');
      const q = query(quotesCollection, where("isArchived", "==", false), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);

      const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setQuotes(fetchedQuotes);
    }



    fetchQuotes();
  }, [user]);

  useEffect(() => {
    console.log("First quotes.quote", quotes[0]?.quote);
  }
    , [quotes]);

  return (
    <div style={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <IconButton sx={{ color: theme.palette.primary.main}} onClick={() => setShowEditProfileBridge(true)}>
          <EditIcon />
        </IconButton>
      </div>

      <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
        <IconButton sx={{ color: theme.palette.primary.main}} onClick={() => setShowMyProfileBridge(null)}>
          <BackIcon />
        </IconButton>
      </div>

      <Avatar
        src={user?.photoURL}
        alt="Profile"
        style={{ width: '200px', height: '200px', marginTop: '1rem' }}
      />

      <Typography variant="h4" component="h1" style={{ marginTop: '1rem' }}>
        {user?.displayName}
      </Typography>


      <Typography variant="h5" component="h1" style={{ marginTop: '1rem' }}>
        {statusText}
      </Typography>

      <Typography variant="h6" component="p" style={{ width: '300px', textAlign: 'center', marginTop: '1rem', marginBottom: '2rem' }}>
        {bioText}
      </Typography>
      {quotes.map(quote => (
        <Box key={quote.id} sx={{ width: { xs: '95%', md: '75%' }, maxWidth: { xs: '100%', sm: '600px', md: '800px', lg: '1000px' }, mx: '2.5%', mb: '10px' }}>
          <Card>
            <CardContent>
              <Typography variant="body1">
                {quote?.quote.toString()}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}
    </div>
  );
}

export default MyProfileBridge;
