import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Avatar,
  useTheme,
  Box,
  Badge,
  styled,
  keyframes,
  Typography
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { onAuthStateChanged } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { doc, collection, query, where, getCountFromServer } from 'firebase/firestore';
import { ThemeContext } from './ThemeContext';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';



const ResponsiveAppBar = ({
  setBridgeDisplayStack,
  setShowMyProfileBridge,
  selectedTab,
  setSelectedTab,
  tabs
}) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);
  const [logoSrc, setLogoSrc] = useState(darkMode ? "/thought-logo-70px.png" : "/thought-logo-70px.png");

  const [coinCount, setCoinCount] = useState(0);
  const prevCoinCountRef = useRef(coinCount);
  const [flash, setFlash] = useState(false);
  const [displayCoinCount, setDisplayCoinCount] = useState(coinCount);


  const moneyCounter = () => {
    if (FIREBASE_AUTH.currentUser) {
      const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid);
      
      // Listen to the document snapshot
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // Update state with coinCount
          setCoinCount(docSnapshot.data().coinCount || 0);
        }
      });

      // return unsubscribe function to clean up on component unmount
      return unsubscribe;
    }
  };

  useEffect(() => {
    if (coinCount !== prevCoinCountRef.current) {
      setFlash(true);
      let startCount = prevCoinCountRef.current;
      const endCount = coinCount;
      const intervalId = setInterval(() => {
        if (startCount < (endCount - 100)) {
          startCount += 100;
          setDisplayCoinCount(startCount);
        } else if (startCount < endCount){
          startCount++;
          setDisplayCoinCount(startCount);
        } 
        else if (startCount > endCount) {
          startCount--;
          setDisplayCoinCount(startCount);
        } else {
          clearInterval(intervalId);
          const flashTimeout = setTimeout(() => setFlash(false), 5000);
          return () => clearTimeout(flashTimeout);
        }
      }, 12);
      
      prevCoinCountRef.current = coinCount;

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [coinCount]);

  

  useEffect(() => {
    setLogoSrc(darkMode ? "/thought-logo-70px.png" : "/thought-logo-70px.png");
  }, [darkMode]);
  
  const checkNewMessages = async () => {
    const messagesColl = collection(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid, "Conversations");
    const messagesQuery = query(messagesColl, where("hasNewMessage", "==", true));
    const messageCount = await getCountFromServer(messagesQuery);
    if (messageCount.data().count > 0) {
      setHasNewMessage(true);
    } else {
      setHasNewMessage(false);
    }
  };

  
  
  useEffect(() => {
    if (isUserLoggedIn) {
      checkNewMessages();
      const unsubscribe = moneyCounter();
      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [isUserLoggedIn, location]);
  
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, (user) => {
      if (user) {
        setIsUserLoggedIn(true);
      } else {
        setIsUserLoggedIn(false);
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  
  const menuItems = [
    { label: 'Home', path: '/homescreenv3' },
    { label: 'Messages', path: '/directmessages', showGreenDot: hasNewMessage },
    // ... other menu items
    { label: 'Settings', path: '/settingsscreen'},
    // ... other menu items
  ];

  const ThemedAppBar = styled(AppBar)(({ theme }) => ({
    height: '70px',
    fontFamily: 'Geologica',
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1500,
  }));
  

  const handleLogoClick = () => {
    setBridgeDisplayStack([]);
    const numTabs = tabs.filter(tab => tab.enabled).length;
    setSelectedTab(numTabs - 1);
    // if not already on thoughts2, then navigate to thoughts2
    if (location.pathname !== '/thoughts2') {
      navigate('/thoughts2');
      setTimeout(() => {
        const numTabs = tabs.filter(tab => tab.enabled).length;
        setSelectedTab(numTabs - 1);
      }
      , 500);
    }
  };
    

  

//<img src={logoSrc} alt="logo" width="150" height="50"/>
  return (
    <>
            <ThemedAppBar position="static" elevation={0} sx={{ background: theme.palette.background.default }}> 
        <Toolbar>
          <Box display="flex" alignItems="center" height="70px" flexGrow={1}>

              <IconButton edge="start" aria-label="menu" onClick={handleLogoClick} sx={{ color: theme.palette.primary.main }}>
                <img src='/newLogo.png' alt="logo" width="50" height="50"/>
              </IconButton>


            <Box onClick={handleLogoClick}>
              <Typography variant="logo" sx={{ fontSize: "30px", color: theme.palette.primary.main, marginLeft: "5px" }}>
                Thought Portals
              </Typography>
            </Box>
          </Box>
          
          {isUserLoggedIn && (
            <Box position="relative" display="inline-flex">
              <Avatar
                src={FIREBASE_AUTH.currentUser ? FIREBASE_AUTH.currentUser.photoURL : null}
                alt="Profile"
                onClick={() => setShowMyProfileBridge(true)}
                sx={{ width: 50, height: 50 }}
              />
            </Box>
          )}
  
        </Toolbar>
      </ThemedAppBar>
    </>
  );
};

export default ResponsiveAppBar;