import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { collection, getDocs, query, orderBy, addDoc, serverTimestamp } from 'firebase/firestore';
import { FIRESTORE_DB, FIREBASE_AUTH } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


function SavedThoughts() {
  const [thoughts, setThoughts] = useState([]);
  const navigate = useNavigate();
  const user = FIREBASE_AUTH.currentUser;

  async function addThought() {
    try {
      const newThoughtRef = await addDoc(collection(FIRESTORE_DB, `Users/${user.uid}/savedThoughts`), {
        text: "",
        createdAt: serverTimestamp(),
      });
      const docId = newThoughtRef.id;
      navigate('/editThought', { state: { id: docId } });
    } catch (error) {
      console.error("Error adding new thought:", error);
    }
  }


  useEffect(() => {
    async function fetchSavedThoughts() {
      try {
        const savedThoughtsRef = collection(FIRESTORE_DB, `Users/${user.uid}/savedThoughts`);

        // Creating a query with orderBy
        const q = query(savedThoughtsRef, orderBy("createdAt", "desc"));

        const snapshot = await getDocs(q);
        const fetchedThoughts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setThoughts(fetchedThoughts);
      } catch (error) {
        console.error("Error fetching saved thoughts:", error);
      }
    }


    fetchSavedThoughts();
  }, [user.uid]);

  return (
    <div>
      {thoughts.map(thought => (
        <Card
          key={thought.id}
          sx={{
            my: 2,
            mx: 1,
            position: 'relative',
            boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
            minHeight: 70
          }}
        >
          <CardContent>
            <IconButton
              aria-label="search"
              onClick={() => navigate('/thoughts2', { state: { text: thought.text } })}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                width: 24,
                height: 24,
              }}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => navigate('/editThought', { state: { id: thought.id } })}
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                width: 24,
                height: 24,
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2" style={{ width: '100%' }}>
              {thought.text}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Fab
        color="primary"
        aria-label="add"
        onClick={addThought}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <AddIcon />
      </Fab>

    </div>
  );
}

export default SavedThoughts;
