import { FIRESTORE_DB } from "../firebaseConfig";
import { collection, doc, addDoc, setDoc, serverTimestamp, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { incrementThoughtShares, incrementThoughtSaves } from "../IncrementStats";

const checkThatThoughtIsUnique = async (inputValue) => {
    console.log("checkThatThoughtIsUnique entered with inputValue:", inputValue);
    const quotesRef = collection(FIRESTORE_DB, 'Quotes');
    const q = query(quotesRef, where("quote", "==", inputValue));
    const querySnapshot = await getDocs(q);
    const fetchedQuotes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log("Fetched quotes:", fetchedQuotes);
    if (fetchedQuotes.length > 0) {
        return false;
    }else{
        return true;
    }
}

function getMillisFromTimestamp(timestamp) {
    if (typeof timestamp === 'number') {
        return timestamp; // Already in milliseconds
    } else if (typeof timestamp === 'string') {
        return new Date(timestamp).getTime(); // Convert from ISO string to milliseconds
    } else {
        return Date.now(); // Fallback to current time
    }
}


const shareThought = async({
    optionalDocID,
    quote,
    createdAt,
    shareLocation,
    lat,
    lon,
    isProfile,
    thoughtNumber,
    user
}) => {
    try{
        let docID = optionalDocID;
        const quoteColRef = collection(FIRESTORE_DB, 'Quotes');
        const quoteDocRef = doc(quoteColRef);
        if (!docID) {
            docID = quoteDocRef.id.toString();
        }
        console.log("Using docID in shareThought of: ", docID)

    

        if (!quote || quote === "") {
            console.log("Thought input is empty");
            return { errorCode: 690, errorMessage: "Thought input is empty" };
        }
        if (!user) {
            console.log("User not found.");
            return { errorCode: 694, errorMessage: "User not found" };
        }
    
        const thoughtIsUnique = await checkThatThoughtIsUnique(quote);
        if (!thoughtIsUnique) {
            return { errorCode: 691, errorMessage: "Thought is not unique" };
        }

        const publicThought = {
            quote,
            createdAt: createdAt ? createdAt : serverTimestamp(),
            thoughtNumber: thoughtNumber,
            userID: user.isAnonymous ? "anonymous_user_id" : user.uid,
            displayName: user.isAnonymous ? "Anon" : user.displayName,
            profilePicURL: user.isAnonymous ? "" : user.photoURL,
        }
        const privateThought = {
            quote,
            createdAt: createdAt ? createdAt : serverTimestamp(),
            thoughtNumber: thoughtNumber,
            isVault: false,
            isPublic: true,
            isProfile: isProfile ? isProfile : false,
        }
        const profileThought = {
            isArchived: false,
            quote,
            createdAt: createdAt ? createdAt : serverTimestamp(),
            thoughtNumber,
        }
    

        // add to quotes (public) collection
        await setDoc(doc(FIRESTORE_DB, 'Quotes', docID), publicThought);
        // send to server to share thought
        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/share_thought', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quote: quote,
                seedID: docID,
                userID: user.uid,
                alsoUpsertPrivately: true, // always true so that the private embedding is kept up to date
                lat: shareLocation ? lat : null,
                lon: shareLocation ? lon : null,
                createdAtMs: createdAt ? getMillisFromTimestamp(createdAt) : Date.now(),
            }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // add to profile collection
        if (isProfile) {
            await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', docID), profileThought);
        }
        // add to private collection
        await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', docID), privateThought);

        incrementThoughtShares();


        return { errorCode: 0, errorMessage: "Success, thought shared: ", data, docID: docID };



    } catch (error) {
        console.error('Error during sharing thought:', error);
        return { errorCode: 700, errorMessage: "An error occurred while sharing the thought"};
    } finally {
        console.log("shareThought finished");
    }
}

const saveThought = async({
    optionalDocID,
    quote,
    createdAt,
    isVault,
    thoughtNumber,
    user
}) => {
    try{
        if (optionalDocID) {
            // deleteDoc(doc(FIRESTORE_DB, 'Quotes', optionalDocID));
            // deleteDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'Profile Quotes', optionalDocID));
        }


        let docID = optionalDocID;
        const quoteColRef = collection(FIRESTORE_DB, 'Quotes');
        const quoteDocRef = doc(quoteColRef);
        if (!docID) {
            docID = quoteDocRef.id.toString();
        }

        console.log("Using docID in saveThought of: ", docID)


        if (!quote || quote === "") {
            console.log("Thought input is empty");
            return { errorCode: 690, errorMessage: "Thought input is empty" };
        }
        if (!user) {
            console.log("User not found.");
            return { errorCode: 694, errorMessage: "User not found" };
        }

        const privateThought = {
            quote,
            createdAt: createdAt ? createdAt : serverTimestamp(),
            thoughtNumber,
            isVault: isVault,
            isPublic: false,
            isProfile: false,
        }

        // send to server to share thought
        const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/save_thought', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                quote: quote,
                seedID: docID,
                userID: user.uid,
            }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // add to private collection
        await setDoc(doc(FIRESTORE_DB, 'Users', user.uid, 'myThoughts', docID), privateThought);

        incrementThoughtSaves();

        return { errorCode: 0, errorMessage: "Success, thought saved: ", data, docID: docID };


    } catch (error) {
        console.error('Error during saving thought:', error);
        return { errorCode: 700, errorMessage: "An error occurred while saving the thought" };
    } finally {
        console.log("saveThought finished");
    }
}


export { shareThought, saveThought };