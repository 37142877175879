import React from 'react';
import { Box, Typography, Avatar, Divider } from '@mui/material';


const RenderAllThoughts = React.memo(({ results = [], theme, setOtherProfileFocusUID }) => {

    console.log('Results in RenderAllThoughts:', results, 'Type:', typeof results);
    return (
      <Box sx={{ paddingTop: 6, maxWidth: "100%", minWidth: "100%" }}>

        {results.map(data => (
          <div key={data.id} style={{ marginBottom: '2rem', paddingLeft: '20px', position: 'relative' }} onClick={() => setOtherProfileFocusUID(data.userID)}>
            {/* Uncomment if you want to use the IconButton again
            <IconButton
              aria-label="search"
              onClick={(event) => handleUseThought(event, data.quote)}
              sx={{ position: 'absolute', top: 8, right: 8, width: 24, height: 24 }}
            >
              <SearchIcon fontSize="small" />
            </IconButton> 
            */}
            <Typography variant="subtitle1" style={{ width: '90%', marginBottom: '8px', marginRight: '200px' }}>
              {data.quote}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mx: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                {data.profilePicURL && <Avatar src={data.profilePicURL} alt={data.displayName} />}
                <Typography variant="subtitle1" fontWeight="bold">
                  {data.displayName}
                </Typography>
                {data.createdAt && (
                  <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                    {new Date(data.createdAt.seconds * 1000).toLocaleDateString()}
                  </Typography>
                )}
                {data.geocode && (
                  <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                    {data.geocode}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider sx={{ mt: 2, backgroundColor: theme.palette.primary.main, opacity: '50%' }} />
          </div>
        ))}
      </Box>
    );
  });

  export default RenderAllThoughts;