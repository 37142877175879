import axios from 'axios';

async function checkString(inputString) {
    try {
        const response = await axios.post('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/moderate', { // old one: https://content-mod-eo4flzxgma-uc.a.run.app/moderate
            input: inputString
        });
        console.log(response.data);
        return true;
        if(response.data.isSafe){
            return true;
        } else {
            // return TRUE NO MATTER WHAT FIX THIS FIX THIS FIX THIS TODO TODO TODO
            return true;
        }
    } catch (error) {
        console.error(error);
    }
}

export default checkString;
