import React, { useEffect } from 'react';
import { Typography, Container } from '@mui/material';

const TermsConditions = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.websitepolicies.io/lib/embed/embed.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Terms & Conditions
      </Typography>
      <a className="wpembed-inline" href="https://app.websitepolicies.com/policies/view/4fpvluxd">View Terms and Conditions</a>
    </Container>
  );
};

export default TermsConditions;
