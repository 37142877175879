import { useEffect, useState } from "react";
import { getItem, setItem } from "./storage";
import { doc, getDoc } from "firebase/firestore";
import { FIRESTORE_DB, FIREBASE_AUTH } from "./firebaseConfig";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from './HomeScreen'
import { checkIfUserBlocked } from "./BlockedUsersHelper";
import { useNavigate } from "react-router-dom";
import SkewedButton from "./SkewedButton";

const CurrentPortal = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        seedID: "",
        quote: "",
        isAnonymous: "",
        // color: "",
        color1: "",
        color2: "",
        createdAt: "",
        allResults: ""
    });

    useEffect(() => {
        getResults(setData);
    }, []);

    return(
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '90%',
            maxWidth: 700,
            margin: '0 auto',
            padding: '1rem',
        }}>
        <Box>
            <Typography>
            <br/><br/>
                You opened a portal with the thought: "{data.quote}"
                <br/><br/>
                Click on the portal to enter the thought realm!
            </Typography>
            <PrimaryButton sx={{marginTop: '2rem'}} onClick={() => {navigate('/allResults')}}>
                Click to enter portal!
            </PrimaryButton>
            <Box
            sx={{
            width: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            marginLeft: '1rem',
            marginRight: '1rem',

            }}>
                <img src="/wizard-portal-trans.png" alt="Wizard Portal" style={{ maxWidth: '100%', maxHeight: '100%' }} onClick={() => navigate('/allResults')} />
            </Box>
        </Box>
        <Box>
            {/* <Typography variant="h6">
                SeedID: {data.seedID}
            </Typography>
            <Typography variant="body1">
                Quote: {data.quote}
            </Typography>
            <Typography variant="body1">
                Is Anonymous: {data.isAnonymous.toString()}
            </Typography>
            <Typography variant="body1">
                Color: {data.color}
            </Typography>
            <Typography variant="body1">
                Color1: {data.color1}
            </Typography>
            <Typography variant="body1">
                Color2: {data.color2}
            </Typography>
            <Typography variant="body1">
                Created At: {data.createdAt ? formatDate(data.createdAt) : ""}
            </Typography> */}
            {/* {data.allResults ? data.allResults.map(result => (
        <Typography key={result.id}>
            Quote: {result.quote} <br/>
            Name: {result.displayName} <br/>
            Color: {result.color} <br/>
            Color1: {result.color1} <br/>
            Color2: {result.color2} <br/>
            Created At: {formatDate(result.createdAt)} <br/>
        </Typography>
        )) : "Loading..."} */}
        {/* </Box> */}
        </Box>
        </Box>
    )
};

export default CurrentPortal;


const getResults = async (setData) => {
    const userProfileDocRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid);
    let allResults = await getItem('allResults');
    let seedID = await getItem('seedID');

    if (!allResults || !seedID){
        if (!seedID){
            await getDoc(userProfileDocRef).then((userDoc)=>{
                seedID = userDoc.data().lastResultsID;
                setItem('seedID', seedID);
            })
        }

        const quoteDocRef = doc(FIRESTORE_DB, "Quotes", seedID);
        await getDoc(quoteDocRef).then(async (quoteDoc)=>{
            const quoteData = quoteDoc.data();

            const quote = quoteData.quote;
            setItem('quote', quote);
            
            const isAnonymous = quoteData.displayName === 'Anonymous';
            setItem('isAnonymous', isAnonymous);

            // const color = quoteData.color;
            // setItem('color', color);

            const color1 = quoteData.color1;
            setItem('color1', color1);
            
            const color2 = quoteData.color2;
            setItem('color2', color2);
            
            const createdAt = quoteData.createdAt;
            const createdAtDate = createdAt.toDate();
            setItem('createdAt', createdAtDate.toJSON());

            console.log("SEED ID PASSED IN: ", seedID)
            const allResults = await fetchAndSaveAllResults(seedID);
            console.log("All Results 1", allResults);
            setItem('allResults', JSON.stringify(allResults));

            setData({
                seedID: seedID,
                quote: quote,
                isAnonymous: isAnonymous,
                // color: color,
                color1: color1,
                color2: color2,
                createdAt: createdAt,
                allResults: allResults
            });
        })
        
    } else {
        const quote = await getItem('quote');
        const isAnonymous = await getItem('isAnonymous');
        //const color = await getItem('color');
        const color1 = await getItem('color1');
        const color2 = await getItem('color2');
        seedID = await getItem('seedID');
        const createdAtString = await getItem('createdAt');
        const createdAt = new Date(createdAtString);
        const allResultsString = await getItem('allResults');
        const allResults = JSON.parse(allResultsString);
        console.log("All results 2", allResults);

        setData({
            seedID: seedID,
            quote: quote,
            isAnonymous: isAnonymous,
            //color: null,
            color1: color1,
            color2: color2,
            createdAt: createdAt,
            allResults: allResults
        });
    }
};

const formatDate = (date) => {
    if(!date) {
        return "";
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleString('en-US', options);
};

const fetchAndSaveAllResults = async (seedID) => {
    if (!seedID) {
      console.log('No seedID provided');
      return [];
    }

    const allResultsDocRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser?.uid, "ResultIDs", seedID);
    let allResultsDoc = await getDoc(allResultsDocRef);
    let retryCount = 0;
    while (!allResultsDoc.exists && retryCount < 50) {
      await new Promise(resolve => setTimeout(resolve, 100));
      allResultsDoc = await getDoc(allResultsDocRef);
      retryCount++;
    }

    if (!allResultsDoc.exists) {
      console.log('allResultsDoc document not found after retrying');
      return [];
    }

    const allResultIDs = allResultsDoc.data();
    const resultIDsArray = Object.values(allResultIDs);

    const fetchQuoteDocument = async (id) => {
      const docRefFetch = doc(FIRESTORE_DB, "Quotes", id);
      const quoteDoc = await getDoc(docRefFetch);

      if (quoteDoc.exists) {
        const quoteData = { id: quoteDoc.id, ...quoteDoc.data() };
        return quoteData;
      } else {
        console.log(`Quote document not found for id: ${id}`);
        return null;
      }
    };

    const fetchedQuoteDocs = await Promise.all(resultIDsArray.map(fetchQuoteDocument));

    console.log('Fetched results:', fetchedQuoteDocs);

    const uids = fetchedQuoteDocs.map((quote) => quote.userID);
    // Check for blocked users
    const blockedUserIDs = await checkIfUserBlocked(uids);

    // blockedUserIDs.push(FIREBASE_AUTH.currentUser.uid); // TODO CHANGE THIS BACK TO REMOVE SELF FRO RESULTS
    // Filter out blocked users and self
    const filteredResults = fetchedQuoteDocs.filter(
      (quote) => !blockedUserIDs.includes(quote.userID)
    );

    console.log("FILTERED RESULTS", filteredResults);
    return filteredResults;
    
  };