import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import checkString from './ContentModeration';
import { addDoc, collection, getDoc, setDoc, doc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { Box, Typography, TextField, IconButton, Slider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { setItem } from './storage';
import { checkIfUserBlocked } from './BlockedUsersHelper';
import LoadingComponent from './LoadingComponent'
import CostPopup from './CostPopup';
import removeCoinsFromUser from './RemoveCoinsFromUser'

const formatDate = (date) => {
    if (!date) {
        return "";
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleString('en-US', options);
};

export const fetchAndSaveAllResults = async (documentIDs = []) => {
    if (!documentIDs.length) {
        console.log('No document IDs provided');
        return [];
    }


    const fetchQuoteDocument = async (id) => {
        const docRefFetch = doc(FIRESTORE_DB, "Quotes", id);
        const quoteDoc = await getDoc(docRefFetch);

        if (quoteDoc.exists) {
            const quoteData = { id: quoteDoc.id, ...quoteDoc.data() };
            return quoteData;
        } else {
            console.log(`Quote document not found for id: ${id}`);
            return null;
        }
    };

    const fetchedQuoteDocs = await Promise.all(documentIDs.map(fetchQuoteDocument));

    console.log('Fetched results:', fetchedQuoteDocs);

    const uids = fetchedQuoteDocs.map((quote) => quote.userID);
    // Check for blocked users
    const blockedUserIDs = await checkIfUserBlocked(uids);

    // blockedUserIDs.push(FIREBASE_AUTH.currentUser.uid);
    // Filter out blocked users and self
    const filteredResults = fetchedQuoteDocs.filter(
        (quote) => !blockedUserIDs.includes(quote.userID)
    );

    console.log("FILTERED RESULTS", filteredResults);
    return filteredResults;
};

const ExploreThoughts = () => {
    const [text, setText] = useState('');
    const navigate = useNavigate();
    const [speech, setSpeech] = useState('');
    const user = FIREBASE_AUTH.currentUser;
    const statsRef = doc(FIRESTORE_DB, 'Users', user.uid, 'Stats', 'exploreWrites');
    const [loading, setLoading] = useState(false);

    const [cost, setCost] = useState(34);
    const [costPopupOpen, setCostPopupOpen] = useState(false);
    const handleOpen = () => {
        setCostPopupOpen(true);
    }
    const handleClose = () => {
        setCostPopupOpen(false);   
        setLoading(false);     
    } 

    const latitudeRef = useRef(null);
    const longitudeRef = useRef(null);
    const [sliderValue, setSliderValue] = useState(0); // Assuming the initial value is the middle point
    const sliderValueRef = useRef(0); // Ref for immediate access
    const sliderTexts = ["Searching based on similarity of thought only", "Searching within 2500km", "Searching within 600km", "Searching within 70km", "Searching within 20km", "Searching within 2km"];

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        sliderValueRef.current = newValue;

        if (newValue === 0){
            latitudeRef.current = null;
            longitudeRef.current = null;
        }else{
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const lat = position.coords.latitude;
                        const lon = position.coords.longitude;
                        latitudeRef.current = lat; // Update ref
                        longitudeRef.current = lon; // Update ref
                    },
                    (error) => {
                        console.error("Error obtaining location:", error);
                    }
                );
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        }

    };

    const handleInputChange = (event) => {
        const input = event.target.value;
        const wordCount = input.split(" ").length;
        if (input.length <= 1800 && wordCount <= 230) {
            setText(input);
        }
    };

    const handleTickClick = async () => {
        setLoading(true);
        setSpeech('');
        if (!user) {
            navigate("/login");
            setLoading(false);
            return;
        }
        // if thought text is empty, say, "the thought cannot be empty!", return
        if (text === "") {
            setSpeech("What thought? There's nothing there! Write something please...");
            setLoading(false);
            return;
        }
        if (text === "Type your thought here...") {
            setSpeech("Please type your own thought, in the bubble above. It could be anything: an idea for a song or artwork, a feeling, even a dream that you had, where you wonder if anyone else has thought of something similar!");
            setLoading(false);
            return;
        }


        // if thought is not accepted by content moderation, say, "Maybe I'm misunderstanding, but that thought doesn't seem appropriate. Please try something different", return
        const isAccepted = await checkString(text);
        if (!isAccepted) {
            setSpeech("Maybe I'm misunderstanding, but that thought doesn't seem appropriate. Please try something different, and make sure you're following our T&Cs");
            setLoading(false);
            return;
        }

        // Check to see if writes today are less than 50
        const statsSnapshot = await getDoc(statsRef);
        if (statsSnapshot.exists()) {
            const statsData = statsSnapshot.data();

            if (statsData.last_write_date.toDate().toDateString() !== new Date().toDateString()) {
                await setDoc(statsRef, {
                    quotes_writes: 0,
                    last_write_date: serverTimestamp()
                });
            } else if (statsData.quotes_writes >= 50) {
                // If it's the same day and the user has already written 50 quotes
                setSpeech("It seems like you've made enough searches for today. We aim for 50 searches per day for unpaid users. If you believe that you have not exceeded this limit, take it up with the guy who wrote it, elijahdecalmer@gmail.com")
                setLoading(false);
                return;
            }
        }

        handleOpen();

    };

    const handleContinue = async () => {
        handleClose();
        const userEntry = {
            createdAt: serverTimestamp(),
            text: text,
            type: "ExploreThoughts"
        };

        const searchObject = {
            createdAt: serverTimestamp(),
            text: text,
            type: "ExploreThoughts",
            userID: user.uid,
            displayName: user.displayName,
        }
        addDoc(collection(FIRESTORE_DB, 'ThoughtSearches'), searchObject);

        // Save to Firestore
        addDoc(collection(FIRESTORE_DB, 'Users', user.uid, 'History'), userEntry)
            .then(async (documentReference) => {
                console.log('DocumentSnapshot written with ID: ', documentReference.id);
                const seedID = documentReference.id;

                updateDoc(doc(FIRESTORE_DB, "Users", user.uid), {
                    lastExploreThoughtsID: seedID
                });


                // Increment counter by 1
                const statsSnapshot = await getDoc(statsRef);
                if (statsSnapshot.exists()) {
                    // Document exists, update it
                    await updateDoc(statsRef, {
                        quotes_writes: increment(1),
                        last_write_date: serverTimestamp()
                    });
                } else {
                    // Document doesn't exist, create it
                    await setDoc(statsRef, {
                        quotes_writes: 1,
                        last_write_date: serverTimestamp()
                    });
                }

                const asyncLocationCheck = async () => {
                    return new Promise((resolve, reject) => {
                        const startTime = Date.now();
                        if (sliderValueRef.current === 0) {
                            latitudeRef.current = null;
                            longitudeRef.current = null;
                            reject('Location not required'); // Reject the Promise with a specific message
                        } else if (Date.now() - startTime < 15000) {
                
                            // Define a function that will check for the latitude and longitude
                            const checkLocation = () => {
                                console.log("Entered checkLocation");
                                if (latitudeRef.current && longitudeRef.current) {
                                    // Latitude and longitude are set, resolve the Promise
                                    console.log("latitudeRef", latitudeRef.current);
                                    console.log("longitudeRef", longitudeRef.current);
                                    console.log("HWELL YEAH");
                                    resolve();
                                } else if (Date.now() - startTime < 15000) { // Continue checking for up to 15 seconds
                                    setTimeout(checkLocation, 25); // Check again in 25ms
                                } else {
                                    // 15 seconds has passed without getting the location
                                    setLoading(false);
                                    reject(); // Reject the Promise
                                }
                            };
                            
                            // Continue checking for up to 15 seconds
                            setTimeout(checkLocation, 25); // Check again in 25ms
                        } else {
                            // 15 seconds has passed without getting the location
                            setLoading(false);
                            reject('Location timeout'); // Reject the Promise with a specific message
                        }
                
                    });
                };
            
                try {
                    await asyncLocationCheck();
                    // Proceed with your code, since latitude and longitude are available
                    console.log("directly after waiting for asyncLocationCheck()");
                } catch (error) {
                    if (error === 'Location timeout') {
                        console.log('Location was not obtained within 15 seconds.');
                        setLoading(false);
                        return;
                    } else {
                        console.log('The checkbox was unchecked.');
                    }
                }

                // Send data to the Flask server
                fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/explore_thought', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        quote: userEntry.text,
                        lat: latitudeRef.current,
                        lon: longitudeRef.current,
                        precision: sliderValueRef.current,
                    }),
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        console.log('Data published:', data);
                        console.log("LINE 163", data.documentIDs);

                        const allResults = await fetchAndSaveAllResults(data);
                        // STORE IN LOCAL STORAGE
                        setItem('exploreThoughtsResults', JSON.stringify(allResults));
                        setItem('exploreThoughtsSeedID', seedID);
                        removeCoinsFromUser(cost);
                        navigate('/explorethoughtsresults');
                        setLoading(false);
                        return;
                    })
                    .catch((error) => {
                        console.error('Error publishing data:', error);
                        setSpeech('Quote not sent (URL ERROR). Please check your internet connection and try again.', { variant: 'error' });
                        setLoading(false);
                        return;
                    });

            })
            .catch((error) => {
                setSpeech('Quote not sent (FIREBASE ERROR). Please check your internet connection and try again. Please note that there is a 50 search limit daily.');
                setLoading(false);
            });

    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="calc(100vh-70px)"
            gap={2}
        >
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    placeholder="Your thought here..."
                    value={text}
                    onChange={handleInputChange}
                    multiline
                    maxRows={4}
                    sx={{
                        minWidth: 280,
                        fontSize: '20px',
                        boxShadow: '2px 2px 3px 1px rgba(0,0,0,0.15)',
                        borderRadius: 2, // set this as per your requirement
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent', // removes the border
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent', // removes the border on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // removes the border when the input is focused
                            },
                        },
                    }}
                />

                <Box maxWidth="50px" maxHeight="50px" display="flex" alignItems="center">
                    <IconButton
                        onClick={handleTickClick}
                        color="white"

                        sx={{
                            boxShadow: '2px 2px 3px 1px rgba(0,0,0,0.15)',
                            borderRadius: '5px',
                            backgroundColor: "#5CDB95", // PaleGreen color
                            '&:hover': {
                                backgroundColor: '#90EE90', // LightGreen color
                            },
                        }}
                    >
                        <CheckIcon fontSize="large" color="inherit" />
                    </IconButton>
                </Box>
            </Box>

            {speech &&
                <Box maxWidth="80%">
                    <Typography sx={{ color: 'red' }}>
                        ERROR: 
                    </Typography>
                    <Typography>
                        {speech}
                    </Typography>
                </Box>

            }
            { loading &&
                <LoadingComponent/>
            }
            <Box sx={{width: "90%", maxWidth: "800px"}}>
                <Slider
                defaultValue={sliderValue}
                step={1}
                marks
                min={0}
                max={5}
                valueLabelDisplay="off"
                onChange={handleSliderChange}
                />
                
            </Box>
            <Typography>
                {sliderTexts[sliderValue]}
                </Typography>
            <CostPopup
            isOpen={costPopupOpen}
            cost={cost}
            onClose={handleClose}
            onContinue={handleContinue}
            text="The quality of this platform relies on an abundance of new ideas and thoughts from people like you! To maintain the ratio of sharing thoughts to exploring them, this will cost "
        />
        </Box>
    );
};

export default ExploreThoughts;
