import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Card, CardContent, Typography, Avatar, Grid } from '@mui/material';
import { getItem } from './storage';
import { FIREBASE_AUTH } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';

const dummyQuote = {
  id: 'dummy',
  quote: '',
  displayName: '',
  profilePicURL: '',
};

const portalQuote = {
  id: 'portal',
  quote: '',
  displayName: '',
  profilePicURL: '',
  color: ""
};

const Square = React.forwardRef(({ quote, onClick, style, label }, ref) => {
  const squareStyles = {
    width: "400px",
    height: "300px",
    background: quote.color ? `linear-gradient(${quote.color}, ${quote.color})` : (quote.id === 'portal') ? `radial-gradient(circle, white 40%, light-blue 100%)` : `linear-gradient(45deg, ${quote.color1} 30%, ${quote.color2} 90%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    margin: '5px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    wordWrap: 'break-word',
    position: 'relative',
    clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)' // This creates a hexagon shape
  };

  const quoteStyles = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  };

  const labelStyles = {
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    borderRadius: '50%',
    width: '30px', // Add these
    height: '30px', // Add these
    display: 'flex', // Add these
    alignItems: 'center', // Add these
    justifyContent: 'center', // Add these
    fontSize: '0.8rem', // Adjust as needed
  };
  

  return (
    <div style={{ ...squareStyles, ...style}} onClick={(event) => onClick(event, quote)} ref={ref}>
      {quote.id === 'portal' ? 
      <Box sx={{ width: "50%"}}>
      <Typography variant="body2" style={{ ...quoteStyles, width: '100%', color: 'black' }}>
        Portal
      </Typography>
      </Box>
      :
      <Box sx={{ width: "50%"}}>
      <Typography variant="body2" style={{ ...quoteStyles, width: '100%', color: 'black' }}>
        {quote.quote}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
        {quote.profilePicURL && <Avatar src={quote.profilePicURL} alt={quote.displayName} />}
        
        <Typography variant="subtitle1" fontWeight="bold" sx={{ ml: 1, color: 'black' }}>
          {quote.displayName}
        </Typography>
      </Box>
      {label && (
        <Typography variant="caption" style={labelStyles}>
          #{label}
        </Typography>
      )}
      </Box>
    }
    </div>
  );
});





function AllResults() {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [centerPosition, setCenterPosition] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const transitionDuration = 770; // transition duration in ms

  const portalRef = useRef(null);
  

  useEffect(() => {
    async function fetchData() {
      console.log('Fetching data...');
      setLoading(true);
      
      const quote = await getItem('quote');
      console.log('Fetched quote:', quote);

      const isAnonymous = await getItem('isAnonymous');
      const color1 = await getItem('color1');
      const color2 = await getItem('color2');
      const seedID = await getItem('seedID');
      const createdAtString = await getItem('createdAt');
      const createdAt = new Date(createdAtString);
      const profilePicURL = isAnonymous ? '' : FIREBASE_AUTH.currentUser.photoURL;

      const userQuote = {
        quote: quote,
        isAnonymous: isAnonymous,
        color1: color1,
        color2: color2,
        id: seedID,
        createdAt: createdAt,
        profilePicURL: profilePicURL
      };
      console.log('Constructed userQuote:', userQuote);


      const allResultsString = await getItem('allResults');
      console.log('Fetched allResultsString:', allResultsString);
      let allResults;
      try {
        allResults = allResultsString ? JSON.parse(allResultsString) : [];
      } catch (e) {
        console.error('Error parsing allResults', e);
        allResults = [];
      }
      const validResults = allResults.filter(result => result !== null && result.quote);
      console.log('Constructed validResults:', validResults);


      const gridSize = Math.ceil(Math.sqrt(validResults.length));
      const totalSquares = gridSize * gridSize;
      const middleIndex = Math.floor(gridSize / 2);
  
      let direction = 'right';
      let steps = 1;
      let posX = middleIndex, posY = middleIndex;
      let quoteIndex = 0;
  
      let resultGrid = Array(gridSize).fill().map(() => Array(gridSize).fill({ quote: dummyQuote, label: null }));
      resultGrid[posX][posY] = { quote: portalQuote };
  
      console.log('Starting the grid filling process...');

  
      while (quoteIndex < validResults.length && steps < gridSize) {
        for (let i = 0; i < steps; i++) {
          console.log("LOOP CONDITIONS. i: ", i, "STEPS: ",  steps, "QUOTE INDEX: ", quoteIndex, "VALID RESULTS LENGTH", validResults.length);
          if (quoteIndex >= validResults.length) {
            console.log("Quote Index is greater than validResults.length");
            break;
          }
          switch (direction) {
            case 'right':
              posX++;
              break;
            case 'down':
              posY++;
              break;
            case 'left':
              posX--;
              break;
            case 'up':
              posY--;
              break;
            default:
              break;
          }
  
          // Check if the new position is within the grid and not already filled
          if (posX >= 0 && posY >= 0 && posX < gridSize && posY < gridSize && resultGrid[posX][posY].quote === dummyQuote) {
            resultGrid[posX][posY] = { quote: validResults[quoteIndex++], label: quoteIndex };
            console.log(`Updated cell [${posX}][${posY}] with quote ${resultGrid[posX][posY].quote.quote}`);

          }

        }
        console.log("Exited the while loop");
  
        // Rotate direction
        direction = direction === 'right' ? 'down' : direction === 'down' ? 'left' : direction === 'left' ? 'up' : 'right';

        // Only increment steps if a result was successfully placed in the current direction
        if ((direction === 'right' || direction === 'left') && quoteIndex > 0) {
          steps++;
        }

        // Don't increment steps if no result was placed in the current direction
        if (quoteIndex === 0) {
          steps--;
        }

      }
      console.log('Filled resultGrid:', resultGrid);
      console.log('Setting Results state with:', resultGrid.flat().map(({ quote, label }) => ({ ...quote, label })));

  
      setResults(resultGrid.flat().map(({ quote, label }) => ({ ...quote, label })));

      // Calculate the initial offset
      const squareWidth = 400; // The width and height of a square
      const squareHeight = 300;
      const portalSquarePosition = { x: middleIndex, y: middleIndex };
      const portalSquareCenter = {
        x: portalSquarePosition.x * squareWidth + squareWidth / 2,
        y: portalSquarePosition.y * squareHeight + squareHeight * 2
      };
      const initialOffset = {
        x: window.innerWidth / 2 - portalSquareCenter.x,
        y: window.innerHeight / 2 - portalSquareCenter.y
      };
      setOffset(initialOffset);

      setLoading(false);
      
      const handleResize = () => {
        setCenterPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && portalRef.current) {
      const rect = portalRef.current.getBoundingClientRect();
      const event = {
        currentTarget: {
          getBoundingClientRect: () => rect
        }
      };
      handleSquareClick(event, portalQuote);
    }
  }, [loading]);

  const handleSquareClick = (event, quote) => {
    if (isAnimating) return; // ignore clicks while animating
    setIsAnimating(true);  // start animating
  
    const rect = event.currentTarget.getBoundingClientRect();
    const squareCenterX = rect.left + rect.width / 2;
    const squareCenterY = rect.top + rect.height / 2;
  
    const offsetX = squareCenterX - centerPosition.x;
    const offsetY = squareCenterY - centerPosition.y;
  
    if(Math.abs(offsetX) < 1 &&  Math.abs(offsetY) < 1) {
      if(quote && quote.id === 'portal') {
        navigate('/currentportal');
        return;
      } else if (quote && quote.quote) {
        navigate('/otherprofilescreen', { state: { focusUID: quote.userID, title: quote.displayName } });
        return;
      }
    }
  
    setOffset(prevOffset => ({ x: prevOffset.x - offsetX, y: prevOffset.y - offsetY }));
  
    setTimeout(() => {
      setIsAnimating(false); // stop animating after transition duration
    }, transitionDuration);
  };
  

  const gridSize = Math.ceil(Math.sqrt(results.length));
  const totalSquares = gridSize * gridSize;
  const dummyQuotes = Array(totalSquares - results.length).fill(dummyQuote);
  const allQuotes = [...results, ...dummyQuotes];

  const calculateStyle = (quoteIndex) => {
    const row = Math.floor(quoteIndex / gridSize);
    const column = quoteIndex % gridSize;
    return (column % 2 === 0)
      ? { transform: `translateY(-155px)` }
      : {};
  };

  const containerStyles = {
    position: "absolute",
    // top: `calc(50% + ${offset.y}px)`,
    // left: `calc(50% + ${offset.x}px)`,
    // transform: "translate(-50%, -50%)",
    // transition: `top ${transitionDuration}ms ease-out, left ${transitionDuration}ms ease-out`,
    transform: `translate3d(calc(50% + ${offset.x}px), calc(50% + ${offset.y}px), 0)`,
    transition: `transform ${transitionDuration}ms ease-in-out`,
    display: "grid",
    gridTemplateColumns: `repeat(${gridSize}, 310px)`,  // Adjusted to accommodate for the margin and new size
    gridTemplateRows: `repeat(${gridSize}, 310px)`,  // Adjusted to accommodate for the margin and new size
    gap: "0px",
  };

  return (
    <GridContainer>
      <Box sx={{ flexGrow: 1, p: 2, backgroundColor: 'background.paper', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <div style={containerStyles}>
            {allQuotes.map((quote, quoteIndex) => (
              <Square
                key={`${quote.id}-${quoteIndex}`}
                quote={quote}
                onClick={handleSquareClick}
                onTouchStart={handleSquareClick}
                style={calculateStyle(quoteIndex)}
                label={quote.label}
                ref={quote.id === 'portal' ? portalRef : null} // Add this line
              />
            ))}
          </div>
        )}
      </Box>
    </GridContainer>
  );
}

export default AllResults;


const GridContainer = ({ children }) => {
  const containerStyle = {
    position: 'fixed', // This will make the container take the whole viewport
    top: '70px', // The height of your app bar
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  return (
    <div style={containerStyle}>
      {children}
    </div>
  );
};