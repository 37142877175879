import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, useTheme } from "@mui/material";
import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Timestamp } from 'firebase/firestore';
import ThoughtSelectorPopup from '../../InBothTabs/ThoughtSelectorPopup';

/*
PROBLEMS
- NONE

*/

export default function MyThoughtsSortAndFilter({
    sortSettings,
    filterSettings,
    setSortSettings,
    setFilterSettings,
    thoughtNumber,
    showSortAndFilter,
    setShowSortAndFilter,
    runSearch,
    searchTerm,
}) {
    const theme = useTheme();
    const [SFSselection, setSFSselection] = useState('sort');
    const [thoughtSelectorOpen, setThoughtSelectorOpen] = useState(false);



    const timestampToDate = (timestamp) => {
        // If no timestamp is provided, return null.
        if (!timestamp) {
            return null;
        }
        // If the timestamp does not have the toDate method, throw an error.
        if (typeof timestamp.toDate !== 'function') {
            throw new Error('Invalid timestamp provided. Expected an object with a toDate() method.');
        }
        // Convert the timestamp to a date.
        return timestamp.toDate();
    };
    
    const dateToTimestamp = (date) => {
        // If no date is provided, return null.
        if (!date) {
            return null;
        }
        // If the date is not an instance of the Date object, throw an error.
        if (!(date instanceof Date)) {
            throw new Error('Invalid date provided. Expected a JavaScript Date object.');
        }
        // If the Timestamp utility is not available or doesn't have the fromDate method, throw an error.
        if (!Timestamp || typeof Timestamp.fromDate !== 'function') {
            throw new Error('Timestamp utility is not available or does not have a fromDate() method.');
        }
        // Convert the date to a timestamp.
        return Timestamp.fromDate(date);
    };
    

    const limitOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 'All Thoughts'];
    const handleLimitChange = (event) => {
        setFilterSettings(prevState => ({
            ...prevState,
            limitToXResults: event.target.value,
        }));
    };


    const handleFromDateChange = (date) => {
        setFilterSettings(prevState => ({
            ...prevState,
            fromDate: dateToTimestamp(date),
            fromAll: false,
        }));
    };
    const handleToDateChange = (date) => {
        setFilterSettings(prevState => ({
            ...prevState,
            toDate: dateToTimestamp(date),
            toAll: false,
        }));
    };

    const handleSortChange = (event) => {
        setSortSettings(prevState => ({
            ...prevState,
            sortBy: event.target.name,
        }));
    };

    const handleFilterChange = (event) => {
        const { name, checked } = event.target;
        
        // If attempting to uncheck
        if (!checked && (name === 'isPublic' || name === 'isProfile' || name === 'isPublicFalse' || name === 'isVault')) {
            // Count the number of currently checked items in the filterSettings object
            // only count the fields of isPublic, isProfile, isPublicFalse, and isVault
            const checkedCount = Object.keys(filterSettings).reduce((count, key) => {
                if (key === 'isPublic' || key === 'isProfile' || key === 'isPublicFalse' || key === 'isVault') {
                    return filterSettings[key] ? count + 1 : count;
                }
                return count;
            }, 0);
            
            // If only one is checked, just return without updating
            if (checkedCount <= 1) return;
        }
    
        setFilterSettings({ ...filterSettings, [name]: checked });
    };
    


    const sortCheckBoxData = {
        searchSort: { title: "search", description: "Sort my thoughts based on whatever is in the search bar" },
        similaritySort: { title: "similarity", description: "Sort my thoughts based on similarity to:" },
        chronoSort: { title: "chronological", description: "Sort my thoughts chronologically" },
        reChronoSort: { title: "reverseChronological", description: "Sort my thoughts chronologically (REVERSE)" },
        lengthSort: { title: "length", description: "Sort my thoughts by length" },
        reLengthSort: { title: "reverseLength", description: "Sort my thoughts by length (REVERSE)" },
        randomSort: { title: "random", description: "Sort my thoughts randomly" },
    };

    const filterCheckBoxData = {
        isPublic: { description: "Show 'Shared' thoughts" },
        isProfile: { description: "Show 'Profile' thoughts" },
        isPublicFalse: { description: "Show 'Saved' thoughts" },
        isVault: { description: "Show 'Vault' thoughts" },
    };


    const backgroundStyle = (stateVariable, borderColor) => {
        return {
            position: 'absolute',
            height: '100%',
            border: '1px solid',
            borderColor: borderColor,
            backgroundColor: 'transparent', // Adjust the color as needed
            transition: '0.3s',
            left: stateVariable ? '50%' : '0%', // Position change based on the state
            width: '50%', // Fixed width
            borderTopLeftRadius: stateVariable ? '0' : '10px', // Adjust the radius as needed
            borderBottomLeftRadius: stateVariable ? '0' : '10px',
            borderTopRightRadius: stateVariable ? '10px' : '0',
            borderBottomRightRadius: stateVariable ? '10px' : '0',
        };
    };

    const selectStyle = {
        width: '140px', // Adjust width as needed
        height: '35px', // Adjust height as needed
        fontSize: '16px', // Adjust font size as needed
        marginX: '10px', // Adjust padding as needed
        borderRadius: '5px', // Adjust border radius for rounded corners
        border: '1px solid #ccc' // Adjust border as needed
    };

    // handle sort button
    const handleSortAndFilterButton = () => {
        setShowSortAndFilter(false);
        runSearch();
    }

    const setSortThought = (thought) => {
        setSortSettings(prevState => ({
            ...prevState,
            sortThought: thought,
        }));
    }

    useEffect(() => {
        console.log("Entered useEffect in MyThoughtsSortAndFilter");

        if (searchTerm.startsWith('#')) {
            switch (searchTerm) {
                case '#recent':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'similaritySort',
                        sortThought: 'Most recent thought (auto)',
                    }));
                    break;
                case '#chronoSort':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'chronoSort',
                    }));
                    break;
                case '#reChronoSort':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'reChronoSort',
                    }));
                    break;
                case '#lengthSort':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'lengthSort',
                    }));
                    break;
                case '#reLengthSort':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'reLengthSort',
                    }));
                    break;
                case '#randomSort':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'randomSort',
                    }));
                    break;
                case '#':
                    setSortSettings(prevState => ({
                        ...prevState,
                        sortBy: 'searchSort',
                    }));
                    break;
                default:
                    // if it is #x where x is a number,
                    // set sortBy to similaritySort
                    // set sortThought to the 'Thought ' + x
                    const number = searchTerm.slice(1);
                    if (!isNaN(number)) {
                        if (number){
                            setSortSettings(prevState => ({
                                ...prevState,
                                sortBy: 'similaritySort',
                                sortThought: 'Thought ' + number,
                            }));
                        }

                    }
                    break;
            }
        } else {
            if (sortSettings.sortBy != 'searchSort'){
                setSortSettings(prevState => ({
                    ...prevState,
                    sortBy: 'searchSort',
                }));
            }
        }

    }, [searchTerm]);



    const SortAndFilterButton = () => {

        return(
                <Button
                variant="contained"
                color="secondary"
                onClick={() => {handleSortAndFilterButton()}}
                sx={{
                    color: 'primary.main',
                    marginLeft: 'auto',
                    backgroundColor: 'transparent',
                    textTransform: 'none',
                    textDecoration: 'none',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    boxShadow: 'none',
                    borderRadius: 0,
                    border: '1px solid',
                    borderColor: 'primary.main',
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: 'primary.main',
                        textDecoration: 'none',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: 'secondary.main',
                        color: 'primary.main',
                        boxShadow: 'none',
                    },
                    '&:focus': {
                        backgroundColor: 'secondary.main',
                        color: 'primary.main',
                        boxShadow: 'none',
                    },
                }}
            >
                Sort & Filter
            </Button>
        );
    }

    return (

        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',

        }}>
            {showSortAndFilter && (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            marginTop: '10px',
            borderRadius: '10px',
            boxShadow: 2,
            border: '1px solid',
            borderColor: 'primary.main',

        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: (SFSselection == 'none') ? '0px' : '10px',

                }}>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        if (SFSselection === 'sort') {
                            setSFSselection('none');
                        } else {
                            setSFSselection('sort')
                        }
                    }}
                    sx={{
                        color: 'primary.main',
                        backgroundColor: 'transparent', // Set background to transparent
                        textTransform: 'none',
                        textDecoration: SFSselection === 'sort' ? 'underline' : 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        height: '100%',
                        boxShadow: 'none', // Remove shadow
                        borderRadius: 0, // Remove rounded corners
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            textDecoration: SFSselection === 'sort' ? 'underline' : 'none',
                            boxShadow: 'none', // Remove shadow on hover
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                        '&:focus': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                    }}
                >
                    Sort
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                        if (SFSselection === 'filter') {
                            setSFSselection('none');
                        } else {
                            setSFSselection('filter')
                        }
                    }}
                    sx={{
                        color: 'primary.main',
                        backgroundColor: 'transparent', // Set background to transparent
                        textTransform: 'none',
                        textDecoration: SFSselection === 'filter' ? 'underline' : 'none',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        height: '100%',
                        boxShadow: 'none', // Remove shadow
                        borderRadius: 0, // Remove rounded corners
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            textDecoration: SFSselection === 'filter' ? 'underline' : 'none',
                            boxShadow: 'none', // Remove shadow on hover
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                        '&:focus': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            boxShadow: 'none',
                        },
                    }}
                >
                    Filter
                </Button>

            </Box>
            <Box sx={{
                width: '100%',
                paddingX: '20px',
                marginTop: (SFSselection == 'none') ? '0px' : '10px',
            }}>
                {SFSselection === 'sort' && (
                    <div>
                    {Object.entries(sortCheckBoxData).map(([key, { title, description }]) => (
                        <div key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sortSettings.sortBy === key}
                                            onChange={handleSortChange}
                                            name={key}
                                        />
                                    }
                                    label={description}
                                />
                            </div>
                            {key === 'similaritySort' && (
                                <Button variant="contained" sx={{ textTransform: 'none' }} onClick={() => { setThoughtSelectorOpen(true) }}>
                                    {sortSettings.sortThought}
                                </Button>
                            )}
                        </div>
                    ))}
                    <Box
                        sx={{
                            display: 'flex', // Ensures the buttons are in a flex row
                            justifyContent: 'space-between', // Spaces out the buttons
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '10px',
                            marginBottom: '20px',
                        }}
                    >

                        <SortAndFilterButton/>
                    </Box>
                    </div>
                )}
                <div>
                    {SFSselection === 'filter' && (
                        <div>
                            {Object.entries(filterCheckBoxData).map(([key, { title, description }]) => (
                                <div key={key} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={filterSettings[key]}
                                                    onChange={handleFilterChange}
                                                    name={key}
                                                />
                                            }
                                            label={description}
                                        />
                                    </div>
                                </div>
                            ))}

                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '20px' }}>

                                {/* Div for "Start date" typography */}
                                <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>
                                    <Typography>Start date:</Typography>
                                </div>

                                {/* Div for DatePicker, Button, and background style */}
                                <div style={{ position: 'relative', flex: 1, display: 'flex' }}>

                                    {/* Background style div */}
                                    <div style={backgroundStyle(filterSettings.fromAll, theme.palette.primary.main)}></div>

                                    {/* Div for DatePicker, centered within its half */}
                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '5px' }}>
                                        <DatePicker
                                            selected={timestampToDate(filterSettings.fromDate)}
                                            onChange={handleFromDateChange}
                                            onTap={() => setFilterSettings(prevState => ({ ...prevState, fromAll: false }))}
                                        />
                                    </div>

                                    {/* Div for Button, centered within its half */}
                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '5px' }}>
                                        <Button
                                            sx={{
                                                textTransform: 'none',
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                                boxShadow: 'none',
                                            }}
                                            onClick={() => setFilterSettings(prevState => ({ ...prevState, fromAll: true }))}
                                        >
                                            No Start Date
                                        </Button>
                                    </div>
                                </div>

                            </div>




                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '10px' }}>

                                {/* Div for "Start date" typography */}
                                <div style={{ width: '90px', display: 'flex', alignItems: 'center' }}>
                                    <Typography>End date:</Typography>
                                </div>

                                {/* Div for DatePicker, Button, and background style */}
                                <div style={{ position: 'relative', flex: 1, display: 'flex' }}>

                                    {/* Background style div */}
                                    <div style={backgroundStyle(filterSettings.toAll, theme.palette.primary.main)}></div>

                                    {/* Div for DatePicker, centered within its half */}
                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '5px' }}>
                                        <DatePicker
                                            selected={timestampToDate(filterSettings.toDate)}
                                            onChange={handleToDateChange}
                                            onTap={() => setFilterSettings(prevState => ({ ...prevState, toAll: false }))}
                                        />
                                    </div>

                                    {/* Div for Button, centered within its half */}
                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '5px' }}>
                                        <Button
                                            sx={{
                                                textTransform: 'none',
                                                fontSize: '1rem',
                                                fontWeight: 'bold',
                                                boxShadow: 'none',
                                            }}
                                            onClick={() => setFilterSettings(prevState => ({ ...prevState, toAll: true }))}
                                        >
                                            No End Date
                                        </Button>
                                    </div>
                                </div>

                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between', // Changed to space-between
                                width: '100%',
                                marginTop: '20px',
                                marginBottom: '20px',
                            }}>
                                <Typography>Limit number of results: </Typography>
                                <select value={filterSettings.limitToXResults} onChange={handleLimitChange} style={selectStyle}>
                                    {limitOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <Box
                        sx={{
                            display: 'flex', // Ensures the buttons are in a flex row
                            justifyContent: 'space-between', // Spaces out the buttons
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '10px',
                            marginBottom: '20px',
                        }}
                    >
                        <SortAndFilterButton/>
                    </Box>


                        </div>
                    )}




                </div>

            </Box>
            {thoughtSelectorOpen && (
                <ThoughtSelectorPopup
                    numberOfThoughts={thoughtNumber}
                    setSortThought={setSortThought}
                    setOpen={setThoughtSelectorOpen}
                />
            )}
        </Box>
        )}
    </Box>

    );
}