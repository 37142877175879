import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, IconButton, Menu, MenuItem, Typography, Modal, styled } from '@mui/material';
import { Slider as MuiSlider } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SaveIcon from '@mui/icons-material/Save';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MicIcon from '@mui/icons-material/Mic';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'; // Sparkle icon
import { getStorage, ref, uploadBytes, getDownloadURL, getMetadata } from 'firebase/storage';
import { FIREBASE_AUTH } from '../../../firebaseConfig';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dictaphone from './Dictaphone';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import pica from 'pica';



const VerticalSlider = styled(MuiSlider)({
  height: 200,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backdropFilter: 'blur(3px)', // Optional: Apply a blur effect to the backdrop
});

const AutoResizeTextarea = ({ value, onChange }) => {
  const textareaRef = React.useRef(null);

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit'; // Reset height to recalculate
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);


  const handleInput = (e) => {
    e.target.style.height = 'inherit'; // Reset height to recalculate
    e.target.style.height = `${e.target.scrollHeight}px`; // Set new height
    onChange(e); // Call the passed onChange function
  };

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onInput={handleInput}
      onChange={onChange}
      style={{
        flexGrow: 1,
        height: 'auto',
        overflowY: 'hidden', // Prevent scrollbar
        resize: 'none', // Optional: prevent manual resizing
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '5px',
        marginBottom: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',
        fontSize: '1.25rem'
      }}
    />
  );
};


class Page {
  constructor({ bookId, imgFile, imageUrl, text = "" }) {
    this.id = null; // To be set when saved to Firestore
    this.bookId = bookId;
    this.imgFile = imgFile;
    this.imageUrl = imageUrl; // Used if you have an existing image URL
    this.text = text;
    this.isSaved = false;
    this.downloadURL = null;
    this.temporaryUrl = imgFile ? URL.createObjectURL(imgFile) : null; // Create a temporary URL
  }


  async getTextViaOCR() {
    try {
      const response = await fetch('https://node-matcher-hfi-eo4flzxgma-uc.a.run.app/extract_text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uri: this.downloadURL }), // Adjusted to send an object
      });

      if (!response.ok) {
        throw new Error(`Server responded with an error: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();


      // Step 1: Replace newlines with spaces, then trim leading and trailing whitespace
      this.text = result.extractedText
        .replace(/\r?\n|\r/g, ' ') // Replace all forms of line breaks with spaces.
        .replace(/\\/g, '') // Remove all backslashes.
        .trim(); // Trim leading and trailing whitespace.

      // Step 2: Check and trim quotes from the start and end if they exist
      if (this.text.startsWith('"') && this.text.endsWith('"')) {
        this.text = this.text.slice(1, -1).trim(); // Trim the quotes and any spaces that might follow the opening quote or precede the closing quote
      }

    } catch (error) {
      console.error('Error fetching OCR data:', error);
      // alert('Error: ' + error.message);
      throw new Error('OCR processing failed: ' + error.message);
    }
  }


  async saveToFirebaseStorage() {
    const storage = getStorage();
    const fileName = this.imgFile.name;
    const storageRef = ref(storage, `textExtractionImages/${fileName}`);

    try {
      await uploadBytes(storageRef, this.imgFile);
      const downloadURL = await getDownloadURL(storageRef);
      this.downloadURL = downloadURL;
    } catch (error) {
      throw error;
    }
  }
}

const NoteScannerTab = ({ saveThought, shareThought, thoughtNumber }) => {
  const [thoughts, setThoughts] = useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentThoughtIndex, setCurrentThoughtIndex] = useState(null);
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [isSplitting, setIsSplitting] = useState(false);
  const [splitPosition, setSplitPosition] = useState(50);
  const [displayedImageHeight, setDisplayedImageHeight] = useState(300); // Default height, adjust as needed
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDividerIndex, setSelectedDividerIndex] = useState(null); // To keep track of which divider's menu is open
  const [isSplittingLoading, setIsSplittingLoading] = useState(false);
  const user = FIREBASE_AUTH.currentUser;


  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedDividerIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function reencodeImage(file) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;

            // Use pica to resize the image if needed
            const picaInstance = pica();
            picaInstance.resize(img, canvas, {
                unsharpAmount: 80,
                unsharpRadius: 0.6,
                unsharpThreshold: 2
            })
            .then(resultCanvas => picaInstance.toBlob(resultCanvas, 'image/jpeg', 0.90))
            .then(blob => {
                const reencodedFile = new File([blob], file.name, {
                    type: 'image/jpeg',
                    lastModified: Date.now(),
                });
                resolve(reencodedFile);
            })
            .catch(reject);
        };
        img.onerror = reject;
    });
}

  const LoadingOverlay = ({ isLoading, isSaved, thoughtNumber }) => {
    if (isLoading) {
      return (<div className="overlay">
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '40px'
        }}>
          <CircularProgress />
        </Box>

      </div>);
    } else if (isSaved) {
      return (<div className="overlay success">
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '40px',
          gap: '1'
        }}>
          <CheckCircleIcon color="success" />
          <Typography>Thought #{thoughtNumber} saved successfully!</Typography>
        </Box>
      </div>);
    }
    return null;
  };


  useEffect(() => {
    // Check if the thoughts array is empty or the last thought is not empty
    if (thoughts.length === 0 || thoughts[thoughts.length - 1].pages.length > 0 || thoughts[thoughts.length - 1].totalText !== '') {
      setThoughts([...thoughts, { pages: [], totalText: "", isLoading: false, isSaved: false }]);
    }
  }, [thoughts]);

  useEffect(() => {
    console.log("THOUGHTS: ", thoughts);
  }, [thoughts]);

const handleImageUpload = async (event, thoughtIndex = null, pageIndex = null) => {
  const file = event.target.files[0];
  if (!file) {
      console.error("No file selected");
      return;
  }

  const newPage = new Page({ bookId: 'uniqueBookId', imgFile: file });

  try {
      //const reencodedFile = await reencodeImage(file);


      setThoughts((currentThoughts) => currentThoughts.map((thought, index) => {
          if (index === thoughtIndex) {
              const newPages = pageIndex !== null ? [
                  ...thought.pages.slice(0, pageIndex + 1),
                  newPage,
                  ...thought.pages.slice(pageIndex + 1)
              ] : [...thought.pages, newPage];

              return {
                  ...thought,
                  pages: newPages,
                  totalText: newPages.length === 1 ? "Interpreting text from image provided..." : "Recalculating text from images...",
              };
          }
          return thought;
      }));

      await newPage.saveToFirebaseStorage();

      await newPage.getTextViaOCR();

      setThoughts(currentThoughts => {
        return currentThoughts.map((thought, index) => {
          if (index === thoughtIndex) {
            // Update the specific page's text within the thought
            const updatedPages = thought.pages.map(page => {
              if (page.temporaryUrl === newPage.temporaryUrl) {
                return newPage; // Update with OCR text and downloadURL
              }
              return page;
            });

            // Recalculate "Total Text" by aggregating text from all pages
            const totalText = updatedPages.map(page => page.text).join("\n");

            return { ...thought, pages: updatedPages, totalText: totalText || "Text extraction failed, please try again." };
          }
          return thought;
        });
      });
  } catch (error) {
      console.error("Error handling the image upload:", error);
      try {
        const reencodedFile = await reencodeImage(file);
        newPage.imgFile = reencodedFile; // Update the Page object with the reencoded file
        await newPage.saveToFirebaseStorage(); // Re-upload
        await newPage.getTextViaOCR();
        setThoughts(currentThoughts => {
          return currentThoughts.map((thought, index) => {
            if (index === thoughtIndex) {
              // Update the specific page's text within the thought
              const updatedPages = thought.pages.map(page => {
                if (page.temporaryUrl === newPage.temporaryUrl) {
                  return newPage; // Update with OCR text and downloadURL
                }
                return page;
              });

              // Recalculate "Total Text" by aggregating text from all pages
              const totalText = updatedPages.map(page => page.text).join("\n");

              return { ...thought, pages: updatedPages, totalText: totalText || "Text extraction failed, please try again." };
            }
            return thought;
          });
        });


      } catch {
        alert('Error: ' + error.message);
      }
  }

  // Reset the file input
  event.target.value = '';
};



  const handleAddPageBelow = () => {
    // Create a new file input dynamically
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.onchange = (event) => {
      // Invoke handleImageUpload with the currentThoughtIndex and currentPageIndex
      // This will insert the new page right after the current page
      handleImageUpload(event, currentThoughtIndex, currentPageIndex);
    };

    // Trigger the file input dialog
    fileInput.click();
  };


  const handleClickMenu = (event, thoughtIndex, pageIndex) => {
    setMenuAnchorEl(event.currentTarget);
    setCurrentThoughtIndex(thoughtIndex);
    setCurrentPageIndex(pageIndex);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleRemovePage = () => {
    setThoughts(currentThoughts => {
      const updatedThoughts = [...currentThoughts];
      updatedThoughts[currentThoughtIndex].pages.splice(currentPageIndex, 1);
      if (updatedThoughts[currentThoughtIndex].pages.length === 0) {
        updatedThoughts.splice(currentThoughtIndex, 1);
      }
      return updatedThoughts;
    });
    handleCloseMenu();
  };



  const handleTotalTextChange = (event, thoughtIndex) => {
    const newText = event.target.value;
    setThoughts(currentThoughts => {
      return currentThoughts.map((thought, index) => {
        if (index === thoughtIndex) {
          return { ...thought, totalText: newText };
        }
        return thought;
      });
    });
  };

  const mergeAdjacentThoughts = (dividerIndex) => {
    setThoughts((currentThoughts) => {
      if (dividerIndex >= currentThoughts.length) {
        console.error("Invalid divider index for merging thoughts.");
        return currentThoughts;
      }

      // Combine pages from the thoughts at dividerIndex - 1 and dividerIndex
      const combinedPages = [
        ...currentThoughts[dividerIndex].pages,
        ...currentThoughts[dividerIndex + 1].pages,
      ];

      // Combine totalText from both thoughts, ensuring to add a newline character in between
      const combinedTotalText = currentThoughts[dividerIndex].totalText + "\n" + currentThoughts[dividerIndex + 1].totalText;

      // Create a new thought with combined pages and text
      const newThought = {
        pages: combinedPages,
        totalText: combinedTotalText,
      };

      // Remove the two old thoughts and insert the new merged thought in their place
      const newThoughts = [
        ...currentThoughts.slice(0, dividerIndex),
        newThought,
        ...currentThoughts.slice(dividerIndex + 2),
      ];

      return newThoughts;
    });
  };


  const initiateSplitImage = (thoughtIndex, pageIndex) => {
    setCurrentThoughtIndex(thoughtIndex);
    setCurrentPageIndex(pageIndex);
    setIsSplitting(true);
  };

  const handleSliderChange = (_, newValue) => {
    setSplitPosition(100 - newValue);
  };

  const performSplitImage = async () => {
    setIsSplittingLoading(true); // Start loading

    if (currentThoughtIndex !== null && currentPageIndex !== null && thoughts[currentThoughtIndex] && thoughts[currentThoughtIndex].pages[currentPageIndex]) {
      const currentThought = thoughts[currentThoughtIndex];
      const currentPage = currentThought.pages[currentPageIndex];

      // Assuming currentPage contains an imgFile or a downloadURL to work with
      const imageUrl = currentPage.downloadURL || currentPage.temporaryUrl;
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const img = new Image();
      const url = URL.createObjectURL(blob);
      img.src = url;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);
        const splitY = (splitPosition / 100) * img.height;

        // Creating top part of the image
        const topCanvas = document.createElement('canvas');
        topCanvas.width = img.width;
        topCanvas.height = splitY;
        const topCtx = topCanvas.getContext('2d');
        topCtx.drawImage(img, 0, 0, img.width, splitY, 0, 0, img.width, splitY);

        // Creating bottom part of the image
        const bottomCanvas = document.createElement('canvas');
        bottomCanvas.width = img.width;
        bottomCanvas.height = img.height - splitY;
        const bottomCtx = bottomCanvas.getContext('2d');
        bottomCtx.drawImage(img, 0, splitY, img.width, img.height - splitY, 0, 0, img.width, img.height - splitY);

        // Convert canvases to blobs, then to File objects for uploading
        const topBlob = await new Promise(resolve => topCanvas.toBlob(resolve, 'image/jpeg'));
        const bottomBlob = await new Promise(resolve => bottomCanvas.toBlob(resolve, 'image/jpeg'));

        const topFile = new File([topBlob], `top_${currentPage.imgFile.name}`, { type: 'image/jpeg' });
        const bottomFile = new File([bottomBlob], `bottom_${currentPage.imgFile.name}`, { type: 'image/jpeg' });

        // Create new Page instances for the top and bottom halves
        const topPage = new Page({ bookId: currentPage.bookId, imgFile: topFile });
        const bottomPage = new Page({ bookId: currentPage.bookId, imgFile: bottomFile });

        // Save the top and bottom halves to Firebase and get the text via OCR
        await Promise.all([topPage.saveToFirebaseStorage(), bottomPage.saveToFirebaseStorage()]);
        await Promise.all([topPage.getTextViaOCR(), bottomPage.getTextViaOCR()]);

        setThoughts(currentThoughts => {
          let updatedThoughts = [...currentThoughts];

          // Update the original thought with the top half
          updatedThoughts[currentThoughtIndex].pages[currentPageIndex] = topPage;


          // If there are pages after the current one, move them to a new thought
          const pagesAfterSplit = currentThought.pages.slice(currentPageIndex + 1);

          // Create a new thought starting with the bottom half
          const newThought = {
            pages: [bottomPage, ...pagesAfterSplit],
            totalText: bottomPage.text + pagesAfterSplit.map(page => page.text).join("\n")
          };

          // Remove the following thoughts
          updatedThoughts[currentThoughtIndex].pages = updatedThoughts[currentThoughtIndex].pages.slice(0, currentPageIndex + 1);


          // Recalculate total text for the original thought
          const originalThoughtText = updatedThoughts[currentThoughtIndex].pages.map(page => page.text).join("\n");
          updatedThoughts[currentThoughtIndex].totalText = originalThoughtText;

          // Insert the new thought into the array
          updatedThoughts.splice(currentThoughtIndex + 1, 0, newThought);

          return updatedThoughts;
        });

        setIsSplitting(false); // Close the splitting UI
        setIsSplittingLoading(false); // Stop loading

      };
    }
  };

  const renderSplitControl = () => {
    const currentThought = thoughts[currentThoughtIndex];
    const currentPage = currentThought?.pages[currentPageIndex];
    const imageSrc = currentPage?.downloadURL || currentPage?.temporaryUrl;

    return (
      <StyledModal
        open={isSplitting}
        onClose={() => setIsSplitting(false)}
        aria-labelledby="split-modal-title"
        aria-describedby="split-modal-description"
      >
        <Card sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
        }}>
          <Typography id="split-modal-title" variant="h6" component="h2">
            Adjust Split Position
          </Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}>
            <VerticalSlider
              orientation="vertical"
              value={100 - splitPosition}
              onChange={handleSliderChange}
              aria-label="Split position"
              defaultValue={50}
              sx={{ height: `${displayedImageHeight}px`, mx: 2 }}
            />
            <Box sx={{
              maxHeight: '80vh',
              maxWidth: '60vw',
              overflow: 'hidden',
              position: 'relative',
              minWidth: '250px',
            }}>
              <img
                src={imageSrc}
                alt="Split preview"
                onLoad={(e) => {
                  const imgElement = e.target;
                  const aspectRatio = imgElement.naturalWidth / imgElement.naturalHeight;
                  const modalMaxHeight = window.innerHeight * 0.8;
                  let displayedHeight = imgElement.naturalHeight > modalMaxHeight ? modalMaxHeight : imgElement.naturalHeight;
                  if (window.innerWidth * 0.6 < displayedHeight * aspectRatio) {
                    displayedHeight = window.innerWidth * 0.6 / aspectRatio;
                  }
                  setDisplayedImageHeight(displayedHeight);
                }}
                style={{ maxHeight: '80vh', maxWidth: '60vw', height: 'auto', width: 'auto', objectFit: 'contain' }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: `${splitPosition}%`,
                  left: 0,
                  right: 0,
                  height: '2px',
                  backgroundColor: 'red',
                  transform: 'translateY(-50%)',
                }}
              />
            </Box>
          </Box>
          {isSplittingLoading ? (
            <CircularProgress sx={{ mt: 2 }} />
          ) : (
            <Button
              variant="contained"
              onClick={performSplitImage}
              sx={{ mt: 2 }}
            >
              Confirm Split
            </Button>
          )}
        </Card>
      </StyledModal>
    );
  };

  const saveThoughtProcess = async (thoughtIndex) => {
    console.log("Thgouth index, ", thoughtIndex);
    if (thoughtIndex < 0 || thoughtIndex >= thoughts.length) {
      console.error("Invalid thoughtIndex passed to handleSaveThought");
      return 0;
    }

    const thoughtToSave = thoughts[thoughtIndex];

    console.log("Thought to save: ", thoughtToSave);

    if (thoughtToSave.totalText == "Interpreting text from image provided..." ||
      thoughtToSave.totalText == "Recalculating text from images...") {
      return 0;
    }

    await saveThought(thoughtToSave.totalText);



    return 1;
  };

  const handleSaveThought = async (thoughtIndex) => {
    // Set loading state
    updateThoughtState(thoughtIndex, { isLoading: true });

    try {
      // Simulate saving process
      await saveThoughtProcess(thoughtIndex); // Your existing save logic here
      // Set saved state
      updateThoughtState(thoughtIndex, { isLoading: false, isSaved: true });
      // Automatically hide the success message after 5 seconds
      setTimeout(() => {
        updateThoughtState(thoughtIndex, { isSaved: false });
        setThoughts((currentThoughts) => {
          return currentThoughts.filter((_, index) => index !== thoughtIndex);
        });
      }, 5000);
    } catch (error) {
      // Handle save error
      updateThoughtState(thoughtIndex, { isLoading: false });
      console.error("Error saving the thought: ", error);
    }
  };

  const updateThoughtState = (thoughtIndex, newState) => {
    setThoughts(currentThoughts => currentThoughts.map((thought, index) => index === thoughtIndex ? { ...thought, ...newState } : thought));
  };

  // function NextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "gray", borderRadius: "50%" }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  // function PrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "gray", borderRadius: "50%" }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          ...style,
          display: "block",
          position: "absolute",
          top: "50%",
          right: "-25px", // Adjust based on your design
          transform: "translate(0, -50%)",
          cursor: "pointer",
          height: "20px",
          width: "20px",
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          ...style,
          display: "block",
          position: "absolute",
          top: "50%",
          left: "-25px", // Adjust based on your design
          transform: "translate(0, -50%)",
          cursor: "pointer",
          height: "20px",
          width: "20px",
        }}
        onClick={onClick}
      >
        <ArrowBackIosIcon />
      </div>
    );
  }




  const sliderSettings = {
    dots: true,
    infinite: false, // Consider setting to false if you don't want to loop back to the first image after the last one.
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true
  };

  const updateThoughtTextFromDictaphone = (newText, thoughtIndex) => {
    // Update the totalText of the thought at thoughtIndex with newText
    setThoughts(currentThoughts => {
      return currentThoughts.map((thought, index) => {
        if (index === thoughtIndex) {
          return { ...thought, totalText: thought.totalText + newText };
        }
        return thought;
      });
    });
  };




  const renderThoughts = () => {
    return thoughts.map((thought, thoughtIndex) => (
      <Box key={thoughtIndex} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
        <Card elevation={5} sx={{ width: '100%', maxWidth: '95%', margin: '10px', display: 'flex', flexDirection: 'column' }}>
          {thought.isLoading || thought.isSaved ? <LoadingOverlay isLoading={thought.isLoading} isSaved={thought.isSaved} thoughtNumber={thoughtNumber} />

            : <>{thought.pages.length > 0 && (
              <Box sx={{ margin: '30px' }}>
                <CardContent sx={{ position: 'relative' }}>
                  <Slider {...sliderSettings}>
                    {thought.pages.map((page, pageIndex) => (
                      <Box key={pageIndex} sx={{ position: 'relative', width: '100%' }}> {/* Ensure this Box is relatively positioned and full width */}
                        <IconButton
                          aria-label="settings"
                          sx={{
                            position: 'absolute',
                            top: 15, // Adjust top positioning as needed
                            right: 15, // Adjust right positioning as needed
                            backgroundColor: 'white', // White background for the circle
                            color: 'black', // Icon color, adjust as needed
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Lighter white on hover for feedback
                            },
                            borderRadius: '50%', // Make it circular
                            padding: '5px', // Adjust padding to control the size of the circle
                            boxShadow: '0 2px 4px rgba(0,0,0,0.5)', // Optional shadow for better visibility
                          }}
                          onClick={(event) => handleClickMenu(event, thoughtIndex, pageIndex)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
                          <img src={page.downloadURL || page.temporaryUrl} loading="lazy" alt={`Page ${pageIndex + 1}`} style={{ maxWidth: '100%', width: 'auto', height: '100%', maxHeight: '30vh', display: 'block' }} />
                        </Box>
                      </Box>
                    ))}
                  </Slider>
                </CardContent>
              </Box>
            )}

              {/* Row for buttons */}
              <Box sx={{ display: 'flex', p: 1 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`file-input-thought-${thoughtIndex}`}
                  multiple
                  type="file"
                  onChange={(event) => handleImageUpload(event, thoughtIndex)}
                />
                <label htmlFor={`file-input-thought-${thoughtIndex}`} style={{ display: 'flex', flexGrow: '1' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CameraAltIcon />}
                    sx={{
                      textTransform: 'none',
                      flexGrow: 1,
                      margin: '5px',

                    }}
                  >
                    Scan notes
                  </Button>
                </label>
                <Dictaphone
                  textFieldText={thought.totalText}
                  appendTextFieldText={(newText) => updateThoughtTextFromDictaphone(newText, thoughtIndex)}
                  setTextFieldText={(newText) => handleTotalTextChange({ target: { value: newText } }, thoughtIndex)}
                />

              </Box>

              {/* Thought text area */}
              <AutoResizeTextarea
                value={thought.totalText}
                onChange={(e) => handleTotalTextChange(e, thoughtIndex)}
                placeholder="Type your thought here..."
              />

              {/* Row for Save and Share buttons */}
              <Box sx={{ display: 'flex', justifyContent: 'end', p: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={() => handleSaveThought(thoughtIndex)}
                  sx={{
                    textTransform: 'none',
                    margin: '5px',
                  }}
                >
                  Save Thought
                </Button>
              </Box></>}
        </Card>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, marginLeft: '30px' }}>
            <img src={`${process.env.PUBLIC_URL}/line_divider.png`} alt="Divider" style={{ width: '100%', height: 'auto' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '8px' }}>
            <IconButton
              aria-label="settings"
              onClick={(event) => handleMenuClick(event, thoughtIndex)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => {
                mergeAdjacentThoughts(selectedDividerIndex);
                handleMenuClose();
              }}>Remove divider & merge adjacent thoughts</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    ));
  }



  return (
    <Box style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {renderThoughts()}
      {/* <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file-new-thought"
        multiple
        type="file"
        onChange={(event) => handleImageUpload(event)} // No thoughtIndex passed, indicating a new thought
      />
      <label htmlFor="raised-button-file-new-thought">
        <Button variant="contained" color="secondary" component="span" startIcon={<CameraAltIcon />} sx={{ mt: 2 }} fullWidth>
          Scan a new page of notes
        </Button>
      </label> */}
      {isSplitting && renderSplitControl()}

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleRemovePage}>Delete this Page</MenuItem>
        <MenuItem onClick={() => initiateSplitImage(currentThoughtIndex, currentPageIndex)}>Split this Page</MenuItem> {/* Add this line */}
        <MenuItem onClick={handleAddPageBelow}>Scan in New Page</MenuItem>

      </Menu>
    </Box>
  );
};

export default NoteScannerTab;




