import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, Typography, Alert, Box, Tab, Tabs, Snackbar, CircularProgress, ThemeProvider, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { FIREBASE_AUTH, FIRESTORE_DB } from './firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { linkWithCredential, signInWithEmailAndPassword, EmailAuthProvider, signInAnonymously, sendPasswordResetEmail as firebaseSendPasswordResetEmail, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, fetchSignInMethodsForEmail, updateProfile } from 'firebase/auth'
import { useUser } from './UserContext';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOffIcon from '@mui/icons-material/PersonOff';

const UpgradeAccount = ({ state }) => {
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { setLoggedIn } = useUser();
  const [loading, setLoading] = useState(true); // add this line
  const navigate = useNavigate();
  const isAnon = FIREBASE_AUTH.currentUser && FIREBASE_AUTH.currentUser.isAnonymous;
  const theme = useTheme();
  const location = useLocation(); // Use the useLocation hook

  const thoughtID = location.state?.thoughtID || null;
  const thoughtText = location.state?.thoughtText || null;

  useEffect(() => {
    if (thoughtID && thoughtText) {
      console.log('Thought ID: ', thoughtID);
      console.log('Thought Text: ', thoughtText);
    }}, [thoughtID, thoughtText]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, async (user) => {
      if (user && !user.isAnonymous) {
        console.log('User is signed in');
        setLoggedIn(true);
        console.log('Thought ID: ', thoughtID);
        console.log('Thought Text: ', thoughtText);
        navigate('/homescreenv4', {
          state: {
            thoughtID: thoughtID,
            thoughtText: thoughtText
          }
        });
      } else {
        console.log('No user is signed in');
      }
      setLoading(false); // set loading to false after checking the user's sign-in state
    });

    return () => unsubscribe();
  }, [navigate, setLoggedIn]);


  const handleEmailLoginClick = () => {
    setShowEmailLogin(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const sendPasswordResetEmail = async (email) => {
    console.log('Sending password reset email...');
    if (email) {
      try {
        await firebaseSendPasswordResetEmail(FIREBASE_AUTH, email);
        console.log('Password reset email sent.');
        setError({ type: 'success', message: 'Password reset email sent. Please check your inbox.' });
      } catch (error) {
        console.log('Error sending password reset email: ', error.message);
        setError({ type: 'error', message: error.message });
      }
    } else {
      console.log('Please enter your email address.');
      setError({ type: 'warning', message: 'Please enter your email address.' });
    }
  };


  const handleSignUp = async () => {  
    try {
      // If the user is signed in anonymously, try upgrading the account.
      if (isAnon) {
        const emailCredential = EmailAuthProvider.credential(email, password);
        await linkWithCredential(FIREBASE_AUTH.currentUser, emailCredential);
        console.log('Anonymous account successfully upgraded to email/password account.');
      } else {
        // If the user isn't signed in or isn't signed in anonymously, create a new account.
        await createUserWithEmailAndPassword(FIREBASE_AUTH, email, password);
        console.log('New email/password account created.');
      }

      const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser.uid);
      const docSnapshot = await getDoc(docRef);


        updateProfile(FIREBASE_AUTH.currentUser, {
          displayName: displayName,
          photoURL: "https://firebasestorage.googleapis.com/v0/b/words-of-a-feather-f196f.appspot.com/o/profilePictures%2F0ADXUFIpkGPEgFT6PIm762RUo8P2?alt=media&token=2a6c08c5-0fc2-4772-90f4-0a9a03b044cc",
        }).then(async () => {
            const docRef = doc(FIRESTORE_DB, "Users", FIREBASE_AUTH.currentUser?.uid)
            const docSnapshot = await getDoc(docRef);

            if (!docSnapshot.exists() || docSnapshot.data().name === "Anon") {
            setDoc(docRef, {
              "name": displayName,
              "picURI": "https://firebasestorage.googleapis.com/v0/b/words-of-a-feather-f196f.appspot.com/o/profilePictures%2F0ADXUFIpkGPEgFT6PIm762RUo8P2?alt=media&token=2a6c08c5-0fc2-4772-90f4-0a9a03b044cc",
              "uid": FIREBASE_AUTH.currentUser?.uid,
              "bio": "I haven't written a bio yet!",
            }, { merge: true })
            .then(() => {
                navigate('/homescreenv4', {
                  state: {
                    thoughtID: thoughtID,
                    thoughtText: thoughtText
                  }
                })
            })
            .catch((error) => {
                console.error('Failed to update Firestore document: ', error);
            });
          } else {
            navigate('/homescreenv4', {
              state: {
                thoughtID: thoughtID,
                thoughtText: thoughtText
              }
            })
          }
        
        })
        .catch((error) => {
            console.error('Failed to update profile: ', error);
        });
        setLoggedIn(true);
        navigate('/homescreenv4', {
          state: {
            thoughtID: thoughtID,
            thoughtText: thoughtText
          }
        });
  
    } catch (error) {
      setError({ type: 'error', message: 'Error signing up: ' + error.message });
    }
  };

  

  const handleSignIn = () => {
    signInWithEmailAndPassword(FIREBASE_AUTH, email, password)
      .then(userCredentials => {
        const user = userCredentials.user;
        console.log("LOGGED INTO ", FIREBASE_AUTH.currentUser?.email)
        console.log('Logged in successfully');
        setLoggedIn(true);
        navigate('/homescreenv4', {
          state: {
            thoughtID: thoughtID,
            thoughtText: thoughtText
          }
        });
        
      })
      .catch(error => setError({ type: 'error', message: 'Wrong email or password, try again!' }));
  }

  const handleGoogleSignIn = async () => {
    const wasAnonymouslySignedIn = FIREBASE_AUTH.currentUser?.isAnonymous;
  
    const provider = new GoogleAuthProvider();
  
    try {
      const result = await signInWithPopup(FIREBASE_AUTH, provider);
      const user = result.user;
  
      if (wasAnonymouslySignedIn && result.additionalUserInfo.isNewUser) {
        await linkWithCredential(user, result.credential);
        console.log('Anonymous account successfully upgraded:', user);
      }
  
      const signInMethods = await fetchSignInMethodsForEmail(FIREBASE_AUTH, user.email);
      if (signInMethods.length > 1 || signInMethods[0] !== 'google.com') {
        setError({
          type: 'error', 
          message: 'This email is already associated with another sign-in method. Please use that to sign in.'
        });
        return;
      }
  
      console.log('User logged in with Google:', user);
  
      const docRef = doc(FIRESTORE_DB, "Users", user.uid);
      const docSnapshot = await getDoc(docRef);
      if (!docSnapshot.exists() || docSnapshot.data().name === "Anon") {
        await setDoc(docRef, {
          "name": user.displayName,
          "picURI": user.photoURL,
          "uid": user.uid,
          "bio": "",
        }, { merge: true });
        navigate('/homescreenv4', {
          state: {
            thoughtID: thoughtID,
            thoughtText: thoughtText
          }
        });
      }else{
        navigate('/homescreenv4', {
          state: {
            thoughtID: thoughtID,
            thoughtText: thoughtText
          }
        });
        setLoggedIn(true);
      }
        
    } catch (error) {
      setError({ type: 'error', message: 'Error signing in with Google: ' + error.message });
    }
  };


  return (
    <Box style={{ backgroundColor: theme.palette.background.default, height: '100vh'}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '50vh', paddingTop: '100px'}}
      >
        <Typography variant="body1" sx={{ fontSize: 16, p: 4, color: theme.palette.primary.main, textAlign: 'center' }}>
          You're about to share a thought. If you want to have your name and profile picture shared with it, and if you want to receive messages from people, please log in with a verified service.
        </Typography>
  
        {!loading ? (
          <>
            <Button
              variant="outlined"
              color='primary'
              style={{
                textTransform: 'none', 
                fontSize: '16px',
                height: '41px',
                backgroundColor: theme.palette.secondary.main, 
                width: '300px',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 16px'
              }}
              onClick={handleGoogleSignIn}
            >
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/google_icon.png`} alt="Google Icon" style={{ width: '30px', height: '30px', marginRight: '8px', position: 'absolute', left: '12px', background: '#ffffff', borderRadius: '20%' }} />
                <div>
                  Sign in with Google
                </div>
              </div>
            </Button>
  
            {!showEmailLogin ? (
              <>
                <Button
                  variant="outlined"
                  color='primary'
                  style={{
                    textTransform: 'none', 
                    fontSize: '16px',
                    height: '41px',
                    backgroundColor: theme.palette.secondary.main,
                    width: '300px',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 16px'
                  }}
                  onClick={handleEmailLoginClick}
                >
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <MailOutlineIcon style={{ width: '24px', height: '24px', marginRight: '8px', position: 'absolute', left: '16px' }} />
                    <div>
                      Sign in with Email
                    </div>
                  </div>
                </Button>
              </>
            ) : (
              <>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12} sm={8} md={6} lg={4}
              >                <TextField
                    fullWidth
                    label="Display Name"
                    value={displayName}
                    onChange={event => setDisplayName(event.target.value)}
                    variant="outlined"
                    margin="normal"
                    sx={{
                      width: '100%',
                      maxWidth: '90%',
                      mx: 'auto',
                  }}                  />
                  <TextField
                    fullWidth
                    label="Email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    variant="outlined"
                    margin="normal"
                    sx={{
                      width: '100%',
                      maxWidth: '90%',
                      mx: 'auto',
                  }}                  />
                  <TextField
                    fullWidth
                    label="Password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    type="password"
                    variant="outlined"
                    margin="normal"
                    sx={{
                      width: '100%',
                      maxWidth: '90%',
                      mx: 'auto',
                  }}                  />
                </Grid>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={handleSignUp}>Sign Up With Email</Button>
                </Box>
                <Box mt={1}>
                  <Button style={{ visibility: 'hidden' }}>Placeholder</Button>
                </Box>
              </>
            )}
          <Typography variant="body1" sx={{ fontSize: 16, p: 4, color: theme.palette.primary.main, textAlign: 'center' }}>
                  OR continue to share anonymously. Note that your thoughts will have no profile picture, the name will be "Anon" and other users will not be able to message you.
          </Typography>
          <Button
              variant="outlined"
              color='primary'
              style={{
                  textTransform: 'none',  // prevents automatic capitalization
                  fontSize: '16px',
                  height: '41px',
                  backgroundColor: theme.palette.secondary.main,  // white background
                  width: '300px', // making it same width as other buttons
                  marginBottom: '10px', // spacing between buttons
                  display: 'flex', // using flexbox
                  justifyContent: 'center', // center items horizontally
                  alignItems: 'center', // center items vertically
                  padding: '0 16px', // default MUI padding for buttons
                  marginBottom: '100px'
              }}
              onClick={() => navigate('/homescreenv4', {
                state: {
                  thoughtID: thoughtID,
                  thoughtText: thoughtText,
                  anonPostAllowed: true
                }
              })} // navigate to the homescreen
          >
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                  <PersonOffIcon style={{ width: '24px', height: '24px', marginRight: '8px', position: 'absolute', left: '16px' }} />
                  <div>
                      Share thought anonymously
                  </div>
              </div>
          </Button>

          </>
        ) : (
          <CircularProgress />
        )}
  
        {error && (
          <Alert severity={error.type} sx={{ width: '100%', mt: 2 }}>
            {error.message}
          </Alert>
        )}
      </Grid>
    </Box>
  );
};
  


export default UpgradeAccount;


