import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import './tabs.css';

const TabsTopBar = ({
    tabs,
    selectedTab,
    setSelectedTab,
    showDotOnManageTab,
    setShowDotOnManageTab
}) => {


    const handleTabClick = (index) => {
        setSelectedTab(index);
    }

    useEffect(() => {
        if (tabs.filter(tab => tab.enabled)[selectedTab].id === 'manageTabs') {
            setShowDotOnManageTab(false);
        }
    }
    , [selectedTab, tabs]);


    return (
        <ul className="tabrow group">
            {tabs.filter(tab => tab.enabled).map((tab, index) => (
                <li key={tab.id} 
                className={`group ${selectedTab === index ? 'selected' : ''} ${tab.id === 'manageTabs' ? 'manageTabs' : ''} ${tab.id === 'manageTabs' && showDotOnManageTab ? 'colorCycle' : ''}`}
                onClick={() => handleTabClick(index)}>
                    {tab.title}

                </li>
            ))}
        </ul>
    );
    
    
}


export default TabsTopBar;