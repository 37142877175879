import React from 'react';
import { Typography, Box } from '@mui/material';

const HowToUse = () => {
    return(
        <Box p={3}>
            <Box mb={3}>
                <Typography variant="h2">
                    🌌 How to Harness the Power of Thought Portals
                </Typography>
            </Box>

            <Typography variant="body1" paragraph>
                When you search on the home screen by typing in a thought and clicking 'search', you'll be matched with similar thoughts from others. That's it, in a sentence.
            </Typography>
            <Typography variant="body1" paragraph>
                We celebrate authenticity, and we'd never dare guess what's on your mind. 🧠✨
                When you send out a search into thought portals, the universe responds with what matches your vibe. Say "Hello", and you'll see a bunch of Hello's welcoming you. Say something more unique, and explore what the rest of the world thinks. 🌟
                But oh, the wonders don’t end there! Dive deeper, look for unique ideas, and you might just find a kindred spirit. Feel a connection? Go on, reach out, and let the galaxies collide! 🌌🤝
                If sometimes the stars don’t align and your search feels a tad off, remember we're still growing, learning from every shared thought. Every shared thought is a step closer to the world coming together 🌠
                Beyond exploration, you can be the beacon for others. Share, contribute, and let your thoughts shimmer in this vast expanse. Who knows? Someone might just resonate with your frequency and connect.🛰️💌
           
            </Typography>

            <Box mb={3} mt={5}>
                <Typography variant="h3">
                    📣 Advertise Yourself – Free and With Flair!
                </Typography>
            </Box>

            <Typography variant="body1" paragraph>
                Showcase yourself, through your social media links, or your YouTube account, or your website. Simply share thoughts that capture your essence, embed your links in your bio (click on your profile picture to edit your profile page), and voila! Every time someone feels a tug towards your thought and clicks on your profile, they find a gateway to explore more of your universe. Let’s get you discovered by those who vibe on your frequency! 🚀
            </Typography>

            <Box mb={3} mt={5}>
                <Typography variant="h3">
                    🕵️ Advanced Features - Dive Deeper with Automatic Searching
                </Typography>
            </Box>

            <Typography variant="body1" paragraph>
                On your 'My thoughts' page or the 'Home' page, if a magnifying glass 🧐 beckons beside a thought, it's an invitation! Tap it and get whisked away on a journey to find musings that dance to the same rhythm. A curated cosmos, just for you!
            </Typography>

            <Box mb={3} mt={5}>
                <Typography variant="h3">
                    📝 Advanced Features - Chronicle and Sculpt Your Thoughts
                </Typography>
            </Box>

            <Typography variant="body1" paragraph>
                Every thought is a star, unique and radiant. Wish to revisit or mold it anew? Simply add your musing to 'My thoughts', or hit save when inspiration strikes on the home page. Your constellations await, ready to be explored and admired! ⭐️🔭
            </Typography>

            <Box mb={3} mt={5}>
                <Typography variant="h3">
                    🌙 Advanced Features - Dive into the Abyss with Dark Mode & More
                </Typography>
            </Box>

            <Typography variant="body1">
                Dive into the tranquility of the night with our Dark Mode or explore other features from your settings page. 🌌🛸
            </Typography>

            <Box mb={3} mt={5}>
                <Typography variant="h3">
                    Contact us
                </Typography>
            </Box>

            <Typography variant="body1">
                Either find Elijah De Calmer on this platform and send a message with your issues, feature requests or suggestions, or send an email to thoughtportals.app@gmail.com
            </Typography>
        </Box>
    );
}

export default HowToUse;
