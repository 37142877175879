import React, { useState, useEffect } from "react";
import { Box, TextareaAutosize, Button, IconButton, useTheme, Typography } from "@mui/material";
import PaletteIcon from '@mui/icons-material/Palette';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const ZenShareSaveTab = ({ shareThought, saveThought, thoughtNumber }) => {
    const sentenceStarters = [
        "Today I feel...",
        "One thing I learned today is...",
        "My main goal for tomorrow is...",
        "I am grateful for...",
        "A challenge I faced today was...",
        "Right now, I’m thinking about...",
        "My favorite memory is...",
        "I’m looking forward to...",
        "One thing I can’t live without is...",
        "The best part of my day is...",
        "I feel happiest when...",
        "A book I really enjoyed recently is...",
        "A goal I have is...",
        "A person who inspires me is...",
        "An important lesson I’ve learned is...",
        "Something that makes me unique is...",
        "I relax by...",
        "A skill I’d like to learn is...",
        "A place I’d love to visit is...",
        "Something I’m proud of is...",
        "My favorite hobby is...",
        "A movie I could watch over and over is...",
        "A dream I have is...",
        "My favorite childhood memory is...",
        "One thing that worries me is...",
        "I feel most comfortable when...",
        "Something I’ve always wanted to do is...",
        "If I could meet anyone, it would be...",
        "My favorite food is...",
        "Something funny that happened today is...",
        "A subject I enjoy studying is...",
        "If I had a million dollars, I would...",
        "My best friend is special because...",
        "Something I’d like to change about myself is...",
        "A place that makes me happy is...",
        "An artist or band I love is...",
        "A habit I’d like to break is...",
        "A subject I’d like to learn more about is...",
        "Something I do well is...",
        "If I could travel back in time, I would go to...",
        "My favorite way to spend the day is...",
        "A question I have about the world is...",
        "Something I’m looking forward to learning is...",
        "If I could have any superpower, it would be...",
        "My role model is...",
        "Something I’m afraid of is...",
        "If I could change one thing about the world, it would be...",
        "My favorite season is...",
        "A new activity I’d like to try is...",
        "One thing that makes me smile is...",
        "An idea that excites me is...",
        "A creative solution I thought of recently is...",
        "One way I could improve myself is...",
        "A complex problem I’m dealing with is...",
        "An unsolvable problem I think about is...",
        "When I’m creative, I like to...",
        "A project I’d love to start is...",
        "A skill that would help me in life is...",
        "A habit that boosts my creativity is...",
        "An invention I wish existed is...",
        "A book that changed my thinking is...",
        "If I could solve one world problem, it would be...",
        "A leader I admire for their creativity is...",
        "A historical event that fascinates me is...",
        "A scientific discovery that I find amazing is...",
        "A philosophy I live by is...",
        "If I could create a new law, it would be...",
        "A career path I find intriguing is...",
        "A country whose culture fascinates me is...",
        "An artist whose work challenges me is...",
        "A personal trait I want to develop is...",
        "A technology that could change the world is...",
        "A language I’d love to learn is...",
        "A mystery of the universe I ponder about is...",
        "An everyday item I could redesign is...",
        "An unconventional way to tackle a common problem is...",
        "A business idea I think could succeed is...",
        "A scientific question I want an answer to is...",
        "A historical mystery I find intriguing is...",
        "A strategy I use to overcome creative blocks is...",
        "A societal change I wish to see in the next decade is...",
        "A technology that could revolutionize daily life is...",
        "An ethical dilemma that challenges me is...",
        "A cultural tradition from another country I find fascinating is...",
        "A fictional character I relate to is...",
        "An alternative energy source I believe in is...",
        "A psychological concept that helps me understand others is...",
        "A habit that contributes to my personal growth is...",
        "A piece of advice that transformed my perspective is...",
        "An environmental issue I am passionate about is...",
        "A form of art I’d like to explore is...",
        "A scientific experiment I’d love to conduct would be about...",
        "A historical figure I’d like to have a conversation with is...",
        "A global issue that doesn’t get enough attention is...",
        "A personal challenge that motivates me is...",
        "A futuristic invention I wish existed is...",
        "A philosophical question that intrigues me is...",
        "A simple invention that could improve everyday life is...",
        "A skill crucial for future generations is...",
        "A surprising fact I recently learned is..."
    ];
    
    const [thoughts, setThoughts] = useState([
        { text: "", responseText: "", placeholderIndex: Math.floor(Math.random() * sentenceStarters.length), status: 'active' }
    ]);
    const theme = useTheme();

    const handleTextChange = (text, index) => {
        const newThoughts = [...thoughts];
        newThoughts[index].text = text;
        setThoughts(newThoughts);
    };

    const handleShare = async (index) => {
        const newThoughts = [...thoughts];
        newThoughts[index].responseText = "Sharing thought...";
        setThoughts(newThoughts);

        const trimmedText = thoughts[index].text.trim();
        if (trimmedText !== "") {
            newThoughts[index].responseText = await shareThought(trimmedText);
            setThoughts(newThoughts);
        }
        newThoughts[index].status = 'shared';
        setThoughts(newThoughts);
        addNewThought();
    };

    const handleSave = async (index) => {
        const newThoughts = [...thoughts];
        newThoughts[index].responseText = "Saving thought...";
        setThoughts(newThoughts);

        const trimmedText = thoughts[index].text.trim();
        if (trimmedText !== "") {
            newThoughts[index].responseText = await saveThought(trimmedText);
            setThoughts(newThoughts);
        }
        newThoughts[index].status = 'saved';
        setThoughts(newThoughts);
        addNewThought();
    };
    
    const hideThought = (index) => {
        setThoughts(thoughts.filter((_, i) => i !== index));
    };

    const addNewThought = () => {
        const randomIndex = Math.floor(Math.random() * sentenceStarters.length);
        setThoughts([...thoughts, { 
            text: "", 
            responseText: "", 
            placeholderIndex: randomIndex,
            status: 'active'  // Ensure this is set for new thoughts
        }]);
    };
    
    

    const removeLastThought = () => {
        if (thoughts.length > 1) {
            setThoughts(thoughts.slice(0, -1));
        }
    };


    

    const handlePromptClick = (index) => {
        setThoughts(thoughts.map((thought, i) => {
            if (i === index) {
                return {
                    ...thought,
                    placeholderIndex: (thought.placeholderIndex + 1) % sentenceStarters.length
                };
            }
            return thought;
        }));
    };
    
    const lightPalettes = [
        // Pastel color palettes (light backgrounds)
        { background: '#B0E0E6', textArea: '#D8F3DC', button: '#3d5a80', buttonText: '#ffffff', textColor: '#000000' }, // Powder Blue
        { background: '#FFDEAD', textArea: '#FFEFD5', button: '#FF7F50', buttonText: '#000000', textColor: '#000000' }, // Navajo White
        { background: '#957DAD', textArea: '#E0BBE4', button: '#D291BC', buttonText: '#ffffff', textColor: '#000000' }, // Lavender Floral
        { background: '#FFCDB2', textArea: '#FFB4A2', button: '#E5989B', buttonText: '#000000', textColor: '#000000' }, // Light Coral
        { background: '#C8E7F7', textArea: '#A2D2FF', button: '#457B9D', buttonText: '#ffffff', textColor: '#000000' }, // Pale Cerulean
        { background: '#FFAFCC', textArea: '#FFC8DD', button: '#FF99AC', buttonText: '#000000', textColor: '#000000' }, // Light Pink
        { background: '#BDB2FF', textArea: '#A0C4FF', button: '#9BF6FF', buttonText: '#000000', textColor: '#000000' }, // Light Periwinkle
        { background: '#A0C4FF', textArea: '#BDB2FF', button: '#8ECAE6', buttonText: '#000000', textColor: '#000000' }, // Celestial Blue
        { background: '#F4EEFF', textArea: '#D4A5A5', button: '#9D8189', buttonText: '#000000', textColor: '#000000' }, // Magnolia
        { background: '#F0EFEB', textArea: '#E8E8E4', button: '#A7C4BC', buttonText: '#000000', textColor: '#000000' }, // Isabelline
        // Additional light color palettes
        { background: '#E6E6FA', textArea: '#FFF0F5', button: '#DB7093', buttonText: '#000000', textColor: '#000000' }, // Lavender
        { background: '#FFF5EE', textArea: '#FFEFDB', button: '#FFDEAD', buttonText: '#000000', textColor: '#000000' }, // Seashell
        { background: '#F0FFF0', textArea: '#FFFACD', button: '#FFEFD5', buttonText: '#000000', textColor: '#000000' }, // Honeydew
        { background: '#F5FFFA', textArea: '#F0FFFF', button: '#AFEEEE', buttonText: '#000000', textColor: '#000000' }, // Mint Cream
        { background: '#FFFAFA', textArea: '#F5F5F5', button: '#DCDCDC', buttonText: '#000000', textColor: '#000000' }, // Snow
        { background: '#FFF8DC', textArea: '#FFFAF0', button: '#FDF5E6', buttonText: '#000000', textColor: '#000000' }, // Cornsilk
        { background: '#F5F5DC', textArea: '#FFFFF0', button: '#FFFFE0', buttonText: '#000000', textColor: '#000000' }, // Beige
        { background: '#FFEBF0', textArea: '#FFF0F5', button: '#FFC0CB', buttonText: '#000000', textColor: '#000000' }, // Pink Lace
        { background: '#F0F8FF', textArea: '#E6E6FA', button: '#B0C4DE', buttonText: '#000000', textColor: '#000000' }, // Alice Blue
        { background: '#E3F2FD', textArea: '#BBDEFB', button: '#90CAF9', buttonText: '#000000', textColor: '#000000' }, // Light Blue

    ];

    const darkPalettes = [
        { background: '#333333', textArea: '#4F4F4F', button: '#FFD700', buttonText: '#ffffff', textColor: '#ffffff' }, // Dark Charcoal
        { background: '#0D253F', textArea: '#1B4F72', button: '#FFC300', buttonText: '#ffffff', textColor: '#ffffff' }, // Oxford Blue
        { background: '#1C2833', textArea: '#2E4053', button: '#E74C3C', buttonText: '#ffffff', textColor: '#ffffff' }, // Gunmetal
        { background: '#17202A', textArea: '#273746', button: '#E8C547', buttonText: '#ffffff', textColor: '#ffffff' }, // Eerie Black
        { background: '#2C3E50', textArea: '#34495E', button: '#B03A2E', buttonText: '#ffffff', textColor: '#ffffff' }, // Charcoal
        { background: '#424949', textArea: '#5D6D7E', button: '#F39C12', buttonText: '#ffffff', textColor: '#ffffff' }, // Outer Space
        { background: '#4A235A', textArea: '#633974', button: '#7D3C98', buttonText: '#ffffff', textColor: '#ffffff' }, // Dark Purple
        { background: '#154360', textArea: '#1A5276', button: '#1F618D', buttonText: '#ffffff', textColor: '#ffffff' }, // Prussian Blue
        { background: '#1B2631', textArea: '#212F3C', button: '#5499C7', buttonText: '#ffffff', textColor: '#ffffff' }, // Dark Blue Gray
        { background: '#641E16', textArea: '#78281F', button: '#A93226', buttonText: '#ffffff', textColor: '#ffffff' },  // Dark Sienna
        // Additional dark color palettes
        { background: '#3C3B3D', textArea: '#636363', button: '#FF6347', buttonText: '#ffffff', textColor: '#ffffff' }, // Dark Gray
        { background: '#2B2B2B', textArea: '#4C4C4C', button: '#FF4500', buttonText: '#ffffff', textColor: '#ffffff' }, // Onyx
        { background: '#37474F', textArea: '#546E7A', button: '#78909C', buttonText: '#ffffff', textColor: '#ffffff' }, // Blue Gray
        { background: '#263238', textArea: '#37474F', button: '#607D8B', buttonText: '#ffffff', textColor: '#ffffff' }, // Charcoal
        { background: '#212121', textArea: '#424242', button: '#757575', buttonText: '#ffffff', textColor: '#ffffff' }, // Eclipse
        { background: '#1C2022', textArea: '#455A64', button: '#546E7A', buttonText: '#ffffff', textColor: '#ffffff' }, // Outer Space
        { background: '#484848', textArea: '#616161', button: '#9E9E9E', buttonText: '#ffffff', textColor: '#ffffff' }, // Dim Gray
        { background: '#3E2723', textArea: '#5D4037', button: '#795548', buttonText: '#ffffff', textColor: '#ffffff' }, // Coffee
        { background: '#1B5E20', textArea: '#2E7D32', button: '#388E3C', buttonText: '#ffffff', textColor: '#ffffff' }, // Dark Green
        { background: '#0D47A1', textArea: '#1565C0', button: '#1E88E5', buttonText: '#ffffff', textColor: '#ffffff' }, // Dark Blue

            ];
    
    
    const [paletteIndex, setPaletteIndex] = useState(0);

    useEffect(() => {
        const savedPaletteIndex = localStorage.getItem('zenShareSavePaletteIndex');
        if (savedPaletteIndex !== null) {
            const index = parseInt(savedPaletteIndex);
            const paletteArray = theme.palette.mode === 'dark' ? darkPalettes : lightPalettes;
            setPaletteIndex(index % paletteArray.length); // Adjust index to the length of the current palette array
        }
    }, [theme.palette.mode]); // Add theme.palette.mode as a dependency

    const isDarkMode = theme.palette.mode === 'dark';
    const currentPalette = isDarkMode ? darkPalettes[paletteIndex] : lightPalettes[paletteIndex];

    const toggleBackgroundColor = () => {
        setPaletteIndex(prevIndex => {
            const newIndex = (prevIndex + 1) % (isDarkMode ? darkPalettes.length : lightPalettes.length);
            localStorage.setItem('zenShareSavePaletteIndex', newIndex.toString());
            return newIndex;
        });
    };

    return (
        <Box
            sx={{
                backgroundColor: currentPalette.background,
                transition: 'background-color 1s ease',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: theme.spacing(2),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[1],
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginBottom: theme.spacing(2),
                }}
            >
                <IconButton 
                    onClick={toggleBackgroundColor} 
                    sx={{ color: currentPalette.button, marginRight: theme.spacing(1) }}
                >
                    <PaletteIcon />
                </IconButton>
                <IconButton 
                    onClick={addNewThought} 
                    sx={{ color: currentPalette.button, marginRight: theme.spacing(1) }}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
                <IconButton 
                    onClick={removeLastThought} 
                    sx={{ color: currentPalette.button }}
                    disabled={thoughts.length <= 1}
                >
                    <RemoveCircleOutlineIcon />
                </IconButton>
            </Box>

            {thoughts.map((thought, index) => (
                <Box key={index} sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >

                    <TextareaAutosize
                        minRows={thought.status === 'active' ? 3 : 1}
                        placeholder={sentenceStarters[thought.placeholderIndex]}
                        value={thought.text}
                        onChange={(e) => handleTextChange(e.target.value, index)}
                        disabled={thought.status !== 'active'}
                        style={{
                            width: '100%',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                            paddingTop: '1rem',
                            paddingBottom: '1rem',
                            marginBottom: theme.spacing(2),
                            backgroundColor: currentPalette.textArea,
                            borderColor: theme.palette.divider,
                            borderRadius: theme.shape.borderRadius,
                            color: theme.palette.text.primary,
                            transition: 'background-color 1s ease',
                            outline: 'none',
                            fontSize: '1rem',
                            lineHeight: '1.5', // Adjust line height as needed
                        }}
                    />
                                            {thought.status !== 'active' && (

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="body2" sx={{ marginX: theme.spacing(1) }}>
                                {thought.status === 'saved' ? 'Saved' : 'Shared'}
                            </Typography>
                            <CheckCircleIcon color="success" />
                        </Box>

                            <IconButton onClick={() => hideThought(index)} sx={{ color: theme.palette.grey[500] }}>
                        <VisibilityOffIcon />
                    </IconButton>
                        </Box>
                                            )}

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >

                        {thought.status === 'active' && (
                        <>
                        <Box>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={!thought.text.trim()}
                            onClick={() => handleSave(index)}
                            sx={{ 
                                marginRight: theme.spacing(1),
                                backgroundColor: currentPalette.button,
                                color: currentPalette.buttonText,
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={!thought.text.trim() || thought.responseText === "Sharing thought..."}
                            onClick={() => handleShare(index)}
                            sx={{ 
                                marginRight: theme.spacing(1),
                                backgroundColor: currentPalette.button,
                                color: currentPalette.buttonText,
                            }}
                        >
                            Share
                        </Button>
                        </Box>
                        <IconButton 
                            onClick={() => handlePromptClick(index)} 
                            sx={{ color: currentPalette.button }}
                        >
                            <LightbulbIcon />
                        </IconButton>
                        </>
                        )}

                    </Box>
                    {thought.status === 'active' && (
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: theme.spacing(2) }}>
                            {thought.responseText}
                        </Typography>
                    )}
                </Box>
            ))}

        </Box>
    );
};

export default ZenShareSaveTab;


