import React from 'react';

function AboutUs() {
    return (
        <div>
            <h1>About Us</h1>
            <p>
                Words of a Feather, founded and developed by Elijah De Calmer. First launched June 8th.
            </p>

            <h2>Contact Info</h2>
            <p>For any queries, suggestions, or support, feel free to get in touch with us:</p>
            <p>
                General Information: <a href="mailto:wordsofafeather.app@gmail.com">wordsofafeather.app@gmail.com</a>
            </p>
            <p>
                Developer, Founder, CEO Contact: <a href="mailto:elijahdecalmer@gmail.com">elijahdecalmer@gmail.com</a>
            </p>
        </div>
    );
}

export default AboutUs;
