import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { getStorage, ref as firebaseRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CameraAlt from '@mui/icons-material/CameraAlt';
import MicIcon from '@mui/icons-material/Mic';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTheme } from '@mui/material/styles';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import LoadingComponent from '../../../LoadingComponent';
import BulkUploadThoughts from './BulkUploadThoughts';
import Dictaphone from './Dictaphone';


const EditThoughtTextArea = forwardRef(({
    setImage,
    maxHeight,
    isLoading,
    setIsLoading,
    textFieldText,
    setTextFieldText,
    showUploadBulkNotes,
    setShowUploadBulkNotes,
    showDictaphone,
    setShowDictaphone
}, ref) => {
    const theme = useTheme();
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState('');
    

    return (
        <>
            <div style={{
                width: '100%',
                maxHeight: maxHeight,
                overflow: 'auto',
                scrollbarWidth: 'none', // for Firefox
                '-ms-overflow-style': 'none', // for IE and Edge
                '&::-webkit-scrollbar': {
                    display: 'none' // for Chrome, Safari, and newer versions of Opera
                }
            }}>
                {isLoading ?
                    <CircularProgress /> :
                    <div>
                        {!showUploadBulkNotes && (
                                            <TextareaAutosize
                                            aria-label="empty textarea"
                                            placeholder="Start typing your note..."
                                            ref={ref}
                                            value={textFieldText}
                                            onChange={(e) => {
                                                setTextFieldText(e.target.value);
                                            }}
                                            style={{
                                                width: '100%',
                                                minHeight: '100px',
                                                background: 'none',
                                                border: 'none',
                                                outline: 'none',
                                                resize: 'none',
                                                paddingRight: '10px',
                                                paddingLeft: '10px',
                                                fontFamily: theme.typography.fontFamily, // Use global font family
                                                fontSize: theme.typography.body1.fontSize, // Use global text size
                                                color: theme.palette.text.primary,
                                                overflowY: 'hidden' // Hide vertical scrollbar
                                            }}
                                        />
                    
                    )}
                    </div>

                }
            </div>
                {!textFieldText && !showUploadBulkNotes && (
                    <Box sx={{
                        flexDirection: 'column',
                        display: 'flex',
                        // need to push children to theleft
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        minWidth: '100%',
                    }}>
                        <Button
                            component="cameraAlt"
                            disabled={isLoading}
                            style={{
                                marginTop: '10px',
                                textTransform: 'none',
                            }}
                        >
                            <CameraAlt style={{ marginRight: '5px' }} />
                            
                            Extract text from handwritten notes
                            <input
                                type="file"
                                accept="image/*"
                                capture="camera"
                                hidden
                                onChange={(event) => setImage(event.target.files[0])}
                            />
                        </Button>
                        <Button
                            component="label"
                            disabled={isLoading}
                            style={{
                                marginTop: '10px',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                setShowDictaphone(true);
                            }
                            }
                        > 
                            <MicIcon style={{ marginRight: '5px' }} />
                            Speak your mind


                        </Button>
                        <Button
                            component="label"
                            disabled={isLoading}
                            style={{
                                marginTop: '10px',
                                textTransform: 'none',
                            }}
                            onClick={() => {
                                setShowUploadBulkNotes(true);
                                setUploadSuccess(false);
                            }}
                        >
                            <UploadFileIcon style={{ marginRight: '5px' }} />
                            Upload bulk notes (from other apps)
                        </Button>
                    </Box>


                )}
                {showDictaphone && (
                    <Dictaphone textFieldText={textFieldText} setTextFieldText={setTextFieldText} />
)}



                {showUploadBulkNotes && (
                    <BulkUploadThoughts
                        uploadSuccess={uploadSuccess}
                        setUploadSuccess={setUploadSuccess}
                        uploadedFileName={uploadedFileName}
                        setUploadedFileName={setUploadedFileName}
                        setIsLoading={setIsLoading}
                    />
                )}
        </>

    );
});

export default EditThoughtTextArea;