import React, { useState, useEffect, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import MicIcon from '@mui/icons-material/Mic';

const Dictaphone = ({ textFieldText, appendTextFieldText, setTextFieldText }) => {
  const [isListening, setIsListening] = useState(false);
  const theme = useTheme();
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const lastTranscriptIndex = useRef(0);
  const processInterval = useRef(null);

  const processText = (text) => {
    let processedText = text;
    const punctuationRegex = /(?:\s+|^)(comma|period|full stop|fullstop|question mark|exclamation mark)(?:\s+|$)/g;
    const punctuationMap = {
      'comma': ', ',
      'period': '. ',
      'full stop': '. ',
      'fullstop': '. ',
      'question mark': '? ',
      'exclamation mark': '! ',
    };

    processedText = processedText.replace(punctuationRegex, (match, p1) => punctuationMap[p1]);

    processedText = processedText.replace(/ \S*backspace /g, '');

    return processedText.trim();
  };

  useEffect(() => {
    if (isListening) {
      const newTranscript = transcript.slice(lastTranscriptIndex.current);
      if (newTranscript.length > 0) {
        appendTextFieldText(newTranscript);
        lastTranscriptIndex.current = transcript.length;
      }
    }
  }, [transcript, isListening]);

  useEffect(() => {
    if (isListening) {
      // Process the entire text every 1000 milliseconds
      processInterval.current = setInterval(() => {
        setTextFieldText(processText(textFieldText));
      }, 1000);
    } else {
      if (processInterval.current) {
        clearInterval(processInterval.current);
      }
    }

    return () => {
      if (processInterval.current) {
        clearInterval(processInterval.current);
      }
    };
  }, [isListening, setTextFieldText]);

  const handleListening = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    } else {
      resetTranscript();
      lastTranscriptIndex.current = 0;
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <p>Browser doesn't support speech recognition.</p>;
  }

  return (
      <Button
        variant="contained"
        color="primary"
        component="span"
        onClick={handleListening}
        startIcon={<MicIcon />}

        style={{ textTransform: 'none', backgroundColor: isListening ? theme.palette.error.main : '',
        flexGrow: 1,
        margin: '5px',
    }}
      >
        {isListening ? 'Stop Listening' : 'Start listening'}
      </Button>
  );
};

export default Dictaphone;
