import { FIREBASE_AUTH, FIRESTORE_DB } from "../../../firebaseConfig";
import { getStorage, ref as firebaseRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, onSnapshot, query, where, orderBy, serverTimestamp } from "firebase/firestore";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";





const BulkUploadThoughts = ({
    uploadSuccess,
    setUploadSuccess,
    uploadedFileName,
    setUploadedFileName,
    setIsLoading
}) =>{
    const user = FIREBASE_AUTH.currentUser;
    const [bulkUploads, setBulkUploads] = useState([]); 

    useEffect(() => {
        if (user) {
            const bulkUploadsColl = collection(FIRESTORE_DB, "Bulk uploads");
            // Create a query against the collection, filtering by uid and ordering by uploadTimestamp
            const q = query(bulkUploadsColl, where("uid", "==", user.uid), orderBy("uploadTimestamp", "desc"));
    
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const uploads = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setBulkUploads(uploads);
            });
    
            // Unsubscribe from the listener when the component unmounts
            return () => unsubscribe();
        }
    }, [user]);
    

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Upload file to Firebase Cloud Storage
            setIsLoading(true);
            const storage = getStorage();
            const filePath = `bulkUploads/${user.uid}/${file.name}`;
            console.log('filePath:', filePath);
            const storageRef = firebaseRef(storage, filePath);
            console.log('storageRef:', storageRef);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
    
            // Create document in Firestore under the collection "Bulk uploads"
            const bulkUploadsColl = collection(FIRESTORE_DB, "Bulk uploads");
            await addDoc(bulkUploadsColl, {
                uid: FIREBASE_AUTH.currentUser.uid,
                name: FIREBASE_AUTH.currentUser.displayName,
                email: FIREBASE_AUTH.currentUser.email,
                fileName: file.name,
                fileURL: downloadURL,
                uploadTimestamp: serverTimestamp(),
                status: "Pending"

            });
            setIsLoading(false);
            setUploadSuccess(true);
            setUploadedFileName(file.name);
    
            console.log('File uploaded and document created:', file.name);
    
    
        }
    };

    




    return(
        <Box sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            minWidth: '100%',
            marginLeft: '20px',
            marginTop: 2, // adds some top margin for spacing
            marginBottom: 2, // adds some bottom margin for spacing
        }}>
            <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
                📂 Upload Bulk Notes
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                Upload your notes here 📝 (e.g., from other apps). Our system will organize them into separate entries for you. Get started quickly and efficiently!
            </Typography>
            <input
                type="file"
                accept=".pdf, .doc, .docx, .txt, .rtf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, application/zip"
                onChange={handleFileChange}
                style={{ marginBottom: '10px' }} // adds some bottom margin for spacing
            />
            {uploadSuccess && (
                <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                    ✅ Success! Check your email for updates (please allow up to 24hrs). Uploaded File: {uploadedFileName}
                </Typography>
            )}

            <Typography variant="body1" component="p" sx={{ mt: 8 }}>
                📂 Previous bulk uploads:
            </Typography>
            <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                {bulkUploads.map(upload => (
                    <div key={upload.id}>
                        {upload.fileName} - {upload.status}
                    </div>
                ))}
            </Typography>

            
        </Box>
    );

}

export default BulkUploadThoughts;